/**
 *
 *   Metadatas.
 *
 *@author TxTony
 *@version 1.0
 *@license MIT
 *@class
 */
class Metadatas {
    /**
     *@constructor
     * @example
     *  // __filters = {
     *  // "type":{"attr":"c.type","colId": "type", "value": null, "action": "equals"},
     *  // "endDate": {"attr":"endDate", "colId": "endDate", "value": null, "action":"lessThan"},
     *  // "path": {"attr":"path", "colId": "path", "value": null, "action":"contains"},
     *  // "categorie": {"attr":"libelleCatgorie", "colId": "libelleCatgorie", "value": null, "action":"contains"}
     *  // }
     * @param object __filters
     * @param string name
     */
    constructor(name=null) {
        this.name = name || null;
        this.filters = {};
        this.directives = [];
        this.counters = {"All":0};
        this.columns = {};
        return this;
    }
    /**
    *
    * @param string name
    * @param string|integer value
    * @param string action default is equals
    *
    */
    setFilter(name, value, action="equals"){
        this.filters[name] = {"attr":name,"colId": name, "value": value, "action": action};
        return this;
    }
    /**
    *
    * @param array filters
    * @return object
    */
    setFiltersFromArray(filters){
        if(!filters.length) {
            this.clearAllFilters();
        }
        let filtersNormalized = {};
        filters.forEach((filter, i) => {
            filtersNormalized[filter["colId"]?filter["colId"]:filter["attr"]] = filter;
        });
        this.setFilters(filtersNormalized);
        //this.filters[name] = {"attr":name,"colId": name, "value": value, "action": action};
        return this.filters;
    }
    /**
    *
    * @param integer name
    * @param string | number value
    * @param string action default is equals
    *
    */
    setLimit(offset, limit){
        let limitDirectiveIndex = this.directives.findIndex((directive) => directive.name == "LIMIT");
        if(limitDirectiveIndex != -1){
            this.directives[limitDirectiveIndex].offset = offset;
            this.directives[limitDirectiveIndex].limit = limit;
        }else{
            this.directives.push({"name":"LIMIT","offset":offset,"limit":limit});
        }

    }
    /**
    * Return true if directive limit exist.
    *
    * @return boolean
    */
    isLimitSet(){
        return this.directives.findIndex((directive) => directive.name == "LIMIT") != -1;
    }
    /**
    * Return true if a filter exists in filters object.
    * @param name
    * @return boolean
    */
    filterExist(name){
        return this.filters.hasOwnProperty(name);
    }
    /**
    * Get a counter by its name. it's an object where the key is the name of the counter,
    * and the value is an integer.
    *
    * @param counter name of the counter
    * @return integer
    */
    getCounter(counter){
        return this.counters[counter];
    }
    /**
    * Delete filter.
    *
    * @param String name
    */
    deleteFilter(name){
        delete this.filters[name];
    }
    /**
    * Delete all current filters.
    *
    * @param String name
    * @return this
    */
    clearAllFilters(){
        this.filters = {};
        return this;
    }
    /**
    * Get value from filter.
    *
    * @param String name
    * @return Any
    */
    getFilterValue(name){
        return this.filters[name].value;
    }
    /**
    * Get action from filter.
    *
    * @param String name
    * @return Any
    */
    getFilterAction(name){
        return this.filters[name].action;
    }
    /**
    * If this metadatas has a name it can be stored.
    * @return boolean
    */
    isStorable(){
        return this.name ? true : false;
    }
    /**
    * Format Metadatas according to server expectation.
    * @return Object
    */
    get(){
        // remove filters where value is null
        let names = Object.keys(this.filters);
        names.forEach((name, i) => {
            if(!this.filters[name].value || this.filters[name].value.length==0){
                delete this.filters[name];
            }
        });
        // le get values doit retourner les filtres sans les valeurs null
        // par contre setFilters doit store les filters tel quel
        return {
            "directives":this.directives,
            "filters":Object.values(this.filters),
            "columns":this.columns
        };
    }

    // Setters-Getters
    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*/
    /**
    * Setter.
    * @param string name
    * @return this
    */
    setName(name){
        this.id = id;
        return this;
    }
    /**
    * HasStoredFilterEqualsTo.
    * Compare given filters with this.filters.
    * @param object filters
    * @return boolean send false if there are differences
    */
    hasStoredFilterEqualsTo(filters){
        filters = JSON.stringify(filters);
        return JSON.stringify(this.filters) == filters ? true : false;
    }
    /**
    * Getter.
    * @return string
    */
    getName(){
        return this.name;
    }
    /**
    * Setter.
    * Columns will be used to format datas output of query
    * @example {
        "e.qrCode": "code",
        "tagEquipement": null
    }
    * @param string columns
    * @return Metadatas
    *
    */
    setColumns(columns){
        this.columns = columns;
        return this;
    }
    /**
    * Setter.
    * @example
    * // {"All":0,"c1":123,"c2":5}
    * @param Object counters
    * @return Metadatas
    */
    setCounters(counters){
        this.counters = counters;
        return this;
    }
    /**
    * Getter.
    * @return Object
    */
    getCounters(){
        return this.counters;
    }
    /**
    * Setter.
    *
    * @param Object filters
    * @return Metadatas
    */
    setFilters(filters){
        // @TODO code cochon 1er jet à revoir
        // gestion du cas particulier filter between avec value = {start: date1,end: date2} au lieu de [date1,date2]

        // on devrait pouvoir setFilters sans enlever les value = null
        // et c'est la méthode get qui renvoie les filtres sans les values null
        let arrayFilters = Object.values(filters);
        let betweenFilters = arrayFilters.filter((filter)=>filter.action=="between");
        betweenFilters.forEach((filter)=>{
            if(filter.value && filter.value.hasOwnProperty("start") && filter.value.hasOwnProperty("end")){
                //console.log("FILTER", filter);
                filters[filter.colId].value = [filter.value.start, filter.value.end];
            }
        });
        this.filters = Object.assign(this.filters, filters);
        return this;
    }
    /**
    * Getter.
    *
    * @return array
    */
    getFilters(){
        return this.filters;
    }
    /**
    * Getter.
    *
    * @param string name nom du filtre
    * @return object
    */
    getFilter(name){
        return this.filters[name];
    }
    /**
    * Setter.
    *
    * @param array directives
    * @return array
    */
    setDirectives(directives){
        this.directives = directives;
        return this;
    }


}
export default  Metadatas;
