<template>

	<vg-app-layout
		:icon="$vgutils.isMobile()?'mobile/di_blanc.png':'origine-DI.png'"
		:title="$t('di.desktop.header-title')"
		:colorheader="$vgutils.isMobile()?$root.vgYellow:''"
		:isActionButtonDisplay="false"
		:isLoadingState="isLoadingState"
		@global-filters-change="handleGlobalFiltersChange"
		hideKeyFiguresPanel>
		<template #header-top-right-pre v-if="$vgutils.isMobile()">
			<vg-button
				type="grey"
				@click="openPlansInteractifs = true">
				<i class="far fa-map"></i> <span>{{ $t("di.desktop.btn-plans-interactifs") }}</span>
			</vg-button>
		</template>
		<template v-slot:search>
			<vg-helper
				class="help"
				:file="'https://verifgood.zohodesk.com/portal/fr/kb/articles/31-comment-cr%C3%A9er-une-demande-d-intervention'"
				:video="'https://www.youtube.com/watch?v=wxwKyx8Nek4&feature=youtu.be'"
			>
			</vg-helper>
		</template>

		<template v-slot:body>
			<form class="main-container-di"
				autocomplete="on"
				@submit="onSubmitForm"
				@keydown="onFormKeyDown">

                <vg-modal v-if="displayQrScan"
					:title='$t("equipements.mobile.modaleScan-header")'
					@close="displayQrScan=false;">
					<template v-slot:body>
                        <vg-scanner
							isFetching
							@scanSuccess="onScanSuccess" />
					</template>
				</vg-modal>

				<vg-input-step
					:step="'1'"
					:title="$t('di.desktop.etape-lieu-title')"
					:placeholder="$t('di.desktop.btn-selection')"
					:validationrequired="true"
					v-model="di.step1"
					@input-search="onInputSearchLieu"
				>
					<template v-slot:subvalue v-if="di.step1 && di.step1.data && di.step1.data.path && di.step1.data.path.includes('/-/')">
						<vg-lieu-path-viewer
							v-model="di.step1.data.path"
							:onlyPath="true"
							:showTooltip="false" />
					</template>
					<template v-slot:value v-if="di.step1 && di.step1.data && di.step1.data.path && di.step1.data.path.includes('/-/')">
						<vg-lieu-path-viewer
							v-model="di.step1.data.path"
							:onlyPiece="true"
							:showTooltip="false" />
					</template>
					<template v-slot:title-complement>
						<vg-button :type="'info'" @click="showTreeLieu=!showTreeLieu">
							{{showTreeLieu?$t("show-list-lieux"):$t("show-tree-lieux")}}
						</vg-button>
					</template>
					<template v-slot:title-right>
						<vg-button v-if="$vgutils.isMobile()"
							type="info"
							size="lg"
	    					@click="displayQrScan=true;">
	    					<i class="fa fa-2x fa-qrcode" aria-hidden="true"></i>
	    				</vg-button>
						<vg-button v-if="!$vgutils.isMobile()"
							type="grey"
	                        @click="openPlansInteractifs = true">
	                        <i class="far fa-map"></i> <span>{{ $t("di.desktop.btn-plans-interactifs") }}</span>
	                    </vg-button>
						<vg-button v-if="!$vgutils.isMobile()"
							type="grey"
	                        @click="openTiroir = true">
	                        <i class="far fa-map"></i> <span>{{ $t("di.desktop.btn-plan") }}</span>
	                    </vg-button>

	                    <vg-files-plans v-if="openTiroir"
	                        @close="openTiroir = false">
	                    </vg-files-plans>

						<vg-etages-plans-interactifs-modal v-if="openPlansInteractifs"
							@close="openPlansInteractifs=false;" />
					</template>
					<template v-slot:complement>
						<div v-if="previousStepsValid(2)" class="lieu-complement" >
							<span v-if="countersMaintenances && countersMaintenances.filtered"><strong>{{countersMaintenances.filtered}}</strong></span>
							<span v-else><strong>0</strong></span>
							<span>{{ $t("di.desktop.info-nbre-maintenance") }}</span>
							<span>{{ di.step1.value }}</span>
							<vg-button type="default-info" size="xs" @click="showFMsRelances=true;" :disabled="!countersMaintenances || !countersMaintenances.filtered">{{ $t("di.desktop.btn-maintenances") }}</vg-button>
						</div>
					</template>

					<template v-slot:body v-if="showTreeLieu">
						<vg-tree-lieux
							:readOnly="true"
							@select-piece="onClickLieu">
						</vg-tree-lieux>
					</template>
					<template v-slot:modal-body >
						<vg-filter-site v-model="agfiltersPieces.path.value"
							style="margin: 10px 0;"
						/>
						<vg-filter-service v-model="agfiltersPieces.service.value"
							@input="onChangeFilterService"
							style="margin: 10px 0;"
						/>
						<vg-lieux-table style="height:40vh;"
							:id="'vg-lieux-di-table'"
							:filters="agfiltersPieces"
							paginate
							isDemandeIntervention
							@select-piece="onSelectPiece"
							@deselect-piece="onDeselectPiece"
						/>
					</template>

					<template v-slot:modal-footer >
						<div  v-if="$app.isDiMultipleLieu && di.extendedstep1.ids && di.extendedstep1.ids.length!=0"  style="text-align: left;margin-bottom: 10px;display:flex;justify-content:flex-start;align-items:center;flex-wrap:wrap;">
							<div v-for="(lieu, index) in di.extendedstep1.datas"
								style="border: 1px solid#fbc429;margin:10px;padding: 0px 10px;display:flex;justify-content:flex-start;align-items:center;">
								<vg-lieu-path-viewer v-model="lieu.path" :key="lieu.id"
					                onlyPiece
									:showTooltip="false">
					            </vg-lieu-path-viewer>
								<i class="fas fa-times" @click="onDeselectPiece(lieu)" style="cursor:pointer;margin-left:5px;"></i>
							</div>
						</div>
						<div >
							<vg-button
								style="width:100%;color:#353535;background-color: #fbc429 !important;border-color: #fbc429 !important; font-weight: bold;"
								type="success"
								:disabled="di.extendedstep1.ids.length==0"
								@click="onValidStep1">
								{{$t('di.desktop.step1-modal-footer-valider')}}
								{{di.extendedstep1.ids.length}}
								{{$t('di.desktop.step1-modal-footer-lieux')}}
							</vg-button>
						</div>
					</template>


				</vg-input-step>

				<vg-input-step v-if="previousStepsValid(2)"
					:step="'2'"
					:title="$t('di.desktop.etape-quoi-title')"
					:placeholder="$t('di.desktop.btn-selection')"
					v-model="di.step2"
					@input-search="onInputSearch"
					>
					<template #modal-body>
						<br>
						<vg-collapse v-for="(typecomposant, index) in composantsGroupByType" 
							:title="index"
							:collapseByDefault="false"
							:killContentOnCollapse="false"
							type="section"
							:key="index">
							<template #content>
								<div v-for="(composant, index) in typecomposant" class="list-item" :key="index">
									<div @click="onClickComposant(composant)" :class="{'selected-item':!di.step2.isequipement && composant.id==di.step2.id}" >
										<img class="icon" :src="composant.icon">
										<span class="libel">{{composant.libelComposant}}</span>
									</div>
								</div>
							</template>
						</vg-collapse>
						<vg-collapse v-if="filteredEquipements && filteredEquipements.length!=0"
							:title="$t('di.desktop.modale-composant-tag-autre')"
							:collapseByDefault="false"
							:killContentOnCollapse="false"
							type="section">
							<template #content>
								<div v-for="(equipement, index) in filteredEquipements" class="list-item" :key="index">
									<div @click="onClickEquipement(equipement)" :class="{'selected-item':di.step2.isequipement && equipement.id==di.step2.id}" >
										<img class="icon" :src="equipement.icon">
										<span class="libel">{{equipement.libelEquipement}}</span>
										<span class="sublibel">{{equipement.qrCode}}</span>
										<span class="tag">{{equipement.tagCategorie}}</span>
									</div>
								</div>
								<br>
								<br>
								<span v-if="((Array.isArray(composantsGroupByType) && composantsGroupByType.length==0) || (!Array.isArray(composantsGroupByType) && Object.keys(composantsGroupByType).length==0)) && getEquipements.length==0"
									style="display:flex;justify-content:center;">
									<b>{{$t("aucun-composant-ou-equipement-recense")}}</b>
								</span>
							</template>
						</vg-collapse>
					</template>
                    <template v-slot:modal-footer>
                        &nbsp;
                    </template>
				</vg-input-step>

				<vg-input-step v-if="previousStepsValid(3)"
					:step="'3'"
					:title="$t('di.desktop.etape-probleme-title')"
					:placeholder="$t('di.desktop.btn-selection')"
					v-model="di.step3"
					@input-search="onInputSearch"
					>
					<template #title-right>
						<vg-tooltip :text="$t('explication-di-urgente')">
							<vg-checkbox
								:inputValue="di.step3.isurgence"
								:defaultChecked="di.step3.isurgence"
								@checked="handleCheckUrgence"
								@unchecked="handleCheckUrgence" >
								<img src="static/assets/icone/urgence.png" style="width:30px;height:30px;"/>
								{{$t('di.desktop.checkbox-urgent')}}
							</vg-checkbox>
						</vg-tooltip>
						<vg-tooltip v-if="$app.handleFmBloquante" 
							:text="$t('explication-di-bloquante')">
							<vg-checkbox
								:label="$t('di.desktop.checkbox-bloquant')"
								:inputValue="di.step3.isbloquant"
								:defaultChecked="di.step3.isbloquant"
								@checked="handleCheckBloquant"
								@unchecked="handleCheckBloquant" >
								<img v-if="$app.handleFmBloquante" src="static/assets/icone/maintenance-bloquante-red.svg" style="width:30px;height:30px;"/>
								{{$t('di.desktop.checkbox-bloquant')}}
							</vg-checkbox>
						</vg-tooltip>
					</template>
					<template v-slot:complement>
                        <vg-input v-model="di.step3.complement" :title="$t('complement-information')" inputType="textarea"></vg-input>
					</template>
					<template v-slot:modal-body >
						<br>
						<span v-if="filteredProblems.length==0" style="color:#35b1ea;font-size:16px;">{{ $t("aucun-problem-predefini") }}</span>
						<vg-collapse :title="$t('problems-predefinis')" v-if="libelsProblemsCategorie.length!=0"
							:collapseByDefault="false"
							type="section">
							<template #content>
								<div v-for="(problem, index) in libelsProblemsCategorie" class="list-item">
									<div @click="onClickProblem(problem)" :class="{'selected-item':problem.id==di.step3.id}" >
										<span class="libel">{{problem.libelle}}</span>
									</div>
								</div>
							</template>
						</vg-collapse>
						<vg-collapse :title="$t('autre')" v-if="libelsProblemsGeneraux.length!=0"
							:collapseByDefault="libelsProblemsCategorie.length!=0"
							type="section">
							<template #content>
								<div v-for="(problem, index) in libelsProblemsGeneraux" class="list-item">
									<div @click="onClickProblem(problem)" :class="{'selected-item':problem.id==di.step3.id}" >
										<span class="libel">{{problem.libelle}}</span>
									</div>
								</div>
							</template>
						</vg-collapse>
					</template>
                    <template v-slot:modal-footer>
                        -
                    </template>
				</vg-input-step>

				<vg-input-step v-if="$app.isFmRetourAFaire && previousStepsValid(4)"
					:step="'4'"
					:title="$t('di.desktop.etape-retour-a-faire')"
					v-model="di.step3"
					>
					<template v-slot:main-input v-if="$app.isFmRetourAFaire">
						<vg-checkbox :label="$t('di.desktop.retour-a-faire-non')"
							:inputValue="di.step3.isRetourAFaire"
							:defaultChecked="!di.step3.isRetourAFaire"
							@checked="di.step3.isRetourAFaire=false"
							@unchecked="di.step3.isRetourAFaire=true" />
						<vg-checkbox :label="$t('di.desktop.retour-a-faire-oui')"
							:inputValue="di.step3.isRetourAFaire"
							:defaultChecked="di.step3.isRetourAFaire"
							@checked="di.step3.isRetourAFaire=true"
							@unchecked="di.step3.isRetourAFaire=false" />
						<vg-input v-if="di.step3.isRetourAFaire" v-model="di.step3.retourAFaire"
							:placeholder="$t('di.desktop.retour-a-faire-placeholder')"/>
					</template>
                    <template v-slot:modal-footer>
                        -
                    </template>
				</vg-input-step>

				<vg-input-step v-if="retourAFaireIsValid && showDemandeurStep && previousStepsValid(4)"
					:step="$app.isFmRetourAFaire?'5':'4'"
					:title="$t('di.desktop.etape-demande-title')"
					:placeholder="$t('di.desktop.btn-selection')"
					v-model="di.step4"
					@input-search="onInputSearch"
					>
					<template v-slot:main-input>
						<vg-input v-model="di.step4.value"
							id="di-demandeur-input"
							name="di-demandeur-input"
							autocomplete="on"
							type="text"
							:placeholder="$t('di.desktop.input-demandeur-placeholder')" />
					</template>
				</vg-input-step>

				<div v-if="previousStepsValid(5)" class="step-validation">
					<vg-checkbox v-if="can('MAINTENANCE.PAGE')"
						:label="$t('redirect-after-save')"
						:inputValue="isOpenMaintenanceAfterCreate"
						:defaultChecked="isOpenMaintenanceAfterCreate"
						@checked="setOpenMaintenanceAfterCreate(true)"
						@unchecked="setOpenMaintenanceAfterCreate(false)"
						style="font-weight: normal !important;"/>
					<input type="submit"
						id="validation-button"
						:disabled="isSaveDIInProgress"
						:value="$t('di.desktop.btn-valider')"
						@mounted="onMounted('validation-button')">
				</div>

				<vg-modal v-if="showPrendrePhoto"
					:title="$vgutils.isMobile()?$t('prendre-photo-title'):$t('joindre-photo-title')"
					@close="onClosePrendrePhoto">
					<template v-slot:body>
						{{$t("joindre-photo")}}
					</template>
					<template v-slot:footer>
						<vg-button
							@click="onClosePrendrePhoto">
							{{$t("non")}}
						</vg-button>
						<vg-files-uploader style="width:100px;"
							:inputLabel="$t('oui')"
							:isOnlyPhoto="true"
							:isUnnamed="true"
							:uidToUpload="lastMaintenance.uid"
							:defaultType="'fichemaintenance'"
							@upload-done="onUploadDocumentDone">
						</vg-files-uploader>
					</template>
				</vg-modal>

                <vg-modal v-if="showConfirm"
					@close="showConfirm = false">
                    <template #header>
                        <span>
                            {{di.extendedstep1.ids.length}} {{$t('di.desktop.modal-show-confirm-title')}}
                        </span>
                    </template>
                    <template v-slot:footer>
                        <vg-button @click="showConfirm=false">
	                        {{$t('di.desktop.modal-show-confirm-footer-annuler')}}
	                    </vg-button>
	                    <vg-button type="success" @click="save">
	                        {{$t('di.desktop.modal-show-confirm-footer-confirmer')}}
	                    </vg-button>
                    </template>
                </vg-modal>

				<vg-fichesav-consultation-relances v-if="previousStepsValid(2)"
					v-show="showFMsRelances"
					:fms="getMaintenances"
					:lieux="di.extendedstep1.datas"
					@relance="handleRelance"
					@close="showFMsRelances=false;" />

			</form>
		</template>


	</vg-app-layout>

</template>

<script>
//import demandeinterventioncomponent from 'src/entity/Demandeintervention/Demandeintervention.vue';

import MaintenanceMixins from 'src/mixins/MaintenanceMixins.js';
import equipementsMixins from 'src/mixins/equipementsMixins.js';
import ComposantMixins from 'src/mixins/ComposantMixins.js';
import LibelProblemMixins from 'src/mixins/LibelProblemMixins.js';

import VgInputStep from "src/components/Vg/DemandeIntervention/VgInputStep.vue";

import VgScanner from 'src/components/Vg/VgScanner.vue';

import VgFichesavConsultationRelances from "src/components/Vg/FicheSAV/VgFichesavConsultationRelances.vue";
import VgButton from 'src/components/Vg/VgButton.vue';

import VgHelper from 'src/components/Vg/Utils/VgHelper.vue';

import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgTooltip from "src/components/Vg/VgTooltip.vue";

import VgInput from "src/components/Vg/VgInput.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgFilesPlans from 'src/components/Vg/Files/VgFilesPlans.vue';

import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgFilterService from "src/components/Vg/Filters/VgFilterService.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";

import VgEtagesPlansInteractifsModal from "src/components/Vg/Lieu/VgEtagesPlansInteractifsModal.vue";

import LieuMixins from "src/mixins/LieuMixins.js";
import LibellesCategorieMixins from "src/mixins/LibellesCategorieMixins.js";

import VgLieuxTable from "src/components/Vg/Lieu/VgLieuxTable.vue";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';

export default {
    name: 'Demandeintervention',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
			"explication-di-urgente": "Problème à résoudre dans les plus brefs délais",
			"explication-di-bloquante": "Problème critique qui empêche l'usage complet du lieu ou de l'équipement",
            "show-list-lieux": "Basculer vue liste",
            "show-tree-lieux": "Basculer vue arborescence",
            "prendre-photo-title": "Voulez-vous prendre une photo?",
            "joindre-photo-title": "Voulez-vous joindre une photo?",
            "joindre-photo": "Joindre une photo à la demande d'intervention.",
            "non": "Non",
            "oui": "Oui",
            "piece-generique": "Pièce générique",
            "etage-generique": "Etage générique",
            "batiment-generique": "Batiment générique",
            "complement-information": "Description complémentaire (optionnelle)",
            "aucun-composant-ou-equipement-recense": "Aucuns équipements ou composants recensés dans la pièce.",
            "redirect-after-save": "Ouvrir la fiche après création",
            "relance-di": "Relance Demande intervention",
            "non-trouve": "Non trouvé",
			"autre": "Liste générale",
			"problems-predefinis": "Liste spécifique au composant ou à l'équipement",
			"aucun-problem-predefini": "Aucun problèmes spécifiques ou généraux saisis pour le composant ou l'équipement",
            "di": {
                "desktop": {
                    "header-title": "Demande d'intervention",
                    "default-text-uploadfile": "Enregistrement de l'image en cours...Veuillez patienter...",
                    "etape-lieu-title": "Quel lieu ?",
                    "btn-selection": "Sélectionner",
                    "info-nbre-maintenance": "Fiche(s) curative(s) en cours dans",
                    "btn-maintenances": "Voir",
                    "step1-modal-footer-valider": "Valider",
                    "step1-modal-footer-lieux": "lieux",
                    "etape-quoi-title": "Quoi ?",
                    "modale-composant-tag-autre": "Equipements",
                    "etape-probleme-title": "Quel problème ?",
                    "checkbox-urgent": "urgent",
                    "checkbox-bloquant": "bloquant",
                    "etape-retour-a-faire": "Retour demandeur",
                    "retour-a-faire-oui": "Oui",
                    "retour-a-faire-non": "Non",
                    "retour-a-faire-placeholder": "A qui ?",
                    "etape-demande-title": "Qui fait la demande ?",
                    "input-demandeur-placeholder": "Indiquez votre nom",
                    "confirm-upload": "Photo enregistrée",
                    "btn-valider": "Valider",
                    "modal-show-confirm-title": "demandes d'intervention à créer ?",
                    "modal-show-confirm-footer-annuler": "Annuler",
                    "modal-show-confirm-footer-confirmer": "Confirmer",
                    "btn-plan": "Plans PDF",
					"btn-plans-interactifs": "Plans interactifs"
                }
            },
            "equipements": {
                "mobile": {
                    "modaleScan-header": "Scanner lieu ou équipement"
                }
            }
        },
        "en": {
			"explication-di-urgente": "To solve as soon as possible",
			"explication-di-bloquante": "Prevents room/equipment usage",
            "show-list-lieux": "See list view",
            "show-tree-lieux": "See tree view",
            "prendre-photo-title": "Do you want to take a photo ?",
            "joindre-photo-title": "Do you want to link a photo ?",
            "joindre-photo": "Attach a photo to intervention request",
            "non": "No",
            "oui": "Yes",
            "piece-generique": "Generic room",
            "etage-generique": "Generic level",
            "batiment-generique": "Generic building",
            "complement-information": "Description (not required)",
			"relance-di": "Intervention request reviving",
			"non-trouve": "Not found",
			"autre": "General list",
			"problems-predefinis": "Component or equipment specific list",
			"aucun-problem-predefini": "No general or specific problems saved for this component or equipment",
            "di": {
                "desktop": {
                    "header-title": "Intervention request",
                    "default-text-uploadfile": "Saving image ... Please wait ...",
                    "etape-lieu-title": "location ?",
                    "btn-selection": "Select",
                    "info-nbre-maintenance": "Curative sheets in progress in",
                    "btn-maintenances": "Open",
                    "step1-modal-footer-valider": "Confirm",
                    "step1-modal-footer-lieux": "places",
                    "etape-quoi-title": "What ?",
                    "modale-composant-tag-autre": "Equipments",
                    "etape-probleme-title": "Indicate the problem",
                    "checkbox-urgent": "emergency",
                    "checkbox-bloquant": "blocking",
                    "etape-demande-title": "Who is the requester ?",
                    "input-demandeur-placeholder": "Type your name",
                    "confirm-upload": "Photo saved",
                    "btn-valider": "Validate",
                    "modal-show-confirm-title": "interventions request",
                    "modal-show-confirm-footer-annuler": "Cancel",
                    "modal-show-confirm-footer-confirmer": "Confirm",
                    "etape-retour-a-faire": "Buyer feedback",
                    "retour-a-faire-oui": "Yes",
                    "retour-a-faire-non": "No",
                    "retour-a-faire-placeholder": "To whom?",
                    "btn-plan": "PDF level plans",
					"btn-plans-interactifs": "Interactive level plans"
                }
            },
            "equipements": {
                "mobile": {
                    "modaleScan-header": "Scan place or equipment"
                }
            },
            "aucun-composant-ou-equipement-recense": "No equipment or component inventored in this room"
        },
        "th": {
            "di": {
                "desktop": {
                    "header-title": "การร้องขอการแทรกแซง",
                    "default-text-uploadfile": "กำลังบันทึกรูปภาพ ... กรุณารอสักครู่ ...",
                    "etape-lieu-title": "ตำแหน่งที่ตั้ง?",
                    "btn-selection": "เลือก",
                    "btn-maintenances": "เปิด",
                    "step1-modal-footer-valider": "ยืนยัน",
                    "step1-modal-footer-lieux": "สถานที่",
                    "etape-quoi-title": "อะไร?",
                    "modale-composant-tag-autre": "อื่น ๆ",
                    "etape-probleme-title": "ระบุปัญหา",
                    "checkbox-urgent": "ความเร่งด่วน",
                    "etape-demande-title": "ใครคือผู้ร้องขอ?",
                    "input-demandeur-placeholder": "พิมพ์ชื่อของคุณ",
                    "confirm-upload": "บันทึกภาพถ่ายแล้ว",
                    "btn-valider": "ตรวจสอบความถูกต้อง",
                    "modal-show-confirm-title": "การร้องขอการแทรกแซง",
                    "modal-show-confirm-footer-annuler": "ยกเลิก",
                    "modal-show-confirm-footer-confirmer": "ยืนยัน",
                    "etape-retour-a-faire": "ข้อเสนอแนะของผู้ซื้อ",
                    "retour-a-faire-oui": "ใช่",
                    "retour-a-faire-non": "ไม่ใช่",
                    "retour-a-faire-placeholder": "ให้ใคร?",
                    "btn-plan": "แผนงาน"
                }
            },
            "equipements": {
                "mobile": {
                    "modaleScan-header": "สแกนสถานที่หรืออุปกรณ์"
                }
            },
            "show-list-lieux": "ดูมุมมองรายการ",
            "show-tree-lieux": "ดูมุมองต้นไม้"
        }
    }
} ,
    components: {
		VgFilesUploader,
		VgInputStep,
		VgScanner,
		VgFichesavConsultationRelances,
        VgButton,
        VgTooltip,

		VgHelper,
		VgCollapse,
		VgInput,
		VgCheckbox,
        VgFilesPlans,
		VgTreeLieux,
		VgFilterSite,
		VgFilterService,
		VgEtagesPlansInteractifsModal,

		VgLieuPathViewer,
		LibelProblemMixins,
		VgLieuxTable
    },
	mixins: [ MaintenanceMixins, LieuMixins, LibellesCategorieMixins, equipementsMixins, ComposantMixins, LibelProblemMixins ],
	props:{
		afterSaveRedirectTo:{
			type: String,
			default: null
		}
	},
    data: function() {
        return {
			equipements: [],
			composants: [],
			problems: [],
			qui: [],
			fms: null,
			currentFilter: "",
			displayQrScan: false,
			di: null,
			showConfirm: false,
			showFMsRelances: false,
            isSaveDIInProgress : false,
            openTiroir:false,
            openPlansInteractifs:false,
			showTreeLieu: false,
			showPrendrePhoto: false,
			lastMaintenance: null,
			agfiltersPieces: {
				type_lieu: {attr: "l.type_lieu", value: "Piece", action: "equals"},
				path: {attr: "l.path", value: null, action: "contains"},
				service: {attr: "l.service", value: this.$storage.get("service-DemandeIntervention.vue"), action: "equals"},
				libel_lieu: {attr: "l.libel_lieu", value: null, action: "contains", openParenthesis: true},
				codeTrois: {attr: "l.codeTrois", value: null, action: "contains", logicalOperator: "OR"},
				codeDeux: {attr: "l.codeDeux", value: null, action: "contains", logicalOperator: "OR"},
				servicetext: {attr: "l.service", value: null, action: "contains", closeParenthesis: true, logicalOperator: "OR"}
			},
			isLoadingState: false,
			isOpenMaintenanceAfterCreate: localStorage.getItem("demande-intervention-open-after-create") || false,
            libellesCategorie:[]
        };
    },
	created: function(){
		this.initDI();
		let filters = {
			"qrCode":{attr: "l.type_lieu", value: null, action: "equals"},
			"idLieu":{attr: "l.type_lieu", value: null, action: "equals"},
			"idEquipement":{attr: "l.type_lieu", value: null, action: "equals"}
		}

		if(this.getGivenCodeFromUrl){
			// fetch equipment by qrCode and feed lieu id
			// and select lieu
			this.isLoadingState = true;
			this.fetchEquipementByQrCode(this.getGivenCodeFromUrl).then((equipement)=>{
				this.onScanSuccess(equipement);
			});
		}else if(this.getGivenIdLieuFromUrl && !this.getGivenIdEquipementFromUrl) {
				 // @TODO il faut loader       //
			   // les équipements            //
			  // les composants du lieu     //
			 //  les maintenances en cours //

		}else if(this.getGivenIdLieuFromUrl && this.getGivenIdEquipementFromUrl) {
			// fetch equipment by id and feed lieu id
			// and select lieu
		}else{

		}

		this.agfiltersPieces.service.value = this.$storage.get("service-DemandeIntervention.vue");
	},
    methods: {
		onFormKeyDown: function(event){
			if (event.keyCode == 13) {
				event.preventDefault();
				return false;
			}
		},
		onSubmitForm: function(event){
			event.preventDefault();
			if(this.di.step1.id) this.save();
			else this.showConfirm = true;
			return false;
		},
		/**
		 * @TODO revoir le scroll avec des anchors car le scroll ne fonctionne pas si la page n'est pas scroll au bout par défaut
		 */
		onMounted: function(elementName){
			let element = document.getElementById(elementName);
  			if(element) element.scrollIntoView({behavior: "smooth"});
		},
		/**
		 * @param boolean isChecked
		 */
		setOpenMaintenanceAfterCreate: function(isChecked){
			this.isOpenMaintenanceAfterCreate = isChecked;
			localStorage.setItem("demande-intervention-open-after-create", isChecked);
		},
		/**
		 * Save service into storage.
		 * @param string service
		 */
		onChangeFilterService: function(service){
			this.$storage.set("service-DemandeIntervention.vue", service);
		},
        handleCheckUrgence:function(data){
            this.di.step3.isurgence=data.value;
        },
		handleCheckBloquant:function(data){
            this.di.step3.isbloquant=data.value;
        },
		/**
		 * @param integer fm id
		 */
		handleRelance: function(fm){
			this.MaintenanceMixins_postMaintenanceOperations(fm.id, [{
				dateOperation: moment().format("YYYY-MM-DD HH:mm"),
				operation: this.$t('relance-di'),
				flag: "relance",
				idUser: this.$app.idUser,
				ficheSav_id: fm.id
			}]).then((data)=>{
				this.initDI();
				this.showFMsRelances = false;
			});
		},
		/**
		 * @param string code
		 */
		onScanSuccess: function(equipement){
			this.isLoadingState = true;
			let lieu = {
				id: equipement.idLieu_id,
				path: equipement.path,
				libel_lieu: equipement.lieu.path.split("/").slice(-1)[0],
				categorie_id: equipement.idCategorie_id
			};
			let isGroupEqp = parseInt(equipement.isGroupEqp);
			if(!lieu){
				alert(this.$t("non-trouve"));
				return;
			}
			if(isGroupEqp) lieu.equipement_id = equipement.id;
			this.onClickLieu(lieu, true);
			this.$store.dispatch("LieuxStore/addSelectedItems", [lieu]);
			this.onValidStep1();
			if(!isGroupEqp){
				this.onClickEquipement(equipement);
			}
			this.isLoadingState = false;
			this.displayQrScan = false;
		},
		/**
		 * @param string filter
		 */
		onInputSearch: function(filter){
			this.currentFilter = filter?filter:"";
		},
		/**
		 * @param string input
		 */
		onInputSearchLieu: function(input){
			this.agfiltersPieces.libel_lieu.value = input;
			this.agfiltersPieces.codeTrois.value = input;
			this.agfiltersPieces.codeDeux.value = input;
			this.agfiltersPieces.servicetext.value = input;
		},
		reinitNextSteps: function(nextStep){
			switch(nextStep){
				case 1:
					this.di.extendedstep1 = {
						ids: [],
						values: [],
						subvalues: [],
						datas: []
					};
					this.di.step1 = {		// lieu
						id: null,
						value: null,
						subvalue: null,
						data: null
					};
				case 2:
					this.di.step2 = {		// equipement || composant
						id: null,
						value: null,
						subvalue: null,
						isequipement: null,
						data: null
					};
				case 3:
					this.di.step3 = {		// problem
						id: null,
						value: null,
						subvalue: null,
						complement: null,
						isurgence: false,
						isbloquant: false,
						data: null,
						isRetourAFaire: false,
						retourAFaire: null
					};
				case 4:
					this.di.step4 = {		// qui
						id: null,
						value: null,
						data: null
					};
					this.di.file = null;
				default:
					this.currentFilter = null;
					break;
			}
		},
		/**
		 * @param {piece} piece
		 */
		onSelectPiece: function(piece){
			this.onClickLieu(piece);
			this.$store.dispatch("LieuxStore/addSelectedItems", [piece]);
		},
		/**
		 * @param {piece} piece
		 */
		onDeselectPiece: function(piece){
			this.onClickLieu(piece);
			this.$store.dispatch("LieuxStore/removeSelectedItems", [piece]);
			this.$store.dispatch("MaintenancesStore/setMaintenances", []);
		},
		 /**
		  * @TODO should be return by composant list lieu
		 * @param {piece} lieu
		 * @param boolean scanned
		 */
		onClickLieu: function(lieu, scanned=false){
			var index = this.di.extendedstep1.ids.indexOf(lieu.id);
			if( index == -1 ){ 	//ce lieu n'a pas encore été sélectionné
				if(this.$app.isDiMultipleLieu){
					this.di.extendedstep1.ids.push(lieu.id);
					this.di.extendedstep1.values.push(lieu.libel_lieu);
					this.di.extendedstep1.subvalues.push(this.$vgutils.formatPathWithoutRoom(lieu.path));
					this.di.extendedstep1.datas.push(lieu);
				}else{
					this.$store.dispatch("LieuxStore/deleteSelectedItems");
					this.di.extendedstep1.ids = [lieu.id];
					this.di.extendedstep1.values = [lieu.libel_lieu];
					this.di.extendedstep1.subvalues = [this.$vgutils.formatPathWithoutRoom(lieu.path)];
					this.di.extendedstep1.datas = [lieu];
				}
			}else{
                if(!scanned){
                    this.di.extendedstep1.ids.splice(index, 1);
    				this.di.extendedstep1.values.splice(index, 1);
    				this.di.extendedstep1.subvalues.splice(index, 1);
    				this.di.extendedstep1.datas.splice(index, 1);
                }
			}

		},
		/**
		 * @param array lieuIds
		 */
		fetchFMsForSelectedLieux: function(lieuIds){
			let metadatas = new Metadatas();
			metadatas.setFilters(
				{
					"idLieu":{attr: "l.id", value: lieuIds, action: "equals"},
					statut: { attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains" }
				}
			);
			this.MaintenanceMixins_getMaintenances(metadatas);
		},
		/**
		 * @param array categorieId
		 * @return {Promise}
		 */
		fetchComposants:function(composantId){
			return new Promise((resolve, reject)=>{
				let metadatas = new Metadatas();
				metadatas.setFilters(
					{
						"idLieu":{attr: "cat.id", value: composantId, action: "equals"}
					}
				);
				this.ComposantMixins_get(metadatas)
			});
		},
		/**
		 * @param array lieuIds
		 * @return {promise}
		 */
		fetchEquipementByQrCode:function(qrCode){
			return new Promise((resolve,reject)=>{
					// @TODO il faut loader       //
				// les équipements            //
				// les composants du lieu     //
				let metadatas = new Metadatas();
				metadatas.setFilters(
					{
						"qrCode":{attr: "e.qrCode", value: qrCode, action: "equals", "openParenthesis":true},
						"codeUn":{attr: "l.codeUn", value: qrCode, action: "equals", "logicalOperator":"OR", "closeParenthesis":true}
					}
				);
				this.equipementsMixins_getEquipements(metadatas).then((equipements)=>{
					if(equipements && equipements.datas && equipements.datas[0]) resolve(equipements.datas[0]);
					else reject();
				});
			})

		},
		/**
		 * @param array lieuIds
		 * @return {promise}
		 */
		fetchEquipements:function(lieuIds){
			return new Promise((resolve,reject)=>{
					// @TODO il faut loader       //
				// les équipements            //
				// les composants du lieu     //
				let metadatas = new Metadatas();
				metadatas.setFilters(
					{
						"idLieu":{attr: "e.idLieu_id", value: lieuIds, action: "equals"},
						"isGEP":{attr: "e.isGroupEqp", value: "1", action: "not_equals"}
					}
				);
				this.equipementsMixins_getEquipements(metadatas).then((equipements)=>{
					resolve(equipements);
				});
			})

		},
		/**
		 * @param {composant} composant
		 * @param {equipement} equipement
		 * @return {promise}
		 */
		fetchLibelProblem:function(composant=null,equipement=null){
			let composantId = composant ? composant.id : null ;
			let equipementCategorieId = equipement ? equipement.idCategorie : null;
			return new Promise((resolve,reject)=>{
				this.problems = [];
				let metadatas = new Metadatas();
				metadatas.setFilters(
					{
						"idLieu":{attr: "lp.composant", value: composantId, action: "equals"}
					}
				);
				let metadatasLibelCategories = new Metadatas();
				metadatasLibelCategories.setFilters({
					categorieId: {attr: "categorie_id", value: equipementCategorieId, action: "equals"}
				});
				this.reinitNextSteps(3);
				// les libellés génériques
				this.LibelProblemMixins_DI_get(metadatas).then((libelsproblem)=>{
					this.problems = this.problems.concat(libelsproblem.datas);
					// libellés composant
					if(composantId){
						this.LibelProblemMixins_DI_get_composant_problem(composantId).then((otherproblems)=>{
							this.problems = this.problems.concat(otherproblems.datas);

						});
					}
					if(equipementCategorieId){
						this.LibellesCategorieMixins_getLibellesCategorie(metadatasLibelCategories).then((libellesCategorie)=>{
							//this.libellesCategorie = libellesCategorie;
							this.problems = this.problems.concat(libellesCategorie);
							resolve(this.problems);
						});
					}

				});




			})

		},
		onValidStep1: function(){
			//console.log("onValidStep1", this.di);
			var extendedstep1 = this.di.extendedstep1;
			if(extendedstep1.ids.length==1){
				this.di.step1 = {
					id: extendedstep1.ids[0],
					value: extendedstep1.values[0],
					subvalue: extendedstep1.subvalues[0],
					data: extendedstep1.datas[0]
				};
			}else{
				this.di.step1 = {
					id: null,
					value: extendedstep1.values.join(" | "),
					subvalue: extendedstep1.ids.length+" lieux",
					data: null
				};
			}
			this.reinitNextSteps(2);
			//this.fetchFMsForSelectedLieux(extendedstep1.ids);
			this.fetchEquipements(extendedstep1.ids);
			let categorieLieuIds =  this.selectedLieux.map((lieu)=>{
				return lieu.categorie_id;
			});
			this.fetchComposants(categorieLieuIds);
		},
		/**
		 * @param {composant} composant
		 */
		onClickComposant: function(composant){
			this.fetchLibelProblem(composant);
			this.di.step2 = {
				id: composant.id,
				value: composant.libelComposant,
				subvalue: composant.typeComposant,
				isequipement: false,
				data: composant
			};
			this.reinitNextSteps(3);
		},
		/**
		 * @param {equipement} equipement
		 */
		onClickEquipement: function(equipement){
			this.di.step2 = {
				id: equipement.id,
				value: equipement.libel_equipement,
				subvalue: equipement.qrCode,
				isequipement: true,
				data: equipement
			};
			this.fetchLibelProblem(null,equipement);
		},
		onClickProblem: function(problem){
			this.di.step3.id = problem.id;
			this.di.step3.value = problem.libelle;
			this.di.step3.data = problem;
			this.reinitNextSteps(4);
		},
		previousStepsValid: function(step){
			var isStepValid = true;
			var di = this.di;
			switch(step){ //retourAFaireIsValid
				case 5:
					isStepValid = isStepValid && ( !this.showDemandeurStep || ( this.showDemandeurStep && this.di.step4.value ) ) && this.retourAFaireIsValid;
				case 4:
					isStepValid = isStepValid && ( ( this.filteredProblems.length!=0 && this.di.step3.value ) || ( this.filteredProblems.length==0 && this.di.step3.complement ) );
				case 3:
					isStepValid = isStepValid && di.step2.id;
				case 2:
					isStepValid = isStepValid && ( di.step1.id || (di.step1.value && di.extendedstep1.ids.length!=0 ));
				default:
					break;
			}
			return isStepValid;
		},
		executeLoaderSave: function(){
			this.$emit("loader-start", {message:"Enregistrement de la demande d'intervention..."});
		},
		executeLoaderStop: function(){
			this.$emit("loader-stop", null);
		},
		initDI: function(){
			this.di = {
				extendedstep1: {
					ids: [],
					values: [],
					subvalues: [],
					datas: []
				},
				step1: {		// lieu
					id: null,
					value: null,
					subvalue: null,
					data: null
				},
				step2: {		// equipement || composant
					id: null,
					value: null,
					subvalue: null,
					isequipement: null,
					data: null
				},
				step3: {		// problem
					id: null,
					value: null,
					subvalue: null,
					complement: null,
					isurgence: false,
					isbloquant: false,
					data: null,
					isRetourAFaire: false,
					retourAFaire: null
				},
				step4: {		// qui
					id: null,
					value: null,
					data: null
				},
				file: null
			};
			this.showConfirm = false;
		},
		getDescription: function(){
			var description = "";
			if(this.di.step3.value) description += this.di.step3.value;
			if(this.di.step3.value && this.di.step3.complement) description += " ";
			if(this.di.step3.complement) description += this.di.step3.complement;
			return description;
		},
		/**
		 * @return array<integer>
		 */
		getIdEquipement: function(){
			let equipementIds = [];
			if(this.di.step2.isequipement){
				equipementIds.push(this.di.step2.id);
			}else{
				if(this.di.step1.id && this.di.step1.data.idGEP){
					equipementIds.push(this.di.step1.data.idGEP);
				}
				else if(this.di.step1.id && this.di.step1.data.equipement_id){
					equipementIds.push(this.di.step1.data.equipement_id);
				}
				else {
					equipementIds = this.di.extendedstep1.datas.map( (lieu) => lieu.idGEP?lieu.idGEP:lieu.equipement_id );
				}
			}
			return equipementIds;
		},
		openMaintenance: function(){
			if(this.isOpenMaintenanceAfterCreate && this.can('MAINTENANCES.PAGE')){
				this.$router.push({ name: '_maintenance', params: { id: this.lastMaintenance.id }});
			}else if(this.isOpenMaintenanceAfterCreate){
				this.$router.push({ name: '_maintenance_activites'});
			}
		},
		executeRedirectTo: function(){
			if(this.afterSaveRedirectTo) this.$router.push({name: this.afterSaveRedirectTo});
		},
		onClosePrendrePhoto: function(){
			this.executeRedirectTo();
			this.openMaintenance();
			this.showPrendrePhoto = false;
            this.lastMaintenance = null;
            this.initDI();
		},
        onUploadDocumentDone:function(){
            // POST operation flag=photo
			this.MaintenanceMixins_postMaintenanceOperations(this.lastMaintenance.id, [{
				dateOperation: moment().format("YYYY-MM-DD HH:mm"),
				operation: "Prise photo",
				flag: "photo",
				idUser: this.$app.idUser,
				ficheSav_id: this.lastMaintenance.id
			}]).then((data)=>{
				this.executeRedirectTo();
				this.openMaintenance();
				this.lastMaintenance = null;
				this.initDI();
                this.showPrendrePhoto = false;
			});
        },
		save: function(){
			this.$store.dispatch("LieuxStore/deleteSelectedItems");
            this.isSaveDIInProgress = true;
			this.executeLoaderSave();
			let equipementsId = this.getIdEquipement();
			let maintenances = [];
			equipementsId.forEach((id)=>{
				maintenances.push({
					"materiel_id":id,
					"composant_id":!this.di.step2.isequipement ? this.di.step2.id : null,
					"corpsDetat_id":parseInt(this.di.step2.data.corpsDetat_id),
					"dateOuvertureSAV":moment().format("YYYY-MM-DD HH:mm:ss"),
					"statut":"En_cours",
					"commentaireClient": this.getDescription(),
					"urgence":this.di.step3.isurgence? '1': '0',
					"isBloquant":this.di.step3.isbloquant? '1': '0',
					"origin":"DI",
					"userId":this.$app.appID,
					"isRetourFait":0,
					"isRetourAFaire":this.di.step3.isRetourAFaire,
					"isRelance":0,
					"idUser":this.$app.idUser,
					"demandeur":this.di.step4.value,
					"retourAfaireA": this.di.step3.isRetourAFaire? this.di.step3.retourAFaire: null
				});
			});
            this.MaintenanceMixins_createMaintenances(maintenances, {_stored: false}).then((reponse)=>{
                this.isSaveDIInProgress = false;
				this.executeLoaderStop();
				this.lastMaintenance = reponse[0];
				this.showConfirm = false;
				this.showPrendrePhoto = true;
            });

		},
		handleGlobalFiltersChange:function(filters){
			this.fetchEquipementByQrCode(filters.code).then((equipement)=>{
				this.onScanSuccess(equipement);
			});
		}
    },
	computed: {
		...mapGetters({
            lieux: 'LieuxStore/getCollection',
            selectedLieux: 'LieuxStore/getSelectedItems',
            getEquipements: 'EquipementsStore/getCollection',
            getMaintenances: 'MaintenancesStore/getMaintenances',
            countersMaintenances: 'MaintenancesStore/getMaintenancesCounters',
            getComposants: 'ComposantsStore/getCollection',
            getLibellesProblem: 'LibellesProblemStore/getCollection'
        }),
		libelsProblemsCategorie: function(){
			return this.filteredProblems.filter((problem)=>(problem.hasOwnProperty("categorie_id") && problem.categorie_id) || (problem.hasOwnProperty("composant") && problem.composant));
		},
		libelsProblemsGeneraux: function(){
			return this.filteredProblems.filter((problem)=>(!problem.hasOwnProperty("categorie_id") || !problem.categorie_id) && (!problem.hasOwnProperty("composant") || !problem.composant));
		},
		showDemandeurStep: function(){
			return !this.$app.isUserTypeAsDemandeur;
		},
		retourAFaireIsValid: function(){
			if(this.$app.isFmRetourAFaire) return (this.di.step3.isRetourAFaire && this.di.step3.retourAFaire && this.di.step3.retourAFaire.length>2) || !this.di.step3.isRetourAFaire;
			else return true;
		},
		filteredEquipements: function(){
			var idsLieu = this.di.extendedstep1.ids;
			if(idsLieu.length==1){
				var equipements = this.getEquipements.filter(function(equipement){
					return idsLieu.indexOf(equipement.idLieu)!=-1;
				});
				if(this.currentFilter){
					var filter = this.currentFilter.toLowerCase();
					return equipements.filter(function(equipement){
						return (equipement.libelEquipement && equipement.libelEquipement.toLowerCase().search( filter )!=-1) || (equipement.qrCode && equipement.qrCode.toLowerCase().search( filter )!=-1) || (equipement.tagCategorie && equipement.tagCategorie.toLowerCase().search( filter )!=-1);
					});
				}else{
					return equipements;
				}
			}else{
				return [];
			}
		},
		filteredComposants: function(){
			if(this.currentFilter){
				let currentFilter = this.currentFilter.toLowerCase();
				return this.getComposants.filter((composant)=>composant.typeComposant.toLowerCase().search(currentFilter)!=-1 || composant.libelComposant.toLowerCase().search(currentFilter)!=-1);
			}else{
				return this.getComposants;
			}
		},
		/**
		 * @return array
		 */
		composantsGroupByType: function(){
			// @TODO refactorer
			var groupBy = function(xs, key) {
			return xs.reduce(function(rv, x) {
				(rv[x[key]] = rv[x[key]] || []).push(x);
				return rv;
			}, {});
			};
			return groupBy(this.filteredComposants, "typeComposant");

		},
		filteredProblems: function(){
			var problems = this.problems;
			if(this.currentFilter){
				var filter = this.currentFilter.toLowerCase();
				problems = problems.filter((problem) => (problem.libelle && problem.libelle.toLowerCase().search( filter )!=-1));
			}
			return problems;
		},
		/**
		 * @return string
		 */
		getGivenCodeFromUrl: function(){
			return this.$route.params.code;
		},
		/**
		 * @return string
		 */
		getGivenIdLieuFromUrl: function(){
			return this.$route.params.idLieu;
		},
		/**
		 * @return string
		 */
		getGivenIdEquipementFromUrl: function(){
			return this.$route.params.idEquipement;
		},
		generateDatalistId: function(){
            return moment().format("x")+"-"+Math.floor(Math.random()*1000);
        },
    },
    mounted: function(){

    },
	destroyed: function(){
		this.$store.dispatch("LieuxStore/deleteSelectedItems");
		this.$store.dispatch("MaintenancesStore/setMaintenances", []);
	}
};
</script>

<style lang="scss" scoped>
#validation-button{
	width: 100%;
	color: white;
    border: 1px solid #50b659;
    background-color: #50b659;
	height: 30px;
}
.help{
	float: right;
}
.main-container-di{
	width:60%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	@media only screen and (max-width: 600px) {
		width:100%;
		gap:10px;
	}
}
.step-validation{
	padding:0 10px;
	width:100%;
	margin-bottom:10px;
}
.lieu-complement{
	border: 1px solid #fbc429;
	width: 100%;
	margin-top: 10px;
	padding: 10px 20px;
	font-size: 11px;
}
.list-item{
	border-bottom: 1px solid #ebeef5;
	cursor: pointer;

	div{
		height: 100%;
		padding: 20px;
	}
	.selected-item{
		border: 1px solid #40a9ff;
	}
	img[class=icon]{
		width: 20px;
		height: 20px;
		margin-right: 20px;
	}
	span[class=libel]{
		font-weight: bold;
	}
	span[class=sublibel]{
		color: grey;
	}
	span[class=tag]{
		font-size: 11px;
		padding: 2px;
		float: right;
		border: 1px solid #fbc429;
	}

}
.file-exist{
	width:100%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	>span{
		display: flex;
		align-items: center;
		>i{
			margin-right: 10px;
		}
	}
}
.simpleButton{
    background-color: #9b9b9b;
    border: none;
    color: white;
    padding: 5px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}
.simpleButton:hover{
    cursor:pointer;
    opacity:0.6;
}
/*.form-footer{
	button{
		float: right;
		width: 200px;
		@media only screen and (max-width: 600px) {
			width:100%;
		}
	}
}*/
</style>
