<template>
    <div class="todo-list">
        <span v-if="!value || value.length==0" style="font-size:16px;color:#3999ff;">{{$t(messageWhenNoItems)}}</span>
        <div class="todo-row" v-for="(item,index) in value" :key="'todo-list-item-'+index">
            <div @click="$emit('click',item)" >
                <slot name="item" :item="item" v-if="!displayedProperty"></slot>
                <div v-else>
                    {{item[displayedProperty]}}
                </div>
            </div>
            <vg-button
                size="xs"
                type="danger"
                @click="deleteItem(item)">
                {{$t('delete')}}
            </vg-button>
        </div>
        <div class="todo-row">
            <vg-input
                v-show="!hideInputText"
                v-model="newItem" 
                :placeholder="placeholderInput?placeholderInput:$t('nouveau')"/>
            <vg-button
                size="xs"
                type="success"
                :disabled="!newItem || newItem.length<1"
                @click="addItem"
                v-show="!hideInputText">
                {{$t('add')}}
            </vg-button>
        </div>
    </div>
</template>

<script>

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";

export default {
    name: "vg-todo-list",
    props: {
        value:{
            type:Array,
            default:function(){
                return null;
            }
        },
        displayedProperty:{
            type:String,
            default: null
        },
        hideInputText:{
            type: Boolean,
            default:false
        },
        messageWhenNoItems:{
            type:String,
            default:function(){
                return "no-items";
            }
        },
        placeholderInput:{
            type:String,
            default: null
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "no-items": "Aucun élément",
            "add": "Ajouter",
            "delete": "Retirer",
            "nouveau": "Nouveau"
        },
        "en": {
            "no-items": "No element",
            "add": "Add",
            "delete": "Remove",
            "nouveau": "New"
        }
    }
},
    components:{
        VgButton,
        VgInput
    },
    data:function(){
        return {
            items:null,
            newItem:null,
        }
    },
    methods:{
        pushItemInList:function(){
            this.value.push()
        },
        addItem:function(){
            if(this.displayedProperty){
                let item = {};
                item[this.displayedProperty] = this.newItem;
                item.userId = this.$app.appID;
                let value = [...this.value];
                value.push(item);
                this.$emit("input", value);
            }else{
                this.$emit('addItem', this.newItem);
            }
            this.newItem = null;

        },
        deleteItem:function(item){
            if(this.displayedProperty){
                this.value.splice(this.value.findIndex(v => v[this.displayedProperty] == item[this.displayedProperty]), 1);
            }
            this.$emit('deleteItem', item);
        },
    },
    created:{
    },
    mounted:{},
}
</script>

<style lang="scss" scoped>
.todo-list{
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
}
.todo-row{
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
    padding:10px 5px;
    border-bottom:1px solid whitesmoke;
    width:100%;
}
.todo-row:hover{
    background-color:whitesmoke;
    cursor:pointer;
}
</style>
