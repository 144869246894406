<template>
    <vg-modal
        :title='getTitle'
        :isSaveDisabled="!isValid"
        :theme="'redskin'"
        @close="onCancel">
        <template #body>
            <div v-if="step == 'scan'">
                {{ $t('scan-etiquette') }}
                <vg-scanner
                    isNewCode
                    @scanSuccess="onScanSuccess" />
            </div>
            <div v-if="step == 'validation'">
                {{$t('valider')}} <b>{{equipement.libelEquipement}}</b>
                {{$t('avec-etiquette')}} <b>{{equipement.qrCode}}</b>
            </div>
        </template>
        <template #footer>
            <vg-button
                @click="onCancel"
            >
                {{$t('cancel')}}
            </vg-button>
            <div v-if="step == 'validation'">
                <vg-button
                    type="success"
                    @click="handleSave"
                >
                    {{$t('yes')}}
                </vg-button>
            </div>
        </template>
    </vg-modal>
</template>
<script>
import VgScanner from 'src/components/Vg/VgScanner.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgModal from 'src/components/Vg/VgModal.vue';
import EquipementsMixins from 'src/mixins/equipementsMixins.js';
import { mapGetters } from 'vuex';

export default {
    name: 'vg-equipement-etiquetage',
	components: {
        VgScanner,
        VgButton,
        VgModal
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "scan-etiquette": "Veuillez scanner le qrCode à coller sur l'équipement",
            "etiquetage": "Etiquetage",
            "yes": "Oui",
            "no": "Non",
            "cancel": "Annuler",
            "valider": "Voulez-vous valider l'étiquetage de l'équipement : ",
            "avec-etiquette": "avec l'étiquette suivante : "
        },
        "en": {
            "scan-etiquette": "Please scan the qrCode to stick on the equipment",
            "etiquetage": "Tagging",
            "yes": "Yes",
            "no": "No",
            "cancel": "Cancel",
            "valider": "Do you confirm the equipment's tagging ",
            "avec-etiquette": "with the following tag : "
        }
    }
},
	mixins: [ EquipementsMixins ],
    props: {},
    data: function() {
        return {
            step:"scan"
        };
    },
    computed: {
        ...mapGetters({
            equipement: 'EquipementsStore/getSelectedItem'
        }),
        getTitle:function(){
            return this.$t("etiquetage")+' '+this.equipement.libelEquipement;
        }
    },
	methods: {
        onScanSuccess:function(code){
            this.equipement.qrCode = code;
            this.equipement.etiquetter = 1;
            this.step = "validation";
        },
        onCancel:function(){
            this.equipement.qrCode = null;
            this.equipement.etiquetter = 0;
            this.$emit('close');
        },
        handleSave:function(){
            let equipement = {
                id:this.equipement.id,
                qrCode:this.equipement.qrCode,
                etiquetter:this.equipement.etiquetter,
                userId:this.$app.appID,
            }
            this.equipementsMixins_update(equipement).then(()=>{
                this.$emit('save');
            });
        }
	}
};

</script>
<style lang="scss" scoped>
.vg-equipement-viewer{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	.img-equipement{
		img{
			width:25px;
			height:25px;
			margin-right: 10px;
		}
	}
	.info-equipement{
		display: flex;
	    flex-direction: column;
		.name{}
		.qrcode{
			font-size: 12px;
		}
	}

}
</style>
