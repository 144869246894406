<template>
    <div id="app-main-container">
        <vg-fin-demo v-if="$app.account_dateFinDemo" />
        <keep-alive>
            <vg-menu 
                @open-search-datas-filter="onOpenSearchDatasFilter"
                @global-filters-change="$emit('global-filters-change', $event)"/>
        </keep-alive>

        <vg-search-datas-filter v-if="showSearchDatasFilter"
            type="modal"
            @close="showSearchDatasFilter=false;"/>
        
        <div id="vg-app-layout" :class="{'vg-app-layout-demo': $app.account_dateFinDemo!=null}">
            <div id="wrapper-content" :class="{'wide-wrapper-content': !isKeyFiguresPanelOpened && (!isZoomLayout && !hideKeyFiguresPanel), 'reduced-wrapper-content': isKeyFiguresPanelOpened && (!isZoomLayout && !hideKeyFiguresPanel)}">
                <!-- Header -->
                <div id="wrapper-header" :class="['vg-print-hide',{'reduced':reducedBody}]">
                    <slot name="header">
                        <div id="wrapper-header-top" :style="'background-color:'+ colorheader" >

                            <!-- Header 1st row -->
                            <slot name="header-top" >
                                <div class="header-top-left" :style="title.length>10 && $vgutils.isMobile() ? 'font-size:13px;': ''" >

                                    <!-- Header 1st row left -->
                                    <slot name="header-top-left" >
                                        <img v-if="!isZoomLayout" v-show="icon" :src="'/static/assets/icone/'+icon" :alt="icon" />
                                        <img v-else-if="isbackButtonDisplayed" :src="!$vgutils.isMobile()?'/static/assets/icone/retour.png':'/static/assets/icone/mobile/fleche-retour.png'" @click="emitBackPage()"
                                            style="cursor:pointer;"/>
                                        <slot name="header-title"><span class="header-top-left-title" :data-keyword="titleDataKeyword">{{ title }}</span></slot>
                                    </slot>
                                    <div class="header-top-left-complement desktop">
                                        <slot name="header-top-left-complement"></slot>
                                    </div>
                                </div>
                                <div class="header-top-right">

                                    <!-- Header 1st row right -->
                                    <slot name="header-top-right" >
                                        <div class="mobile" >
                                            <slot name="header-top-right-pre"></slot>
                                            <vg-button v-if="isSlotNotEmpty('panel-filters') || isSlotNotEmpty('panel-filters-attributs') || isSlotNotEmpty('panel-filters-localisation')"
                                                :type="'default-info'"
                                                class="header-mobile-filters-button"
                                                :pulseLight="activeFilters && activeFilters!='0' && activeFilters!=0"
                                                @click="showModalFilters=true;">
                                                {{$t("filtres")}}
                                            </vg-button>
                                            <div class="header-mobile-icon" v-if="isSlotMenuMoreNotEmpty"
                                                @click="displayMenuMore()">
                                                <i class="fa fa-ellipsis-v vg-font-size-23" ></i>
                                            </div>
                                        </div>
                                        <div class="desktop">
                                            <div class="container-header-top-right container-header-top-search">
                                                <slot name="search" ></slot>
                                            </div>
                                            <div class="container-header-top-right container-header-top-create-button">
                                                <slot name="create-button"></slot>
                                            </div>
                                            <div class="container-header-top-right container-header-top-action-button" v-if="isSlotNotEmpty('action-button') || isSlotNotEmpty('action-button-display-items') || isSlotNotEmpty('action-button-item') || isActionButtonDisplay">
                                                <slot name="action-button">
                                                    <vg-button-dropdown :title="$t('action-button')" v-if="isActionButtonDisplay">
                                                        <slot name="action-button-display-items">
                                                            <li @click="emitEvent('gestionColonnesEventId')">{{$t("gere-colonne")}}</li>
                                                            <li @click="emitEvent('askForCSV')">{{$t("export-csv")}}</li>
                                                            <li v-if="canExportXls" @click="emitEvent('askForXLS')">{{$t("export-xls")}}</li>
                                                            <li v-if="importable" @click="isImportationDonneesModalDisplayed = true" style="display: flex;justify-content: flex-start;align-items: center;gap: 5px;">{{titleImportationDonneesModal}}</li>
                                                            <slot name="action-button-item"></slot>
                                                        </slot>
                                                    </vg-button-dropdown>
                                                </slot>
                                            </div>
                                        </div>
                                    </slot>
                                </div>
                            </slot>

                        </div>
                        <div id="menu-more" v-if="showMenuMore" @click="showMenuMore = !showMenuMore">
                            <slot name="panel-menu-more" ></slot>
                        </div>
                        <div id="wrapper-header-bottom" class="desktop">

                            <!-- Header 2nd row -->
                            <slot name="header-bottom" >
                                <!-- Header 2nd row left -->
                                <div class="header-bottom-left">
                                    <slot name="header-bottom-left" ></slot>
                                    <transition name="fade">
                                        <div :class="['header-bottom-left-multiple-actions', {'active': isSlotLeftActionsButtonsNotEmpty}]">
                                            <div class="__count">
                                                <slot name="header-bottom-left-multiple-actions-count"></slot>
                                            </div>
                                            <div class="__buttons">
                                                <slot name="header-bottom-left-multiple-actions-buttons"></slot>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                                <div class="header-bottom-right">
                                    <slot name="header-bottom-right" ></slot>
                                    <div v-if="!isZoomLayout && ( isSlotNotEmpty('secondary') || isSlotNotEmpty('secondary-filters') || isSlotNotEmpty('secondary-widget') )">
                                        <vg-button v-if="isSecondaryCollapse"
                                            type="grey"
                                            size="sm"
                                            @click="collapseSecondary(false)">
                                            {{$t("ouvrir-filtrage")}} <i class="fas fa-angle-left" style="margin-left:5px;"></i>
                                        </vg-button>
                                        <vg-button v-else
                                            type="grey"
                                            size="sm"
                                            @click="collapseSecondary(true)">
                                            {{$t("fermer-filtrage")}} <i class="fas fa-angle-right" style="margin-left:5px;"></i>
                                        </vg-button>
                                    </div>
                                </div>
                            </slot>
                        </div>
                    </slot>
                </div>

                <!--div :class="['mask-mobile mobile', {'active': showMenuMore}]" @click="displayMenuMore"></div-->

                <!-- Footer - doit etre avant wrapper-body pour le css mobile -->
                <div id="wrapper-footer" v-if="isSlotNotEmpty('footer')" :class="'wrapper-footer-height-'+footerHeight">
                    <slot name="footer"></slot>
                </div>

                <!-- Body -->
                <div id="wrapper-body"
                    :class="[{'reduced':reducedBody},{'full-primary':isSecondaryCollapse == true},{'zoom': isZoomLayout == true}]"
                    v-show="secondaryContent!='widget-lieux'">
                    <vg-button v-if="$vgutils.isMobile() && globalFilters" type="danger" size="sm" style="width:100%;margin-bottom: 10px;" @click="$emit('global-filters-remove')">{{ $t("filtres-recherche-globale") }} <i class="fas fa-times" style="margin-left: 5px;"/></vg-button>
                    <div v-if="$vgutils.isMobile() && activeFilters && activeFilters!='0' && activeFilters!=0" class="badge-actives-filters">
                        <span>{{activeFilters}} {{$t("filtres-actifs")}}</span> 
                        <vg-button :type="'default-info'" @click="$emit('remove-filters')" pulse>{{$t("remove-filters")}}</vg-button>
                    </div>
                    <slot></slot>
                    <slot name="body" >
                        <div id="wrapper-body-secondary" class="vg-print-hide" 
                            v-if="isSlotNotEmpty('secondary') || isSlotNotEmpty('secondary-filters') || isSlotNotEmpty('secondary-widget')">
                            <slot name="secondary">
                                <div id="wrapper-body-secondary-sub-container">
                                    <vg-group collapsable v-if="isSlotNotEmpty('secondary-filters')"
                                        :placeholder="$t('placeholder-filters-hide')">
                                        <template #title>
                                            <div style="display:flex;justify-content:flex-start;align-items:center;gap:10px;">
                                                <img src="static/assets/icone/Icones-60x60-appli-web.png" height="15" alt="" style="margin-right: 0;">
                                                <span>{{$t('filter-title')}}</span>
                                                <vg-button v-if="globalFilters" type="danger" size="sm" @click="$emit('global-filters-remove')">{{ $t("filtres-recherche-globale") }} <i class="fas fa-times" style="margin-left: 5px;"/></vg-button>
                                                <div v-if="activeFilters && activeFilters!='0' && activeFilters!=0" class="badge-actives-filters">
                                                    <span>{{activeFilters}} {{$t("filtres-actifs")}}</span> 
                                                    <vg-button :type="'default-info'" pulse @click="$emit('remove-filters')">{{$t("remove-filters")}}</vg-button>
                                                </div>
                                                <vg-button :type="'default-info'" style="margin-left:10px;" v-show="showSavefilterButton"  @click="$emit('save-filters')"  >
                                                    {{$t("memoriser-filtrage")}}
                                                </vg-button>
                                            </div>
                                        </template>
                                        <slot name="secondary-filters"></slot>
                                    </vg-group>
                                    <slot name="secondary-widget"></slot>
                                </div>
                            </slot>
                        </div>

                        <div id="wrapper-body-primary" class="vg-print-hide">
                            <slot name="primary" ></slot>
                            <slot name="pagination"></slot>
                        </div>
                    </slot>
                </div>
            </div>
            <div v-if="!isZoomLayout && !hideKeyFiguresPanel"
                id="wrapper-key-figures-panel" 
                :class="{'key-figures-panel-opened': isKeyFiguresPanelOpened, 'key-figures-panel-closed': !isKeyFiguresPanelOpened}">
                <vg-key-figures-panel 
                    @collapse="onCollapseKeyFiguresPanel"/>
            </div>

            <!-- Tools :  destiné a mettre les composants non visible comme les modales formulaire ect -->
            <slot name="tools"></slot>

            <vg-modal v-if="showModalFilters"
                @close="showModalFilters=false;">
                <template #header>
                    <i class="fas fa-filter" style="margin-right:10px;"></i> <span>{{filtersTitle?filtersTitle:$t('modal-filters-title')}}</span>
                </template>
                <template #body>
                    <div v-if="activeFilters && activeFilters!='0' && activeFilters!=0" class="badge-actives-filters" style="margin-bottom:10px;">
                        <span>{{activeFilters}} {{$t("filtres-actifs")}}</span> 
                        <vg-button :type="'default-info'" @click="$emit('remove-filters')" pulse>{{$t("remove-filters")}}</vg-button>
                    </div>
                    <slot name="search"></slot>
                    <div style="margin-bottom:20px;"></div>
                    <slot name="panel-filters">
                        <vg-collapse v-if="isSlotNotEmpty('panel-filters-attributs')"
                            :type="'section'" :title="$t('collapse-section-filters-attributs')"
                            :killContentOnCollapse="false" :collapseByDefault="true">
                            <template #content>
                                <slot name="panel-filters-attributs"></slot>
                            </template>
                        </vg-collapse>
                        <br>
                        <vg-collapse v-if="isSlotNotEmpty('panel-filters-localisation') || isSlotNotEmpty('panel-widget-lieux')"
                            :type="'section'" :title="$t('collapse-section-filters-localisation')"
                            :killContentOnCollapse="false" :collapseByDefault="true">
                            <template #content>
                                <slot name="panel-filters-localisation"></slot>
                                <slot name="panel-widget-lieux"></slot>
                            </template>
                        </vg-collapse>
                    </slot>
                </template>
                <template #footer>
                    <vg-button :type="'info'" @click="showModalFilters=false;">{{filtersTitle?filtersTitle:$t('modal-filters-title')}}</vg-button>
                </template>
            </vg-modal>

            <vg-loader v-if="isLoadingState">
            </vg-loader>

            <vg-importation-donnees-modal 
                v-if="isImportationDonneesModalDisplayed" 
                :contactSupportToImportDatas="contactSupportToImportDatas"
                :title="titleImportationDonneesModal"
                @selected-files="handleImportationDonneesFileChange"
                @close="isImportationDonneesModalDisplayed=false"
            >
            </vg-importation-donnees-modal>
            <!--div id="wrapper-body-overlay" v-if="searchValue && searchValue.length!=0">
                <vg-search-datas-results v-model="searchValue"
                    @close="onCloseSearchDatasResults" />
            </div-->
        </div>

    </div>

</template>

<script>
import VgButtonDropdown from 'src/components/Vg/VgButtonDropdown.vue';
import VgLoader from 'src/components/Vg/VgLoader.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgGroup from 'src/components/Vg/VgGroup.vue';
import VgModal from 'src/components/Vg/VgModal.vue';
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgFinDemo from "src/components/Vg/Utils/VgFinDemo.vue";
import VgKeyFiguresPanel from "src/components/Vg/Utils/VgKeyFiguresPanel.vue";
import VgImportationDonneesModal from 'src/components/Vg/ImportationsDonnees/VgImportationDonneesModal.vue';
import VgSearchDatasFilter from 'src/components/Vg/SearchDatas/VgSearchDatasFilter.vue';
export default {
    name:'vg-app-layout',
    components: {
        VgButtonDropdown,
        VgButton,
        VgLoader,
        VgGroup,
        VgModal,
        VgCollapse,
        VgFinDemo,
        VgKeyFiguresPanel,
        VgImportationDonneesModal,
        VgSearchDatasFilter
    },
    props: {
        title:{
            type: String,
            default: ""
        },
        icon:{
            type: String,
            default: ""
        },
        colorheader:{
            type: String,
            default: "white"
        },
        showSecondary: {    // open/close secondary body block
            type: Boolean,
            default: true
        },
        showSavefilterButton: {    // open/close secondary body block
            type: Boolean,
            default: false
        },
        isActionButtonDisplay: {
            type: Boolean,
            default: true
        },
        isZoomLayout:{
            type: Boolean,
            default: false
        },
        isLoadingState:{
            type: Boolean,
            default: false
        },
        reducedBody:{
            type: Boolean,
            default:false
        },
        activeFilters: {
            type: Number | String,
            default: null
        },
        globalFilters: {
            type: Boolean,
            default: false
        },
        filtersTitle:{
            type: String,
            default: null
        },
        isbackButtonDisplayed:{
            type: Boolean,
            default: true
        },
        /**
        * footer height size : sm md lg
        */
        footerHeight: {
            type: String,
            default: "md"
        },
        importable: {
            type: Boolean,
            default: true
        },
        contactSupportToImportDatas:{
            type: Boolean,
            default: true
        },
        titleImportationDonneesModal:{
            type: String,
            default: "Importation de données (excel)"
        },
        hideKeyFiguresPanel: {
            type: Boolean,
            default: false
        },
        titleDataKeyword:{
            type: String,
            default: ''
        },
        canExportXls: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "action-button": "Action",
            "export-xls": "Export Excel (.xlsx)",
            "export-xml": "Export Excel (.xml)",
            "export-csv": "Export CSV",
            "gere-colonne": "Gérer les colonnes",
            "ouvrir-filtrage": "Montrer les filtres",
            "fermer-filtrage": "Cacher les filtres",
            "filter-title": "Filtres",
            "placeholder-filters-hide": "Déployer les filtres",
            "filtres-actifs": "Filtre(s) actif(s)",
            "filtres-recherche-globale": "Recherche globale active",
            "filtres": "Filtres",
            "modal-filters-title": "Filtres",
            "close": "Fermer",
            "collapse-section-filters-attributs": "Filtres attribut",
            "collapse-section-filters-localisation": "Filtres localisation",
            "remove-filters": "supprimer les filtres",
            "memoriser-filtrage": "Mémoriser les filtres",
            "import": "Importer un fichier excel"
        },
        "en": {
            "action-button": "Action",
            "export-xls": "Export Excel (.xlsx)",
            "export-xml": "Export Excel (.xml)",
            "export-csv": "Export CSV",
            "gere-colonne": "Show/hide columns",
            "ouvrir-filtrage": "Show filters",
            "fermer-filtrage": "Hide filters",
            "filter-title": "Filters",
            "placeholder-filters-hide": "Deploy filters",
            "filtres-actifs": "Active filter(s)",
            "filtres-recherche-globale": "Active global research",
            "close": "Close",
            "modal-filters-title": "Filters",
            "filtres": "Filters",
            "collapse-section-filters-attributs": "Attribute filters",
            "collapse-section-filters-localisation": "Location filters",
            "remove-filters": "remove filters",
            "memoriser-filtrage": "Save filters state",
            "import": "Import (soon)"
        },
        "th": {
            "action-button": "ปฏิบัติการ",
            "export-xls": "ส่งออก Excel (.xlsx)",
            "export-xml": "ส่งออก Excel (.xml)",
            "export-csv": "ส่งออก CSV",
            "gere-colonne": "แสดง/ซ่อนคอลัมน์"
        }
    }
},
    data: function(){
        return {
            openSecondary: true,
            secondaryContent: null,
            showMenuMore:false,
            isSecondaryCollapse: !this.showSecondary || false,
            showModalFilters: false,
            isKeyFiguresPanelOpened: true,
            searchValue: null,
            isImportationDonneesModalDisplayed:false,
            showSearchDatasFilter: false
        }
    },
    watch: {
        showSecondary: {
            handler: function(show){
                //console.log("SHOW SECONDARY WATCHER", show);
                this.collapseSecondary(!show);
            }
        }
    },
	computed:{
        isSlotMenuMoreNotEmpty: function(){
          return !!this.$slots["panel-menu-more"];
        },
        isSlotActionButtonNotEmpty: function(){
          return !!this.$slots["action-button"];
        },
        isSlotLeftActionsButtonsNotEmpty: function(){
            return !!this.$slots["header-bottom-left-multiple-actions-buttons"] && !!this.$slots["header-bottom-left-multiple-actions-count"];
        }
    },
    methods: {
        onOpenSearchDatasFilter: function(isOpen){
            this.showSearchDatasFilter = isOpen;
        },
        onCloseSearchDatasResults: function(){
            this.searchValue = null;
        },
        onChangeSearchValue: function(value){
            this.searchValue = value;
        },
        onCollapseKeyFiguresPanel: function(collapse){
            this.isKeyFiguresPanelOpened = !collapse;
            localStorage.setItem("key-figures-panel-opened", this.isKeyFiguresPanelOpened);
        },
        isSlotNotEmpty: function(slotName){
            if(!this.$scopedSlots[slotName]) return false;
            else return !!this.$scopedSlots[slotName]();
        },
        openSecondaryBlock: function(content){
            if(content==this.secondaryContent){
                this.openSecondary = false;
                this.secondaryContent = null;
            }else{
                this.openSecondary = true;
                this.secondaryContent = content;
                this.$emit("open-secondary-block", this.secondaryContent);
            }
        },
        displayMenuMore: function(){
            this.showMenuMore = !this.showMenuMore;
        },
        collapseSecondary: function(bool=null){
            this.isSecondaryCollapse = bool;
        },
        emitEvent: function (eventName) {
            this.$emit("action-export", {name: eventName});
        },
        emitBackPage: function(){
            console.log("BACK PAGE EMIT EVENT");
            this.$emit("backpage");
        },
        handleImportationDonneesFileChange: function(files){
            this.$emit("importation-donnees-file-change", files);
            this.isImportationDonneesModalDisplayed = false;
        }
    },
    created:function(){
        if(localStorage.getItem("key-figures-panel-opened") && localStorage.getItem("key-figures-panel-opened")==="false") this.isKeyFiguresPanelOpened = false;
        this.collapseSecondary(this.isSecondaryCollapse);
    }
}
</script>

<style lang="scss" scoped>

$main-container-height: calc(var(--vh, 1vh) * 100);

.badge-actives-filters{
    font-size:12px;
    margin-left:10px;
    font-weight:normal;
    color: rgb(108, 58, 158);
    padding: 2px 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    .vg-button{
        color: black;
        border-color: rgb(108, 58, 158);
        height:28px;
    }
}
.badge-filter-recherche-globale{
    font-size:12px;
    margin-left:20px;
    font-weight:normal;
    color: red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:5px;
}

@media only screen and (min-width: 1200px){

    $menu-height: 60px;
    $bandeau-fin-demo: 43px;
    $vg-app-layout-height: calc(#{$main-container-height} - #{$menu-height});
    $vg-app-layout-margin-sides: 20px;
    $header-height: 130px;
    $header-top-height: 90px;
    $body-height: calc(#{$vg-app-layout-height} - #{$header-height});
    $body-padding-top: 10px;
    $body-padding-bottom: 10px;
    $body-primary-max-height: calc(#{$body-height} - #{$body-padding-top} - #{$body-padding-bottom});
    $footer-height-sm: 30px;
    $footer-height-md: 50px;
    $footer-height-lg: 100px;
    $key-figures-panel-width: 300px;
    $key-figures-panel-reduced-width: 60px;

    .vg-fin-demo + .vg-menu-navbar{
        /*$vg-app-layout-height: calc(#{$main-container-height} - #{$menu-height} - #{$bandeau-fin-demo});*/
    }
    .mobile{
        display: none !important;
    }
    .reduced{
        width: 50%;
        margin: auto;
    }

    #app-main-container{
        height: $main-container-height;
        #vg-app-layout{
            height: $vg-app-layout-height;
            /*border: 1px solid blue;*/
            display: flex;
            /*flex-direction: column;*/
            justify-content: space-between;
            align-items: center;
            #wrapper-content{
                height: inherit;
                /*width: calc(100% - #{$key-figures-panel-width});*/
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                /*border: 1px solid green;*/
            }
            /*.default-wrapper-content{
                width: ;
            }*/
            .wide-wrapper-content{
                width: calc(100% - #{$key-figures-panel-reduced-width}) !important;
            }
            .reduced-wrapper-content{
                width: calc(100% - #{$key-figures-panel-width}) !important;
            }
            #wrapper-key-figures-panel{
                height: inherit;
                /*width: $key-figures-panel-width;*/
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                /*border: 1px solid red;*/
            }
            .key-figures-panel-opened{
                width: $key-figures-panel-width !important;
            }
            .key-figures-panel-closed{
                width: $key-figures-panel-reduced-width !important;
            }
            #wrapper-header{
                width: 100%;
                height: $header-height;
                order: 1;
                /*border: 1px solid blue;*/
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                padding: 0 $vg-app-layout-margin-sides;
                #wrapper-header-top{
                    height: $header-top-height;
                    width: 100%;
                    display: flex;
                    justify-content: stretch;
                    align-items: center;
                    flex-wrap: nowrap;
                    border-bottom: 1px solid #AFB6BF;
                    .header-top-left{
                        flex-grow: 1;
                        height: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap:10px;
                        font-size: 24px;
                        font-weight: 400;
                        max-width: 60vw;
                        img{
                            height: 30px;
                            width: 30px;
                        }
                        .header-top-left-title{
                            white-space: nowrap;
                        }
                    }
                    .header-top-left-complement{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;
                        height: inherit;
                        overflow-x: auto;
                    }
                    .header-top-right{
                        flex-grow: 1;
                        height: 100%;
                        .desktop{
                            height: 100%;
                            display: flex !important;
                            justify-content: flex-end;
                            align-items: center;
                            gap:10px;
                            .container-header-top-right{
                                
                            }
                            .container-header-top-search{
                                min-width: 150px;
                            }
                            .container-header-top-create-button{
                                min-width: 200px;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                gap: 10px;
                                *{
                                    width:100%;
                                }
                                button{
                                    flex: 1 !important;
                                    max-width: 200px;
                                    min-width: 150px;
                                }
                            }
                            .container-header-top-action-button{
                                min-width: 100px;
                            }
                        }
                    }
                }
                #wrapper-header-bottom{
                    height: calc(#{$header-height} - #{$header-top-height});
                    width: 100%;
                    display: flex;
                    justify-content: stretch;
                    align-items: center;
                    gap:10px;
                    .header-bottom-left{
                        width: 65%;
                        height: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap:10px;
                        .header-bottom-left-multiple-actions{
                            width: 100%;
                            height: 100%;
                            display: inline-flex;
                            padding: 1rem;
                            &.active{
                                //border-radius: 8px;
                                box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                                background: #f8f9fa;
                            }
                            .__count {
                                color: #6c757d;
                                font-size: 0.9rem;
                                margin-bottom: 0.5rem;
                                flex-grow: 1;
                                
                            }
                            .__buttons {
                                width:100%;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 0.5rem;
                                flex-grow: 2;
                            }
                        }
                    }
                    .header-bottom-right{
                        width: 35%;
                        height: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap:10px;
                    }
                }
                #menu-more{
                    background-color: white;
                    color: grey;
                    border-left: 1px solid whitesmoke;
                    height: $vg-app-layout-height;
                    overflow-y: auto;
                    padding: 20px;
                    position: absolute;
                    right: 0px;
                    width: 80%;
                    z-index: 4;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap:10px;
                }
            }
            #wrapper-body{
                height: $body-height;
                max-height: $body-height;
                width: 100%;
                /*border: 1px solid green;*/
                order: 2;
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: flex-start;
                gap: 10px;
                padding: 0 $vg-app-layout-margin-sides;
                #wrapper-body-primary{
                    width:100%;
                    height: 100%;
                    max-height: $body-primary-max-height;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: stretch;
                    align-items: flex-start;
                    >div{
                        width: 100%;
                    }
                }
                #wrapper-body-secondary + #wrapper-body-primary{
                    width: 65%;
                }
                #wrapper-body-secondary{
                    width: 35%;
                    height: 100%;
                    max-height: $body-primary-max-height;
                    overflow-y: auto;
                    .wrapper-body-secondary-sub-container{
                        width: 100%;
                        height: $body-primary-max-height;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }
                &.full-primary{
                    #wrapper-body-primary{
                        width: 100% !important;
                    }
                    #wrapper-body-secondary{
                        width: 0%;
                        display: none;
                    }
                }
                &.zoom{
                    gap: 20px;
                    #wrapper-body-primary{
                        width: 35% !important;
                        padding: 20px;
                        border: 1px solid lightgray;
                        >div{
                            width: 100%;
                        }
                    }
                    #wrapper-body-secondary{
                        width: 65% !important;
                    }
                }
            }
            #wrapper-footer{
                height: $footer-height-md;
                width: 100%;
                bottom: 0px;
                background-color: #f2f2f2;
                border-top: 1px solid #ccc;
                display: flex;
                justify-content: space-around;
                align-items: center;
                gap: 10px;
                order: 3;
            }
            .wrapper-footer-height-sm{
                height: $footer-height-sm !important;
            }
            .wrapper-footer-height-md{
                height: $footer-height-md !important;
            }
            .wrapper-footer-height-lg{
                height: $footer-height-lg !important;
            }
            .wrapper-footer-height-sm + #wrapper-body{
                height: calc(#{$body-height} - #{$footer-height-sm});
                max-height: calc(#{$body-height} - #{$footer-height-sm});
            }
            .wrapper-footer-height-md + #wrapper-body{
                height: calc(#{$body-height} - #{$footer-height-md});
                max-height: calc(#{$body-height} - #{$footer-height-md});
            }
            .wrapper-footer-height-lg + #wrapper-body{
                height: calc(#{$body-height} - #{$footer-height-lg});
                max-height: calc(#{$body-height} - #{$footer-height-lg});
            }

            #wrapper-body-overlay{
                position: fixed;
                top: $menu-height;
                left: 0;
                height: $vg-app-layout-height;
                width: 100%;
                z-index: 100;
                background-color: white;
            }
        }
        .vg-app-layout-demo{
            height: calc(#{$main-container-height} - #{$menu-height} - #{$bandeau-fin-demo}) !important;
        }
    }
}
</style>
<style lang="scss" scoped>
$main-container-height: calc(var(--vh, 1vh) * 100);
@media only screen and (max-width: 1200px){

    $menu-height: 60px;
    $vg-app-layout-height: calc(#{$main-container-height} - #{$menu-height});
    $header-height: 60px;
    $body-height: calc(#{$vg-app-layout-height} - #{$header-height});
    $body-padding-top: 10px;
    $body-padding-bottom: 10px;
    $body-primary-max-height: calc(#{$body-height} - #{$body-padding-top} - #{$body-padding-bottom});
    $footer-height: 50px;

    .desktop{
        display: none !important;
    }
    .mobile{
        display: block !important;
    }
    #app-main-container{
        height: $main-container-height;
        #vg-app-layout{
            height: $vg-app-layout-height;
            /*border: 1px solid blue;*/
            display: flex;
            /*flex-direction: column;*/
            justify-content: space-between;
            align-items: center;
            #wrapper-content{
                height: inherit;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                /*border: 1px solid green;*/
            }
            #wrapper-key-figures-panel{
                display: none;
            }
    /*#app-main-container{
        height: $main-container-height;
        #vg-app-layout{
            height: $vg-app-layout-height;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;*/
            #wrapper-header{
                width: 100%;
                height: $header-height;
                color: white;
                order: 1;
                #wrapper-header-top{
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 10px;
                    flex-wrap: nowrap;
                    .header-top-left{
                        height: inherit;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap:10px;
                        img{
                            height: 30px;
                            width: 30px;
                        }
                    }
                    .header-top-right{
                        height: 100%;
                        .mobile{
                            height: 100%;
                            display: flex !important;
                            justify-content: flex-end;
                            align-items: center;
                            gap:15px;
                            .header-mobile-icon{
                                width: 25px;
                                text-align: center;
                            }
                            .header-mobile-filters-button{
                                min-width: 80px !important;
                                height: 28px !important;
                                font-size: 14px !important;
                                color: rgb(108, 58, 158) !important;
                                border-color: rgb(108, 58, 158) !important;
                                opacity: 100 !important;
                            }
                        }
                    }
                }
                #menu-more{
                    background-color: white;
                    color: grey;
                    border-left: 1px solid whitesmoke;
                    height: $vg-app-layout-height;
                    overflow-y: auto;
                    padding: 20px;
                    position: absolute;
                    right: 0px;
                    width: 80%;
                    z-index: 4;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap:10px;
                }
                #wrapper-header-bottom{
                    display: none;
                }
            }
            #wrapper-body{
                height: $body-height;
                max-height: $body-height;
                width: 100%;
                /*border: 1px solid green;*/
                padding: $body-padding-top 10px $body-padding-bottom 10px;
                overflow-y: auto;
                order: 2;
                #wrapper-body-primary{
                    width: 100%;
                    height: 100%;
                    max-height: $body-primary-max-height;
                    overflow-y: auto;
                }
                #wrapper-body-secondary{
                    display: none;
                }
                .badge-actives-filters{
                    margin-bottom: 10px;
                }
            }
            #wrapper-footer{
                height: $footer-height;
                width: 100%;
                bottom: 0px;
                background-color: #f2f2f2;
                border-top: 1px solid #ccc;
                display: flex;
                justify-content: space-around;
                align-items: center;
                gap: 10px;
                order: 3;
            }
            #wrapper-footer + #wrapper-body{
                height: calc(#{$body-height} - #{$footer-height});
                max-height: calc(#{$body-height} - #{$footer-height});
            }
        }
    }
}

@media print {
    #vg-fin-demo{
        display: none;
    }
	#vg-menu-navbar{
        display: none;
    }
    #wrapper-header{
        display: none;
    }
}

</style>
<!--style>
.mask-mobile{
    background: black;
    opacity: 0;
    z-index: 3;
    width: 100%;
    display: none;
    position: absolute;
    height: 100%;
    top: 120px;
    &.active{
        display: block;
        opacity: 0.5;
    }
}
</style-->
