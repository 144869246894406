<template>
	<div class="navbar" id="vg-menu-navbar">
		<div class="navbar-main">
			<a href="javascript:void(0);" class="collapse-icon" @click="openMenu()">
				<i class="fa fa-bars fa-2x"></i>
			</a>
			<a class="navbar-logo">
				<img src="/static/assets/icone/New-logo-verifgood-jaune-blanc.png" />
			</a>
			<div :class="['menu-sections', {'menu-sections-full-width': isGlobalFilterFocused}]">
				<div class="menu-section-recherche-globale only-responsive" v-if="$app.role!='ROLE_CHEF_EQUIPE'">
					<vg-search-datas-filter key="search-datas-filter-mobile"
						noInteraction
						@click-with-no-interaction="onClickWithNoInteraction"/>
					<router-link :to="{name:'_scanner_type', params: {typeScanner: 'equipement'}}">
						<vg-icon url="static/assets/icone/menu/menu-scann.png" color="#eee" size="30"/>
					</router-link>
				</div>
				<div class="menu-section-recherche-globale only-responsive" v-if="$app.role=='ROLE_CHEF_EQUIPE'">
					<router-link :to="{name:'_lancement'}" class="only-responsive" >
						<img src="static/assets/icone/mobile/verification.png" style="margin-right: 10px;">
						{{ $t("headbar.verification.lancement") }}
					</router-link>
				</div>
				<div class="menu-section-recherche-globale only-responsive" v-if="$app.role=='ROLE_CHEF_EQUIPE'">
					<router-link :to="{name:'_process_verification_equipement_selection',params:{'type':'lieux'}}" class="only-responsive" 
						v-show="can('CHECKS.PAGE')">
						<img src="static/assets/icone/mobile/lieu-blanc.png" style="margin-right: 10px;">
						{{ $t("headbar.verification.checkerLieu") }}
					</router-link>
				</div>
				<div class="menu-sections-container">
					<div class="menu-section menu-section-1 not-responsive" v-show="showMenuRecensement"
						@mouseleave="onMouseLeaveMenu('lieuxequipements')">
						<button class="menu-section-title">
							{{ $t("menu-1-title") }}
							<i class="fas fa-angle-down not-responsive"></i>
						</button>
						<div class="menu-sub-sections menu-sub-section-1">
							<div class="not-responsive router-link-exact-active router-link-active menu-with-submenu" v-show="can('LIEUX.PAGE')">
								<a @mouseenter="onMouseEnterSection('lieux')">
									<img src="static/assets/icone/menu/lieu.png" />
									{{ $t('menu-1-section-lieux')}}
									<div :class="['menu-collapseur', {'menu-collapseur-open': isSubMenuLieuxVisible}]">
										<i class="fa fa-chevron-right"></i>
									</div>
								</a>
								<div :class="['submenu', {'submenu-visible': isSubMenuLieuxVisible}]">
									<router-link :to="{name:'_lieux'}">
										<img src="static/assets/icone/menu/lieu.png" />
										{{ $t('menu-1-lieux')}}
									</router-link>
									<router-link :to="{name:'_categories_lieux'}" v-show="can('CATEGORIES_LIEUX.PAGE')">
										<img src="static/assets/icone/menu/categorie-lieu.png">
										{{ $t("menu-1-categories-lieux") }}
									</router-link>
									<router-link :to="{name:'_plan_interactif'}" v-show="can('PLAN_INTERACTIF.PAGE')">
										<img src="static/assets/icone/menu/lieu.png">
										{{ $t("menu-1-plan-interactif") }}
									</router-link>
								</div>
							</div>
							<div class="not-responsive router-link-exact-active router-link-active menu-with-submenu" v-show="can('EQUIPEMENTS.PAGE') || can('CATEGORIES_EQUIPEMENTS.PAGE')">
								<a @mouseenter="onMouseEnterSection('equipements')">
									<img src="static/assets/icone/menu/equipement.png"/>
									{{ $t('menu-1-section-equipements')}}
									<div :class="['menu-collapseur', {'menu-collapseur-open': isSubMenuEquipementsVisible}]">
										<i class="fa fa-chevron-right"></i>
									</div>
								</a>
								<div :class="['submenu', {'submenu-visible': isSubMenuEquipementsVisible}]">
									<router-link :to="{name:'_equipements'}" v-show="can('EQUIPEMENTS.PAGE')" >
										<img src="static/assets/icone/menu/equipement.png"/>
										{{ $t('menu-1-equipements')}}
									</router-link>
									<router-link :to="{name:'_categories_equipements'}"  v-show="can('CATEGORIES_EQUIPEMENTS.PAGE')">
										<img src="static/assets/icone/menu/categorie-equipement.png"/>
										{{ $t("menu-1-categories-equipements") }}
									</router-link>
									<router-link :to="{name:'_mouvements_equipements'}" v-show="can('MOUVEMENTS_EQUIPEMENTS.PAGE')">
										<img src="static/assets/icone/menu/equipement.png"/>
										{{ $t('menu-1-mouvements-equipements')}}
									</router-link>
									<router-link :to="{name:'_vg_inventaire'}" v-show="can('INVENTAIRES.PAGE')">
										<img src="static/assets/icone/menu/inventaire.png" />
										{{ $t('menu-1-inventaires-equipements')}}
									</router-link>
									<router-link :to="{name:'_equipements_rapport'}" v-show="can('REGISTRE_IMMOBILISATIONS.PAGE')">
										<img src="static/assets/icone/menu/equipement.png"/>
										{{ $t('menu-1-registre-immobilisations')}}
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="menu-section menu-section-1 only-responsive" v-show="showMenuRecensement">
						<button class="menu-section-title">
							{{ $t("menu-1-section-lieux") }}
						</button>
						<div class="menu-sub-sections menu-sub-section-1">
							<router-link :to="{name:'_sites'}" v-show="can('SITES.PAGE')">
								{{ $t('menu-1-sites')}}
							</router-link>
							<router-link :to="{name:'_lieux'}" v-show="can('LIEUX.PAGE')">
								<!--img src="static/assets/icone/mobile/lieu-blanc.png" /-->
								{{ $t('menu-1-pieces')}}
							</router-link>
							<router-link :to="{name:'_plan_interactif'}" v-show="can('PLAN_INTERACTIF.PAGE')">
								{{ $t("menu-1-plan-interactif") }}
							</router-link>
						</div>
					</div>
					<div class="menu-section menu-section-1 only-responsive" v-show="showMenuRecensement">
						<button class="menu-section-title">
							{{ $t("menu-1-section-equipements") }}
						</button>
						<div class="menu-sub-sections menu-sub-section-1">
							<div class="section" v-show="can('EQUIPEMENTS.PAGE')">
								<router-link :to="{name:'_equipements'}" >
									{{ $t('menu-1-equipements')}}
								</router-link>
								<router-link :to="{name:'_scanner_type', params: {typeScanner: 'equipement'}}">
									<vg-icon url="static/assets/icone/menu/menu-scann.png" color="#eee" size="20"/>
								</router-link>
							</div>
							<router-link :to="{name:'_categories_equipements'}"  v-show="can('CATEGORIES_EQUIPEMENTS.PAGE')">
								{{ $t("menu-1-categories-equipements") }}
							</router-link>
							<router-link :to="{name:'_mouvements_equipements'}" v-show="can('MOUVEMENTS_EQUIPEMENTS.PAGE')">
								{{ $t('menu-1-mouvements-equipements')}}
							</router-link>
							<router-link :to="{name:'_vg_inventaire'}" v-show="can('INVENTAIRES.PAGE')">
								<img src="static/assets/icone/mobile/Icone-blanche-equipement_VG.png">
								{{ $t('menu-1-inventaires-equipements')}}
							</router-link>
						</div>
					</div>
					<div class="menu-section menu-section-2" v-show="showMenuVerification"
						@mouseleave="onMouseLeaveMenu('preventif')">
						<button class="menu-section-title" v-show="$app.role!='ROLE_CHEF_EQUIPE'">{{ $t("headbar.verification.name") }}
							<i class="fas fa-angle-down not-responsive"></i>
						</button>
						<div class="menu-sub-sections menu-sub-section-2" v-show="$app.role!='ROLE_CHEF_EQUIPE'">
							<router-link :to="{name:'_plan_maintenance_preventive'}" v-show="can('PLAN_MAINTENANCE_PREVENTIF.PAGE')" class="not-responsive">
								<img src="static/assets/icone/menu/equipement.png">
								{{$t('headbar.verification.plan-maintenance')}}
							</router-link>
							<!--router-link :to="{name:'_gamme_maintenance_preventive'}" v-show="can('GAMME_MAINTENANCE_PREVENTIF.PAGE')" class="not-responsive">
								<img src="static/assets/icone/menu/equipement.png">
								{{$t('headbar.verification.gamme-maintenance')}}
							</router-link-->
							<router-link :to="{name:'_contrat'}" class="not-responsive" v-show="can('CONTRATS.PAGE')">
								<img src="static/assets/icone/menu/contrat.png">
								{{ $t("headbar.tiers.contrat") }}
							</router-link>
							<router-link :to="{name:'_interventions'}" v-show="can('INTERVENTIONS.PAGE')">
								<img src="static/assets/icone/menu/intervention.png" class="not-responsive">
								<img src="static/assets/icone/mobile/intervention_blanc.png" class="only-responsive">
								{{ $t("headbar.tiers.interventions") }}
							</router-link>
							<router-link :to="{name:'_reserves'}" v-show="can('RESERVES.PAGE')" class="not-responsive">
								<img src="static/assets/icone/menu/maintenance.png" alt="reserves-blanc" >
								{{ $t('headbar.maintenance.reserves')}}
							</router-link>
							<div class="not-responsive router-link-exact-active router-link-active menu-with-submenu">
								<a @mouseenter="onMouseEnterSection('preventif')">
									<img src="static/assets/icone/menu/tache-de-verification.png" />
									{{ $t('headbar.verification.tache')}}
									<div :class="['menu-collapseur', {'menu-collapseur-open': isSubMenuPreventifVisible}]">
										<i class="fa fa-chevron-right"></i>
									</div>
								</a>
								<div :class="['submenu', {'submenu-visible': isSubMenuPreventifVisible}]">
									<router-link :to="{name:'_taches'}" class="not-responsive" v-show="can('TACHES.PAGE')">
										<img src="static/assets/icone/menu/tache-de-verification.png">
										{{ $t('headbar.verification.gerer-taches')}}
									</router-link>
									<router-link :to="{name:'_verification'}" v-show="can('VERIFICATIONS.PAGE')">
										<img src="static/assets/icone/mobile/verification.png">
										{{ $t('headbar.verification.histoVerif')}}
									</router-link>
									<router-link :to="{name:'_verification_historique_compteur'}" v-show="can('VERIFICATIONS.PAGE')">
										<img src="static/assets/icone/menu/historique-releve-de-compteur.png">
										{{ $t('headbar.verification.histoCompteur')}}
									</router-link>
									<router-link :to="{name:'_verification_historique_grandeur_physique'}" v-show="can('VERIFICATIONS.PAGE')">
										<img src="static/assets/icone/mobile/temperature.png" class="ico-header">
										{{ $t('headbar.verification.histoGrandPhysique')}}
									</router-link>
									<router-link :to="{name:'_progressions'}" v-show="can('PROGRESSION.PAGE')">
										<img src="static/assets/icone/mobile/progression-des-verifications.png">
										{{ $t("headbar.verification.progression") }}
									</router-link>
								</div>
							</div>
							<router-link :to="{name:'_verification'}" v-show="can('VERIFICATIONS.PAGE')" class="only-responsive">
								<img src="static/assets/icone/mobile/verification.png" class="only-responsive">
								{{ $t('headbar.verification.histoVerif')}}
							</router-link>
							<router-link :to="{name:'_progressions'}" v-show="can('PROGRESSION.PAGE')" class="only-responsive">
								<img src="static/assets/icone/mobile/progression-des-verifications.png" >
								{{ $t("headbar.verification.progression") }}
							</router-link>

							<div class="section" v-show="can('CHECKS.PAGE') && $app.role!='ROLE_CHEF_EQUIPE'">
								<router-link :to="{name:'_process_verification_equipement_selection',params:{'type':'equipement'}}" class="only-responsive">
									<img src="static/assets/icone/mobile/Icone-blanche-equipement_VG.png">
									{{ $t("headbar.verification.checkerEquipement") }}
								</router-link>
								<router-link :to="{name:'_process_verification_equipement_selection',params:{'type':'equipement'}}" class="only-responsive">
									<vg-icon url="static/assets/icone/menu/menu-scann.png" color="#eee" size="20"/>
								</router-link>
							</div>

							<router-link :to="{name:'_process_verification_equipement_selection',params:{'type':'compteur'}}" class="only-responsive" 
								v-show="can('CHECKS.PAGE') && $app.role!='ROLE_CHEF_EQUIPE'">
								<img src="static/assets/icone/menu/historique-releve-de-compteur.png">
								{{ $t("headbar.verification.checkerCompteur") }}
							</router-link>
							<router-link :to="{name:'_process_verification_equipement_selection',params:{'type':'grandeur-physique'}}" class="only-responsive" 
								v-show="can('CHECKS.PAGE') && $app.role!='ROLE_CHEF_EQUIPE'">
								<img src="static/assets/icone/mobile/temperature.png">
								{{ $t("headbar.verification.checkerGrandeurPhysique") }}
							</router-link>
							<router-link :to="{name:'_process_verification_equipement_selection',params:{'type':'lieux'}}" class="only-responsive" 
								v-show="can('CHECKS.PAGE')">
								<img src="static/assets/icone/mobile/lieu-blanc.png">
								{{ $t("headbar.verification.checkerLieu") }}
							</router-link>
						</div>
					</div>
					<div class="menu-section menu-section-3">
						<button class="menu-section-title" v-show="$app.role!='ROLE_CHEF_EQUIPE'">{{ $t("headbar.maintenance.name") }}
							<i class="fas fa-angle-down not-responsive"></i>
						</button>
						<div class="menu-sub-sections menu-sub-section-3">
							<div class="section" v-show="can('DEMANDE_INTERVENTION.PAGE') && $app.role!='ROLE_CHEF_EQUIPE'">
								<router-link :to="{name:'_demande_intervention'}" >
									<img src="static/assets/icone/menu/demande-d_intervention.png" class="not-responsive">
									<img src="static/assets/icone/mobile/di_blanc.png" class="only-responsive">
									{{ $t('headbar.maintenance.di')}}
								</router-link>
								<router-link :to="{name:'_scanner', params: {redirect: '_demande_intervention'}}" class="only-responsive">
									<vg-icon url="static/assets/icone/menu/menu-scann.png" color="#eee" size="20"/>
								</router-link>
							</div>
							<div class="section" v-show="can('MAINTENANCES.PAGE')">
								<router-link :to="{name:'_maintenances'}">
									<img src="static/assets/icone/menu/maintenance.png" class="not-responsive">
									<img src="static/assets/icone/mobile/cle_blanc.png" class="only-responsive">
									{{ $t('headbar.maintenance.fm')}}
								</router-link>
								<router-link :to="{name:'_maintenances', params: {openScanner: true}}" class="only-responsive">
									<vg-icon url="static/assets/icone/menu/menu-scann.png" color="#eee" size="20"/>
								</router-link>
							</div>
							<router-link :to="{name:'_maintenance_operations'}" v-show="can('MAINTENANCES_TACHES.PAGE')">
								<img src="static/assets/icone/menu/maintenance.png" class="not-responsive">
								<img src="static/assets/icone/mobile/cle_blanc.png" class="only-responsive">
								{{ $t('headbar.maintenance.operations')}}
							</router-link>
							<router-link :to="{name:'_maintenance_activites'}" v-show="can('ACTIVITE_MAINTENANCE.PAGE')">
								<img src="static/assets/icone/menu/fil.png" class="not-responsive">
								<img src="static/assets/icone/mobile/fil_blanc.png" class="only-responsive">
								{{ $t('headbar.maintenance.fil')}}
							</router-link>
							<!--router-link :to="{name:'_planification', params: {focus: 'maintenance'}}" v-show="can('PLANIFICATION.PAGE')" class="not-responsive">
								<img src="static/assets/icone/menu/planification.png">
								{{$t('headbar.maintenance.planification')}}
							</router-link-->
						</div>
					</div>
					<div class="menu-section menu-section-4" v-show="showMenuGestionDeStock">
						<button class="menu-section-title">{{ $t("headbar.stock.name") }}
							<i class="fas fa-angle-down not-responsive"></i>
						</button>
						<div class="menu-sub-sections menu-sub-section-4">
							<router-link :to="{name:'_demande_consommables'}" v-show="can('DEMANDE_CONSOMMABLES.PAGE')" >
								<img src="static/assets/icone/menu/demande-d_intervention.png" class="not-responsive">
								<img src="static/assets/icone/mobile/di_blanc.png" class="only-responsive">
								{{ $t('headbar.stock.demande-consommables')}}
							</router-link>
							<router-link :to="{name:'_consommables'}" v-show="can('CONSOMMABLES.PAGE')">
								<div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
									<vg-icon url="static/assets/icone/menu/menu-gear.svg" color="#BBBFC7" size="20" />
									<span>{{ $t('headbar.stock.consommables')}}</span>
								</div>
							</router-link>
							<router-link :to="{name:'_mouvements_consommables'}" v-show="can('MOUVEMENTS_CONSOMMABLES.PAGE')">
								<div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
									<vg-icon url="static/assets/icone/menu/menu-gear.svg" color="#BBBFC7" size="20"/>
									<span>{{ $t('headbar.stock.mouvements-consommables')}}</span>
								</div>
							</router-link>
							<router-link :to="{name:'_bons'}" v-show="can('BONS.PAGE')">
								<div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
									<vg-icon url="static/assets/icone/menu/menu-gear.svg" color="#BBBFC7" size="20" />
									<span>{{ $vgutils.isMobile()?$t('headbar.stock.bons-de-sortie'):$t('headbar.stock.bons-dentree-bons-de-sortie')}}</span>
								</div>
							</router-link>
							<router-link :to="{name:'_fichedemandeconsommables'}" v-show="can('FICHES_DEMANDE_CONSOMMABLES.PAGE')">
								<div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
									<vg-icon url="static/assets/icone/menu/menu-gear.svg" color="#BBBFC7" size="20" />
									<span>{{ $t('headbar.stock.fichedemandeconsommables')}}</span>
								</div>
							</router-link>
							<router-link :to="{name:'_bons_de_commande'}" v-show="can('BONS_DE_COMMANDE.PAGE')" class="not-responsive">
								<div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
									<vg-icon url="static/assets/icone/menu/menu-gear.svg" color="#BBBFC7" size="20" />
									<span>{{ $t('headbar.stock.bonsDeCommande')}}</span>
								</div>
							</router-link>
						</div>
					</div>
					<div class="menu-section menu-section-5" v-show="can('TIERS.PAGE') || can('CONTRATS.PAGE') || can('INTERVENTIONS.PAGE')">
						<button class="menu-section-title">{{ $t('headbar.tiers.name')}}
							<i class="fa fa-angle-down not-responsive"></i>
						</button>
						<div class="menu-sub-sections menu-sub-section-5" >
							<router-link :to="{name:'_tiers'}" v-show="can('TIERS.PAGE')">
								<img src="static/assets/icone/menu/tiers-contact.png" class="not-responsive">
								<img src="static/assets/icone/mobile/tiers.png" class="only-responsive">
								{{ $t("headbar.tiers.tiersContacts") }}
							</router-link>
							<router-link :to="{name:'_contrat'}" class="not-responsive" v-show="can('CONTRATS.PAGE')">
								<img src="static/assets/icone/menu/contrat.png">
								{{ $t("headbar.tiers.contrat") }}
							</router-link>
						</div>
					</div>
					<div class="menu-section menu-section-6 not-responsive" v-show="can('DASHBOARD.PAGE')">
						<router-link :to="{name:'_dashboard'}"
							tag="button"
							class="menu-section-title">
							{{$t("headbar.dashboard")}}
						</router-link>
					</div>
					<div class="menu-section menu-section-7 only-responsive" v-show="can('DASHBOARD.PAGE')">
						<button class="menu-section-title">{{ $t("headbar.dashboard") }}
							<i class="fas fa-angle-down not-responsive"></i>
						</button>
						<div class="menu-sub-sections menu-sub-section-7">
							<router-link :to="{name:'_dashboard_focus', params: {focus: 'curative'}}">
								{{$t("headbar.dashboard-curative")}} 
							</router-link>
							<router-link :to="{name:'_dashboard_focus', params: {focus: 'preventive'}}">
								{{$t("headbar.dashboard-preventive")}} 
							</router-link>
						</div>
					</div>

					<div class="menu-section menu-section-8 not-responsive" v-show="can('CALENDRIER.PAGE')">
						<router-link :to="{name:'_calendar'}"
							tag="button"
							class="menu-section-title">
							{{$t("headbar.calendar")}}
						</router-link>
					</div>
					<div class="menu-section menu-section-8 only-responsive" v-show="can('CALENDRIER.PAGE')">
						<button class="menu-section-title">
							{{ $t("headbar.calendar") }}
							<i class="fas fa-angle-down not-responsive"></i>
						</button>
						<div class="menu-sub-sections menu-sub-section-8">
							<router-link :to="{name:'_calendar'}">{{$t("headbar.calendar")}}</router-link>
						</div>
					</div>

					<div class="menu-section-9 only-responsive">
						<div class="menu-line"></div>
					</div>
					<div class="menu-section menu-section-10 only-responsive">
						<div class="menu-sub-sections" >
							<a @click="handleDisconnectSession">
								<vg-icon url="static/assets/icone/menu/menu-logout.svg" color="#eee" size="25" style="margin-right:10px;"/> {{ $t("myAccount.deco") }}
							</a>
							<a @click="clearStorage">
								<vg-icon url="static/assets/icone/menu/menu-logout.svg" color="#eee" size="25" style="margin-right:10px;"/> {{ $t("reset") }}
							</a>
							<br>
							<div style="display: flex;justify-content: space-around;align-items: center;gap:10px;">
								<vg-lang-selector />
								<vg-helper menu/>
							</div>
							<br>
							<img id="badge-google-play" v-if="$vgutils.isMobile() && $vgutils.isAndroid()" src="/static/assets/google-play-badge.png" @click="openStore('google-play')">
							<img id="badge-app-store" v-if="$vgutils.isMobile() && $vgutils.isIOS() " src="/static/assets/appstore-badge.png" @click="openStore('app-store')">
							<br>
							<br>
						</div>
					</div>
				</div>
				
			</div>
			<div :class="['menu-overlay', {'menu-overlay-hidden': isGlobalFilterFocused}]" @click="openMenu()">
			</div>
		</div>
		<div class="navbar-secondary not-responsive">
			<vg-search-datas-filter key="search-datas-filter-desktop"
				noInteraction
				style="flex-grow: 8;"
				menu
				@click-with-no-interaction="onClickWithNoInteraction"
				@filters-change="onFiltersChange"/>
			<!-- parametres -->
			<router-link :to="{name:'_parametres'}" v-show="can('PARAMETRES.PAGE')">
				<!--i class="fa fa-cog fa-2x" style="color:#ccc;"></i-->
				<!--img width="30" height="30" src="static/assets/icone/menu/menu-gear.svg" style="color:#eee;"/-->
				<vg-icon url="static/assets/icone/menu/menu-gear.svg" color="#BBBFC7" size="25"/>
			</router-link>
			<!-- invitations -->
			<router-link :to="{name:'_manage_invitations'}" v-show="can('PARAMETRES.PAGE')">
				<vg-icon url="static/assets/icone/custom/link.svg" color="#BBBFC7" size="25"/>
			</router-link>
			<!-- helper -->
			<vg-helper menu/>
			<!-- user infos -->
			<div class="user-profile"
				style="display:flex;justify-content:space-between;align-items:center;gap:10px;cursor:pointer;" @click="showMyAccountModal=true;">
				<div style="display:flex;flex-direction:column;justify-content:center;align-items:flex-start;">
					<span style="font-size:15px;color:#eee;font-weight: bold;">{{$app.nom}}</span>
					<span style="font-size:11px;color:#888E97;">{{$app.prenom}}</span>
				</div>
				<vg-icon url="static/assets/icone/menu/menu-chevron-down.svg" color="#eee" size="18" />
			</div>
		</div>
		<div class="navbar-secondary only-responsive" style="font-size:12px;">
			<div style="height:100%;display:flex;flex-direction:column;justify-content:center;align-items:flex-end;">
				<span style="font-size:12px;color:#eee;font-weight: bold;">{{$app.nom}}</span>
				<span style="font-size:10px;color:#888E97;">{{$app.prenom}}</span>
			</div>
		</div>
		<vg-my-account v-if="showMyAccountModal"
			@close="showMyAccountModal=false;" />
	</div>
</template>
<script>
	import VgIcon from 'src/components/Vg/VgIcon.vue';
	import VgMyAccount from 'src/components/Vg/User/VgMyAccount.vue';
	import VgSearchDatasFilter from 'src/components/Vg/SearchDatas/VgSearchDatasFilter.vue';
	// import mynotifications from 'src/components/Popover/Mynotifications.vue';
    import PersonalParameters from 'src/mixins/personnalParameters';

	import VgLangSelector from "src/components/Vg/Internationalisation/VgLangSelector.vue";
	import VgNewBadge from "src/components/Vg/Utils/VgNewBadge.vue";

	import VgFilesMixins from "src/components/Vg/Files/VgFilesMixins.js";

	import VgHelper from "src/components/Vg/Utils/VgHelper.vue";
    import UserMixins from "src/mixins/UserMixins";

    import { mapActions } from 'vuex';
    export default {
        name: 'vg-menu',
	    i18n:{
			messages: {
				"fr": {
					"menu-1-title": "Lieux & équipements",
					"menu-1-section-lieux": "Lieux",
					"menu-1-sites": "Liste des sites",
					"menu-1-lieux": "Liste des lieux",
					"menu-1-pieces": "Liste des pièces",
					"menu-1-categories-lieux": "Catégories lieux",
					"menu-1-plan-interactif": "Plan interactif",
					"menu-1-section-equipements": "Equipements",
					"menu-1-equipements": "Liste des équipements",
					"menu-1-categories-equipements": "Catégories équipements",
					"menu-1-mouvements-equipements": "Mouvements équipements",
					"menu-1-inventaires-equipements": "Inventaires équipements",
					"menu-1-registre-immobilisations": "Registre des immobilisations",
					"headbar": {
						"verification": {
							"name": "Préventif",
							"tache": "Tâches récurrentes",
							"gerer-taches": "Gérer tâches récurrentes",
							"histoVerif": "Historique tâches récurrentes",
							"histoCompteur": "Historique relevés compteurs",
							"histoGrandPhysique": "Historique relevés grandeurs physiques",
							"progression": "Progression",
							"plan-maintenance": "Plan de maintenance",
							"gamme-maintenance": "Gamme de maintenance",
							"checkerLieu": "Check lieu",
							"lancement": "Ronde",
							"checkerEquipement": "Check équipement",
							"checkerGrandeurPhysique": "Check grandeur physique",
							"checkerCompteur": "Check compteur",
							"relever" : "Relever compteur"
						},
						"maintenance": {
							"name": "Curatif",
							"di": "Demande d'intervention",
							"fm": "Fiches curatives",
							"operations": "Sous-actions fiches curatives",
							"reserves": "Réserves / Observations",
							"fil": "Fil fiches curatives",
							"planification": "Planification"
						},
						"stock": {
							"name": "Gestion de stock",
							"demande-consommables": "Demande consommables",
							"consommables": "Consommables",
							"mouvements-consommables": "Mouvements consommables",
							"bons-dentree-bons-de-sortie": "Bons de sortie / Bons d'entrée",
							"bons-de-sortie": "Bons de sortie",
							"fichedemandeconsommables": "Fiches demandes consommables",
							"bonsDeCommande": "Bons de commande"
						},
						"tiers": {
							"name": "Tiers",
							"tiersContacts": "Tiers / Contacts",
							"interventions": "Historique interventions tiers",
							"contrat": "Contrats tiers"
						},
						"dashboard":"Tableau de Bord",
						"dashboard-curative": "Maintenance curative",
						"dashboard-preventive": "Maintenance préventive",
						"calendar":"Calendrier"
					},
					"myAccount": {
						"deco": "Déconnexion",
						"optionScannerIntegratedInKeyboard": "Je possède un scanner intégré au clavier"
					}
				},
				"en": {
					"menu-1-title": "Locations & Equipment",
					"menu-1-section-lieux": "Locations",
					"menu-1-sites": "Site List",
					"menu-1-lieux": "Location List",
					"menu-1-pieces": "Room List",
					"menu-1-categories-lieux": "Location Categories",
					"menu-1-plan-interactif": "Interactive Map",
					"menu-1-section-equipements": "Equipments",
					"menu-1-equipements": "Equipment List",
					"menu-1-categories-equipements": "Equipment Categories",
					"menu-1-mouvements-equipements": "Equipment Movements",
					"menu-1-inventaires-equipements": "Equipment Inventories",
					"menu-1-registre-immobilisations": "Asset Register",
					"headbar": {
						"verification": {
							"name": "Preventive",
							"tache": "Recurrent tasks",
							"gerer-taches": "Manage recurrent tasks",
							"histoVerif": "Recurrent tasks log",
							"histoCompteur": "Meters history",
							"histoGrandPhysique": "Physical values history",
							"progression": "Progress",
							"plan-maintenance": "Maintenance plan",
							"gamme-maintenance": "Maintenance range",
							"checkerLieu": "Check place",
							"checkerEquipement": "Check equipment",
							"checkerGrandeurPhysique": "Check physical value",
							"checkerCompteur": "Check meter",
							"relever" : "Read counter"
						},
						"maintenance": {
							"name": "Curative",
							"di": "Intervention request",
							"fm": "Curative sheets",
							"operations": "Curative sheet sub-actions",
							"reserves": "Reservations / Observations",
							"fil": "Curative sheets thread",
							"planification": "Planning"
						},
						"stock": {
							"name": "Stock managment",
							"demande-consommables": "Consumables request",
							"consommables": "Consumables",
							"mouvements-consommables": "Consumables movements",
							"bons-dentree-bons-de-sortie": "Exit vouchers / Entrance vouchers",
							"bons-de-sortie": "Exit vouchers",
							"fichedemandeconsommables": "Consumables request sheets",
							"bonsDeCommande": "Purchase orders"
						},
						"tiers": {
							"name": "Suppliers",
							"tiersContacts": "Suppliers / Contacts",
							"contrat": "Suppliers contracts",
							"interventions": "Third-party intervention history"
						},
						"dashboard":"Dashboard",
						"dashboard-curative": "Curative maintenance",
						"dashboard-preventive": "Preventive maintenance",
						"calendar":"Calendar"
					},
					"myAccount": {
						"deco": "logout",
						"optionScannerIntegratedInKeyboard": "I have a scanner integrated in my keyboard"
					}
				},
				"th": {
					"headbar": {
						"recensement": {
							"name": "สินค้าคงคลัง",
							"equipement": "อุปกรณ์",
							"lieux": "ตำแหน่งที่ตั้ง",
							"categories-equipement": "หมวดหมู่อุปกรณ์",
							"categories-lieu": "หมวดหมู่ชิ้นส่วน"
						},
						"verification": {
							"checkerLieu": "ตรวจสอบสถานที่",
							"checkerEquipement": "ตรวจสอบอุปกรณ์",
							"checkerGrandeurPhysique": "ตรวจสอบค่าทางกายภาพ",
							"checkerCompteur": "ตรวจสอบการวัด"
						},
						"tiers": {
							"name": "ซัพพลายเออร์",
							"tiersContacts": "ซัพพลายเออร์/การติดต่อ"
						}
					},
					"myAccount": {
						"deco": "การขาดการเชื่อมต่อ",
						"optionScannerIntegratedInKeyboard": "ฉันมีสแกนเนอร์ที่รวมอยู่แป้นพิมพ์"
					}
				}
			}
		} ,
        mixins:[PersonalParameters, VgFilesMixins, UserMixins],
		components: {
            VgIcon,
            VgMyAccount,
			VgLangSelector,
			VgHelper,
			VgNewBadge,
			VgSearchDatasFilter
        },
		props:{
			
		},
        data: function() {
            return {
                lang: null,
                optionScannerIntegratedInKeyboard : this.personnalParameters_getUseIntegratedScanerInKeyboard(),
                user: {
                    nom: this.$app.nom,
                    prenom: this.$app.prenom,
                    fonction: this.$app.fonction,
                    mail: this.$app.email,
                    role: this.$app.role
                },
				showMyAccountModal: false,
				isGlobalFilterFocused: false,
				deployedSections: [],
			};
        },
		computed: {
			isGestionMagasin: function(){
	            let user = window.sessionStorage.getItem("user");
	            user = JSON.parse(user);
	            return user.isGestionMagasin;
	        },
			showMenuRecensement: function(){
				return this.can('EQUIPEMENTS.PAGE') || this.can('LIEUX.PAGE') || this.can('SITES.PAGE') || this.can('REGISTRE_IMMOBILISATIONS.PAGE')
					|| this.can('INVENTAIRES.PAGE') || this.can('CATEGORIES_EQUIPEMENTS.PAGE') || this.can('CATEGORIES_LIEUX.PAGE')
					|| this.can('PLAN_INTERACTIF.PAGE');
			},
			showMenuVerification: function(){
				return this.can('VERIFICATIONS.PAGE') || this.can('TACHES.PAGE') || this.can('PROGRESSION.PAGE') 
					|| this.can('STATISTIQUES_VERIFICATION.PAGE') || this.can('CHECKS.PAGE');
			},
			showMenuGestionDeStock: function(){
				return this.can('GESTION_DE_STOCK.SECTION');
			},
			isSubMenuLieuxVisible: function(){
				return this.deployedSections.findIndex(section => section === 'lieux') !== -1;
			},
			isSubMenuEquipementsVisible: function(){
				return this.deployedSections.findIndex(section => section === 'equipements') !== -1;
			},
			isSubMenuPreventifVisible: function(){
				return this.deployedSections.findIndex(section => section === 'preventif') !== -1;
			},
		},
        watch:{
            optionScannerIntegratedInKeyboard:function(value){
                this.personnalParameters_setUseIntegratedScanerInKeyboard(value);
            }
        },
        methods: {
            ...mapActions([
                'appStateUpdateCurrentView'
            ]),
			onMouseEnterSection: function(sectionName){
				if(this.deployedSections.findIndex(section => section === sectionName) === -1) this.deployedSections.push(sectionName);
			},
			onMouseLeaveMenu: function(sectionName){
				if(sectionName==='lieuxequipements'){ 
					this.deployedSections = [...this.deployedSections.filter(section => section !== "lieux" && section!== "equipements")];
				}else{
					this.deployedSections = [...this.deployedSections.filter(section => section !== sectionName)];
				}
			},
			onClickWithNoInteraction: function(){
				this.$emit("open-search-datas-filter", true);
				this.openMenu();
			},
			onFiltersChange: function(event){
				this.$emit('global-filters-change', event);
				this.isGlobalFilterFocused = false;
			},
			onFocusedGlobalFilter: function(isFocused){
				this.isGlobalFilterFocused = isFocused;
			},
			onCloseGlobalFilter: function(){
				this.isGlobalFilterFocused = false;
			},
			openMenu: function(){
				var menu = document.getElementById("vg-menu-navbar");	// utiliser this.$refs
				if (menu.className === "navbar") {
					menu.className += " responsive";
				} else {
					menu.className = "navbar";
				}
			},
			handleDisconnectSession: function(){
				this.isLoading = true;
                this.VgFilesMixins_deleteFirebaseUserInSessionStorage();
                this.UserMixins_logout().then(()=>{
                    this.VgFilesMixins_logoutFirebaseApp();
                    this.$router.push({ name: '_login' });
                })
			},
			clearStorage: function(){
				localStorage.clear();
				document.location.reload();
			},
			removeSessionValues: function(){
				window.sessionStorage.removeItem("user");
			},
			/**
			 * @param string storeName
			 */
			openStore:function(storeName){
				let link = "https://play.google.com/store/apps/details?id=com.herokuapp.verifgoodfrontend.twa";
				if(storeName === "app-store") link = "https://apps.apple.com/fr/app/verifgood/id1633481733";
				window.open(link, '_blank').focus();
			}
        },
		created: function(){}
    };
</script>
<style lang="scss" scoped>

$background-color: #1F2937;
$background-color-hover: #171F2A;
$font-color: #eee;
$font-section-title-color: #ffbf43;
$font-size: 14px;
$menu-height: 60px;
$menu-mobile-height: calc(var(--vh, 1vh) * 100 - #{$menu-height});


#badge-app-store{
    width: 141px !important;
    height: 45px !important;
}
#badge-google-play{
	width: 163px;
	height:64px;
}
/* Navbar container */
.navbar {
	z-index: 100;
  overflow: hidden;
  background-color: $background-color;
  font-family: Arial;
  border-radius: none !important;
  height: $menu-height;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  .navbar-logo{
	  padding-right:20px;
	  >img{
		  height:20px;
	  }
  }
}
.navbar-main{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 3;
}

/* Links inside the navbar */
.navbar-main a {
  float: left;
  font-size: $font-size;
  color: $font-color;
  text-align: center;
  text-decoration: none;
}

.navbar-secondary{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: $font-color;
	gap: 20px;
	height: inherit;
	flex-grow: 2;
	.user-name{
		height: inherit;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

.menu-sections{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: $menu-height;
	.menu-sections-container{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: $menu-height;
	}
}
.menu-overlay{
	display: none;
}
.user-profile{
	color: $font-color;
	font-size: 12px;
}

/* The dropdown container */
.menu-section {
  float: left;
  overflow: hidden;
  height: inherit;
}

/* Dropdown button */
.menu-section .menu-section-title {
  font-size: $font-size;
  border: none;
  outline: none;
  color: $font-color;
  /*padding: 14px 16px;*/
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  /*height: 100%;*/
  /*padding: 20px;*/
  height: inherit;
  padding: 0 20px;
  >i{
	  margin-left: 5px;
  }
}

/* Add a background color to navbar links on hover */
.navbar a:hover, .menu-section:hover .menu-section-title {
  background-color: $background-color-hover;
}

/* Dropdown content (hidden by default) */
.menu-sub-sections {
  display: none;
  position: absolute;
  background-color: $background-color;
  border-top: 1px solid #ccc;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1001;
  img{
	  height: 20px;
	  width: 20px;
	  margin-right: 10px;
  }
  .only-responsive{
	  display: none;
  }
}

/* Links inside the dropdown */
.menu-sub-sections a {
  float: none;
  color: $font-color;
  padding: 10px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.menu-sub-sections a:hover {
  background-color: $background-color-hover;
}

/* Show the dropdown menu on hover */
.menu-section-1:hover .menu-sub-section-1 {
  display: block;
}
.menu-section-2:hover .menu-sub-section-2 {
  display: block;
}
.menu-section-3:hover .menu-sub-section-3 {
  display: block;
}
.menu-section-4:hover .menu-sub-section-4 {
  display: block;
}
.menu-section-5:hover .menu-sub-section-5 {
  display: block;
}
.menu-section-1:hover .menu-sub-section-2 .menu-sub-section-3 .menu-sub-section-4 .menu-sub-section-5{
  display: none;
}
.menu-section-2:hover .menu-sub-section-1 .menu-sub-section-3 .menu-sub-section-4 .menu-sub-section-5{
  display: none;
}
.menu-section-3:hover .menu-sub-section-1 .menu-sub-section-2 .menu-sub-section-4 .menu-sub-section-5{
  display: none;
}
.menu-section-4:hover .menu-sub-section-1 .menu-sub-section-2 .menu-sub-section-3 .menu-sub-section-5{
  display: none;
}
.menu-section-5:hover .menu-sub-section-1 .menu-sub-section-2 .menu-sub-section-3 .menu-sub-section-4{
  display: none;
}

/* responsive */
.navbar .collapse-icon {
  display: none;
}

.only-responsive{
	display: none;
}

.menu-with-submenu {
	position: relative;
	display: inline-block;
	width: 100%;
}
.menu-collapseur{
	float:right;
	margin:auto;
	right:5px;
	height: 100%;
	width:10px;
}
.menu-collapseur-open{
	i{
		transform: rotate(90deg);
	}
}

.menu-with-submenu a {
	display: block;
	text-decoration: none;
	width: 100%;
	img{
		height: 20px;
		width: 20px;
		margin-right: 10px;
	}
}

.submenu {
	position: relative;
	left:auto;
	width:100%;
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	transform: translateY(-10px);
	transition: opacity 0.2s ease-out, transform 0.2s ease-out, height 0.2s ease-out;
}
.submenu a {
	width:100%;
	padding-left: 20px;
}

.menu-with-submenu:hover .submenu {
	max-height: max-content;
	opacity: 1;
	transform: translateY(0);
	transition-delay: 0s;
}

.menu-with-submenu:hover .submenu:focus-within {
  opacity: 1;
  transform: translateY(0);
}

.submenu-visible{
	max-height: max-content;
	opacity: 1;
	transform: translateY(0);
	transition-delay: 0s;
}

@media screen and (max-width: 991.98px) {
  	.navbar a.collapse-icon {
	    float: right;
	    display: block;
	}
	.navbar-logo{
		>img{
			margin-left: 10px;
		}
	}
	.menu-sections{
		display: none;
	}
	.menu-overlay{
		display: none;
	}
	.not-responsive{
		display: none;
	}
	.only-responsive{
		display: block;
	}
}

@media screen and (max-width: 991.98px) {
  	.navbar.responsive {
	  	position: fixed;
  		top: 0;
		left: 0;
  }
  	.navbar.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  	.navbar.responsive{
		.menu-sections-container{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			overflow-y: auto;
			width: inherit;
			border-top: 1px solid #ccc;
			height: $menu-mobile-height;
		}
	  	.menu-sections{
			position: fixed;
			top: $menu-height;
			left: 0;
		    width: 80vw;
			height: $menu-mobile-height;
		    background-color: $background-color;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			/*overflow: auto;*/
			
			.menu-section-recherche-globale{
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 4px;
				width: calc(100% - 20px);
				margin: 10px 10px;
				height: 30px;
				img{
					height: 36px;
				}
			}
			.menu-section-title{
				color: $font-section-title-color;
				font-weight: bold;
				font-size: 13px;
				height: 35px;
				width: 100%;
				text-align: left;
				padding: 0 20px;
			}
			.menu-sub-sections{
				display: block;
			    position: relative;
			    background-color: $background-color;
			    border-top: none;
			    width: 100%;
				padding: 0 10px 0 30px;
			    box-shadow: none;
			    z-index: 100;
			}
			.menu-sub-sections a{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				color: white;
				font-size: 12px;
			}
			.menu-sub-sections .section{
				display: flex;
				justify-content: space-between;
			}
			.menu-sub-sections .not-responsive{
				display: none;
			}
			.menu-sub-sections-1 .menu-sub-sections-2 .menu-sub-sections-3{
				display: block;
			}
			.menu-section{
				overflow: initial;
				width: 100%;
			}
			.menu-section-1{
				order: 1;
			}
			.menu-section-2{
				order: 3;
			}
			.menu-section-3{
				order: 2;
			}
			.menu-section-4{
				order: 4;
			}
			.menu-section-5{
				order: 5;
			}
			.menu-section-6{
				order: 6;
			}
			.menu-section-7{
				order: 7;
			}
			.menu-section-8{
				order:8;
			}
			.menu-section-9{
				order: 9;
				margin: 20px 0;
				padding: 0 20px;
				width:100%;
				.menu-line{
					border-top: 1px solid white;
				}
			}
			.menu-section-10{
				order: 10;
			}
  		}
		.menu-sections-full-width{
			width: -webkit-fill-available;
		}
		.menu-overlay{
			display: block;
			width: 20vw;
			height: $menu-mobile-height;
			background-color: $background-color;
			opacity: 0.5;
			top: $menu-height;
			left: 80vw;
			position: fixed;
		}
		.menu-overlay-hidden{
			width: 0px;
		}
  	}
	.navbar a:hover, .menu-section:hover .menu-section-title {
	  	background-color: $background-color;
	}
	.section{
		a:first-child{
			width:85%;
		}
		a:last-child{
			width:15%;
		}
		a:hover, a:active{
			background-color: $background-color-hover;
		}
	}
	.section:hover, .section:active{
		background-color: $background-color-hover;
	}
	.menu-sub-sections{
		a:hover, a:active{
			background-color: $background-color-hover;
		}
	}
}
</style>
