<template>
	<div class="parameter-user" v-loading="isLoadingList">
		<span class="title">{{ $t("parametres-utilisateurs-autorisations-header") }}</span>
		<div class="headbar">
			<vg-text-filter class="vg-search-user" style="width:30%;"
                v-model="searchQuery" />
            <vg-button v-if="isSessionAdmin" v-show="false"
				type="success"
                @click="isUserFormDisplayed = true">
                {{ $t("parametres-utilisateurs-autorisations-bouton-ajout") }}
            </vg-button>
			<vg-button v-if="isSessionAdmin" v-show="can('PARAMETRES.CREATE_UTILISATEUR')"
				type="success"
                @click="showInviteUser = true">
                {{ $t("parametres-utilisateurs-autorisations-bouton-inviter") }}
            </vg-button>
            <vg-button type="success" @click="handleExportUsers">
                {{$t("exporter")}}
            </vg-button>
            <vg-button type="success" @click="showMySignature=true;">
                {{$t("ma-signature")}}
            </vg-button>
		</div>
		<tag-grid :idTableau="'user-tableau'"
			v-model="gridOptions"
			:columnDefs="columnDefs"
			:rowData="users"
			:groupSelectsChildren="true"
            :suppressRowClickSelection="true"
			@ag-dbl-click="updateUser" />
        <div v-if="users" style="display:flex;flex-direction:column">
            <div>{{$t('user-qty')}} : {{activeUsers}} / {{users.length}}</div>
            <div>{{$t('max-allowed-user')}} :  {{$app.account_maxUsersAllowed}}</div>
        </div>
		<vg-user-form v-if="user || isUserFormDisplayed"
            v-model="user"
            @close="isUserFormDisplayed=false;user=null;"
            @created="isUserFormDisplayed=false;fetch()"
            @updated="isUserFormDisplayed=false;fetch()"
            @deleted="isUserFormDisplayed=false;fetch()"
			@refetch-user="fetch();" />
		<vg-credits />
		<vg-my-signature v-if="showMySignature" @close="showMySignature=false;"/>
		<!-- Invitation modal -->
		<vg-invitation-link-modal 
		v-if="showInviteUser" 
		@close="showInviteUser=false"
		@invitation-created="onInvitationCreated"
		@error="handleError" />
	</div>
</template>

<script>
	import UserMixin from "src/mixins/UserMixins.js";
	import TagGrid from 'src/components/Grid/TagGrid.vue';
	import CheckBoxReadCellRender from "src/components/Vg/TagGrid/CheckBoxReadCellRender.vue";
	import RestrictionSiteCellRender from "src/components/Vg/TagGrid/RestrictionSiteCellRender.vue";
	import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
	import UserRoleCellRender from "src/components/Vg/TagGrid/UserRoleCellRender.vue";
	import VgModal from 'src/components/Vg/VgModal.vue';
	import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
	import LieuMixins from "src/mixins/LieuMixins.js";
	import TagGridMixins from 'src/mixins/TagGridMixins.js';
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgUserForm from "src/components/Vg/Forms/VgUserForm.vue";
    import VgMySignature from "src/components/Vg/User/VgMySignature.vue";
    import VgCredits from "src/components/Vg/Layout/Credits/VgCredits.vue";
	import Metadatas from "src/services/Metadatas.js";
    import OuiNonCellRender from "src/components/Vg/TagGrid/OuiNonCellRender.vue";
	import VgInvitationLinkModal from 'src/components/Vg/Invitation/VgInvitationLinkModal.vue';

    export default {
        name: 'parametres-utilisateurs',
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "exporter": "Exporter vers excel",
            "parametres-utilisateurs-autorisations-header": "Les utilisateurs du compte",
            "parametres-utilisateurs-autorisations-bouton-ajout": "Ajouter un utilisateur",
			"parametres-utilisateurs-autorisations-bouton-inviter": "Inviter un utilisateur",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-restriction-site": "Restriction ( site )",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-tiers": "Tiers",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-nom": "Nom",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-prenom": "Prenom",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-fonction": "Fonction",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-mail": "E-Mail",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-telephone": "Téléphone",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Deplacement": "Déplacement équipement",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Sortie": "Sortie équipement",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-3m": "Notif. email alerte iminence fin de contrat 3 mois",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-1m": "Notif. email alerte iminence fin de contrat 1 mois",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-Intervention7j": "Notif. email alerte iminence date prévisionnelle intervention 7j",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-Intervention0j": "Notif. email alerte iminence date prévisionnelle intervention 0j",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-ClotureFM": "Cloture fiche curative",
            "parametres-utilisateurs-role-Admin": "Role Admin",
            "parametres-utilisateurs-role-Superviseur": "Role Superviseur",
            "parametres-utilisateurs-role-Technicien": "Role Technicien",
            "parametres-utilisateurs-role-Basic": "Role Basic",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-role": "Rôle ( droits )",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-reception-mails-generaux": "Réception mails généraux",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-reception-mails-verifications": "Réception mails préventifs",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-reception-mails-maintenances": "Réception mails curatifs",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-actions": "Actions",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-actions-bouton-edition": "Modifier l utilisateur",
            "parametres-utilisateurs-autorisations-modale-edition-utilisateur-header": "Modifier l utilisateur : ",
            "parametres-utilisateurs-autorisations-modale-ajout-utilisateur-header": "Nouvel utilisateur",
            "parametres-utilisateurs-autorisations-modale-restriction-header": "Restriction site utilisateur",
            "parametres-utilisateurs-autorisations-modale-restriction-btnAnnuler": "Annuler",
            "parametres-utilisateurs-autorisations-modale-restriction-btnValider": "Enregistrer",
            "restriction-site": "Restriction site",
            "alerte-contrat-tacite-reconduction": "Alerte contrat tactite reconduction",
            "rapport-hebdomadaire-verification": "Rapport hebdomadaire préventif",
            "rapport-hebdomadaire-maintenance": "Rapport hebdomadaire curatif",
            "alerte-fin-contrat": "Alerte fin contrat",
            "alerte-stock-minimum": "Alerte stock minimum",
            "alerte-enregistrement-intervention-periodique": "Alerte enregistrement intervention périodique",
            "alerte-sotie-equipement": "Alerte sortie équipement",
            "isEnabled": "Utilisateur actif ?",
            "user-qty": "Nombre total d'utilisateur actif",
            "max-allowed-user": "Nombre d'utilisateur autorisé sur ce compte",
            "alerte-deplacement-equipement": "Alerte déplacement équipement",
			"ma-signature": "Ma signature"
        },
        "en": {
            "exporter": "Export to excel",
            "parametres-utilisateurs-autorisations-header": "Account users",
			"parametres-utilisateurs-autorisations-bouton-inviter": "Invite user",
            "parametres-utilisateurs-autorisations-bouton-ajout": "Add user",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-restriction-site": "Restriction (site)",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-nom": "Name",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-fonction": "Function",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-mail": "E-mail",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-telephone": "Phone",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-role": "Role (rights)",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-reception-mails-generaux": "Receipt of general mail",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-reception-mails-verifications": "Receipt of preventive mail",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-reception-mails-maintenances": "Receipt of curative mail",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-actions": "Actions",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-actions-bouton-edition": "Edit user",
            "parametres-utilisateurs-autorisations-modale-edition-utilisateur-header": "Edit User: ",
            "parametres-utilisateurs-autorisations-modale-ajout-utilisateur-header": "New user",
            "parametres-utilisateurs-autorisations-modale-restriction-header": "User site restriction",
            "parametres-utilisateurs-autorisations-modale-restriction-btnAnnuler": "Cancel",
            "parametres-utilisateurs-autorisations-modale-restriction-btnValider": "Save",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-prenom": "First name",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Deplacement": "Move equipment",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Sortie": "Equipment output",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-3m": "Email notification imminent warning end of contract 3 months",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-1m": "Email notification imminent warning end of contract 1 month",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-Intervention7j": "Email notification imminent warning estimated date 7 days",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-Intervention0j": "Email notification imminent warning estimated date 0 days",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-ClotureFM": "Curative sheet closure",
            "parametres-utilisateurs-role-Admin": "Admin role",
            "parametres-utilisateurs-role-Superviseur": "Supervisor role",
            "parametres-utilisateurs-role-Technicien": "Technician role",
            "parametres-utilisateurs-role-Basic": "Basic role",
            "restriction-site": "Location perimeter",
            "alerte-contrat-tacite-reconduction": "Tacit contract renewal alert",
            "rapport-hebdomadaire-verification": "Weekly preventive report",
            "rapport-hebdomadaire-maintenance": "Weekly curative report",
            "alerte-fin-contrat": "Contract ending alert",
            "alerte-stock-minimum": "Minimal stock alert",
            "alerte-enregistrement-intervention-periodique": "New periodic intervention alert",
            "alerte-sotie-equipement": "Equipment output alert",
            "alerte-deplacement-equipement": "Equipment movement alert",
            "isEnabled": "Active user ?",
            "user-qty": "Total active user",
            "max-allowed-user": "Max active user allowed on this account",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-tiers": "Third parties",
			"ma-signature": "My signature"
        },
        "th": {
            "parametres-utilisateurs-autorisations-header": "ผู้ใช้งานบัญชี",
            "parametres-utilisateurs-autorisations-bouton-ajout": "เพิ่มผู้ใช้งาน",
			"parametres-utilisateurs-autorisations-bouton-inviter": "เชิญผู้ใช้งาน",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-restriction-site": "การหวงห้าม (ไซต์)",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-nom": "ชื่อ",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-fonction": "ฟังก์ชัน",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-mail": "อีเมล",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-telephone": "โทรศัพท์",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-role": "หน้าที่ (สิทธิ)",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-reception-mails-generaux": "ผู้รับเมลทั่วไป",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-reception-mails-verifications": "ผู้รับเมลการตรวจสอบ",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-reception-mails-maintenances": "ผู้รับเมลการซ่อมบำรุง",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-actions": "การดำเนินการ",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-actions-bouton-edition": "แก้ไขผู้ใช้งาน",
            "parametres-utilisateurs-autorisations-modale-edition-utilisateur-header": "แก้ไขผู้ใช้งาน: ",
            "parametres-utilisateurs-autorisations-modale-ajout-utilisateur-header": "ผู้ใช้งานใหม่",
            "parametres-utilisateurs-autorisations-modale-restriction-header": "การหวงห้ามไซต์ผู้ใช้งาน",
            "parametres-utilisateurs-autorisations-modale-restriction-btnAnnuler": "ยกเลิก",
            "parametres-utilisateurs-autorisations-modale-restriction-btnValider": "บันทึก",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-prenom": "ชื่อต้น",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Deplacement": "เคลื่อนย้ายอุปกรณ์",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Sortie": "ข้อมูลที่ส่งออกมาของอุปกรณ์",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-3m": "การแจ้งเตือนทางอีเมลคำเตือนที่ใกล้เข้ามาของการสิ้นสุดของสัญญา 3 เดือน",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-1m": "การแจ้งเตือนทางอีเมลคำเตือนที่ใกล้เข้ามาของการสิ้นสุดของสัญญา 1 เดือน",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-Intervention7j": "การแจ้งเตือนทางอีเมลคำเตือนที่ใกล้เข้ามาวันที่โดยประมาณ 7 วัน",
            "parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-Alerte-Intervention0j": "การแจ้งเตือนทางอีเมลคำเตือนที่ใกล้เข้ามาวันที่โดยประมาณ 0 วัน",
            "parametres-utilisateurs-role-Admin": "หน้าที่ของผู้ดูแลระบบ",
            "parametres-utilisateurs-role-Superviseur": "หน้าที่ของหัวหน้างาน",
            "parametres-utilisateurs-role-Technicien": "หน้าที่ของช่างเทคนิค",
            "parametres-utilisateurs-role-Basic": "หน้าที่พื้นฐาน",
            "restriction-site": "ขอบเขตที่ตั้ง",
            "alerte-contrat-tacite-reconduction": "การแจ้งเตือนการต่อสัญญาโดยปริยาย",
            "rapport-hebdomadaire-verification": "รายงานการตรวจสอบรายสัปดาห์",
            "rapport-hebdomadaire-maintenance": "รายงานการบำรุงรักษารายสัปดาห์",
            "alerte-fin-contrat": "การแจ้งเตือนการสิ้นสุดสัญญา",
            "alerte-stock-minimum": "การแจ้งเตือนสินค้าคงคลังขั้นต่ำ",
            "alerte-enregistrement-intervention-periodique": "การแจ้งเตือนการแทรกแซงโดยปริยายใหม่",
            "alerte-sotie-equipement": "การแจ้งเตือนผลงานอุปกรณ์",
            "alerte-deplacement-equipement": "การแจ้งเตือนการเคลื่อนย้ายอุปกรณ์"
        }
    }
} ,
		mixins: [ LieuMixins , UserMixin, TagGridMixins],
        components: {
			VgModal,
			TagGrid,
			VgTextFilter,
			CheckBoxReadCellRender,
			RestrictionSiteCellRender,
			IconNameCellRender,
			UserRoleCellRender,
            VgButton,
            VgUserForm,
			VgCredits,
			VgMySignature,
			VgInvitationLinkModal
        },
        data() {
            return {
                userId: this.$app.appID,
				users: null,
				user: null,
				isUserFormDisplayed: false,
				modalIsUpdateRestrictionSite: false,
				isLoadingList: false,
				sites: [],
				currentuser: null,
				searchQuery: '',
				restrictionsite: null,
				metadatasUsers: new Metadatas(),
	            agfilters: {},
				columnDefs: [
					{
						headerName: this.$t('parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-prenom'),
						width: 100,
						cellClass: [""],
						field: 'prenom',
						colId: 'prenom',
						pinned: 'left',

					},
					{
						headerName: this.$t('parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-nom'),
						width: 100,
						cellClass: [""],
						field: 'nom',
						colId: 'nom',
						pinned: 'left',

					},
					{
						headerName:this.$t('parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-fonction'),
						width: 100,
						cellClass: [""],
						field: 'fonction',
						colId: 'fonction',
					},
					{
						headerName: this.$t('parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-mail'),
						width: 200,
						cellClass: [""],
						field: 'email',
						colId: 'email',
						pinned: 'left',
					},
					{
						headerName: this.$t('isEnabled'),
						width: 200,
						cellClass: [""],
						field: 'enabled',
						colId: 'enabled',
                        cellRenderer:(params) => new OuiNonCellRender({
                            propsData: {
                                params: params,
                                isValid: params.data.enabled && params.data.enabled == "1"
                            }
                        }).$mount().$el,

					},
					{
						headerName: this.$t('parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-telephone'),
						width: 100,
						cellClass: [""],
						field: 'telephone',
						colId: 'telephone',
					},
					{
						headerName: this.$t('parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-role'),
						width: 150,
						cellClass: [""],
						field: 'roles',
						colId: 'roles',
						cellRenderer: (params)=>new UserRoleCellRender({propsData: {role: params.data.roles}}).$mount().$el
					},
					{
						headerName: this.$t('parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-restriction-site'),
						width: 200,
						field: 'lieux',
						colId: 'lieux',
						cellRenderer: (params)=>new RestrictionSiteCellRender({propsData: {lieux: params.data.lieux}}).$mount().$el
					},
					{
						headerName: this.$t('parametres-utilisateurs-autorisations-tableau-utilisateurs-column-header-tiers'),
						width: 150,
						field: 'tiers.name',
						colId: 'tiers.name',
						cellRenderer: (params)=>new IconNameCellRender({propsData: {icon: "static/assets/icone/circle/tiers-cercle.png", dataColumn: params.data.tiers_id?params.data.tiers.name:null, isMainColumn: false}}).$mount().$el
					},
					{
						headerName: this.$t('alerte-contrat-tacite-reconduction'),
						width: 200,
						cellClass: [""],
						field: 'parameters.alerte_contrat_tacite_reconduction',
						colId: 'parameters.alerte_contrat_tacite_reconduction',
						cellRenderer: (params) => new CheckBoxReadCellRender({propsData: {paramsUser: params.data.parameters.alerte_contrat_tacite_reconduction?params.data.parameters.alerte_contrat_tacite_reconduction.entry:null}}).$mount().$el,

					},
					{
						headerName: this.$t('rapport-hebdomadaire-verification'),
						width: 200,
						cellClass: [""],
						field: 'parameters.rapport_hebdomadaire_verification',
						colId: 'parameters.rapport_hebdomadaire_verification',
						cellRenderer: (params) => new CheckBoxReadCellRender({propsData: {paramsUser: params.data.parameters.rapport_hebdomadaire_verification?params.data.parameters.rapport_hebdomadaire_verification.entry:null}}).$mount().$el,

					},
					{
						headerName: this.$t('rapport-hebdomadaire-maintenance'),
						width: 200,
						cellClass: [""],
						field: 'parameters.rapport_hebdomadaire_maintenance',
						colId: 'parameters.rapport_hebdomadaire_maintenance',
						cellRenderer: (params) => new CheckBoxReadCellRender({propsData: {paramsUser: params.data.parameters.rapport_hebdomadaire_maintenance?params.data.parameters.rapport_hebdomadaire_maintenance.entry:null}}).$mount().$el,

					},
					{
						headerName: this.$t('alerte-fin-contrat'),
						width: 200,
						cellClass: [""],
						field: 'parameters.alerte_fin_contrat',
						colId: 'parameters.alerte_fin_contrat',
						cellRenderer: (params) => new CheckBoxReadCellRender({propsData: {paramsUser: params.data.parameters.alerte_fin_contrat?params.data.parameters.alerte_fin_contrat.entry:null}}).$mount().$el,

					},
					{
						headerName: this.$t('alerte-stock-minimum'),
						width: 200,
						cellClass: [""],
						field: 'parameters.alerte_stock_minimum',
						colId: 'parameters.alerte_stock_minimum',
						cellRenderer: (params) => new CheckBoxReadCellRender({propsData: {paramsUser: params.data.parameters.alerte_stock_minimum?params.data.parameters.alerte_stock_minimum.entry:null}}).$mount().$el,

					},
					{
						headerName: this.$t('alerte-enregistrement-intervention-periodique'),
						width: 200,
						cellClass: [""],
						field: 'parameters.alerte_enregistrement_intervention_periodique',
						colId: 'parameters.alerte_enregistrement_intervention_periodique',
						cellRenderer: (params) => new CheckBoxReadCellRender({propsData: {paramsUser: params.data.parameters.alerte_enregistrement_intervention_periodique?params.data.parameters.alerte_enregistrement_intervention_periodique.entry:null}}).$mount().$el,

					},
					{
						headerName: this.$t('alerte-sotie-equipement'),
						width: 200,
						cellClass: [""],
						field: 'parameters.alerte_sotie_equipement',
						colId: 'parameters.alerte_sotie_equipement',
						cellRenderer: (params) => new CheckBoxReadCellRender({propsData: {paramsUser: params.data.parameters.alerte_sotie_equipement?params.data.parameters.alerte_sotie_equipement.entry:null}}).$mount().$el,

					},
					{
						headerName: this.$t('alerte-deplacement-equipement'),
						width: 200,
						cellClass: [""],
						field: 'parameters.alerte_deplacement_equipement',
						colId: 'parameters.alerte_deplacement_equipement',
						cellRenderer: (params) => new CheckBoxReadCellRender({propsData: {paramsUser: params.data.parameters.alerte_deplacement_equipement?params.data.parameters.alerte_deplacement_equipement.entry:null}}).$mount().$el,
					}
				],
				showMySignature: false,
				showInviteUser: false
            };
        },
		watch: {
			searchQuery: {
				handler: function(query){
					this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			},
		},
        computed:{
            /**
             * @return Boolean
             */
            activeUsers:function(){
                if(!this.users){
                    return 0;
                }
                let activeUsers = this.users.filter( (user)=>user.enabled == 1 );
                return activeUsers.length;
            }
        },
        methods: {
            handleExportUsers:function(){
                this.UserMixins_getFile(this.metadatasUsers,"xlsx");
            },
			handleChangeRestrictionSite: function(e){
				// //console.log("handleChangeRestrictionSite", e);
				this.restrictionsite = e;
			},
			handleSaveRestrictionSite: function(){
				// //console.log("handleSaveRestrictionSite", this.restrictionsite, this.currentuser);
				var that = this;
				if(this.restrictionsite){
					//this.isLoadingList = true;
					this.$emit("general-loader-start",null);
					var sites = this.restrictionsite;
					var user = this.currentuser.id;
					var collection = [];
					for(var index in sites){
						collection.push({
							idLieu: sites[index],
							idUser: user
						});
					}
					if(collection.length==0) collection = [{idLieu: null, idUser: user}];
					this.LieuMixins_saveRestrictionSiteForUser(collection, function(data){
						that.fetch();
						that.$emit("general-loader-stop", null);
						that.currentuser = null;
						that.modalIsUpdateRestrictionSite = false;
					});
				}else{
					this.currentuser = null;
					this.modalIsUpdateRestrictionSite = false;
				}
			},

			updateUser: function(row){
				if(this.can('PARAMETRES.UPDATE_UTILISATEUR')) this.user = Object.assign(row.data,{ parameters : Object.values(row.data.parameters) });
			},
            /**
            * Get site's list, account info, and users with their personnal
            * parameters.
            *
            */
            fetch: function(){
                this.isLoadingList = true;
				this.metadatasUsers.setFilters(this.agfilters);
				let metadatasSite = new Metadatas();
				metadatasSite.setFilters({
					"typeLieu":{"attr":"l.type_lieu", "colId": "l.type_lieu", "value": "Site", "action":"equals"},
					"libelSorties":{"attr":"l.libel_lieu", "colId": "l.libel_lieu", "value": "Sorties", "action":"not_equals"},
					"libelExterieur":{"attr":"l.libel_lieu", "colId": "l.libel_lieu", "value": "Exterieur", "action":"not_equals"}
				});
				this.LieuMixins_getLieux(metadatasSite).then((sites)=>{
					this.sites = sites;
				});
                this.UserMixins_getUsers(this.$app.appID,this.metadatasUsers).then((datas)=>{
                	let formatedUsers = [];
                	let userParameters = {};
					let users = datas.datas;
                	users.users.forEach((user)=>{

                		user.parameters.forEach((parameter)=>{

                			userParameters[parameter.name] = parameter;
                		});
                		formatedUsers.push(Object.assign(user, {parameters: userParameters}));
                        userParameters = {};
                	});
                    this.users = users.users;
					if(this.user){ 
						let userFound = this.users.find((u)=>u.id == this.user.id);
						this.user = Object.assign({}, userFound,{ parameters : Object.values(userFound.parameters) });
                    }
					this.isLoadingList = false;
                });
			},
			isSessionAdmin: function(){
				return this.$app.role == "ROLE_ADMIN";
			},
        },
		created: function(){
			this.fetch();
		}
    };
</script>
<style lang="scss" scoped>
	.parameter-user{
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 10px;
		.title{
			color: #02a7f0;
			font-size: 20px;
			width: 100%;
		}
		.headbar{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
			width: 100%;
		}
	}
</style>
