<template>
    <div>
        <vg-modal
            v-show="!isModalConfirmDeleteOpened"
            @close="$emit('close')">
            <template #header>
                {{$t("contacts-form-add")}} : {{$t("contacts-form-contacts")}}
            </template>
            <template #body>
                <form ref="contacts-form" onSubmit="false">

                    <vg-input
                        v-if="isAnUpdateContactsForm"
                        :title="$t('tiers')"
                    >
                        <vg-tiers-selector
                            v-model="contact.tiers_id"
                        >
                        </vg-tiers-selector>
                    </vg-input>

                    <vg-input
                        :title="$t('nom')"
                        isRequiredValue
                        v-model="contact.lastname"
                    >
                    </vg-input>
                    <vg-input
                        :title="$t('prenom')"
                        v-model="contact.firstname"
                    >
                    </vg-input>
                    <vg-input
                        :title="$t('email')"
                        :inputType="'email'"
                        isRequiredValue
                        v-model="contact.email"
                    >
                    </vg-input>
                    <vg-input
                        :title="$t('telephone')"
                        :inputType="'tel'"
                        pattern="[0-9]{4}[0-9]{6}"
                        v-model="contact.tels"
                    >
                    </vg-input>
                    <vg-input
                        :title="$t('telephone-mobile')"
                        :inputType="'tel'"
                        pattern="[0-9]{4}[0-9]{6}"
                        v-model="contact.telMobile"
                    >
                    </vg-input>


                    <input v-show="false" type="submit" ref="submitContacts" />
                </form>
            </template>
            <template #footer>
                <vg-button v-if="isAnUpdateContactsForm"
                    type="default"
                    @click="$emit('close')"
                    >
                    {{$t("contacts-form-cancel")}}
                </vg-button>

                <vg-button v-if="isAnUpdateContactsForm"
                    type="danger"
                    @click="isModalConfirmDeleteOpened = true"
                    >
                    {{$t("contacts-form-delete")}}
                </vg-button>

                <vg-button v-if="isAnUpdateContactsForm"
                    type="default-danger"
                    :disabled="!isValid || isLoading"
                    @click="handleUpdateContacts"
                    >
                    {{$t("contacts-form-update")}}
                </vg-button>

                <vg-button v-if="!isAnUpdateContactsForm"
                    type="success"
                    :disabled="!isValid || isLoading"
                    @click="handleCreateContacts"
                    >
                    {{$t("contacts-form-save")}}
                </vg-button>
              </template>
        </vg-modal>

        <dialog-secured-action
          v-if="isModalConfirmDeleteOpened"
          :valid-response="'1'"
          @save="handleDeleteContacts"
          @close="isModalConfirmDeleteOpened = false"
        >
          <template v-slot:header-title>
            {{ $t("contact-form-delete") }} <b>{{ contact.email }}</b> ?
          </template>
        </dialog-secured-action>
    </div>

</template>
<script>
    import ContactsMixins from "src/mixins/ContactsMixins.js";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
    import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";

    export default {
        name: 'vg-contacts-form',
        mixins: [ ContactsMixins ],
        components:{
            VgModal,
            VgButton,
            VgInput,
            DialogSecuredAction,
            VgTiersSelector
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "contacts-form-cancel": "Annuler",
            "contacts-form-save": "Enregistrer",
            "contacts-form-update": "Modifier",
            "contacts-form-delete": "Supprimer",
            "contacts-form-add": "Ajout",
            "contacts-form-name": "Libeller",
            "contacts-form-type": "Type",
            "contacts-form-description": "Commentaire",
            "lieux": "Affectation aux sites",
            "contacts-form-contacts": "contacts",
            "tiers": "Prestataire",
            "telephone": "Télephone",
            "telephone-mobile": "N° mobile",
            "email": "Email",
            "nom": "Nom",
            "prenom": "Prénom"
        },
        "en": {
            "contacts-form-cancel": "Cancel",
            "contacts-form-save": "Save",
            "contacts-form-update": "Update",
            "contacts-form-delete": "Delete",
            "contacts-form-add": "Add",
            "contacts-form-name": "Name",
            "contacts-form-type": "Type",
            "contacts-form-description": "Comment",
            "lieux": "Site's assignment",
            "contacts-form-contacts": "contacts",
            "tiers": "Supplier",
            "telephone": "Phone N°",
            "telephone-mobile": "Mobile N°",
            "email": "Email",
            "nom": "Lastname",
            "prenom": "Firstname"
        }
    }
},
        props: {
            /**
            * @model
            */
            value:{
                type: Object,
                default:function(){
                    return {};
                }
            },
            id:{
                type: Number | String,
                default: null
            },
            skipVueXStorage:{
                type: Boolean,
                default: false
            },
            tiersId:{
                type:Number,
                default:function(){
                    return null;
                }
            }
        },
        data: function() {
            return {
                contact:{
                    firstname: null,
                    lastname: null,
                    email: null,
                    tels: null,
                    telMobile: null,
                    description: null,
                    fonction: null,
                    userId: this.$app.appID,
                    tiers_id: this.tiersId
                },
                isModalConfirmDeleteOpened:false,
                isLoading:false
            };
        },
        computed:{
            isAnUpdateContactsForm:function(){
                return this.value.hasOwnProperty('id');
            },
            isValid: function(){
                return this.contact.lastname && this.contact.lastname.length>=2 && this.contact.email && this.contact.email.length>=5;
            }
        },
        created: function() {
            if(this.id && this.skipVueXStorage) this.fetchContact();
            else Object.assign(this.contact, this.value);
        },
        methods: {
            fetchContact: function(){
                
            },
            handleUpdateContacts:function(){
                this.isLoading = true;
                this.ContactsMixins_update(this.contact).then(()=>{
                    this.$emit("updated");
                    this.$emit("close");
                });

            },
            handleCreateContacts:function(){
                this.isLoading = true;
                this.ContactsMixins_create([this.contact]).then(()=>{
                    this.$emit("created");
                    this.$emit("close");
                });
            },
            handleDeleteContacts:function(){
                this.isLoading = true;
                this.ContactsMixins_delete(this.contact).then(()=>{
                    this.$emit("deleted");
                    this.$emit("close");
                });
            }
        },
        destroyed:function(){
            this.$store.dispatch("ContactsStore/setSelectedItem",{});
        }
    };
</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-contacts-form exemple:

    #### basic usage
    Comment here
    ```html static
          <vg-contacts-form></vg-contacts-form>
    ```

    ### Create form contacts
    ```js
    new Vue({
      data(){
        return {}
      },
      template: `
        <div>
            <vg-contacts-form>
            </vg-contacts-form>

        </div>
      `
    })
    ```
    ### update form contacts
    ```js
    new Vue({
      data(){
        return {
            contacts:{id:1}
        }
      },
      template: `
        <div>
            <vg-contacts-form v-model="contacts">
            </vg-contacts-form>

        </div>
      `
    })
    ```
</docs>
