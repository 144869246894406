<template>
	<vg-modal
		:title="isUpdateForm?$t('modal-title-update'):$t('modal-title-create')"
		:width="'50vw'"
		@close="$emit('close')">
		<template #body>
			<vg-input v-show="!isUniqueSite && !isUpdateForm && !bon.ficheDemandeConsommable_id" :title="$t('site')" isRequiredValue>
				<vg-sites-selector v-model="demande.site_id"
					@selected="onSiteSelected" />
			</vg-input>
			<vg-input v-show="!isUpdateForm && !bon.ficheDemandeConsommable_id" :title="$t('service')" isRequiredValue>
				<vg-service-selector v-model="demande.serviceDemandeur" showAll/>
			</vg-input>
			<vg-signataire-datalist v-if="!isUpdateForm" v-show="!defaultSignataireDonneur"
				v-model="bon.signataireDonneur"
				:title="$t('signataire-donneur')"
				:placeholder="$t('signataire-donneur')"
				isBonDeSortieConsommables
				typeSignataire="donneurs"
				isRequiredValue
				key="bs-conso-signataire-donneur" />
			<!--vg-signataire-datalist v-if="!isUpdateForm" v-show="!defaultSignataire"
				v-model="bon.signataire"
				:title="$t('signataire-receveur')"
				:placeholder="$t('signataire-receveur')"
				isBonDeSortieConsommables
				typeSignataire="receveurs"
				isRequiredValue 
				key="bs-conso-signataire-receveur"/-->
			<vg-input v-if="bon.consommations && bon.ficheDemandeConsommable_id && !isUpdateForm"
				:title="$t('consommables-sortis')"
				isRequiredValue>
				<div v-for="(consommation, index) in bon.consommations" :key="index" 
					style="width: 100%;display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;gap:5px;border-bottom:1px solid whitesmoke;padding: 5px 0;">
					<div style="width:100%;display: flex;flex-direction:row;justify-content: flex-start;align-items:center;gap:5px;">
						<vg-input v-model="consommation.quantite" style="width:100px;" inputType="float" />
						<span v-if="consommation.consommable">{{consommation.consommable.unite?consommation.consommable.unite:$t("unite")}}</span>
						<span v-else></span>
						<span v-if="consommation.consommable">{{consommation.consommable.name}} </span>
						<vg-consommable-datalist v-else :key="'consommable-datalist-'+index"
							:placeholder="$t('consommable')"
							:depotId="getDefaultDepotId"
							readOnly
							@select="onSelectConsommable($event, consommation, index)"/>
					</div>
					<div style="display: flex;flex-direction:row;justify-content: space-between;align-items:center;gap:10px;width: 100%;">
						<div style="display: flex;flex-direction: column;gap: 5px;justify-content: flex-start;">
							<small v-if="consommation.consommable">{{$t("ref")}} {{consommation.consommable.refExterne}}</small>
							<small v-if="consommation.consommable">{{$t("quantite-en-stock")}}: {{consommation.consommable.stock.quantite}} {{consommation.consommable.unite?consommation.consommable.unite:$t("default-unite")}}</small>
							<small v-else></small>
						</div>
						<vg-button
							type="default-danger"
							@click="removeItem(consommation, index)">
							{{$t("retirer")}}
						</vg-button>
					</div>
				</div>
				<br>
				<vg-button type="info" size="sm" @click="addItem" :disabled="!lastItemIsNotEmpty">
					{{$t("add-consommable")}}
				</vg-button>
				<vg-button v-if="$vgutils.isMobile()" type="success" size="sm" @click="showScanner=true;">
					{{$t("scanner-consommable")}}
				</vg-button>
			</vg-input>
			<vg-input v-else-if="!isUpdateForm && !$vgutils.isMobile()"
				:title="$t('consommables-sortis')"
				isRequiredValue>
				<div class="consommable-row consommable-row-header">
					<span></span>
					<span>{{$t("header-quantite")}}</span>
					<span>{{$t("header-unite")}}</span>
					<span>{{$t("quantite-en-stock")}}</span>
					<span></span>
				</div>
				<div v-for="(consommation, index) in bon.consommations" class="consommable-row">
					<vg-consommable-datalist v-if="!consommation.consommable_id" :key="'consommable-datalist-'+index"
						:placeholder="$t('consommable')"
						:depotId="getDefaultDepotId"
						readOnly
						@select="onSelectConsommable($event, consommation, index)"/>
					<span v-else>{{consommation.consommable.name}} <small>{{$t("consommable-ref")}}: {{consommation.consommable.refExterne}}</small></span>
					<vg-input inputType="float" v-model="consommation.quantite" />
					<vg-input>
						<span v-if="consommation.consommable && consommation.consommable.unite">{{consommation.consommable.unite}}</span>
						<span v-else>{{$t("default-unite")}}</span>
					</vg-input>
					<span v-if="consommation.consommable">{{consommation.consommable.stock.quantite}} {{consommation.consommable.unite?consommation.consommable.unite:$t("default-unite")}}</span>
					<span v-else />
					<vg-button type="default-danger" @click="removeItem(consommation, index)">{{$t("retirer")}}</vg-button>
				</div>
				<br>
				<vg-button type="info" size="sm" @click="addItem" :disabled="!lastItemIsNotEmpty">
					{{$t("add-consommable")}}
				</vg-button>
				<vg-button v-if="$vgutils.isMobile()" type="success" size="sm" @click="showScanner=true;">
					{{$t("scanner-consommable")}}
				</vg-button>
			</vg-input>
			<vg-input v-else-if="!isUpdateForm && $vgutils.isMobile()"
				:title="$t('consommables-sortis')"
				isRequiredValue>
				<div v-for="(consommation, index) in bon.consommations" style="width: 100%;margin-bottom:10px;">
					<vg-consommable-datalist v-if="!consommation.consommable_id" :key="'consommable-datalist-'+index"
						style="width:100%;"
						:placeholder="$t('add-consommable')"
						:depotId="getDefaultDepotId"
						readOnly
						@select="onSelectConsommable($event, consommation, index)"/>
					<vg-button v-if="!consommation.consommable_id" 
						type="default-danger" size="sm" style="width: 100%;" 
						@click="removeItem(consommation, index)">
						{{$t("retirer")}}
					</vg-button>
					<div v-if="consommation.consommable_id" style="width: 100%;display: flex;justify-content: space-between;align-items: flex-start;gap:10px;">
						<div style="width:30%;display: flex;justify-content: flex-start;align-items: center;gap:5px;">
							<vg-input inputType="float" v-model="consommation.quantite" style="width:70%;"/>
							<vg-input style="width:30%;">
								<span v-if="consommation.consommable && consommation.consommable.unite">{{consommation.consommable.unite}}</span>
								<span v-else>{{$t("default-unite")}}</span>
							</vg-input>
						</div>
						<div style="width:70%;display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;">
							<span v-if="consommation.consommable_id">{{consommation.consommable.name}} </span>
							<small>{{$t("consommable-ref")}}: {{consommation.consommable.refExterne}}</small>
							<small v-if="consommation.consommable">{{$t("quantite-en-stock")}}: {{consommation.consommable.stock.quantite}} {{consommation.consommable.unite?consommation.consommable.unite:$t("default-unite")}}</small>
							<vg-button type="default-danger" size="sm" style="width: 100%;" @click="removeItem(consommation, index)">{{$t("retirer")}}</vg-button>
						</div>
					</div>
				</div>
				<br>
				<vg-button type="info" size="sm" @click="addItem" :disabled="!lastItemIsNotEmpty">
					{{$t("add-consommable")}}
				</vg-button>
				<vg-button v-if="$vgutils.isMobile()" type="success" size="sm" @click="showScanner=true;">
					{{$t("scanner-consommable")}}
				</vg-button>
			</vg-input>
			<vg-input :title="$t('commentaire')" v-model="bon.description"
				inputType="textarea" />
			<vg-signataire-datalist v-if="!bon.ficheDemandeConsommable_id" v-model="demande.nomDemandeur"
				:title="$t('demandeur')"
				:placeholder="$t('demandeur')"
				isDemandeConsommables />
			<vg-checkbox v-if="bon.ficheDemandeConsommable_id && !isUpdateForm"
				:defaultChecked="isFicheAutomaticallyClosed"
				:label="$t('fermer-fiche-apres-bon-de-sortie')"
				@checked="isFicheAutomaticallyClosed=true;"
				@unchecked="isFicheAutomaticallyClosed=false;"/>
			<vg-modal v-if="showScanner"
				:title="$t('scanner-consommable')"
				@close="showScanner=false;">
				<template #body>
					<vg-scanner
						@scanSuccess="onScannSuccess" />
				</template>
				<template #footer>
					<vg-button @click="showScanner=false;">
						{{$t('close')}}
					</vg-button>
				</template>
			</vg-modal>
		</template>
		<template #footer>
			<vg-button @click="$emit('close')">
				{{$t("cancel")}}
			</vg-button>
			<vg-button v-if="!isUpdateForm" type="success" @click="onOpenPDF" :disabled="isBonNotValid">
				{{$t("generate-bon")}}
			</vg-button>
			<vg-button v-else type="danger"
				@click="updateBonDeSortie">
				{{$t("update")}}
			</vg-button>
			<vg-modal v-if="showPDF"
				:title="$t('bon-de-sortie-pdf')"
				:width="'100vw'"
				:height="$vgutils.isMobile()?'auto':'100vh'"
				@close="showPDF=false;">
				<template #body>
					<div v-if="$vgutils.isMobile() && !showSignaturePad ">
						<span v-if="!signatureImage">{{$t("veuiller-signer-bon-de-sortie")}}</span>
						<hr v-if="!signatureImage">
						<div v-for="item in bon.consommations">
							<span>{{item.quantite}} {{item.consommable.name}}</span>
						</div>
					</div>
					<vg-bon-de-sortie-pdf v-if="!showSignaturePad"
						v-model="bon"
						:demande="demande"
						:site="getSelectedSite"
						:serviceDemandeur="getServiceDemandeur"
						:nomDemandeur="getNomDemandeur"
						:signatureImage="signatureImage"
						@generated-pdf-datas="onGeneratedPDF"/> 
					<div v-else>
						<span>{{ $t("signature-donneur", {donneur: bon.signataireDonneur}) }}</span>
						<br>
						<br>
						<vg-signature-pad
							@close="showSignaturePad=false;"
							@save="onSaveSignaturePad"/>
					</div>
				</template>
				<template #footer>
					<vg-button v-if="!showSignaturePad"
						@click="showPDF=false;signatureImage=false;">
						{{$t("cancel")}}
					</vg-button>
					<!--vg-button type="success" v-if="!showSignaturePad && !signatureImage"
						@click="onSigner">
						{{$t("signer-bon")}}
					</vg-button-->
					<vg-button type="success" v-if="!showSignaturePad && bonDeSortiePDF && showOpenBonDeSortieEquipementBouton"
						:disabled="isLoading"
						@click="saveBonDeSortie(true)">
						{{$t("envoyer-bon-et-sortir-equipements")}}
					</vg-button>
					<vg-button type="success" v-if="!showSignaturePad && bonDeSortiePDF"
						:disabled="isLoading"
						@click="saveBonDeSortie(false)">
						{{$t("envoyer-bon-et-fermer")}}
					</vg-button>
				</template>
			</vg-modal>
		</template>
	</vg-modal>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
	import VgSignaturePad from "src/components/Vg/VgSignaturePad.vue";
	import VgBonDeSortiePdf from "src/components/Vg/BonsDeSortie/VgBonDeSortiePdf.vue";
	import VgConsommableDatalist from "src/components/Vg/Datalists/VgConsommableDatalist.vue";
	import VgSitesSelector from "src/components/Vg/Selectors/VgSitesSelector.vue";
	import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
	import VgSignataireDatalist from "src/components/Vg/Datalists/VgSignataireDatalist.vue";
	import VgScanner from 'src/components/Vg/VgScanner.vue';
	import BonsDeSortieMixins from "src/mixins/BonsDeSortieMixins.js";
	import StocksMixins from "src/mixins/StocksMixins.js";
	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
	import Metadatas from "src/services/Metadatas";
	import { mapGetters, mapActions } from "vuex";
    export default {
        name: 'vg-bon-de-sortie-form',
		mixins: [ BonsDeSortieMixins, StocksMixins, ConsommablesMixins ],
		components: {
			VgInput,
			VgButton,
			VgCheckbox,
			VgSignaturePad,
			VgBonDeSortiePdf,
			VgConsommableDatalist,
			VgScanner,
			VgSitesSelector,
			VgServiceSelector,
			VgSignataireDatalist
		},
        props: {
            value: {
				type: Object,
				default: function(){
					return null;
				}
			},
			siteId: {
				type: String | Number,
				default: null
			},
			serviceDemandeur:{
				type: String,
				default: null
			},
			defaultSignataireDonneur:{
				type: String,
				default: null
			},
			defaultSignataire: {
				type: String,
				default: null
			},
			defaultSignatureImageDonneur:{
				type: String,
				default: null
			},
			defaultSignatureImageReceveur: {
				type: String,
				default: null
			},
			showOpenBonDeSortieEquipementBouton:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				demande:{
					site_id: this.siteId || null,
					serviceDemandeur: this.serviceDemandeur || null,
					nomDemandeur: null
				},
				items: [],
                bon: {
					numero: null,
					dateCreation: null,
					description: null,
					signataire: this.defaultSignataire,
					signataireDonneur: this.defaultSignataireDonneur || this.$app.fullname,
					ficheDemandeConsommable_id: null,
					depotOrigin_id: null,
		            depotDestination_id: null,
					createur_id: this.$app.idUser,
					consommations: []
				},
				showPDF: false,
				showSignaturePad: false,
				signatureImage: this.defaultSignatureImageReceveur,
				bonDeSortiePDF: null,
				isFicheAutomaticallyClosed: false,
				showScanner: false,
				isLoading: false,
				selectedSite: null
            };
        },
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title-create": "Créer bon de sortie consommables",
            "modal-title-update": "Modifier bon de sortie consommables",
            "cancel": "Annuler",
            "update": "Modifier",
            "generate-bon": "Générer bon de sortie",
            "commentaire": "Commentaire",
            "signataire-donneur": "Qui transmet",
            "signataire-receveur": "Signataire du bon",
            "consommables-sortis": "Consommables sortis",
            "header-quantite": "Quantité",
            "header-unite": "Unité",
            "unite": "u.",
            "default-unite": "u.",
            "quantite-en-stock": "Quantité en stock",
            "ref": "Réf",
            "signer-bon": "Signer bon de sortie",
            "envoyer-bon-et-fermer": "Enregistrer bon de sortie et fermer",
            "envoyer-bon-et-sortir-equipements": "Enregistrer bon de sortie et sortir équipement(s)",
            "file-name": "bon_de_sortie_",
            "fermer-fiche-apres-bon-de-sortie": "Fermer fiche après bon de sortie",
            "retirer": "Retirer",
            "add-consommable": "Ajout consommable",
            "consommable-ref": "Réf",
            "bon-de-sortie-pdf": "Bon de sortie consommables",
            "scanner-consommable": "Scanner un consommable",
            "close": "Fermer",
            "veuiller-signer-bon-de-sortie": "Veuillez signer le bon de sortie",
			"site": "Site demandeur",
			"service": "Service demandeur",
			"signature-donneur": "Signature donneur: {donneur}",
			"demandeur": "Demandeur"
        },
        "en": {
			"modal-title-create": "Create consumables exit voucher",
			"modal-title-update": "Edit consumables exit voucher",
			"cancel": "Cancel",
			"update": "Edit",
			"generate-bon": "Generate exit voucher",
			"commentaire": "Comment",
			"signataire-donneur": "Giver",
			"signataire-receveur": "Exit voucher signatory",
			"consommables-sortis": "Consumables withdrawn",
			"header-quantite": "Quantity",
			"header-unite": "Unit",
			"unite": "u.",
			"default-unite": "u.",
			"quantite-en-stock": "Quantity in stock",
			"ref": "Ref",
			"signer-bon": "Sign exit voucher",
			"envoyer-bon": "Save exit voucher",
			"file-name": "exit_voucher_",
			"fermer-fiche-apres-bon-de-sortie": "Close form after exit voucher",
			"retirer": "Delete",
			"add-consommable": "Add consumable",
			"consommable-ref": "Ref",
			"bon-de-sortie-pdf": "Consumables exit voucher",
			"scanner-consommable": "Scan consumable",
			"close": "Close",
			"veuiller-signer-bon-de-sortie": "Please sign the exit voucher",
			"site": "Requesting site",
            "service": "Requesting service",
			"signature-donneur": "Donor signature: {donneur}",
			"demandeur": "Requester"
        }
    }
},
		watch: {
			value: function(value){
				if(this.value) this.bon = Object.assign({}, this.bon, this.value);
			}
		},
		created: function(){

		},
		mounted: function(){
			if(this.value) this.bon = Object.assign({}, this.bon, this.value);
			this.initBon();
		},
		methods: {
			onScannSuccess: function(code){
				let metadatas = new Metadatas();
				metadatas.setFilters({
					code: {attr: "c.code", value: code, action: "equals"},
					depot_id: {attr: "s.depot_id", value: this.getDepotId, action: "equals"}
				});
				this.ConsommablesMixins_getConsommables(metadatas, {_stored: false}).then((datas)=>{
					if(datas && datas.consommables && datas.consommables.length!=0){
						let consommable = datas.consommables[0];
						this.bon.consommations.push({
							consommable_id: consommable.id,
							quantite: 1,
							consommable: Object.assign({}, {}, consommable)
						});
					}else alert(this.$t("aucun-consommable-correspondant"));
					this.showScanner = false;
				});
			},
			addItem: function(){
				this.bon.consommations.push({
					consommable_id: null,
					quantite: 1
				});
			},
			removeItem: function(consommation, index){
				this.bon.consommations.splice(index, 1);
			},
			onSelectConsommable: function(consommable, consommation, index){
				this.bon.consommations[index].consommable_id = consommable.id;
				this.bon.consommations[index].consommable = Object.assign({}, {}, consommable);
			},
			initBon: function(){
				this.fetchDepots().then((datas)=>{
					this.bon.depotOrigin_id = this.depotDefault.id;
					this.bon.depotDestination_id = this.getDepotDestinationId;
					console.log("FDC", this.ficheDemandeConsommable);
					if(this.ficheDemandeConsommable && this.ficheDemandeConsommable.id){
						this.bon.ficheDemandeConsommable_id = this.ficheDemandeConsommable.id;
						this.isFicheAutomaticallyClosed = true;
						this.feedConsommations();
					}
				});
			},
			onSaveSignaturePad: function(signatureImage){
				this.signatureImage = signatureImage;
				this.showSignaturePad = false;
			},
			onGeneratedPDF: function(pdf){
				this.bonDeSortiePDF = pdf;
			},
			fetchStocks: function(){
				return new Promise((resolve, reject)=>{
					let consommablesIds = this.ficheDemandeConsommable.items.map((item)=>item.consommable_id);
					let metadatasStocks = new Metadatas();
					metadatasStocks.setFilters({
						consommable_id: {attr:"consommable_id", value: consommablesIds, action: "equals"},
						depotDefault_id: {attr: "depot_id", value: this.depotDefault.id, action: "equals"},
						//depotFlottant_id: {attr: "depot_id", value: this.depotFlottant.id, action: "equals"}
					});
					this.StocksMixins_getStocks(metadatasStocks).then((datas)=>{
						resolve(datas);
					});
				});
			},
			fetchDepots: function(){
				return new Promise((resolve, reject)=>{
					let metadatasDepots = new Metadatas();
					this.StocksMixins_getDepots(metadatasDepots).then((datas)=>{
						resolve(datas);
					});
				});
			},
			fetchConsommables: function(idConsommables){
				return new Promise((resolve, reject)=>{
					let metadatasConsommables = new Metadatas();
					metadatasConsommables.setFilters({
						depot_id: {attr: "s.depot_id", value: this.depotDefault.id, action: "equals"},
						id: {attr: "c.id", value: idConsommables, action: "equals"}
					});
					this.ConsommablesMixins_getConsommables(metadatasConsommables, {_stored: false}).then((datas)=>{
						resolve(datas.consommables);
					});
				}).catch((reason) => {
					reject(reason);
				});
			},
			fetchConsommations: function(idConsommables){
				return new Promise((resolve, reject)=>{
					let metadatas = new Metadatas();
		            metadatas.setFilters({
						ficheDemandeConsommable_id: {attr: "bs.ficheDemandeConsommable_id", value: this.ficheDemandeConsommable.id, action: "equals"},
						consommable_id: {attr: "cm.consommable_id", value: idConsommables, action: "equals"}
					});
		            this.ConsommablesMixins_getConsommations(metadatas, {_stored: false}).then((datas)=>{
						resolve(datas.datas);
		            });
				}).catch((reason) => {
					reject(reason);
				});
			},
			feedConsommations: function(){
				let idConsommables = this.ficheDemandeConsommable.items.map((item)=>item.consommable_id);
				Promise.all([
					this.fetchConsommables(idConsommables),
					this.fetchConsommations(idConsommables)
				]).then((values)=>{
					let consommables = values[0]?values[0]:[];
					let consommations = values[1]?values[1]:[];
					let totalConsomme = 0;
					this.bon.consommations = this.ficheDemandeConsommable.items.map((item)=>{
						totalConsomme = consommations.filter((consommation)=>consommation.consommable_id==item.consommable_id)
							.map((consommation)=>consommation.quantite)
							.reduce((partialSum, quantite) => Number(partialSum) + Number(quantite), 0);
						return {
							quantite: Number(item.quantite)-totalConsomme<=0?0:Number(item.quantite)-totalConsomme,
							consommable_id: item.consommable_id,
							consommable: consommables.find((conso)=>conso.id==item.consommable_id)
						};
					});
				});
			},
			onOpenPDF: function(){
				this.bon.dateCreation = moment().format("YYYY-MM-DD HH:mm");
				this.showPDF = true;
			},
			onSigner: function(){
				this.showSignaturePad = true;
			},
			saveBonDeSortie: function(){ 
				this.isLoading = true;
				this.$emit("loading");
				this.bon.consommations = this.bon.consommations.filter((consommation)=>consommation.quantite!=0);
				if(this.bon.ficheDemandeConsommable_id){ // si BS lié à une FDC existante
					this.BonsDeSortieMixins_create([this.bon]).then((datas)=>{
						console.log("DATAS", datas);
						this.VgFilesMixins_uploadPDFBlob(this.bonDeSortiePDF, datas[0].uid, 4301, "bon-de-sortie", this.$t("file-name")+moment().format("YYYY-MM-DD_HH:mm")).then((document)=>{
							this.$store.dispatch("BonsDeSortieStore/updateItem", Object.assign({}, datas[0], {document: document}));
							this.$emit("save", {
								bonDeSortie: Object.assign({}, datas[0], {document: document}),
								automaticallyClose: this.isFicheAutomaticallyClosed,
								signatureImage: this.signatureImage
							});
						});
					});
				}else{	// si BS non lié à une FDC existante => il faut en créer une
					let ficheDemandeConsommable = {
						demandeur_id: this.$app.idUser,
						site_id: this.demande.site_id,
						serviceDemandeur: this.demande.serviceDemandeur,
						items: this.getFicheDemandeConsommableItems,
						dateCreation: this.bon.dateCreation,
						dateCloture: this.bon.dateCreation,
						nomDemandeur: this.demande.nomDemandeur
					};
					this.BonsDeSortieMixins_create([this.bon], ficheDemandeConsommable).then((datas)=>{
						console.log("DATAS", datas);
						this.VgFilesMixins_uploadPDFBlob(this.bonDeSortiePDF, datas[0].uid, 4301, "bon-de-sortie", this.$t("file-name")+moment().format("YYYY-MM-DD_HH:mm")).then((document)=>{
							this.$store.dispatch("BonsDeSortieStore/updateItem", Object.assign({}, datas[0], {document: document}));
							this.$emit("save", {
								bonDeSortie: Object.assign({}, datas[0], {document: document}),
								automaticallyClose: this.isFicheAutomaticallyClosed,
								signatureImage: this.signatureImage
							});
						});
					});
				}
			},
			updateBonDeSortie: function(){
				this.$emit("loading");
				let bonDeSortie = {
					id: this.bon.id,
					description: this.bon.description,
					userId: this.$app.appID
				};
				this.BonsDeSortieMixins_update(bonDeSortie).then((data)=>{
					this.$emit("update", {
						bonDeSortie: data
					});
				});
			},
			onSiteSelected: function(site){
				console.log("SITE SELECTED", site);
				this.selectedSite = Object.assign({}, {}, site);
			}
		},
        computed: {
			...mapGetters({
	            bonsDeSortie: 'BonsDeSortieStore/getCollection',
				bonDeSortie: "BonsDeSortieStore/getSelectedItem",
				ficheDemandeConsommable: "FicheDemandeConsommablesStore/getSelectedItem",
				depotDefault: "StocksStore/getDepotDefault",
				depotFlottant: "StocksStore/getDepotFlottant"
	        }),
			isGestionMagasin: function(){
	            let user = window.sessionStorage.getItem("user");
	            user = JSON.parse(user);
	            return user.isGestionMagasin;
	        },
			getDepotDestinationId: function(){
				return this.isGestionMagasin?this.depotFlottant.id:null;
			},
			lastItemIsNotEmpty: function(){
				if(this.bon.consommations.length==0) return true;
				let lastIndex = this.bon.consommations.length-1;
				return this.bon.consommations[lastIndex].consommable_id && this.bon.consommations[lastIndex].quantite!=null;
			},
			isUpdateForm: function(){
				return this.bon && this.bon.id;
			},
			isSiteServiceValid: function(){
				if(!this.bon.ficheDemandeConsommable_id){
					return this.demande.site_id && this.demande.serviceDemandeur;
				}else{
					return true;
				}
			},
			isBonNotValid: function(){
				return !this.isSiteServiceValid
					|| !this.bon.signataireDonneur 
					|| this.bon.consommations.length==0 
					|| (!this.bon.ficheDemandeConsommable_id && this.bon.consommations.findIndex((consommation)=>!consommation.consommable_id)!=-1);
			},
			getDepotId: function(){
				return this.isGestionMagasin?this.depotFlottant.id:this.depotDefault.id;
			},
			getDefaultDepotId: function(){
				return this.depotDefault.id;
			},
			getFicheDemandeConsommableItems: function(){
				return this.bon.consommations.map((consommation)=>{
					return {
						consommable_id: consommation.consommable_id,
						quantite: consommation.quantite
					};
				});
			},
			getSelectedSite: function(){
				if(this.selectedSite) return this.selectedSite;
				else if(this.ficheDemandeConsommable && this.ficheDemandeConsommable.site_id && this.ficheDemandeConsommable.site){
					return this.ficheDemandeConsommable.site;
				}else return "-";
			},
			getServiceDemandeur: function(){
				if(this.ficheDemandeConsommable && this.ficheDemandeConsommable.serviceDemandeur) return this.ficheDemandeConsommable.serviceDemandeur;
				else return null;
			},
			getNomDemandeur: function(){
				if(this.ficheDemandeConsommable && this.ficheDemandeConsommable.nomDemandeur) return this.ficheDemandeConsommable.nomDemandeur;
				else if(this.bon.nomDemandeur) return this.bon.nomDemandeur;
				else return this.demande.nomDemandeur;
			}
		}
    };

</script>
<style lang="scss" scoped>
.consommable-row{
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;
}
.consommable-row-header{
	margin-bottom: 5px;
	font-size: 12px;
}
</style>