<template lang="html">
    <div class="vg-affectables-selector">
        <div class="affectable-collapser"
            @click="displayDatalist=!displayDatalist;">
            <span v-if="!selectedAffectables || (selectedAffectables && selectedAffectables.length==0)" style="color:#bbbcbe">{{$t("placeholder")}}</span>
            <div class="selected-affectes">
                <span v-for="(affecte, key) in selectedAffectables" class="selected-affecte" :key="key">
                    <i :class="['fas',{'fa-user':affecte.nom?true:false,'fa-industry':affecte.name?true:false}]" style="margin-right:5px;"></i> {{affecte.name?affecte.name:affecte.nom+' '+affecte.prenom}}
                </span>
            </div>
            <span v-if="selectedAffectables.length>2" class="selected-affecte-more only-desktop">+ {{selectedAffectables.length-2}}</span>
            <span v-if="selectedAffectables.length>1" class="selected-affecte-more only-mobile">+ {{selectedAffectables.length-1}}</span>
            <i v-if="!displayDatalist" class="fas fa-chevron-down"></i>
            <i v-else class="fas fa-chevron-up"></i>
        </div>
        <div v-if="displayDatalist" class="affectables-datalist">
            <div class="search">
                <vg-text-filter v-model="searchQuery"
                    :placeholder="$t('search-field')" />
            </div>
            <div class="affectables-users">
                <div v-for="user in getUsers" class="affectable"
                    @click="onSelectUser(user)">
                    <i class="fas fa-user" style="margin-right:5px;"></i> {{user.nom}} {{user.prenom}}
                    <i class="far fa-check-circle" v-if="isUserSelected(user)" style="color:#0099ff;"></i>
                    <span v-if="user.tiers_id && user.tiers && user.tiers.name" style="margin-left:10px;font-weight:bold;">{{user.tiers.name}}</span>
                </div>
                <span v-if="getUsers.length==0">{{ $t("aucun-user") }}</span>
            </div>
            <div style="background-color:#0099ff;height:2px;margin:10px;background-color:#0099ff;"></div>
            <div class="affectables-tiers">
                <div v-for="tier in getTiers" class="affectable"
                    @click="onSelectTier(tier)">
                    <i class="fas fa-industry"></i> {{tier.name}}
                    <i class="far fa-check-circle" v-if="isTierSelected(tier)" style="color:#0099ff;"></i>
                </div>
                <span v-if="getTiers.length==0">{{ $t("aucun-tiers") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
import UserMixins from "src/mixins/UserMixins.js";
import TiersMixins from "src/mixins/TiersMixins.js";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-users-selector',
    components: {
        VgInput,
        VgSelect,
        VgTextFilter
    },
    mixins: [UserMixins, TiersMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "placeholder": "Utilisateur(s) ou tiers",
            "search-field": "Rechercher un utilisateur ou un tiers",
            "aucun-user": "Aucun utilisateur",
            "aucun-tiers": "Aucun tiers"
        },
        "en": {
            "placeholder": "User(s) or supplier",
            "search-field": "Search",
            "aucun-user": "No user",
            "aucun-tiers": "No supplier"
        }
    }
},
    props: {
        /**
        * @model
        */
        value: {
            type: Array,
            default: null
        },
        /**
        * Attribut de l'objet tache à retourner comme valeur.
        * @default id
        * Si attributeValue=null retourne l'objet sélectionné.
        */
        attributeValue: {
            type: String,
            default: "id"
        },
        /**
         * Selection de plusieurs users.
         * @default false
         */
        multiple: {
            type:Boolean,
            default:false
        },
        clearable: {
        	type: Boolean,
        	default: true
        },
        /**
        * Object {user: [array of ids], tiers: [array of tiers]}
        */
        defaultAffectes: {
            type: Object,
            default: function(){
                return { users: [], tiers: [] };
            }
        },
        filtersTiers:{
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    data: function() {
        return {
            selectedAffectables: this.value || [],
            metadatasUsers: new Metadatas(),
            metadatasTiers: new Metadatas(),
            agfiltersUsers: {
                roles: {attr: "fu.roles", value: ["ROLE_ADMIN", "ROLE_TECHNICIEN", "ROLE_SUPERVISEUR", "ROLE_SOUS_TRAITANT"], action: "equals"},
                enabled: {attr: "fu.enabled", value: "1", action: "equals"}
            },
            agfiltersTiers: {},
            displayDatalist: false,
            searchQuery: null
        };
    },
    watch: {
        value: {
            handler: function(val){

            }
        }
    },
    created: function(){
        this.fetch();
    },
    mounted: function(){

    },
    methods: {
        indexUserAffecte: function(user){
            if(this.selectedAffectables) return this.selectedAffectables.findIndex((aff)=>aff.id==user.id && aff.nom==user.nom && aff.prenom==user.prenom);
            else return -1;
        },
        indexTierAffecte: function(user){
            if(this.selectedAffectables) return this.selectedAffectables.findIndex((aff)=>aff.id==user.id && aff.nom==user.nom && aff.prenom==user.prenom);
            else return -1;
        },
        isUserSelected: function(user){
            return this.indexUserAffecte(user)!=-1;
        },
        isTierSelected: function(tier){
            return this.indexTierAffecte(tier)!=-1;
        },
        onSelectUser: function(user){
            if(!this.isUserSelected(user)) this.selectedAffectables.push(user);
            else this.selectedAffectables.splice(this.indexUserAffecte(user), 1);
            this.$emit("select", this.selectedAffectables);
        },
        onSelectTier: function(tier){
            let indexTiersAffecte = this.selectedAffectables.findIndex((aff)=>aff.hasOwnProperty("name"));
            if(indexTiersAffecte!=-1){
                let tiersSelected = Object.assign({},{},this.selectedAffectables[indexTiersAffecte]);
                this.selectedAffectables.splice(indexTiersAffecte, 1);
                if(tiersSelected.id!=tier.id) this.selectedAffectables.push(tier);
            }else this.selectedAffectables.push(tier);
            this.$emit("select", this.selectedAffectables);
        },
        initDefaultUsersAffectes: function(){
            let usersAffectesParDefault = this.users.filter((user)=>this.defaultAffectes.users.indexOf(user.id)!=-1);
            this.selectedAffectables = usersAffectesParDefault;
        },
        initDefaultTiersAffectes: function(){
            let tiersAffectesParDefault = this.tiers.filter((tier)=>this.defaultAffectes.tiers.indexOf(tier.id)!=-1);
            this.selectedAffectables = this.selectedAffectables.concat(tiersAffectesParDefault);
        },
        fetch: function(){
            this.metadatasUsers.setFilters(this.agfiltersUsers);
            this.metadatasTiers.setFilters(Object.assign({}, this.agfiltersTiers, this.filtersTiers));
            let restrictionSite = null;
            if(this.maintenance){
                restrictionSite = this.maintenance.equipement.path.split("/");
                restrictionSite = restrictionSite[0]+"/"+restrictionSite[1];
                // @WARNING HACK pour FpF PHD PMSCI PSA PS
                if(restrictionSite.startsWith("FpF/PSA")) restrictionSite = "FpF/PSA ";
                else if(restrictionSite.startsWith("FpF/PS")) restrictionSite = "FpF/PS ";
                else if(restrictionSite.startsWith("FpF/PMSCI")) restrictionSite = "FpF/PMSCI ";
                else if(restrictionSite.startsWith("FpF/PHD")) restrictionSite = "FpF/PHD ";
            }
            this.UserMixins_getUsersRestreints(this.$app.appID, this.metadatasUsers, restrictionSite).then((datas)=>{
                this.$emit("fetch-success-users", {users: datas});
                this.initDefaultUsersAffectes();
                this.TiersMixins_get(this.metadatasTiers).then((datas)=>{
                    this.$emit("fetch-success-tiers", {tiers: datas});
                    this.initDefaultTiersAffectes();
                    this.$emit("set-default-affectes", this.selectedAffectables);
                });
            });
        }
    },
    computed: {
        ...mapGetters({
            users: 'UsersStore/getCollection',
            tiers: 'TiersStore/getCollection',
            maintenance: "MaintenancesStore/getSelectedMaintenance"
        }),
        getUsers: function(){
            let query = this.searchQuery?this.searchQuery.toLowerCase():null;
            let users = this.users.sort((u1, u2)=>{
                if(u1.nom<u2.nom) return -1;
                else if(u1.nom==u2.nom){
                    if(u1.prenom<u2.prenom) return -1;
                    else if(u1.prenom==u2.prenom) return 0;
                    else return -1;
                }
                else return 1;
            });
            if(query!=null){ 
                return users.filter((u)=>
                    u.nom.toLowerCase().search(query)!=-1 
                    || (u.prenom?u.prenom.toLowerCase().search(query)!=-1:false)
                    || (u.email?u.email.search(query)!=-1:false)
                    || (u.tiers && u.tiers.name? u.tiers.name.toLowerCase().search(query)!=-1:false)
                );
            }else return users;
        },
        getTiers: function(){
            let query = this.searchQuery?this.searchQuery.toLowerCase():null;
            if(query!=null){
                return this.tiers.filter((t)=>
                    t.name.toLowerCase().search(query)!=-1
                    || (t.type? t.type.toLowerCase().search(query)!=-1: false)
                    || (t.tags? t.tags.toLowerCase().search(query)!=-1: false)
                );
            }else{
                return this.tiers;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.vg-affectables-selector{
    width: 100%;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
    .affectable-collapser{
        border:1px solid #dcdfe6;
        width:100%;
        height:36px;
        display:flex;
        justify-content:space-between;
        align-items:center;
        padding:0 10px;
        cursor:pointer;
    }
    .selected-affectes{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        .selected-affecte{
            margin:2px 5px 2px 0;
            padding:5px;
            border:1px solid #dcdfe6;
            border-radius: 1px;
        }
        .selected-affecte:nth-child(n+3){
            display: none;
        }
    }
    .selected-affecte-more{
        margin:2px 5px 2px 0;
        padding:5px;
        border-radius: 3px;
        background-color: #0099ff;
        font-weight: bold;
        color:white;
    }
}
.affectable{
    padding:10px;
    cursor:pointer;
    background-color: white;
}
.affectable:hover{
    background-color: whitesmoke;
}
.affectables-datalist{
    max-height:300px;
    width:100%;
    overflow:auto;
    padding:10px;
    .search{
        margin-top: 5px;
    }
}
.only-desktop{
    display: inherit;
}
.only-mobile{
    display: none;
}
@media only screen and (max-width: 768px) {
    .only-desktop{
        display: none;
    }
    .only-mobile{
        display: block;
    }
    .selected-affectes{
        .selected-affecte:nth-child(n+2){
            display: none;
        }
    }
}
</style>
<docs>

    ### vg-affectables-selector

</docs>
