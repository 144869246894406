<template>
    <vg-app-layout
        :title="$t('page-title')"
        :icon="getPageIcon"
        :colorheader="getPageColor"
		:isLoadingState="isLoadingState">
        <template #primary>
            <div class="lancement-container">
                <b class="title">{{ $t("page-main-title") }}</b>
                <span class="subtitle">{{ $t("etape-1") }}</span>
                <vg-search-datas-filter 
                    position="slot"
                    type="lancement"
                    size="lg"
                    afterSaveRedirectTo="_lancement"
                    :placeholder="$t('rechercher-equipement-lieu')" />
                <button
                    class="button-lancement-scann"
                    @click="showScanner=true;">
                    <vg-icon url="static/assets/icone/custom/qr-code.svg" 
                        color="white"
                        size="30" />
                    <span>{{ $t("scanner") }}</span>
                    <!--div style="display: flex;flex-direction: column;justify-content: center;align-items: center;gap:10px;height: 100%;width:100%;">
                        
                    </div-->
                </button>
                <div class="separation-line"></div>
                <vg-collapse :title="$t('informations-complementaires')"
                    :collapseByDefault="false"
                    style="width:100%;">
                    <template #content>
                        <vg-calendar-planning 
                            :calendars="calendarsDisplayed"
                            :schedules="calendarEventsDisplayed"
                            :isReadOnly="true"
                            :showCalendarDays="false"
                            @click-schedule="openCheck" />
                    </template>
                </vg-collapse>
            </div>
            <vg-modal v-if="showScanner"
               :title="$t('scanner-une-etiquette')"
               @close="showScanner=false;">
               <template #body>
                   <vg-scanner
                        isFetching
                        :collection="false"
                       @scanSuccess="onScannSuccess" />
               </template>
               <template #footer>
                   <vg-button @click="showScanner=false;">
                       {{$t('close')}}
                   </vg-button>
               </template>
            </vg-modal>
        </template>
    </vg-app-layout>
</template>

<script>
    const ICON_DESKTOP = "verification.png";
    const ICON_MOBILE = "mobile/verification.png";
    const HEADER_BACKGROUND_COLOR_MOBILE = "#f36f3a";
    
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgIcon from "src/components/Vg/VgIcon.vue";
    import VgCollapse from "src/components/Vg/VgCollapse.vue";
    import VgSearchDatasFilter from "src/components/Vg/SearchDatas/VgSearchDatasFilter.vue";
    import VgScanner from "src/components/Vg/VgScanner.vue";
    import VgCalendarPlanning from "src/components/Vg/Calendar/VgCalendarPlanning.vue";

    import equipementsMixins from 'src/mixins/equipementsMixins.js';
    import CalendarMixins from 'src/mixins/CalendarMixins.js';

    import { mapGetters } from 'vuex';
    import Metadatas from "src/services/Metadatas";
    export default {
        name: 'lancement',
        components: {
            VgInput,
            VgButton,
            VgModal,
            VgIcon,
            VgCollapse,
            VgSearchDatasFilter,
            VgScanner,
            VgCalendarPlanning
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Ronde",
            "page-main-title": "Valider une tâche récurrente",
            "etape-1":"Etape 1: Sélectionner le lieu ou l'équipement",
            "scanner": "Scanner QR Code",
            "scanner-une-etiquette": "Scanner une étiquette",
            "informations-complementaires": "Informations complémentaires",
            "close": "Fermer",
            "aucune-tache-associee-equipement": "Aucune tâche associée à l'équipement {libel}",
            "aucune-tache-associee-lieu": "Aucune tâche associée à la pièce {libel}",
            "impossible-charger-equipement": "Impossible de charger l'équipement {libel}",
            "impossible-charger-lieu": "Impossible de charger la pièce {libel}",
            "rechercher-equipement-lieu": "Saisissez au moins 3 lettres",
            "quel-lieu-ou-equipement": "Quel lieu ou équipement?",
            "valider-tache": "Valider une tâche"
        },
        "en": {
            "page-title": "Round",
            "scanner": "SCANNER",
            "scanner-une-etiquette": "Scan a label",
            "informations-complementaires": "Additional information",
            "close": "Close",
            "aucune-tache-associee-equipement": "No task associated with equipment {libel}",
            "aucune-tache-associee-lieu": "No task associated with room {libel}",
            "impossible-charger-equipement": "Unable to load equipment {libel}",
            "impossible-charger-lieu": "Unable to load room {libel}",
            "rechercher-equipement-lieu": "Enter at least 3 letters",
            "quel-lieu-ou-equipement": "Which place or equipment?",
            "valider-tache": "Validate a task"
        }
    }
},
		mixins:[equipementsMixins, CalendarMixins],
        props:{
            
        },
        data: function() {
            return {
                searchQuery: "",
    			showScanner: false,
    			isLoadingState: false,
                calendarsDisplayed: [
                    {
                        id: "tache-session",
                        name: this.$t("calendar-tache-session"),
                        checked: true,
                        color: "white",
                        bgColor: "#4F56A6",
                        dragBgColor: "#4F56A6",
                        borderColor: "transparent",
                        section: this.$t("section-preventif")
                    }
                ],
                calendarEvents: []
            }
        },
        watch:{
            
        },
        methods:{
            /**
             * @param array lieuIds
             * @return {promise}
             */
            fetchEquipementByQrCode:function(qrCode){
                return new Promise((resolve,reject)=>{
                    let metadatas = new Metadatas();
                    metadatas.setFilters(
                        {
                            "qrCode":{attr: "e.qrCode", value: qrCode, action: "equals", "openParenthesis":true},
                            "codeUn":{attr: "l.codeUn", value: qrCode, action: "equals", "logicalOperator":"OR", "closeParenthesis":true}
                        }
                    );
                    this.equipementsMixins_getEquipements(metadatas).then((equipements)=>{

                        resolve(equipements);
                    }).catch((error)=>{
                        reject(error);
                    });
                });
            },
            onScannSuccess: function(equipement){
                if(equipement.isGroupEqp=="0"){ // equipement
                    if(!equipement.taches || equipement.taches.length==0){ 
                        alert(this.$t("aucune-tache-associee-equipement", {libel: equipement.libel_equipement}));
                    }else if(equipement.taches.length==1){
                        let tacheId = equipement.taches[0].idTache;
                        this.$router.push({name:"_process_verification", params: {type:"equipement", equipementId: equipement.id, tacheId: tacheId, afterSaveRedirectTo: "_lancement"} });
                    }else{
                        this.$router.push({name:"_process_verification_taches_selection", params: {type:"equipement", equipementId: equipement.id, afterSaveRedirectTo: "_lancement"} });
                    }
                }else{ // piece
                    if(!equipement.taches || equipement.taches.length==0){ 
                        alert(this.$t("aucune-tache-associee-lieu", {libel: equipement.libel_equipement}));
                    }else if(equipement.taches.length==1){
                        let tacheId = equipement.taches[0].idTache;
                        this.$router.push({name:"_process_verification", params:{type:"lieu", equipementId: equipement.id, tacheId: tacheId, afterSaveRedirectTo: "_lancement"} });
                    }else{
                        this.$router.push({name:"_process_verification_taches_selection", params: {type:"lieu", equipementId: equipement.id, afterSaveRedirectTo: "_lancement"}});
                    }
                }
            },
            fetchCalendarEvents: function(){
				this.isLoadingState = true;
				this.calendarEvents = [];
				let dateStart = moment().format("YYYY-MM-DD 00:00:00");
				let dateEnd = moment().format("YYYY-MM-DD 23:59:59");
				this.CalendarMixins_get(dateStart, dateEnd, [], [], [this.$app.idUser]).then((datas)=>{
					this.calendarEvents = datas.events;
					this.isLoadingState = false;
				});
			},
            openCheck: function(datas){
                let schedule = datas.schedule;
                if(this.can("CHECKS.PAGE")){
                    if(schedule.raw.tache.type_tache=="Verification_equipement") {
                        if(schedule.raw.tache.isScanRequired==1) this.$router.push({name: "_process_verification_tache", params: { type: "equipement", tacheId: schedule.raw.tache_id, afterSaveRedirectTo: "_lancement" }});
                        else this.$router.push({ name: '_progression', params: { tacheId: schedule.raw.tache.id, afterSaveRedirectTo: "_lancement"} });
                    }else if(schedule.raw.tache.type_tache=="Relever_compteur") {
                        this.$router.push({name: "_process_verification_tache", params: { type: "compteur", tacheId: schedule.raw.tache_id, afterSaveRedirectTo: "_lancement" }});
                    }else if(schedule.raw.tache.type_tache=="Relever_Grandeur_physique") {
                        this.$router.push({name: "_process_verification_tache", params: { type: "grandeur-physique", tacheId: schedule.raw.tache_id, afterSaveRedirectTo: "_lancement" }});
                    }else if(schedule.raw.tache.type_tache=="Verification_Lieu") {
                        this.$router.push({name: "_process_verification_tache", params: { type: "lieux", tacheId: schedule.raw.tache_id, afterSaveRedirectTo: "_lancement" }});
                    }
                }
            },
        },
		created: function(){
            this.fetchCalendarEvents();
		},
		mounted: function(){

		},
        computed:{
            ...mapGetters({
                
            }),
            getPageIcon: function(){
                return this.$vgutils.isMobile()?ICON_MOBILE:ICON_DESKTOP;
            },
            getPageColor: function(){
                return this.$vgutils.isMobile() ? HEADER_BACKGROUND_COLOR_MOBILE : 'white';
            },
            calendarEventsDisplayed: function(){
                return this.calendarEvents.filter((event)=>this.calendarsDisplayed.findIndex((calendar)=>event.calendarId==calendar.id)!=-1);
            },
        }


    };
</script>
<style lang="scss" scoped>
.lancement-container{
    padding:24px; 
    height:--webkit-fill-available;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    .title{
        font-size: 24px;
    }
    .subtitle{
        font-size: 16px;
        color: #4B5563;
    }
    .separation-line{
        border-color: rgb(243 244 246/var(1));
        border-width: 1px;
    }
}
.button-lancement-scann{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 24px;
    border-radius: 12px;
    background-color: #2563eb;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}
.button-lancement-scann:hover {
    background-color: #1d4ed8;
}
.button-lancement-scann span {
    padding-top:3px;
    font-weight: 500;
}

</style>
