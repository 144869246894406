<template>
  <div class="check-invitation-container">
    <div class="card">
      <div class="card-header">
        <h2>{{ $t('title') }}</h2>
      </div>
      <div class="card-body" style="display:flex;flex-direction: column;">
        <div v-if="error" class="alert alert-danger">
          <vg-icon url="/static/assets/icone/custom/error.svg" class="error-icon" />
          {{ error }}
        </div>
        <div v-if="loading" class="loading-container">
          <div class="spinner"></div>
          <p>{{ $t('validating_invitation') }}</p>
        </div>
        <div v-if="success" class="alert alert-success">
          <vg-icon url="/static/assets/icone/custom/check-circle.svg" class="success-icon" />
          {{ success }}
          <p>{{ $t('redirecting') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VgIcon from 'src/components/Vg/VgIcon.vue';

export default {
  name: 'CheckInvitation',
  components: {
    VgIcon
  },
  data() {
    return {
      loading: true,
      error: null,
      success: null
    };
  },
  i18n: {
    messages: {
      en: {
        title: 'Invitation Validation',
        validating_invitation: 'Validating your invitation...',
        missing_token: 'Missing invitation token. Please check your invitation link.',
        invalid_token: 'Invalid invitation token. Please check your invitation link.',
        expired_token: 'This invitation has expired. Please request a new invitation.',
        already_used: 'This invitation has already been used.',
        server_error: 'An error occurred while validating your invitation. Please try again later.',
        validation_success: 'Invitation validated successfully!',
        redirecting: 'Redirecting to registration page...'
      },
      fr: {
        title: 'Validation de l\'invitation',
        validating_invitation: 'Validation de votre invitation...',
        missing_token: 'Jeton d\'invitation manquant. Veuillez vérifier votre lien d\'invitation.',
        invalid_token: 'Jeton d\'invitation invalide. Veuillez vérifier votre lien d\'invitation.',
        expired_token: 'Cette invitation a expiré. Veuillez demander une nouvelle invitation.',
        already_used: 'Cette invitation a déjà été utilisée.',
        server_error: 'Une erreur s\'est produite lors de la validation de votre invitation. Veuillez réessayer plus tard.',
        validation_success: 'Invitation validée avec succès !',
        redirecting: 'Redirection vers la page d\'inscription...'
      },
      th: {
        title: 'การตรวจสอบคำเชิญ',
        validating_invitation: 'กำลังตรวจสอบคำเชิญของคุณ...',
        missing_token: 'ไม่พบโทเค็นคำเชิญ โปรดตรวจสอบลิงก์คำเชิญของคุณ',
        invalid_token: 'โทเค็นคำเชิญไม่ถูกต้อง โปรดตรวจสอบลิงก์คำเชิญของคุณ',
        expired_token: 'คำเชิญนี้หมดอายุแล้ว โปรดขอคำเชิญใหม่',
        already_used: 'คำเชิญนี้ถูกใช้ไปแล้ว',
        server_error: 'เกิดข้อผิดพลาดขณะตรวจสอบคำเชิญของคุณ โปรดลองอีกครั้งในภายหลัง',
        validation_success: 'ตรวจสอบคำเชิญสำเร็จแล้ว!',
        redirecting: 'กำลังเปลี่ยนเส้นทางไปยังหน้าลงทะเบียน...'
      }
    }
  },
  created() {
    // Get the token from the URL path parameter or query parameter
    const token = this.$route.params.token || this.$route.query.token;
    
    console.log('Route params:', this.$route.params);
    console.log('Route query:', this.$route.query);
    console.log('Token:', token);
    if (!token) {
      this.loading = false;
      this.error = this.$t('missing_token');
      return;
    }
    
    // Get the origin for the redirect
    let origin = window.location.origin;
    // Validate the invitation token
    this.validateInvitation(token, origin);
  },
  methods: {
    async validateInvitation(token, origin) {
      try {
        // Try to use the Verifgood JS SDK to validate the invitation
        try {
          const response = await this.$vg.invitations.checkInvitation({
            token: token,
            email: "",
            origin: origin
          });
          if( response.token ){
            console.log('Invitation validated:', response);
            this.loading = false;
            this.success = this.$t('validation_success');
            setTimeout(() => {
              let redirectUrl = `register?email=&invitation_token=${response.token}`;
              let origin = window.location.origin;
              redirectUrl = `${origin}/${redirectUrl}`;
              console.log('Redirecting to:', redirectUrl);
              window.location.href = redirectUrl;
            }, 1500);
          }else{
            this.loading = false;
            this.error = this.$t('invalid_token');
          }
          
        } catch (sdkError) {
          console.error('SDK error:', sdkError);
         
        }
      } catch (err) {
        console.error('Error validating invitation:', err);
        this.loading = false;
        
        // Handle different error types
        if (err.status === 404) {
          this.error = this.$t('invalid_token');
        } else if (err.status === 410) {
          this.error = this.$t('expired_token');
        } else if (err.status === 400) {
          this.error = this.$t('already_used');
        } else {
          this.error = err.message || this.$t('server_error');
        }
      }
    }
  }
};
</script>

<style scoped>
.check-invitation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.card {
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.card-header {
  background-color: #2563EB;
  color: white;
  padding: 15px;
}

.card-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.card-body {
  padding: 20px;
  background-color: white;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

.alert {
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error-icon, .success-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 576px) {
  .card {
    max-width: 100%;
  }
}
</style>
}
