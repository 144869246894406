<template>
    <vg-app-layout
        :title="getTitle"
        :icon="getIconPage"
        isZoomLayout
        :isLoadingState="isLoading"
        :isActionButtonDisplay="false"
        :colorheader="getHeaderBackgroundColor"
        :activeFilters="activeFilters"
        :filtersTitle="$t('modal-filters-title')"
        @remove-filters="onRemoveFilters"
        @backpage="backPage()">
        <template #body>
            <div style="display:flex;flex-direction:column;justify-content: flex-start;align-items: flex-start;gap:5px;width:100%;height:100%;">
                <div v-if="focusedTache" style="display: flex;flex-direction: column;justify-content: flex-start;align-items:flex-start;gap: 5px;width:100%;">
                    <div style="display: flex;justify-content: space-between;align-items: center;gap:10px;">
                        <span>
                            {{ $t("page-title") }} 
                            <b>{{ progressionTache.total - progressionTache.verified }}</b> 
                            {{ $t("equipements-avant-le") }} 
                            <vg-datetime-viewer v-model="progressionTache.dateFin" hideTime />
                            <small>( {{ $t("progressionVerif.mobile.reste") }} <b>{{progressionTache.dateFin | timeFromGivenValueToNow}}</b> ) </small>
                        </span>
                        <vg-button v-if="$vgutils.isMobile()" class="vg-button-scan" @click="goToCheckEquipement"><img src="static/assets/icone/inventaire.png" height="30" width="30">   </vg-button>
                    </div>
                    <vg-progress v-model="progressionTache.verified"
                        :total="progressionTache.total"
                        bar
                        percentage
                        style="width:100%;" />
                </div>
                <vg-text-filter v-model="searchQuery" />
                <vg-select v-if="!isLoadingState && equipementsRestants" v-model="agfilters.path.value"
                    class="select-filter-lieu"
                    :options="pieceDesEquipementAverifier"
                    :placeholder="$t('progressionVerif.mobile.filtre-lieu')" />
                <vg-progression-tache-equipements-table v-if="!isLoadingState && focusedTache" 
                    style="width: 100%;"
                    :filters="agfilters"
                    :searchQuery="searchQuery" />
            </div>
        </template>
    </vg-app-layout>
</template>
<script>
import VgGroup from "src/components/Vg/VgGroup.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgDatetimeViewer from 'src/components/Vg/VgDatetimeViewer.vue';

import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgProgress from "src/components/Vg/VgProgress.vue";
const ICON_DESKTOP = "menu/progression.png";
const ICON_MOBILE = "mobile/progression-des-verifications.png";
import FiltersMixins from 'src/mixins/FiltersMixins';
import VerificationMixins from 'src/mixins/VerificationMixins.js';
import TachesMixins from 'src/mixins/TachesMixins.js';

import VgProgressionTacheEquipementsTable from "src/components/Vg/Progressions/VgProgressionTacheEquipementsTable.vue";
import { mapGetters } from 'vuex';

export default {
    name: "progression",
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Reste à faire",
            "equipements-avant-le": "équipement(s) avant le",
            "progressionVerif": {
                "mobile": {
                    "header-titre": "Progression",
                    "avantLe": "avant le",
                    "reste": "Reste",
                    "total": "Total de",
                    "zoom-equipRest": "équipement(s) ",
                    "feedback-a": "Vérifications Ok",
                    "reste-a-faire": "Reste à faire",
                    "requiert-scan": "Requiert un scan de l'équipement",
                    "filtre-lieu": "Sélectionner une pièce",
                    "filtre-categorie": "Filtrage par categorie",
                    "filtre-group": "Filtre",
                    "affectation-tache": "Affectation tâche"
                }
            },
            "modal-filters-title": "Filtrer la progression"
        },
        "en": {
            "page-title": "To do",
            "progressionVerif": {
                "mobile": {
                    "header-titre": "Progress",
                    "avantLe": "Before",
                    "reste": "Remaining :",
                    "total": "Total of",
                    "zoom-equipRest": "remaining equipments",
                    "feedback-a": "Verifications OK",
                    "reste-a-faire": "To do",
                    "requiert-scan": "This requires an equipment scan",
                    "filtre-lieu": "Select a room",
                    "filtre-categorie": "Filter by category",
                    "filtre-group": "Filter",
                    "affectation-tache": "Task assignment"
                }
            }
        }
    }
},
    components:{
        VgTextFilter,
        VgButton,
        VgSelect,
        VgInput,
        VgGroup,
        VgFilterSite,
        VgCheckbox,
        VgProgress,
        VgDatetimeViewer,
        VgProgressionTacheEquipementsTable
    },
    mixins:[
        FiltersMixins,
        VerificationMixins,
        TachesMixins
    ],
    filters:{
        timeFromGivenValueToNow:function( value ){
            moment.updateLocale('en', {
                relativeTime : {
                    future: "dans %s",
                    past:   "il y a %s",
                    s:  "secondes",
                    m:  "une minute",
                    mm: "%d minutes",
                    h:  "une heure",
                    hh: "%d heures",
                    d:  "un jour",
                    dd: "%d jours",
                    M:  "un mois",
                    MM: "%d mois",
                    y:  "un an",
                    yy: "%d ans"
                }
            });
            var time = moment(value).toNow(true);
            return time;
        }
    },
    data: function() {
        return {
            equipmentToCheck: null,
            searchQuery: null,
            agfilters: {
                site: {attr: "l.path", colId: "path", value: null, action: "contains"},
                path: {attr: "l.path", colId: "path", value: null, action: "contains"}
            },
            progressionTache: null,
            isLoading: false
        };
    },
    created: function(){
        this.initFiltersFromLocalStorage();
        this.fetchTache();
    },
    mounted: function(){
        this.FiltersMixins_page_name = "progression";
    },
    methods: {
        // this filter is important because it gets the filters from the local storage set previously on the page progressions and set them to the agfilters
        initFiltersFromLocalStorage: function(){
            let progressionsStoredFilters = this.$storage.get("agFilters-progression.vue");
            if(progressionsStoredFilters && progressionsStoredFilters.path && progressionsStoredFilters.path.value){
                this.agfilters.site.value = progressionsStoredFilters.path.value;
            }else{
                this.agfilters.site.value = null;
            }
            //this.agfilters = Object.assign({}, this.agfilters, progressionsStoredFilters);
            console.log("PROGRESSIONS STORED FILTERS", progressionsStoredFilters, this.agfilters);
        },
        backPage: function(){
            this.$router.go(-1);
        },
        fetchTache: function(){
            this.isLoading = true;
            this.$store.dispatch("ProgressionsStore/setSelectedItem", null);
            this.$store.dispatch("ProgressionsStore/setEquipementsRestants", []);
            this.tachesMixins_getTache(this.$route.params.tacheId).then((tache)=>{
                if (tache && tache.tacheSites) {
                    let filteredSite = tache.tacheSites.find(tacheSite => tacheSite.site.path === this.agfilters.site.value);
                    console.log("filteredSite", filteredSite, tache.tacheSites, this.agfilters.path.value);
                    if (filteredSite) {
                        this.progressionTache = filteredSite.progressionTache;
                    }else{
                        this.progressionTache = tache.progressionGlobale;
                    }
                }
                this.$store.dispatch("ProgressionsStore/setSelectedItem", tache);
                this.isLoading = false;
            });
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.searchQuery = null;
        },
        goToCheckEquipement:function(){
            this.$router.push({ name: '_process_verification_equipement_selection', params: {type: "equipement"} })
        },
        handleCheckSelectedEquipement:function(row){
            if(1 != this.targetedTacheState.isScanRequired){
                this.$router.push({name:"_process_verification", params:{type:"equipement", equipementId:row.data.id, tacheId: this.targetedTacheState.id_tache} });
            }else{
                alert(this.$t('progressionVerif.mobile.requiert-scan'));
            }

        },
    },
    computed: {
        ...mapGetters({
            progressions: "ProgressionsStore/getCollection",
            categories: "ProgressionsStore/getCategories",
            focusedTache: "ProgressionsStore/getSelectedItem",
            equipementsRestants: "ProgressionsStore/getEquipementsRestants"
        }),
        getTitle: function(){
            if(this.focusedTache) return this.$t("page-title") + " " + this.focusedTache.libel_tache;
            else return this.$t("page-title");
        },
        pageFilters: function(){
            return ["path"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.filterCategorieQuery && this.filterCategorieQuery.length!=0) nFilters += 1;
            //f(this.agfilters.nom.value || this.agfilters.prenom.value) nFilters += 1;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        getHeaderBackgroundColor: function(){
            return this.$vgutils.isMobile() ? this.$root.vgOrange : "";
        },
        getIconPage: function(){
            return this.$vgutils.isMobile() ? ICON_MOBILE : ICON_DESKTOP;
        },
        pieceDesEquipementAverifier: function(){
            let pieces = this.equipementsRestants.map((equipement)=>this.$vgutils.getPathEnd(equipement.path));
            pieces = pieces.filter((piece, index, self)=>self.indexOf(piece) == index);
            return pieces.sort();
        },
        isMesAffectationsTaches : function(){
            return this.agfilters.nom.value == null ? false : true;
        }
    },
    watch:{
        searchQuery: {
			handler: function(query){
                this.FiltersMixins_saveSearchQuery(query);
			}
		},
        filterCategorieQuery: {
            handler: function(data) {
                var filters = [{ colId: "libelleCatgorie", value: data }];
            },
            deep: true
        },
        agfilters:{
            handler: function(nfilters) {
                ////console.log("WATCH AG FILTERS", nfilters);
                const agfilters = Object.values(nfilters);
                //this.$storage.set("agFilters-progression.vue",nfilters);
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" >
.vg-button-scan{
    align-self: center;
    height: 57px !important;
    width: 57px !important;
    cursor: pointer;
    white-space: nowrap;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    transition: 0.1s;
    opacity: 0.9;
    background-color: white;
    border:1px solid #ff6928 !important;
    padding: 10px;
    padding-top: 7px;
    img{
        margin:0 !important;
    }
    &:focus{
        box-shadow: 0 1px #ffffff !important;
    }
    &:active{
        box-shadow: 0 1px #ffffff !important;
        background-color: rgba(#ff7a00, 0.19);
    }
}
</style>
