<template>

    <vg-app-layout
        :title="getTitle"
        :isLoadingState="isLoading"
        :colorheader="getHeaderColor"
        :isZoomLayout="showBackPageButton"
        :isbackButtonDisplayed="showBackPageButton"
        :isActionButtonDisplay="false"
        @backpage="$router.go(-1);">
        <template #body>

            <vg-scanner v-if="isDisplayedQrCodeScanner || $route.params.type == 'equipement' "
                isFetching
    			@scanSuccess="onScanSuccess" />

            <select-releve v-else-if="$route.params.type == 'compteur' || $route.params.type == 'grandeur-physique'"
                :releves="equipements" />

            <select-lieu v-else-if="$route.params.type == 'lieux'"
                :lieux="equipements" />

        </template>
        <template #footer v-if="$vgutils.isMobile()">
            <vg-button type="grey" @click="$router.go(-1)" style="width:100%;">
                {{ $t("annuler") }}
            </vg-button>
        </template>
    </vg-app-layout>

</template>

<script>
import TachesMixins from "src/mixins/TachesMixins";
import VerificationMixins from "src/mixins/VerificationMixins";
import equipementsMixins from "src/mixins/equipementsMixins";
import Metadatas from "src/services/Metadatas";

import VgButton from 'src/components/Vg/VgButton.vue';

import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgScanner from "src/components/Vg/VgScanner.vue";
import selectReleve from "src/views/Verification/Check/components/selectReleve";
import selectLieu from "src/views/Verification/Check/components/selectLieu";

import { mapGetters } from 'vuex';

export default {
    name: "vg-process-verification-equipement-selection",
    components: {
		VgLieuPathViewer,
        VgButton,
        VgScanner,
        selectReleve,
        selectLieu
    },
    mixins: [TachesMixins, equipementsMixins, VerificationMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "selection-equipement": "Sélectionner un équipement",
            "selection-lieu": "Sélectionner un lieu",
            "selection-compteur": "Sélectionner un compteur",
            "selection-grandeur-physique": "Sélectionner un équipement",
            "equipment-not-found": "Equipement non trouvé",
            "annuler": "Annuler",
            "no-tasks-associated": "Aucune vérification préventive"
        },
        "en": {
            "selection-equipement": "Select an equipment",
            "selection-lieu": "Select a room",
            "equipment-not-found": "Not found equipment",
            "annuler": "Cancel",
            "no-tasks-associated": "No preventive verification"
        }
    }
},
    props:{
        afterSaveRedirectTo:{
			type: String,
			default: null
		}
    },
    data: function() {
        return {
            isLoading:false,
            equipements:[],
            showBackPageButton: false
        }
    },
    watch: {},
    computed: {
        ...mapGetters({
              equipement: 'EquipementsStore/getSelectedItem',
        }),
        /**
        * @return string
        */
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#f36f3a' : 'white';
        },
        getTitle: function(){
            if(this.$route.params.type == 'equipement') return this.$t('selection-equipement');
            else if(this.$route.params.type == 'lieux') return this.$t('selection-lieu');
            else if(this.$route.params.type=="compteur") return this.$t("selection-compteur");
            else if(this.$route.params.type=="grandeur-physique") return this.$t("selection-grandeur-physique");
        }
    },
    methods: {
        onScanSuccess:function(scannedEquipement){
            this.isLoading = true;
            this.equipementsMixins_getEquipement(scannedEquipement.id).then((equipement)=>{
                this.isLoading = false;
                if(this.$route.params.tacheId){ // tache préselectionnée dans la route
                    this.$route.push({name: "_process_verification", params: {type: "equipement", equipementId: equipement.id, tacheId: this.$route.params.tacheId, afterSaveRedirectTo: this.afterSaveRedirectTo}});
                }else if(equipement.taches.length == 1 ){
                    let tacheId = equipement.taches[0].idTache;
                    this.$router.push({name:"_process_verification", params:{type:"equipement", equipementId: equipement.id, tacheId:tacheId, afterSaveRedirectTo: this.afterSaveRedirectTo} });
                }else if(equipement.taches.length > 1){
                    this.$router.push({name:"_process_verification_taches_selection", params:{type:"equipement", equipementId: equipement.id, afterSaveRedirectTo: this.afterSaveRedirectTo} });
                }else{
                    alert(this.$t("no-tasks-associated"));
                }
            });
        },
        /**
         * @param string typeTache
         */
        getTachesState:function(typeTache){
            let metadatas = new Metadatas();
            metadatas.setFilters(
                {
                    type_tache:{"attr":"t.type_tache","value":typeTache,"action":"equals"}
                }
            );
            this.isLoading = true;
            this.VerificationMixins_getVerificationsEquipementsTaches(metadatas).then((equipements)=>{
                this.equipements = [...equipements];
                this.isLoading = false;
            })
        }
    },
    created: function() {
        if(this.$route.params.type == 'compteur'){
            this.getTachesState("Relever_compteur");
        }else if(this.$route.params.type == 'grandeur-physique'){
            this.getTachesState("Relever_Grandeur_physique");
        }else if(this.$route.params.type == 'lieux'){
            this.getTachesState("Verification_Lieu");
        }
        this.showBackPageButton = this.$route.params.showBackPageButton==true;
    }
}
</script>

<style lang="scss">

</style>
