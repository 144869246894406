<template>
	<div class="vg-fin-demo">
		<vg-helper 
			:isIcon="false" />
		<div class="info">
			<span>{{$t("pre")}}&nbsp;</span>
			<u><b>{{joursRestants}} {{$t("jours")}}</b></u> 
			<span>&nbsp;{{$t("post")}}</span>
		</div>
		<vg-button 
			size="sm"
			type="danger"
			class="btn-demander"
			@click="openLink">
			{{$t("btn-demander-presentation")}}
		</vg-button>
		<vg-button 
			size="sm"
			type="danger"
			class="btn-demander"
			@click="openContact">
			{{$t("btn-contact")}}
		</vg-button>
		<stripe-open-portal-button />
	</div>
</template>
<script>
import VgButton from "src/components/Vg/VgButton.vue";
import VgHelper from "src/components/Vg/Utils/VgHelper.vue";
import StripeOpenPortalButton from "src/components/Vg/Stripe/StripeOpenPortalButton.vue";
    export default {
        name: 'vg-fin-demo',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "pre": "Plus que",
			"jours": "jours",
			"post": "pour tester toutes les fonctionnalités de Verifgood",
			"btn-demander-presentation": "Planifier une présentation personnalisée",
			"btn-contact": "Nous contacter"
        },
        "en": {
            "pre": "",
            "jours": "days left",
			"post": "to test all Verifgood features",
			"btn-demander-presentation": "Schedule a custom presentation",
			"btn-contact": "Contact us"
        }
	}
},
		components: {
			VgButton,
			VgHelper,
			StripeOpenPortalButton
		},
        props: {
			
        },
        data: function() {
            return {
				
            };
        },
		methods: {
			openLink: function(){
				window.open("https://www.verifgood.io/rdv", "_blank");
			},
			openContact: function(){
				window.open("https://www.verifgood.io/contact", "_blank");
			}
		},
		computed: {
			joursRestants: function(){
				return moment(this.$app.account_dateFinDemo).diff(moment(), "days");
			}
		}
    };

</script>
<style lang="scss" scoped>
.vg-fin-demo{
	width: 100%;
	background-color: black !important;
	color: white !important;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	span{
		white-space: nowrap;
	}

	.info{
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 2px;
	}
	.btn-demander{
		padding-left: 10px !important;
		padding-right: 10px !important;
		background-color: #fd5a55;
		border: 1px solid #fd5a55;
		box-shadow: 0 1px #fd5a55;
		border-radius: 4px;
		text-transform: uppercase;
		color: white !important;
	}
}
@media only screen and (max-width: 1200px){
	.vg-fin-demo{
		display: block !important;
		span{
			white-space: normal;
		}
		.info{
			display: inline;
			margin-right: 10px;
		}
	}
}

</style>
