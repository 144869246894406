<template>
    <div class="vg-modal">
        <transition name="modal">
            <div class="vg-modal-mask">
                <div class="vg-modal-wrapper">
                    <div class="vg-modal-container" :style="{'width': getWidth, 'height': height}">
                        <div :class="['vg-modal-header', {'vg-modal-alice-blue': theme=='normal', 'vg-modal-redskin': theme=='redskin', 'vg-modal-without-header': theme=='without-header', 'vg-modal-info': theme=='info'}]">
                            <div class="header-title">
                                <slot name="header">
                                    <span class="title">{{title}}</span>
                                </slot>
                            </div>
                            <div class="header-button-close" @click="close">
                                <img src="static/assets/icone/fermer.png" height="20" alt="header-button-close"/>
                            </div>
                        </div>
                        <div class="vg-modal-body" :style="{'height': height}">
                            <slot name="body">
                                <div class="vg-modal-body-container">
                                    <div class="modal-body-container-left" v-if="!!$slots['body-left']">
                                        <slot name="body-left"></slot>
                                    </div>
                                    <div class="modal-body-container-right" v-if="!!$slots['body-right']">
                                        <slot name="body-right"></slot>
                                    </div>
                                </div>
                            </slot>
                        </div>
                        <div class="vg-modal-footer vg-print-hide">
                            <slot name="footer">
                                <vg-button type="default" @click="close">{{$t("btn-annule")}}</vg-button>
                                <vg-button type="success" :disabled="isSaveDisabled" @mouseup="save">{{$t("btn-save")}}</vg-button>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import VgButton from 'src/components/Vg/VgButton';
export default {
  name: "vg-modal",
  components:{
    VgButton
  },
  props: {
    /** disable save button if true. default false **/
    isSaveDisabled:{
        type:Boolean,
        default:false
    },
    /** String fix to 600px by default **/
    width:{
        type:String,
        default:"600px"
    },
    height:{
        type: String,
        default: null
    },
    theme:{
      type:String,
      default:"normal"
    },
	title:{
		type: String,
		default: null
    }
  },
  i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "btn-annule": "Annuler",
            "btn-save": "Enregistrer"
        },
        "en": {
            "btn-annule": "Cancel",
            "btn-save": "Save"
        },
        "th": {
            "btn-annule": "ยกเลิก",
            "btn-save": "บันทึก"
        }
    }
},
  data:function() {
    return {};
  },
  methods: {
    /**
     * close event.
     *
     * @event close
     */
    close:function(){
        this.$emit("close");
    },
    /**
     * save event.
     *
     * @event save
     */
    save:function(){
        this.$emit("save");
    }
},
    computed: {
        getWidth: function(){
            return this.$slots["body-left"] && this.$slots["body-right"]?"70vw":this.width;
        }
    }
};
</script>

<style lang="scss">
.vg-modal-body-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .modal-body-container-left{
        flex-grow: 1;
        padding-right: 20px;
    }
    .modal-body-container-right{
        flex-grow: 1;
        padding-left: 20px;
        border-left: 1px solid #f2f2f2;
    }
}

@media only screen and (max-width:800px){
    .vg-modal-body-container{
        flex-wrap:wrap;
    }
}

@media only screen and (max-width:1200px){
    .vg-modal-body-container{
        display: block;
        .modal-body-container-left{
            padding-right: 0 !important;
            margin-bottom: 20px;
        }
        .modal-body-container-right{
            padding-left: 0 !important;
            border: none !important;
        }
    }
}
</style>
<style lang="scss" scoped>
$headerHeight: 60px;
$footerHeight: 60px;
.vg-modal-mask{
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    transition: opacity 0.3s ease;

}
.vg-modal-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: calc(100vh - env(safe-area-inset-bottom));
}
.vg-modal-container{
    width: 600px;
    max-height: 100vh;
    margin: 0px auto;
    /*padding: 20px 30px;*/
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}
/* ------- HEADER ------ */
.vg-modal-header{
    height: $headerHeight;
    background-color: #ebf7fa;
    border-bottom: 1px solid #f2f2f2;
    font-size: 1.5em;
    color: #5b5e62;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    .header-title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: -webkit-fill-available;
    }
    .header-button-close{
        cursor: pointer;
    }
}
.vg-modal-without-header{
    display: none;
}
.vg-modal-redskin{
  background: #F36F3A;
  color:white;
}
.vg-modal-alice-blue {
  background-color: #ebf7fa;
}
.vg-modal-info{
    background-color: #3999ff;
    color: white;
    .header-button-close{
        display: none;
    }
}
/* ------- BODY ------ */
.vg-modal-body{
    position: relative;
    display: block;
    padding: 20px 30px;
    overflow-y: auto;
    max-height: calc( 100vh - #{$headerHeight} - #{$footerHeight} );
    max-width: 100%;
}

/* ------- FOOTER ------ */
.vg-modal-footer{
    height: $footerHeight;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;
    border-top: 1px solid #f2f2f2;
    gap: 10px;
}
@media only screen and (max-width:800px){
    .vg-modal-header{
        height: $headerHeight;
        background-color: #ebf7fa;
        border-bottom: 1px solid #f2f2f2;
        font-size: 1.1em;
        color: #5b5e62;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        .header-title{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .header-button-close{
            cursor: pointer;
        }
    }
}
@media only screen and (max-width: 1200px) {
    .vg-modal-container{
        width: 100vw !important;
    }
    .vg-modal-wrapper{
        max-height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        max-height: calc(var(--vh, 1vh) * 100);
    }
    .vg-modal-container{
        max-height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        max-height: calc(var(--vh, 1vh) * 100);
    }
    .vg-modal-body{
        max-height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        max-height: calc(var(--vh, 1vh) * 100 - #{$headerHeight} - #{$footerHeight});
        padding: 20px;
    }
    .vg-modal-without-header{
        display: none;
    }
}
</style>

<docs>

    ### vg-input examples:

    #### basic usage

    ```html static
          <vg-input title="Prénom"  inputType="text" :isRequiredValue="true" ></vg-input>
    ```
    #### Make a form with multiple input

    **Remarque**
    il faut mettre les input dans un formulaire et y ajouter un submit cacher, et déclencher ce submit via la méthode .click()
    les référence ajoutées sur les inputs permettent de récupérer l'élément du DOM. pour déclencher linput avec vuejs on peut alors faire
    ```javascript this.$refs['btnSubmit'].click()```

    ```html static

    ```
    ### Input Text
    ```js
    new Vue({
      data(){
        return {
            showModal:false
        }
      },
      template: `
        <div>
            <button @click="showModal=true">Voir modal</button>
            <vg-modal
                v-if="showModal"
                @close="showModal=false"
                @save="showModal=false"
            >
                <h3 slot="header-title">
                    Modal title
                </h3>
                <div slot="body">
                    modal body
                </div>

            </vg-modal>

        </div>
      `
    })
    ```

</docs>
