<template>
    <div class="vg-contacts-table">
        <tag-grid v-if="!isCards"
            style="height:70vh"
            :idTableau="'contacts-table'"
            v-model="gridOptions"
            :columnDefs="columnDefs"
            :rowData="contactsStore"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick"
            @ag-dbl-click="handleDblClick"
        />
        <div v-else style="width:100%;">
            <div v-for="contact in contactsStore" :key="contact.id" style="width:100%;border-bottom: 1px solid whitesmoke;padding: 10px 0;display: flex;justify-content: space-between;align-items: center;gap:10px;">
                <div>
                    {{ contact.firstname }} {{ contact.lastname }}
                    <br>
                    <small v-if="contact.email && contact.email.length!=0">{{$t("email")}}: {{ contact.email }}</small> 
                    <small v-if="contact.tels && contact.tels.length!=0">{{$t("tels")}}: {{ contact.tels }}</small> 
                    <small v-if="contact.telMobile && contact.telMobile.length!=0">{{$t("tels-mobile")}}: {{ contact.telMobile }}</small>
                </div>
                <vg-button type="danger" size="sm" @click="handleClickShowUpdate({data: contact})">{{ $t("update") }}</vg-button>
            </div>
        </div>
        <vg-contacts-form
            :tiersId="tiersId"
            v-model="selectedContact"
            v-if="isContactsFormDisplayed"
            @close="isContactsFormDisplayed = false"
        >
        </vg-contacts-form>
    </div>
</template>
<script>
import ContactsMixins from "src/mixins/ContactsMixins";
import TagGrid from 'src/components/Grid/TagGrid.vue';
import VgContactsForm from 'src/components/Vg/Forms/VgContactsForm.vue';
import ButtonCellRender from "src/components/Vg/TagGrid/ButtonCellRender.vue";
import ContactsTiersCellRender from "src/components/Vg/TagGrid/ContactsTiersCellRender.vue";
import VgButton from "src/components/Vg/VgButton.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-contacts-table',
    components:{
        TagGrid,
        VgContactsForm,
        VgButton
    },
    mixins:[ContactsMixins],
    props: {
        id:{
            type: String,
            default: "contacts-table"
        },
        /**
         * permet de récupérer les contacts du tiers.
         * par defaut tous les contacts tiers sont affichés.
         */
        tiersId:{
            type:Number,
            default: null
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        isCards: {
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr":{
            "overlay-loading-text": "Chargement des contacts...",
    		"overlay-no-rows-text": "Aucun contact correspondant au filtrage",
            "update":"Modifier",
            "tiers":"Entreprise",
            "firstname":"Prénom",
            "lastname":"Nom",
            "email":"Email",
            "tels":"Tél.",
            "tels-mobile":"Tél. mobile",
            "actions":"Actions",
            "header-id":"id"

        },
        "en":{
            "overlay-loading-text": "Loading contacts...",
    		"overlay-no-rows-text": "No contact matching filtering",
            "update":"Update",
            "tiers":"Company",
            "firstname":"Firstname",
            "lastname":"Lastname",
            "email":"Email",
            "tels":"Ph.",
            "tels-mobile":"Mobile ph.",
            "actions":"Actions",
            "header-id":"id"

        }
    }
},
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                rowHeight: 70,
            },
            columnDefs: [
                {
                    headerName: this.$t("header-id"),
                    width: 90,
                    field: 'id',
                    colId: 'id',
                    suppressSizeToFit: false,
                    unSortIcon: true,
                    hide: true,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("tiers"),
                    width: 90,
                    field: 'tiers_name',
                    colId: 'tiers_name'
                },
                {
                    headerName: this.$t("firstname"),
                    width: 90,
                    field: 'firstname',
                    colId: 'firstname',
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("lastname"),
                    width: 90,
                    field: 'lastname',
                    colId: 'lastname',
                    suppressSizeToFit: false,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("email"),
                    width: 90,
                    field: 'email',
                    colId: 'email',
                    suppressSizeToFit: false,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("tels"),
                    width: 90,
                    field: 'tels',
                    colId: 'tels',
                    suppressSizeToFit: false,
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) =>{
                        // if telMobile is empty, we display only tels
                        if(params.data.telMobile && !params.data.tels ) return params.data.telMobile;
                        // else if only tels is empty, we display only telMobile
                        else if(params.data.tels && !params.data.telMobile ) return params.data.tels;
                        else return `fixe : ${params.data.tels} / mobile : ${params.data.telMobile}`;
                    }
                },
                {
                    headerName: this.$t("actions"),
                    width: 90,
                    field: 'tels',
                    colId: 'tels',
                    suppressSizeToFit: false,
                    cellRenderer: (params) => new ButtonCellRender({propsData: {params: params, label:this.$t('update')}})
                        .$on("click", this.handleClickShowUpdate)
                        .$mount().$el,
                    cellClass: ["vg-cell-ballam-theme"]
                },
            ],
            agfilters:{
                tiers:{"attr":"c.tiers_id","value":this.tiersId,"action":"equals"}
            },
            metadatas: new Metadatas(),
            selectedContact:null,
            isContactsFormDisplayed:false
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        }
    },
    created:function(){
        if(this.$vgutils.isMobile()){
            this.columnDefs.map((columnDef)=>{
                columnDef.hide = true;
            })
            this.columnDefs.unshift({
                headerName: "",
                field:"",
                cellRenderer: (params) => new ContactsTiersCellRender({propsData: {contact: params.data}}).$mount().$el
                //cellRenderer: (params) => this.$vgutils.formatFicheMaintenanceCell(params.data)
            });
        }
        this.fetch();
    },
    computed: {
        ...mapGetters({
            contactsStore: 'ContactsStore/getCollection',
        })
    },
    methods:{
        fetch: function(){
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(this.agfilters);
            this.ContactsMixins_getContacts(this.metadatas).then((contacts)=>{
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click",row);
        },
        handleClickShowUpdate:function(row){
            this.isContactsFormDisplayed = true;
            this.selectedContact = row.data;
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            this.$emit("row-click",row);
        }
    },
    destroyed:function(){
        this.$store.dispatch("ContactsStore/clear");
    }
};
</script>
<style lang="scss" scoped>
</style>
