<template>
    <div class="auth" >
        <div class="auth-content">
            <div class="auth-logo">
                <img src="/static/assets/icone/new-logo-verifgood-jaune-gris.png" alt="logo verifgood" 
                    class="loader" 
                    style="height: 24px;" />
            </div>
            <div class="auth-info" > 
                <img :src="authUser.picture" alt="Auth0" style="width: 32px; height: 32px;">
                <span class="subtitle">{{ authUser.email }}</span>
            </div>
            <div class="auth-info" > 
                <span class="title">{{ step }} </span>
            </div>
            <div class="auth-info" >
                <span class="subtitle">{{$t("veuillez-patienter")}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import pubnubMixins from 'src/mixins/pubnubMixins';
import LandingPageSelector from '../Logic/LandingPageSelector.ts';
export default {
    name: 'Auth',
    mixins:[pubnubMixins],
    i18n: {
        messages: {
            fr: {
                "trial_expiration": "Période d'éssaie terminée veuillez vous rapprocher du support",
                "verification-en-cours": "Vérification du compte en cours...",
                "unauthorized": "Accès non autorisé",
                "veuillez-patienter": "Veuillez patienter pendant que nous vérifions vos informations."
            },
            en:{
                "trial_expiration": "Trial period expired please contact the support",
                "verification-en-cours": "Account Verification in progress...",
                "unauthorized": "Unauthorized access",
                "veuillez-patienter": "Please wait while we verify your information."
            }
        }
    },
    data() {
        return {
            email: '',
            password: '',
            step:""
        }
    },
    computed:{
        authUser(){
            return JSON.parse(sessionStorage.getItem("auth0User"));
        }
    },
    created: function(){
        console.log("Auth component created");
    },
    mounted() {
        console.log('Auth component mounted');
        console.log("check credentials and fetch VG parameters + user");
        this.step = this.$t("verification-en-cours");
        this.checkAuthToken().then((response)=>{
            //this.$router.push({ name: '_maintenances', params: {} });
            console.log({response});
            //window.location.href = "/maintenances";
        }).catch((err)=>{
            console.log({err});
        });
    },
    methods: {
        /**
         * @param string token
         * @return {Promise}
         */
        checkAuthToken: function(token) {
            return new Promise((resolve, reject) => {
                let endpoint = "/api/auth_0";

                // Handle error query parameters
                if (window.location.search.indexOf("error") !== -1 && window.location.search.indexOf("error_description") !== -1) {
                    const params = new URLSearchParams(window.location.search);
                    let error = params.get("error");
                    let errorDescription = params.get("error_description") ?? this.$t("unauthorized");
                    endpoint = `${endpoint}?error=${error}&error_description=${errorDescription}`;
                    this.step = this.$t(errorDescription);
                    alert(this.$t(errorDescription));
                    this.$store.dispatch("UsersStore/set", {});
                    window.sessionStorage.removeItem("user");
                    this.$auth0.logout({
                        logoutParams: {
                            returnTo: window.location.origin
                        }
                    });
                    return reject(new Error(errorDescription));
                }

                this.$rc.get(endpoint, {}, (response) => {
                    console.log("*** LOGIN ***", { response });
                    let status = response.httpCode;
                    let token = JSON.parse(sessionStorage.getItem("user"))?.["__t"];

                    if (status !== 200) {
                        alert("Authentication rejected");
                        return reject(new Error("Authentication rejected"));
                    }

                    response = response.message;
                    response["__t"] = token;

                    if (response && response.user) {
                        this.initAccount(response);
                    } else if (response && !response.user) {
                        if (response.message === "INVALID_LOGIN") {
                            alert(this.$t("desktop.feedback-error-login"));
                        }
                        this.isCheckingCredentials = false;
                    } else {
                        this.isCheckingCredentials = false;
                    }

                    resolve(response);
                }, (err) => {
                    console.error("Error during authentication", err);
                    reject(err);
                });
            });
        },
        initFirebase: function(){
            //console.log("======================== INIT FIREBASE", this.$app, firebase);
            return new Promise((resolve, reject) => {
                if(this.$app && (!firebase || (firebase && firebase.apps.length==0))){
                    // //console.log("************** FIREBASE IS EMPTY **************");
                    this.VgFilesMixins_initializeFirebase();
                    this.VgFilesMixins_connectToFirebase().then((response)=>{
                        //console.log("************** FIREBASE CONNECTED **************");
                        resolve(response);
                    }).catch((err)=>{
                        //console.log("************** FIREBASE CONNECTION FAILED **************");
                        reject(err);
                    });
                }else{
                    resolve();
                }
            });
        },
        initAccount: function(response){
            let user = this.formatUser(response);
            this.saveInSessionStorage("user", JSON.stringify(user));
            this.saveInSessionStorage("exclude_categorie_tags", user["exclude_categorie_tags"]);
            this.saveInSessionStorage("exclude_categorie_tags_user_parameter_id", user["exclude_categorie_tags_user_parameter_id"]);
            localStorage.setItem('_expired_at', this.setSessionExpirationDate(10));
            this.initFirebase().then((response)=>{
                this.redirect(user);
            }).catch((err)=>{
                console.log({err});
            });
            
        },
        /**
         * @param integer hours
         * @return string
         */
        setSessionExpirationDate(hours){
            let now = moment().add(hours, 'h');
            return now.format();
        },
        saveInSessionStorage: function(storageKey, data){
            window.sessionStorage.setItem(storageKey, data);
        },
        getAccountLogo: function(){
            this.VgFilesMixins_getBase64Logo();
        },
        formatUser:function(response){
            console.log({response});
            // __t a disparu à ce moment il faut trouver un moyen de le récupérer plus haut
            return Object.assign({}, response, {
                idUser: response.user,
                fullname: response.prenom && response.prenom.length!=0?response.nom+" "+response.prenom:response.nom,
                role: response.role[0],
                isSupervisor: response.role[0]=="ROLE_ADMIN" && response.isFmClosedBySuperviseur,
                underSupervisor: response.role[0]=="ROLE_TECHNICIEN" && response.isFmClosedBySuperviseur,
                __fconf: JSON.stringify(response.__fconf)
            });
        },
        redirect: function(user){
            if(this.$storage.get("personalParameters_page") == "/auth_" 
                || this.$storage.get("personalParameters_page") == "/login" 
                || this.$storage.get("personalParameters_page") == "/invitations"
                || this.$storage.get("personalParameters_page") == "/invitations/check-invitation/:token"
                || this.$storage.get("personalParameters_page") == "/register"
            ){
                this.$storage.set("personalParameters_page", "/maintenances");
            }
            let lps = new LandingPageSelector();
            let page = this.$storage.get("personalParameters_page");
            this.$router.push(page?page:lps.getLandingPage(user));
        },
    }
}
</script>
<style lang="scss" scoped>
$menu-bg-color: #1F2937;
.auth{
    height:100vh;
    background-color:$menu-bg-color;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.auth-content{
    height:230px;
    width:480px;
    background-color:white;
    border-radius:5px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    gap:15px;
}
.auth-logo{
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-bottom:15px;
}
.auth-info{
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.title{
    font-size:24px !important;
}
.subtitle{
    font-size:14px !important;
}
.loader {
    animation: pulse 2s linear infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.90);
    }
    70% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.90);
    }
}
@media only screen and (max-width:1200px){
    .auth-content{
        height:100vh;
        width: 100vw;
    }
    
    .auth-info{
        display:flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .title{
        font-size:24px !important;
        text-align: center;
    }
    .subtitle{
        font-size:14px !important;
        text-align: center;
    }
}
</style>