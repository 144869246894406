<template>
    <div>

        <vg-modal
            v-if="!showHubspotFormCreate"
            :title="title"
            @close="$emit('close')">
            <template #body> 
                <vg-button v-if="contactSupportToImportDatas" @click="showHubspotFormCreate=true">{{ $t("contact-support") }}</vg-button>
                <vg-drag-n-drop-zone v-else @selected-files="handleFileChange" style="margin-left:20%;" />
            </template>
            <template #footer>
                <vg-button
                    :type="'default-danger'"
                    @click="cancel">
                    {{ $t("cancel") }}
                </vg-button>
            </template>
        </vg-modal>
        <vg-hubspot-form-create v-if="showHubspotFormCreate" @close="showHubspotFormCreate=false" />
    </div>
</template>

<script>
import VgButton from "src/components/Vg/VgButton.vue";
import VgHubspotFormCreate from "src/components/Vg/Utils/VgHubspotFormCreate.vue";
import VgModal from "src/components/Vg/VgModal.vue";
import VgDragNDropZone from "src/components/Vg/VgDragNDropZone.vue";
export default{
    name: 'vg-importation-donnees-modal',
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "contact-support": "Contacter le support",
                "cancel": "Annuler"
            },
            "en": {
                "contact-support": "Contact support",
                "cancel": "Cancel"
            }
        }
    },
    props: {
        value: {
            type: Object
        },
        title: {
            type: String,
            default: 'Importation de données'
        },
        contactSupportToImportDatas: {
            type: Boolean,
            default: false
        }
    },
    components: {
        VgButton,
        VgHubspotFormCreate,
        VgModal,
        VgDragNDropZone
    },
    data(){
        return {
            showHubspotFormCreate:false,
            filelist: [] // Store our uploaded files
        }
    },
    methods:{
        save(){
        },
        cancel(){
            this.$emit('close');
            this.$emit('input', null);
        },
        handleFileChange:function(files){
            this.$emit('selected-files', files);
            this.cancel();
        }
    }
}
</script>
<style scoped>

</style>