<template>
  <vg-modal width="600px" @close="$emit('close')" :title="$t('invitation_link_title')">
    <template #title>
      <div class="modal-title">
        <vg-icon url="/static/assets/icone/custom/link.svg" class="title-icon"/>
        <h2>{{ $t('invitation_link_title') }}</h2>
      </div>
    </template>
    <template #body>
      <div class="invitation-container">
        <div v-if="!generatedInvitation" class="create-form">
          <form @submit.prevent="generateInvitation">
            <vg-input
              v-model="invitationRequest.email"
              label="Email"
              placeholder="email@example.com"
              icon="mail"
              :isRequiredValue="true"
              :class="{'is-invalid': validationErrors.email}"
            />
            <div class="invalid-feedback" v-if="validationErrors.email">
              {{ validationErrors.email }}
            </div>

            <div class="form-group">
              <label>{{ $t('role') }}</label>
              <div class="info-icon" @click="toggleRoleInfo">
                <span>ⓘ</span>
              </div>
              <div id="role-info-panel" v-show="showRoleInfo">
                <h4>{{ $t('role_description') }}</h4>
                <ul>
                  <li v-for="role in roleOptions" :key="role.value">
                    <strong>{{ role.label }}</strong>: {{ getRoleDescription(role.value) }}
                  </li>
                </ul>
              </div>
              <div class="dropdown">
                <div class="dropdown-toggle" @click="toggleDropdown">
                  <span>{{ getRoleName(invitationRequest.role) }}</span>
                </div>
                <div class="dropdown-menu" :class="{ 'show': showDropdown }">
                  <div class="dropdown-item" v-for="role in roleOptions" :key="role.value" @click="selectRole(role.value)">
                    {{ role.label }}
                  </div>
                </div>
              </div>
              <div class="invalid-feedback" v-if="validationErrors.role">
                {{ validationErrors.role }}
              </div>
            </div>

            <div class="form-group">
              <span class="option-label">{{ $t('invitation_info') }}</span>
              <div class="info-box">
                <ul>
                  <li>{{ $t('invitation_email_sent') }}</li>
                  <li>{{ $t('invitation_expiry') }}</li>
                </ul>
              </div>
            </div>
          </form>
        </div>

        <div v-else class="invitation-result">
          <div class="success-container">
            <vg-icon url="/static/assets/icone/custom/check.svg" color="#28a745"/>
            <h3 class="success-title">{{ $t('invitation_created') }}</h3>
            <p class="success-message">{{ $t('invitation_sent_message') }}</p>
            
            <div class="expiry-notice">
              <vg-icon url="/static/assets/icone/custom/calendar.svg" class="calendar-icon"/>
              <span>{{ $t('link_expiry_notice') }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <vg-button
        v-if="!generatedInvitation"
        type="secondary"
        @click="$emit('close')">
        {{ $t('cancel') }}
      </vg-button>
      <vg-button
        v-if="!generatedInvitation"
        type="primary"
        @click="generateInvitation"
        :disabled="isGenerating">
        <span v-if="isGenerating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ $t('generate_link') }}
      </vg-button>
      <vg-button v-else
        type="primary"
        @click="$emit('close')">
        {{ $t('done') }}
      </vg-button>
    </template>
  </vg-modal>
</template>

<script>
import VgInput from 'src/components/Vg/VgInput.vue';
import VgIcon from 'src/components/Vg/VgIcon.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgSelect from 'src/components/Vg/VgSelect.vue';

export default {
  name: 'vg-invitation-link-modal',
  components: {
    VgInput,
    VgIcon,
    VgButton,
    VgSelect
  },
  i18n: {
    messages: {
      en: {
        invitation_link_title: 'Invite new member',
        role: 'Role',
        email: 'Email',
        cancel: 'Cancel',
        generate_link: 'Send the invitation',
        invitation_created: 'Invitation Created Successfully',
        invitation_link: 'Invitation Link',
        link_expiry_notice: 'This link will expire in 7 days. The recipient must complete registration before then.',
        create_another: 'Create Another',
        done: 'Done',
        email_required: 'Email is required',
        email_invalid: 'Please enter a valid email address',
        role_required: 'Role is required',
        generation_error: 'An error occurred while generating the invitation link',
        link_copied: 'Link copied to clipboard',
        role_admin: 'Administrator',
        role_user: 'User',
        role_manager: 'Manager',
        role_viewer: 'Viewer',
        role_technicien: 'Technician',
        role_basic: 'Requester',
        role_sous_traitant: 'Subcontractor',
        role_basic_verificateur: 'Requester Verifier (e.g., Housekeeper)',
        role_superviseur: 'Supervisor',
        role_chef_equipe: 'Verification Operator',
        role_description: 'Role Descriptions',
        invitation_info: 'Invitation Information',
        invitation_email_sent: 'An invitation email will be automatically sent to the specified address.',
        invitation_expiry: 'The invitation will automatically expire after 7 days.',
        role_admin_description: "Full access to all features, including user management and system configuration.",
        role_superviseur_description: "Access to most features, can validate certain actions but cannot manage users or system configuration.",
        role_technicien_description: "Access to many features, cannot manage users or system configuration.",
        role_sous_traitant_description: "Limited access to certain features, cannot create or update some items and cannot manage users or system configuration.",
        role_chef_equipe_description: "Limited access mainly to viewing, can validate certain actions but cannot create or update some items and cannot manage users or system configuration.",
        role_basic_verificateur_description: "Limited access to viewing, can create intervention requests and maintenance but cannot manage users or system configuration.",
        role_basic_description: "Very limited access, mainly for viewing, can create intervention requests but cannot manage users or system configuration.",
        invitation_sent_message: "An email has been sent to the invited user with instructions to complete registration.",
      },
      fr: {
        invitation_link_title: 'Inviter un nouveau membre',
        role: 'Rôle',
        email: 'Email',
        cancel: 'Annuler',
        generate_link: 'Envoyer l\'invitation',
        invitation_created: 'Invitation créée avec succès',
        invitation_link: 'Lien d\'invitation',
        link_expiry_notice: 'Ce lien expirera dans 7 jours. Le destinataire doit terminer son inscription avant cette date.',
        create_another: 'Créer une autre',
        done: 'Terminé',
        email_required: 'L\'email est requis',
        email_invalid: 'Veuillez entrer une adresse email valide',
        role_required: 'Le rôle est requis',
        generation_error: 'Une erreur s\'est produite lors de la génération du lien d\'invitation',
        link_copied: 'Lien copié dans le presse-papiers',
        role_admin: 'Administrateur',
        role_user: 'Utilisateur',
        role_manager: 'Gestionnaire',
        role_viewer: 'Observateur',
        role_technicien: 'Technicien',
        role_basic: 'Demandeur',
        role_sous_traitant: 'Sous-traitant',
        role_basic_verificateur: 'Demandeur vérificateur (ex: Gouvernante)',
        role_superviseur: 'Superviseur',
        role_chef_equipe: 'Opérateur vérification',
        role_description: 'Description des rôles',
        invitation_info: 'Informations sur l\'invitation',
        invitation_email_sent: 'Un e-mail d\'invitation sera automatiquement envoyé à l\'adresse indiquée.',
        invitation_expiry: 'L\'invitation expire automatiquement après 7 jours.',
        role_admin_description: "Accès complet à toutes les fonctionnalités, y compris la gestion des utilisateurs et la configuration système.",
        role_superviseur_description: "Accès à la plupart des fonctionnalités, peut valider certaines actions mais ne peut pas gérer les utilisateurs ou la configuration système.",
        role_technicien_description: "Accès à de nombreuses fonctionnalités, ne peut pas gérer les utilisateurs ou la configuration système.",
        role_sous_traitant_description: "Accès limité à certaines fonctionnalités, ne peut pas créer ou mettre à jour certains éléments et ne peut pas gérer les utilisateurs ou la configuration système.",
        role_chef_equipe_description: "Accès limité principalement à la visualisation, peut valider certaines actions mais ne peut pas créer ou mettre à jour certains éléments et ne peut pas gérer les utilisateurs ou la configuration système.",
        role_basic_verificateur_description: "Accès limité à la visualisation, peut créer des demandes d'intervention et des maintenances mais ne peut pas gérer les utilisateurs ou la configuration système.",
        role_basic_description: "Accès très limité, principalement à la visualisation, peut créer des demandes d'intervention mais ne peut pas gérer les utilisateurs ou la configuration système.",
        invitation_sent_message: "Un email a été envoyé à l'utilisateur invité avec les instructions pour finaliser son inscription.",
      },
      th: {
        invitation_link_title: 'เชิญสมาชิกใหม่',
        role: 'บทบาท',
        email: 'อีเมล',
        cancel: 'ยกเลิก',
        generate_link: 'สร้างลิงก์',
        invitation_created: 'สร้างคำเชิญสำเร็จแล้ว',
        invitation_link: 'ลิงก์คำเชิญ',
        link_expiry_notice: 'ลิงก์นี้จะหมดอายุใน 7 วัน ผู้รับต้องลงทะเบียนให้เสร็จสิ้นก่อนถึงเวลานั้น',
        create_another: 'สร้างอีกหนึ่ง',
        done: 'เสร็จสิ้น',
        email_required: 'ต้องระบุอีเมล',
        email_invalid: 'กรุณาใส่อีเมลที่ถูกต้อง',
        role_required: 'ต้องระบุบทบาท',
        generation_error: 'เกิดข้อผิดพลาดขณะสร้างลิงก์คำเชิญ',
        link_copied: 'คัดลอกลิงก์ไปยังคลิปบอร์ดแล้ว',
        role_admin: 'ผู้ดูแลระบบ',
        role_user: 'ผู้ใช้',
        role_manager: 'ผู้จัดการ',
        role_viewer: 'ผู้ดู',
        role_technicien: 'ช่างเทคนิค',
        role_basic: 'ผู้ใช้พื้นฐาน',
        role_sous_traitant: 'ผู้รับเหมาช่วง',
        role_basic_verificateur: 'ผู้ตรวจสอบพื้นฐาน',
        role_superviseur: 'ผู้ควบคุมงาน',
        role_chef_equipe: 'หัวหน้าทีม',
        role_description: 'คำอธิบายบทบาท',
        invitation_info: 'ข้อมูลเกี่ยวกับคำเชิญ',
        invitation_email_sent: 'อีเมลเชิญจะถูกส่งไปยังที่อยู่อีเมลที่ระบุโดยอัตโนมัติ',
        invitation_expiry: 'คำเชิญจะหมดอายุโดยอัตโนมัติหลังจาก 7 วัน',
        role_admin_description: "เข้าถึงคุณสมบัติทั้งหมดได้ รวมถึงการจัดการผู้ใช้และการกำหนดค่าระบบ",
        role_superviseur_description: "เข้าถึงคุณสมบัติส่วนใหญ่ได้ สามารถตรวจสอบการดำเนินการบางอย่าง แต่ไม่สามารถจัดการผู้ใช้หรือการกำหนดค่าระบบได้",
        role_technicien_description: "เข้าถึงคุณสมบัติหลายอย่างได้ แต่ไม่สามารถจัดการผู้ใช้หรือการกำหนดค่าระบบได้",
        role_sous_traitant_description: "การเข้าถึงที่จำกัดเฉพาะคุณสมบัติบางอย่าง ไม่สามารถสร้างหรืออัปเดตรายการบางรายการและไม่สามารถจัดการผู้ใช้หรือการกำหนดค่าระบบได้",
        role_chef_equipe_description: "การเข้าถึงที่จำกัดส่วนใหญ่สำหรับการดู สามารถตรวจสอบการดำเนินการบางอย่าง แต่ไม่สามารถสร้างหรืออัปเดตรายการบางรายการและไม่สามารถจัดการผู้ใช้หรือการกำหนดค่าระบบได้",
        role_basic_verificateur_description: "การเข้าถึงที่จำกัดสำหรับการดู สามารถสร้างคำขอเข้าแทรกแซงและการบำรุงรักษาได้ แต่ไม่สามารถจัดการผู้ใช้หรือการกำหนดค่าระบบได้",
        role_basic_description: "การเข้าถึงที่จำกัดมาก ส่วนใหญ่สำหรับการดู สามารถสร้างคำขอเข้าแทรกแซงได้ แต่ไม่สามารถจัดการผู้ใช้หรือการกำหนดค่าระบบได้",
        invitation_sent_message: "อีเมลถูกส่งไปยังผู้ใช้ที่ได้รับเชิญพร้อมคำแนะนำในการลงทะเบียนให้เสร็จสมบูรณ์",
      }
    }
  },
  data() {
    return {
      invitationRequest: {
        email: '',
        role: 'ROLE_ADMIN'
      },
      generatedInvitation: null,
      isGenerating: false,
      validationErrors: {},
      roleOptions: [],
      showDropdown: false,
      showRoleInfo: false
    };
  },
  created() {
    // Initialize role options with translations
    this.roleOptions = [
      { value: 'ROLE_ADMIN', label: this.$t('role_admin') },
      { value: 'ROLE_TECHNICIEN', label: this.$t('role_technicien') },
      { value: 'ROLE_BASIC', label: this.$t('role_basic') },
      { value: 'ROLE_SOUS_TRAITANT', label: this.$t('role_sous_traitant') },
      { value: 'ROLE_BASIC_VERIFICATEUR', label: this.$t('role_basic_verificateur') },
      { value: 'ROLE_SUPERVISEUR', label: this.$t('role_superviseur') },
      { value: 'ROLE_CHEF_EQUIPE', label: this.$t('role_chef_equipe') }
    ];
  },
  methods: {
    validateForm() {
      this.validationErrors = {};

      if (!this.invitationRequest.email) {
        this.validationErrors.email = this.$t('email_required');
      } else if (!this.isValidEmail(this.invitationRequest.email)) {
        this.validationErrors.email = this.$t('email_invalid');
      }

      if (!this.invitationRequest.role) {
        this.validationErrors.role = this.$t('role_required');
      }

      return Object.keys(this.validationErrors).length === 0;
    },

    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    async generateInvitation() {
      if (!this.validateForm()) {
        return;
      }

      this.isGenerating = true;
      let origin = window.location.origin;
      try {
        const response = await this.$vg.invitations.generateInvitationLink({
          email: this.invitationRequest.email,
          origin: origin,
          role: this.invitationRequest.role
        });

        // Process the response to create a complete invitation object
        const invitation = response.invitation || response;

        // Add the invitation link to the invitation object using the correct API endpoint
        if (invitation.token) {
          const origin = window.location.origin;
          invitation.invitation_link = `${origin}/invitations/check-invitation/${invitation.token}?origin=${encodeURIComponent(origin)}`;
        }

        this.generatedInvitation = invitation;
        console.log('Generated invitation:', invitation);
        // Emit success event so parent component can refresh the list
        this.$emit('invitation-created', invitation);
      } catch (error) {
        console.error('Error generating invitation link:', error);
        // Show error message
        this.$emit('error', error.message || this.$t('generation_error'));
      } finally {
        this.isGenerating = false;
      }
    },

    copyInvitationLink() {
      if (this.generatedInvitation && this.generatedInvitation.invitation_link) {
        navigator.clipboard.writeText(this.generatedInvitation.invitation_link);
        // Show success message
        alert(this.$t('link_copied'));
      }
    },

    resetForm() {
      this.invitationRequest = {
        email: '',
        role: 'ROLE_ADMIN'
      };
      this.generatedInvitation = null;
      this.validationErrors = {};
    },

    getRoleName(roleValue) {
      const role = this.roleOptions.find(r => r.value === roleValue);
      return role ? role.label : roleValue;
    },

    getRoleDescription(roleValue) {
      const roleKey = roleValue.toLowerCase() + '_description';
      return this.$t(roleKey);
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    closeDropdown() {
      this.showDropdown = false;
    },

    selectRole(role) {
      this.invitationRequest.role = role;
      this.showDropdown = false;
    },

    toggleRoleInfo() {
      this.showRoleInfo = !this.showRoleInfo;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-title {
  display: flex;
  align-items: center;
  gap: 12px;

  .title-icon {
    width: 24px;
    height: 24px;
  }
}

.invitation-container {
  padding: 16px;
}

.create-form {
  .form-group {
    margin-bottom: 20px;
    position: relative;
  }

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .info-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: #3880ff;
    font-size: 20px;
  }

  #role-info-panel {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    margin: 10px 0;
    border-left: 4px solid #3880ff;

    h4 {
      margin-top: 0;
      color: #333;
    }

    ul {
      padding-left: 20px;
      margin-bottom: 0;
    }

    li {
      margin-bottom: 8px;
    }
  }

  .dropdown {
    position: relative;

    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 12px;
      border: 1px solid #ddd;
      border-radius: 4px;

      &::after {
        content: '▼';
        font-size: 12px;
      }
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background: white;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 10;
      display: none;

      &.show {
        display: block;
      }
    }

    .dropdown-item {
      padding: 10px 15px;
      cursor: pointer;

      &:hover {
        background-color: #f5f7fa;
      }
    }
  }

  .info-box {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    margin-top: 10px;

    p {
      margin: 0 0 10px 0;
      color: #555;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  .option-label {
    display: block;
    margin-top: 15px;
    color: #666;
    font-size: 14px;
  }
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.invitation-result {
  .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
    margin: 20px 0;
    padding: 24px;
    background-color: rgba(40, 167, 69, 0.05);
    border-radius: 8px;

    .success-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 72px;
      background-color: #28a745;
      border-radius: 50%;
      box-shadow: 0 4px 12px rgba(40, 167, 69, 0.2);
      animation: pulse 2s infinite;
    }

    .success-icon {
      width: 40px;
      height: 40px;
      color: white;
    }

    .success-title {
      margin: 0;
      color: #28a745;
      font-size: 24px;
      font-weight: 600;
    }

    .success-message {
      margin: 0;
      color: #6c757d;
      max-width: 400px;
      line-height: 1.5;
    }

    .expiry-notice {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-top: 16px;
      padding: 12px 16px;
      background-color: #fff3cd;
      border-left: 4px solid #ffc107;
      border-radius: 4px;
      width: 100%;

      .calendar-icon {
        width: 20px;
        height: 20px;
        color: #856404;
      }

      span {
        color: #856404;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(40, 167, 69, 0.2);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 8px 24px rgba(40, 167, 69, 0.3);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(40, 167, 69, 0.2);
  }
}

.invalid-feedback {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 4px;
}

.is-invalid {
  border-color: #dc3545;
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 0.2em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  margin-right: 8px;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}
</style>
