<template>
    <vg-modal width="800px" @close="$emit('close')">
        <template #title>
            <div class="modal-title">
                <vg-icon url="/static/assets/icone/custom/link.svg" class="title-icon"/>
                <h2>Gestion des liens de partage</h2>
            </div>
        </template>
        <template #body>
            <div class="loader-container" v-if="isLoading">
                <div class="spinner"></div>
                <p>Récupération des liens de partage...</p>
            </div>
            <div v-if="isManageable" class="shared-links-container">
                <div v-if="sharedLinks.length === 0" class="empty-state">
                    <vg-icon url="/static/assets/icone/custom/info.svg" class="empty-icon"/>
                    <p>Aucun lien de partage trouvé</p>
                </div>

                <div v-show="!showCreateLink" class="links-list">
                    <div class="list-header">
                        <h3>Liens de partage existants</h3>
                        <vg-button 
                            @click="showCreateLink = true"
                            icon="plus"
                            type="primary">
                            Créer un nouveau lien
                        </vg-button>
                    </div>

                    <div class="links-grid">
                        <div v-for="link in sharedLinks" 
                             :key="link.id" 
                             class="link-card">
                            <div class="link-header">
                                <h4>{{ link.name || 'Lien sans nom' }}</h4>
                                <span class="origin-badge">{{ getOrigin(link) }}</span>
                            </div>
                            
                            <div class="link-body">
                                <p v-if="link.description" class="description">
                                    {{ link.description }}
                                </p>
                                <p v-if="link.expires_at" class="expiry">
                                    <vg-icon url="/static/assets/icone/custom/calendar.svg"/>
                                    Expire le {{ link.expires_at.split(" ")[0] }}
                                </p>
                            </div>

                            <div class="link-actions">
                                <vg-button 
                                    @click="window.open('https://salty-tor-71937-b4f753c2bb16.herokuapp.com'+link.url, '_blank')"
                                    icon="external-link"
                                    type="secondary"
                                    title="Ouvrir le lien">
                                </vg-button>
                                <vg-button 
                                    @click="copyLink(link)"
                                    icon="copy"
                                    type="secondary"
                                    title="Copier le lien">
                                </vg-button>
                                <vg-button 
                                    @click="deleteSharedLink(link.id)"
                                    icon="trash"
                                    type="danger"
                                    title="Supprimer">
                                </vg-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="showCreateLink" class="create-form">
                    <div class="form-header">
                        <h3>Créer un nouveau lien de partage</h3>
                        <vg-button 
                            @click="showCreateLink = false"
                            icon="x"
                            type="secondary">
                        </vg-button>
                    </div>

                    <form @submit.prevent="createSharedLink">
                        <vg-input
                            v-model="newSharedLink.name"
                            label="Nom du lien"
                            placeholder="ex: Formulaire APT. 34"
                            icon="tag">
                        </vg-input>

                        <vg-input
                            v-model="newSharedLink.description"
                            type="textarea"
                            label="Description"
                            placeholder="Description du lien de partage"
                            icon="file-text">
                        </vg-input>

                        <vg-input
                            v-model="newSharedLink.expires_at"
                            type="date"
                            label="Date d'expiration"
                            icon="calendar">
                        </vg-input>

                        <div class="form-actions">
                            <vg-button
                                type="secondary"
                                @click="showCreateLink = false">
                                Annuler
                            </vg-button>
                            <vg-button
                                type="primary"
                                native-type="submit">
                                Créer le lien
                            </vg-button>
                        </div>
                    </form>
                </div>
            </div>

            <div v-else class="simple-view">
                <div v-if="sharedLinks.length > 0" class="copy-link">
                    <p class="shared-link-info">
                        Ceci est une URL publique qui donne accès à : <b>{{ origin.split("/")[0] }}  </b>/ <b>{{ name.split("/")[name.split("/").length-1] || "ce contenu" }}</b>. <br> 
                        Utilisez ce lien pour générer un QR code que vous allez imprimer et partager.<br>
                        Par exemple,
                        vous pouvez disposer ce qrcode dans la chambre ou le logement correspondant pour permettre au client de faire une demande en cas de dysfonctionnement technique
                    </p>
                    <vg-input
                        v-model="sharedLinks[0].url"
                        label="Lien de partage"
                        icon="link"
                        disabled />
                    <vg-button
                        @click="copyLink(sharedLinks[0])"
                        :label="sharedLinks[0].url"> Cliquer pour copier le lien de partage
                    </vg-button>
                </div>
            </div>
        </template>
    </vg-modal>
</template>

<script>
import { Metadatas } from 'verifgood-js-sdk';
import VgInput from '../VgInput.vue';
import VgIcon from '../VgIcon.vue';
import VgButton from '../VgButton.vue';
export default {
    components: {
        VgInput,
        VgIcon,
        VgButton
    },
    props: {
        entityId: {
            type: Array,
            default: () => []
        },
        origin: {
            type: String,
            default: 'demande-intervention/lieu'
        },
        name: {
            type: String,
            default: 'Formulaire de demande d\'intervention'
        },
        isManageable: {
            type: Boolean,
            default: false
        }
    },
    computed(){

    },
    data() {
        return {
            sharedLinks: [],
            showCreateLink: false,
            isLoading:true,
            newSharedLink: {
                origin: this.origin,
                internalId: this.entityId,
                description: "",
                name: this.name,
                scopes: "read,write"
            }
        };
    },
    methods: {
        copyLink(sharedLink) {
            let link = 'https://salty-tor-71937-b4f753c2bb16.herokuapp.com' + sharedLink.url;
            navigator.clipboard.writeText(link);
            // add feedback for user
            console.log('Link copied to clipboard:', link);
            alert('Le lien a été copié dans le presse-papiers');
        },
        getOrigin(sharedLink) {
            let splittedUrl = sharedLink.url.split('/');
            let origin = splittedUrl[1];
            return this.origin;
        },
        async fetchSharedLinks() {
            try {
                let metadatas = new Metadatas();
                metadatas.setFilter('internalId', this.entityId, 'equals');
                this.sharedLinks = await this.$vg.sharedLinks.getAll(metadatas);
                this.isLoading = false;
            } catch (error) {
                console.error('Error fetching shared links:', error);
                this.isLoading = false;
            }
        },
        async createSharedLink() {
            try {
                await this.$vg.sharedLinks.create(this.newSharedLink);
                this.fetchSharedLinks();
            } catch (error) {
                console.error('Error creating shared link:', error);
            }
        },
        async deleteSharedLink(id) {
            try {
                await this.$vg.sharedLinks.delete(id);
                this.fetchSharedLinks();
            } catch (error) {
                console.error('Error deleting shared link:', error);
            }
        }
    },
    mounted() {
        //this.fetchSharedLinks();
        this.createSharedLink();
    }
};
</script>

<style lang="scss" scoped>
.modal-title {
    display: flex;
    align-items: center;
    gap: 12px;
    .title-icon {
        width: 24px;
        height: 24px;
    }
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    .spinner {
        width: 40px;
        height: 40px;
        border: 3px solid #f3f3f3;
        border-top: 3px solid #3498db;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }
}

.links-list {
    .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
}

.links-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.link-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    
    .link-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 12px;

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .origin-badge {
        background: #e9ecef;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
    }

    .link-body {
        margin-bottom: 16px;

        .description {
            color: #6c757d;
            font-size: 14px;
            margin: 8px 0;
        }

        .expiry {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #dc3545;
            font-size: 13px;
        }

        .shared-link-info {
            color: #6c757d;
            font-size: 14px;
            margin-top: 8px;
        }
    }

    .link-actions {
        display: flex;
        gap: 8px;
        justify-content: flex-end;
    }
}

.create-form {
    background: white;
    border-radius: 8px;
    padding: 24px;

    .form-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 24px;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .links-grid {
        grid-template-columns: 1fr;
    }
}
</style>