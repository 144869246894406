<template>
    <div>
        <vg-modal
            width="800px"
            v-show="!isModalConfirmDeleteOpened"
            @close="$emit('close')"
            @save="onSaveCategorie">
            <template #header>
                <span v-if="isGenerique">{{$t("title-categorie-generique")}}</span>
                <span v-else-if="isGe">{{$t("title-categorie-lieu")}}</span>
                <span v-else-if="isAnUpdateForm">{{ $t("update") }}</span>
                <span v-else>{{$t("title")}}</span>
            </template>
            <template #body-left>
                <form id="categorie-form"
                    ref="categorie-form"
                    onSubmit="return false;">
                    <div v-if="isGenerique">
                        <vg-input v-if="!categorieProposee || (categorieProposee && !categorieProposee.isAutre)" :title="$t('use-categorie-proposee')">
                            <vg-select
                                :options="categoriesProposees"
                                :attributeLabel="'label'"
                                @input="onSelectCategorieProposee">
                                <template v-slot:selected-option="{option}">
                                    <img :src="option.icon" height="20" width="20" style="margin-right:10px;">
                                    <span>{{option.libelleCatgorie}}</span>
                                </template>
                                <template v-slot="{option}">
                                    <img :src="option.icon" height="20" width="20" style="margin-right:10px;">
                                    <span>{{option.libelleCatgorie}}</span>
                                </template>
                            </vg-select>
                        </vg-input>
                        <div v-else>
                            <vg-button :type="'grey'" :size="'sm'"
                                @click="revoirCategoriesProposees">
                                <i class="fas fa-arrow-left"></i> {{$t("revoir-categories-proposees")}}
                            </vg-button>
                            <vg-input
                                :title="$t('icone')">
                                <vg-composant-icon-selector
                					v-model="categorie.icon">
                				</vg-composant-icon-selector>
                            </vg-input>
                            <vg-input :title="$t('libelle')"
                                v-model="categorie.libelleCatgorie">
                            </vg-input>
                        </div>
                    </div>
                    <div v-else-if="isGe"> <!-- CATEGORIE LIEU -->
                        <vg-input v-model="categorie.libelleCatgorie"
                            :title="$t('libelle')"
                            isRequiredValue>
                        </vg-input>
                        <vg-input v-show="hasAtLeastOneTypologieMaintenance" :title="$t('typologie-maintenance-par-defaut')" isRequiredValue>
                            <vg-typologies-maintenance-selector v-model="categorie.typologieMaintenance_id" />
                        </vg-input>
                    </div>
                    <div v-else> <!-- CATEGORIE Equipement -->
                        <swatches colors="text-advanced" v-model="categorie.codeCouleur" />
                        
                        <vg-composant-icon-selector
                            v-model="categorie.icon">
                        </vg-composant-icon-selector>

                        <vg-input v-model="categorie.libelleCatgorie"
                            :title="$t('libelle')"
                            isRequiredValue>
                        </vg-input>

                        <vg-input v-show="hasAtLeastOneTypologieMaintenance" :title="$t('typologie-maintenance-par-defaut')">
                            <vg-typologies-maintenance-selector 

                                v-model="categorie.typologieMaintenance_id"
                            />
                        </vg-input>

                        <vg-categorie-tags-datalist v-model="categorie.tags" :label="$t('tags')"/>
                        
                        <vg-input
                            v-if="isAnUpdateForm"
                            :title="$t('accounting-tag')"
                            >
                            <vg-tags-selector
                                v-model="categorie.accountingTagLabel"
                                :attributeValue="null"
                                type="categorie/comptable"
                                @change="HandleAccountingTagChange"
                            >
                            </vg-tags-selector>
                        </vg-input>

                        <vg-input v-model="categorie.marque"
                            :placeholder="$t('marque')"
                            :title="$t('marque')"
                        >
                        </vg-input>
                        <vg-input
                            :placeholder="$t('corps-detat')"
                            :title="$t('corps-detat')"
                        >
                            <vg-corps-detat-selector
                                v-if="isAnUpdateForm"
                                v-model="categorie.corpsDetat"
                                :attributeValue="null"
                                @change="handleChangeCorpsDetat"
                            >
                            </vg-corps-detat-selector>
                            <vg-corps-detat-selector
                                v-else
                                :attributeValue="null"
                                @change="handleChangeCorpsDetat"
                            >
                            </vg-corps-detat-selector>
                        </vg-input>

                        <vg-input v-model="categorie.tauxDepreciationAnnuelDefault"
                            :title="$t('taux-depreciation-annuel')"
                        >
                        </vg-input>
                        <vg-input v-model="categorie.prixDefault"
                            type="number"
                            :title="$t('prix-par-default')+' '+$app.devise"
                        >
                        </vg-input>
                        <vg-input v-model="categorie.indiceCriticite"
                            :title="$t('indice-criticite')"
                            >
                        </vg-input>

                        <vg-input
                            v-model="categorie.isInventoriable"
                            :title="$t('is-inventoriable')"
                        >
                            <vg-switch style="margin-left:20px;"
                                :checked="parseInt(categorie.isInventoriable)"
                                v-model="categorie.isInventoriable">
                            </vg-switch>
                        </vg-input>
                    </div>

                    <input
                      v-show="false"
                      ref="categorie-submit"
                      type="submit"
                    >
                </form>
            </template>
            <template #body-right v-if="!isGe">
                <h3>{{$t("listing-libeller-categories")}}</h3>
                <vg-todo-list
                    @deleteItem="handleDeleteLibelleCategorie"
                    v-model="categorie.libellesCategorie"
                    :displayedProperty="'libelle'"
                    :placeholderInput="$t('placeholder-new-libelle')">
                    <template v-slot:item="{item}">
                       <div>{{item.message}}</div>
                    </template>
                </vg-todo-list>
                <hr>

                <vg-input v-model="categorie.sourceFinancementDefault"
                    :title="$t('source-financement-default')"
                >
                </vg-input>
                <vg-input v-model="categorie.refConstructeurDefault"
                    :title="$t('ref-constructeur-default')"
                >
                </vg-input>
                <vg-input :title="$t('set-statut-equipement')" v-show="showTagsStatutsEquipements">
                    <vg-tags-equipement-statut-selector v-model="categorie.typeSetStatutEquipement"
                        :attributeValue="'type'"
                        isSet 
                        @after-fetch="onAfterFetch"/>
                </vg-input>
            </template>


            <template #footer>
              <vg-button
                type="default"
                @click="$emit('close')"
              >
                {{ $t("cancel") }}
              </vg-button>

              <vg-button
                v-if="isAnUpdateForm"
                type="danger"
                @click="isModalConfirmDeleteOpened = true"
                :disabled="isDisabledUi || isLoading"
              >
                {{ $t("delete") }}
              </vg-button>

              <vg-button
                v-if="isAnUpdateForm"
                type="default-danger"
                @click="onSaveCategorie"
                :disabled="isDisabledUi || isLoading"
              >
                {{ $t("update") }}
              </vg-button>

              <vg-button
                v-if="!isAnUpdateForm"
                type="success"
                @click="onSaveCategorie"
                :disabled="isDisabledUi || isLoading"
              >
                {{ $t("save") }}
              </vg-button>
            </template>

        </vg-modal>

        <dialog-secured-action
          v-show="isModalConfirmDeleteOpened"
          :valid-response="'1'"
          @save="handleDeleteCategorie"
          @close="isModalConfirmDeleteOpened = false"
        >
        <template v-slot:header-title>
            {{ $t("suppression-categorie") }}
        </template>
        <template v-slot:warning>
            {{ $t("suppression-categorie-warning") }}
        </template>
        </dialog-secured-action>
    </div>

</template>
<script>
/***
* @TODO pour l'instant gère uniquement formulaire nouvelle catégorie générique
*/

import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgComposantIconSelector from "src/components/Vg/Selectors/VgComposantIconSelector.vue";
import CategorieMixins from "src/mixins/CategorieMixins.js";
import LibellesCategorieMixins from "src/mixins/LibellesCategorieMixins.js";
import Swatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.min.css";
import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
import VgTagsSelector from "src/components/Vg/Selectors/VgTagsSelector.vue";
import VgTypologiesMaintenanceSelector from "src/components/Vg/Selectors/VgTypologiesMaintenanceSelector.vue";
import VgCorpsDetatSelector from "src/components/Vg/Selectors/VgCorpsDetatSelector.vue";
import VgSwitch from 'src/components/Vg/VgSwitch.vue';
import VgTodoList from 'src/components/Vg/VgTodoList.vue';
import VgCategorieTagsDatalist from 'src/components/Vg/Datalists/VgCategorieTagsDatalist.vue';
import VgTagsEquipementStatutSelector from 'src/components/Vg/Selectors/VgTagsEquipementStatutSelector.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'vg-categorie-form',
    components:{
        VgModal,
        VgButton,
        VgInput,
        VgSelect,
        VgComposantIconSelector,
        Swatches,
        DialogSecuredAction,
        VgTagsSelector,
        VgCorpsDetatSelector,
        VgSwitch,
        VgTodoList,
        VgCategorieTagsDatalist,
        VgTagsEquipementStatutSelector,
        VgTypologiesMaintenanceSelector
    },
    mixins: [CategorieMixins,LibellesCategorieMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Création catégorie",
            "update": "Modifier catégorie",
            "tags": "Etiquettes ( séparer les étiquettes par des ' / ' exemple 'mobilier/chaise')",
            "corps-detat": "Corps d'état par défaut affecté à la fiche curative",
            "prix-par-default": "Prix par défaut",
            "source-financement-default": "Source de financement",
            "ref-constructeur-default": "Référence constructeur défaut",
            "marque": "Marque",
            "cancel": "Annuler",
            "delete": "Supprimer",
            "save": "Sauvegarder",
            "is-inventoriable": "Les équipements de cette catégorie apparaissent dans l'inventaire ? ",
            "suppression-categorie": "Voulez-vous supprimer cette catégorie ?",
            "suppression-categorie-warning": "Attention cela supprimera les équipements liés à cette catégorie",
            "accounting-tag": "étiquette comptable",
            "title-categorie-lieu": "Création catégorie lieu",
            "title-categorie-generique": "Création catégorie générique",
            "icone": "Icone",
            "libelle": "Libellé",
            "use-categorie-proposee": "Catégories proposées",
            "installation-electrique": "Installation électrique",
            "installation-gaz": "Installation gaz",
            "reseau-eau-potable": "Réseau eau potable",
            "reseau-eau-usee": "Réseau eau usée",
            "reseau-eau-pluviale": "Réseau eau pluviale",
            "reseau-informatique": "Réseau informatique",
            "clos-couvert": "Clos couvert",
            "structure-beton": "Structure béton",
            "facades": "Façades",
            "parc-climatiseurs": "Parc climatiseurs",
            "parc-extincteurs": "Parc extincteurs",
            "ou-personnaliser-categorie": "ou personnaliser une catégorie",
            "personnaliser-categorie": "Personnaliser une catégorie",
            "indice-criticite": "Indice de criticité",
            "taux-depreciation-annuel": "Dépréciation annuelle (%)",
            "listing-libeller-categories": "Libellés problème",
            "revoir-categories-proposees": "Revoir les catégories proposées",
            "libelle-hors-service": "Hors service",
            "libelle-abime": "Abimé",
            "typologie-maintenance-par-defaut": "Selectionner une typologie à l'ouverture d'une fiche curative",
            "libelle-autre": "Autre",
            "set-statut-equipement": "Set statut équipement",
            "placeholder-new-libelle": "Nouveau libellé"
        },
        "en": {
            "title": "Create a category",
            "update": "update category",
            "tags": "tags ( dissociate tags with ' / ' as exemple 'furniture/chair')",
            "corps-detat": "Default technical field assigned to the curative sheet",
            "prix-par-default": "Default price",
            "save": "Save",
            "source-financement-default": "Funding origin",
            "ref-constructeur-default": "default manufacturer reference",
            "marque": "Brand",
            "cancel": "Cancel",
            "delete": "Delete",
            "is-inventoriable": "Equipments with this category will be in the assets tracking ?",
            "suppression-categorie": "Do you want to delete this category ?",
            "suppression-categorie-warning": "Warning linked equipments will be deleted",
            "accounting-tag": "Accounting tag",
            "title-categorie-generique": "Create a generic category",
            "icone": "Icon",
            "libelle": "Name",
            "use-categorie-proposee": "Category",
            "installation-electrique": "Electrical installation",
            "installation-gaz": "Gas installation",
            "reseau-eau-potable": "Drinking water network",
            "reseau-eau-usee": "Waste water network",
            "reseau-eau-pluviale": "Rainwater network",
            "reseau-informatique": "Computer network",
            "clos-couvert": "Enclosed covered",
            "structure-beton": "Concrete structure",
            "facades": "Facades",
            "parc-climatiseurs": "Air conditioners",
            "parc-extincteurs": "Fire extinguisher",
            "ou-personnaliser-categorie": "Customize a category",
            "personnaliser-categorie": "Customize category",
            "indice-criticite": "Criticity index",
            "taux-depreciation-annuel": "Annual deprecation (%)",
            "listing-libeller-categories": "Issue wording",
            "revoir-categories-proposees": "See default categories",
            "libelle-hors-service": "Out of order",
            "libelle-abime": "Damaged",
            "typologie-maintenance-par-defaut": "select a typologie on curative sheet creation",
            "libelle-autre": "Other",
            "set-statut-equipement": "Set equipment status",
            "placeholder-new-libelle": "New wording"
        }
    }
},
    props: {
        value:{
            type: Object,
            default: function(){
                return null;
            }
        },
        id:{
            type: Number | String,
            default: null
        },
        skipVueXStorage:{
            type: Boolean,
            default: false
        },
        /**
        * création d'une catégorie générique
        */
        isGenerique: {
            type: Boolean,
            default: false
        },
        isGe: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            categorie: {
                icon: null,
                libelleCatgorie: null,
                isInventoriable: 1,
                codeCouleur: this.$vgutils.randomColor(),
                libellesCategorie:[
                    {libelle: this.$t("libelle-hors-service")},
                    {libelle: this.$t("libelle-abime")},
                    {libelle: this.$t("libelle-autre")}
                ],
                tags: null,
                typologieMaintenance_id:null,
                typeSetStatutEquipement: null
            },
            icons:null,
            isModalConfirmDeleteOpened:false,
            categorieProposee: null,
            showTagsStatutsEquipements: true,
            isLoading: false
        };
    },
    computed:{
        ...mapGetters({
            typologiesMaintenance: "TypologiesMaintenanceStore/getCollection",
            hasAtLeastOneTypologieMaintenance: "TypologiesMaintenanceStore/hasAtLeastOneTypologieMaintenance"
        }),
        categoriesProposees: function(){
            return [
                {libelleCatgorie:this.$t("personnaliser-categorie"), isAutre: true},
                {icon:"/static/assets/icone/composant/installation-electrique.png",libelleCatgorie:this.$t("installation-electrique")},
                {icon:"/static/assets/icone/composant/reseau-eau-potable.png",libelleCatgorie:this.$t("reseau-eau-potable")},
                {icon:"/static/assets/icone/composant/installation-gaz.png",libelleCatgorie:this.$t("installation-gaz")},
                {icon:"/static/assets/icone/composant/reseau-eaux-usees.png",libelleCatgorie:this.$t("reseau-eau-usee")},
                {icon:"/static/assets/icone/composant/reseau-eaux-usees.png",libelleCatgorie:this.$t("reseau-eau-pluviale")},
                {icon:"/static/assets/icone/composant/reseau-informatique.png",libelleCatgorie:this.$t("reseau-informatique")},
                {icon:"/static/assets/icone/composant/clos-couvert.png",libelleCatgorie:this.$t("clos-couvert")},
                {icon:"/static/assets/icone/composant/mur.png",libelleCatgorie:this.$t("structure-beton")},
                {icon:"/static/assets/icone/composant/facade.png",libelleCatgorie:this.$t("facades")},
                {icon:"/static/assets/icone/composant/climatiseur.png",libelleCatgorie:this.$t("parc-climatiseurs")},
                {icon:"/static/assets/icone/composant/extincteur.png",libelleCatgorie:this.$t("parc-extincteurs")}
            ];
        },
        isAnUpdateForm: function(){
            if(this.id && this.skipVueXStorage) return true;
            else if(this.value && this.value.hasOwnProperty("id") && this.value.id) return true;
            else return false;
        },
    },
    created: function() {
        if(this.id && this.skipVueXStorage) this.fetchCategorie();
        else if(this.value) this.categorie = Object.assign({}, this.categorie, this.value);
    },
    methods: {
        onAfterFetch: function(tagsStatutsEquipements){
            this.showTagsStatutsEquipements = tagsStatutsEquipements && tagsStatutsEquipements.length!=0;
        },
        fetchCategorie: function(){
            this.isLoading = true;
            this.CategorieMixins_getCategorie(this.id?this.id:this.categorie.id, {skipVueXStorage: this.skipVueXStorage}).then((categorie)=>{
                this.categorie = Object.assign({}, this.categorie, categorie);
                this.isLoading = false;
            });
        },
        handleDeleteLibelleCategorie:function(item){
            if(this.isAnUpdateForm){
                this.isLoading = true;
                this.LibellesCategorieMixins_delete(item.id).then(()=>{
                    this.isLoading = false;
                    this.fetchCategorie();
                });
            }

        },
        handleDeleteCategorie:function(){
            this.isLoading = true;
            this.CategorieMixins_delete(this.categorie).then(()=>{
                this.isLoading = false;
                this.$emit("close");
            });
        },
        revoirCategoriesProposees: function(){
            this.categorieProposee = null;
            this.categorie = {
                icon: null,
                libelleCatgorie: null
            };
        },
        onSelectCategorieProposee: function(categorie){
            //console.log("SELECT CATEGORIE PROPOSEE", categorie);
            this.categorieProposee = categorie;
            if(this.categorieProposee && !this.categorieProposee.isAutre) this.categorie = Object.assign(this.categorie, this.categorieProposee);
            else{
                this.categorie = {
                    icon: null,
                    libelleCatgorie: null
                };
            }
        },
        onSaveCategorie: function(){
            this.isLoading = true;
            if(this.isAnUpdateForm){
                this.CategorieMixins_update(this.categorie).then((data)=>{
                    this.isLoading = false;
                    this.$emit("save", data);
                });
            }else{
                let categorie = Object.assign({}, {
                    userId: this.$app.appID,
                    isGe: this.isGe,
                    isGenerique: this.isGenerique
                }, this.categorie);
                this.CategorieMixins_create([categorie]).then((datas)=>{
                    this.isLoading = false;
                    this.$emit("save", datas[0]);
                });
            }
        },
        /**
         *
         * @param Object tag
         */
        HandleAccountingTagChange:function(tag){
            this.categorie.accountingTagLabel = tag.label;
            this.categorie.idAccountingTag = tag.id;
        },
        handleChangeCorpsDetat:function(corpsDetat){
            if(corpsDetat){
                this.categorie.corpsDetats = [corpsDetat];
            }else{
                delete this.categorie.corpsDetats;
            }
        },
        setTypologieMaintenanceId:function(typologieMaintenanceId){
            console.log({typologieMaintenanceId})
        }

    }
};
</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-categorie-form
    formulaire création catégorie

    #### basic usage
    ```html static
        <vg-categorie-form
            @close="...">
        </vg-categorie-form>
    ```
</docs>
