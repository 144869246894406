<template>
    <div class="operations-simple-view" @click="onClickOperationView">
        <div class="date">
            <vg-datetime-viewer v-model="operation.data.dateOperation">
            </vg-datetime-viewer>
        </div>
        <div class="description text-ellipsis">
            <vg-operation-viewer class="text-ellipsis"
                v-model="operation.data"
                :fm="fm"
                @update="onUpdateOperation"
                @open-cloture-photo="onOpenCloturePhoto" />
        </div>
        <div class="photo" v-if="operation.data.document_name">
            <vg-files-thumbnail  :file="file"
                :heightImg="'30px'">
            </vg-files-thumbnail>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";


    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgOperationViewer from "src/components/Vg/Operation/VgOperationViewer.vue";
    import VgOperationFlag from "src/components/Vg/Operation/VgOperationFlag.vue";
    import VgFilesThumbnail from "src/components/Vg/Files/VgFilesThumbnail.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "operations-simple-view",
        mixins:[LangMixins],
		props: {
			operation: {
				type: Object,
				required: true,
				default: {}
			},
            fm: {
                type: Object,
                required:true,
                default:{}
            }
		},
        components:{
            VgDatetimeViewer,
            VgOperationViewer,
            VgOperationFlag,
            VgFilesThumbnail
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "par": "Par"
        },
        "en": {
            "par": "By"
        }
    }
},
        computed:{
            file: function(){
                return {
                    uploadedTo: this.operation.data.document_uploadedTo,
                    name: this.operation.data.document_name
                };
            }
        },
        methods: {
            onClickOperationView: function(){
                if(!this.operation.data.flag in ["tache","a_prevoir","cloture-photo"]){ 
                    this.$emit("click", this.operation.data);
                }
            },
            onUpdateOperation: function(operation){
                this.$emit("update", operation);
            },
            onOpenCloturePhoto: function(datas){
                this.$emit("open-cloture-photo", datas);
            }
        }
    });
</script>

<style lang="scss" scoped>

.operations-simple-view{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .date{
        margin-right: 5px;
    }
}
@media only screen and (max-width: 768px){
    .operations-simple-view{
        font-size: 12px;
    }
}

</style>
