<template>
    <vg-input :title="title"
        :isRequiredValue="isRequiredValue">
        <input v-model="inputValue"
            :list="'signataires-list-'+datalistId"
            :id="'signataires-'+datalistId"
            :placeholder="placeholder"
            name="signataires"
            class="signataires"
            @input="onInput" />
        <datalist :id="'signataires-list-'+datalistId">
            <option v-for="data, index in signataires" :value="data.signataire" :key="'signataire'+datalistId+'-'+index" />
        </datalist>
    </vg-input>
</template>
<script>
import VgInput from "src/components/Vg/VgInput.vue";
import BonsDeSortieMixins from "src/mixins/BonsDeSortieMixins.js";
import MouvementsEquipementsMixins from "src/mixins/MouvementsEquipementsMixins.js";
import Metadatas from "src/services/Metadatas";
export default {
    name: "vg-signataire-datalist",
    mixins: [ BonsDeSortieMixins, MouvementsEquipementsMixins ],
	components: {
		VgInput
	},
    props: {
		/**
        * @model
        */
        value: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        isRequiredValue:{
            type: Boolean,
            default: false
        },
        isBonDeSortieConsommables:{
            type: Boolean,
            default: true
        },
        isBonDeSortieEquipements:{
            type: Boolean,
            default: false
        },
        isDemandeConsommables:{
            type: Boolean,
            default: false
        },
        typeSignataire:{
            type: String,
            default: "donneurs" // donneurs || receveurs
        }
    },
    data: function() {
        return {
            inputValue: this.value,
            metadatas: new Metadatas(),
            agfilters: {},
            datalistId: this.generateDatalistId(),
            signataires: []
        };
    },
    watch: {
        value: {
            handler: function(newvalue){
                this.inputValue = newvalue;
            }
        }
    },
	created: function(){
        this.fetch();
	},
    methods:{
        generateDatalistId: function(){
            return moment().format("x")+"-"+Math.floor(Math.random()*1000);
        },
        fetch: function(){
            if(this.isBonDeSortieConsommables){
                this.BonsDeSortieMixins_getSignataires(this.metadatas, this.typeSignataire).then((datas)=>{
                    this.signataires = datas.signataires;
                });
            }else if(this.isBonDeSortieEquipements){
                this.MouvementsEquipementsMixins_getMouvementsSignataires(this.metadatas, this.typeSignataire).then((datas)=>{
                    this.signataires = datas.signataires;
                });
            }else if(this.isDemandeConsommables){
                this.FicheDemandeConsommablesMixins_getSignataires(this.metadatas).then((datas)=>{
                    this.signataires = datas.signataires;
                });
            }
        },
        onInput: function(data){
            this.$emit("input", this.inputValue);
        }
    }
};

</script>
<style lang="scss" scoped>
.signataires{
    width:100%;
    border: 1px solid #dcdfe6;
    height: 30px;
    padding: 0 15px;
    font-size:16px !important;
}
</style>
