<template>
<div id='showEquipement' class="full-height">
    <vg-app-layout
       :title="title"
       :icon="'equipements.png'"
       :isZoomLayout="true"
       :isActionButtonDisplay="false"
       :colorheader="getHeaderColor"
       @backpage="handleClickBtnRetour"
       v-if="getEquipement"
    >
    <template v-slot:header-top-left-complement>
        <vg-tag v-if="getEquipement.sortieEquipement" :label="$t('sorti')" /> 
        <vg-tag v-if="getEquipement.isHorsService" :label="$t('hors-service')" color="red"/> 
    </template>

            <template #create-button>
                <vg-button v-show="can('EQUIPEMENT.UPDATE')"
                    @click="showUpdateEquipementForm = true"
                    type="default-danger"
                    :disabled="getEquipement.sortieEquipement" >
                    {{$t('update')}}
                </vg-button>
                <vg-button v-if="!getEquipement.sortieEquipement" v-show="can('EQUIPEMENT.CREATE_MAINTENANCE')"
                    @click="showModal.createfm = true" 
                    type="success">
                    {{$t('Mobile-zoom-creationFM')}}
                </vg-button>
                <vg-button v-if="!getEquipement.sortieEquipement" v-show="can('EQUIPEMENT.CREATE_DEMANDE_INTERVENTION')"
                    @click="goToDemandeIntervention" 
                    type="default-info">
                    {{$t('demande-intervention')}}
                </vg-button>
                <vg-button v-if="!getEquipement.sortieEquipement" v-show="can('EQUIPEMENT.CREATE_INTERVENTION_PREVENTIVE')"
                    @click="showModal.actions = true;" 
                    type="default">
                    {{$t('btn-footer-actions')}} <i class="fas fa-caret-down"></i>
                </vg-button>
            </template>

           <template #primary v-if="!$vgutils.isMobile()">
               <vg-collapse :title="$t('detail')"
               :collapseByDefault="false"
               :type="'section'">
               <template #pretitle>
                   
            </template>
            <template v-slot:content>
                

                       <span >
                           <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=70x70&amp;data='+getEquipement.qrCode">
                       </span>
                       <vg-libelle-editable :label="$t('Desktop-zoom-libelle')">
                           <template v-slot:read>
                               <span >
                                   <b> {{getEquipement.libel_equipement}} </b>
                               </span>
                           </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('Desktop-zoom-qrCode')">
                           <template v-slot:read>
                               <span >
                                   <b> {{getEquipement.qrCode}} </b>
                               </span>
                           </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('batch-number')">
                           <template v-slot:read>
                               <span >
                                   <b v-if="getEquipement.batchNumber"> {{getEquipement.batchNumber}} </b>
                                   <span v-else> - </span>
                                   <input :ref="'batch-number-value'" v-model="getEquipement.batchNumber" style="display:none" />
                                   <vg-button v-if="getEquipement.batchNumber"
                                        size="xs" type="grey" @click="copy">
                                       <i class="far fa-copy"></i>
                                   </vg-button>
                               </span>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-localisation')">
                           <template v-slot:read>
                               <span>
                                   <vg-lieu-path-viewer v-model="getEquipement.lieu.path"></vg-lieu-path-viewer>
                               </span>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-refConstructeur')">
                           <template v-if="getEquipement.categorie.reference_constructeur_par_defaut" v-slot:read><span > {{getEquipement.categorie.reference_constructeur_par_defaut}} </span></template>
                           <template v-else v-slot:read><span > {{getEquipement.refConstructeur}} </span></template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-categorie')">
                           <template v-slot:read><span > {{ getEquipement.categorie.libelle }} </span></template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-table-header-tag-categorie')">
                           <template v-slot:read><span > {{ getEquipement.categorie.tags }} </span></template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-numSerie')">
                           <template v-slot:read><span > {{ getEquipement.num_serie }} </span></template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('ref-document-externe')">
                           <template v-slot:read><span > {{ getEquipement.ReferenceDocumentExterne }} </span></template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-etat')">
                           <template v-slot:read><span > {{ getEquipement.etat }} </span></template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('caracteristiques-techniques')">
                           <template v-slot:read>
                                <vg-text-multiligne v-model="getEquipement.carac_techniques"/>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-marque')">
                           <template v-slot:read><span > {{ getEquipement.marque }} </span></template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-fournisseur')">
                           <template v-slot:read><span v-if="getEquipement.tiers"> {{ getEquipement.tiers.name }} </span></template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-type')">
                           <template v-slot:read><b>{{getEquipement.equipementType}}</b></template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('Desktop-zoom-isEtiqueter')" style="align-items: inherit;">
                           <template v-slot:read>
                                <vg-checkbox
                                   :defaultChecked="parseInt(getEquipement.etiquetter)"
                                   isDisabled>
               				    </vg-checkbox>
                           </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('statut-equipement')" v-show="getEquipement.categorie && getEquipement.categorie.typeSetStatutEquipement">
                           <template #read>
                                <div style="display: flex;justify-content: space-between;align-items: center;gap: 20px;width:100%;">
                                    <span>{{ getEquipement.statutVerification }}</span>
                                    <vg-button type="danger" size="sm" 
                                        @click="showUpdateStatut=true;">
                                        {{ $t("update-statut") }}
                                    </vg-button>
                                </div>
                           </template>
                       </vg-libelle-editable>

                   </template>
               </vg-collapse>
               <vg-collapse :title="$t('usage')" v-if="getEquipement && getEquipement.usage && getEquipement.usage[0]"
                   :collapseByDefault="false"
                   :type="'section'">
                   <template v-slot:content>
                       <vg-libelle-editable :label="getEquipement.usage[0].question">
                           <template v-slot:read>
                                <b>{{getEquipement.usage[0].reponse}}</b>
                           </template>
                       </vg-libelle-editable>
                   </template>
               </vg-collapse>

               <vg-collapse :title="$t('verification-preventive')"
                   :collapseByDefault="false"
                   :type="'section'">
                    <template v-slot:content>
                        <vg-equipement-actives-taches 
                            :taches="getEquipement.taches"
                            readOnly />
                   </template>
               </vg-collapse>



               <vg-collapse :title="$t('Desktop-zoom-gestionCalendaire')"
                   :collapseByDefault="false"
                   :type="'section'">
                   <template v-slot:pretitle>
                       <vg-button
                           @click="showUpdateEquipementForm = true"
                           size="md" type="default-round">
                           <i class="fas fa-calendar-alt"></i>
                       </vg-button>

                   </template>
                   <template v-slot:content>
                       <vg-libelle-editable :label="$t('Desktop-zoom-recensement')">
                           <template v-slot:read>
                                <vg-datetime-viewer v-model="getEquipement.created_at"/>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-dateMiseEnService')">
                           <template v-slot:read>
                               <vg-datetime-viewer v-model="getEquipement.miseEnService" hideTime/>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('date-expiration-garantie')">
                           <template v-slot:read>
                                <vg-datetime-viewer v-model="getEquipement.dateExpirationGarantie" hideTime/>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('date-remplacement-previsionnelle')">
                           <template v-slot:read>
                                <vg-datetime-viewer v-model="getEquipement.dateRemplacementPrevisionelle" hideTime/>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('duree-de-vie-previsionnelle')">
                           <template v-slot:read>
                               <span >
                                   <b> {{getEquipement.dureeDeViePrevisionnelle}} </b>
                               </span>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable v-if="getEquipement.date_sortie" :label="$t('Desktop-zoom-dateSortie')">
                           <template v-slot:read>
                               <vg-datetime-viewer v-model="getEquipement.date_sortie"/>
                           </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable v-if="documentSortieEquipement" :label="documentSortieEquipement.tagLabel">
                           <template v-slot:read>
                               <vg-files-viewer-document
                                   :file="documentSortieEquipement">
                               </vg-files-viewer-document>
                           </template>
                       </vg-libelle-editable>
                       <div class="row__sortir inherit" style="width:100%;display:flex;justify-content:space-between;gap: 10px;">
                           <vg-button v-if="$app.role=='ROLE_ADMIN' && !getEquipement.date_sortie" v-show="can('EQUIPEMENT.DEPLACER')"
                               :type="'info'"
                               :size="'sm'"
                               style="width:50%;"
                               @click="goToDeplacement">
                               {{ $t("Desktop-btn-deplacer") }}
                           </vg-button>
                           <vg-button v-if="$app.role=='ROLE_ADMIN' && !getEquipement.date_sortie" v-show="can('EQUIPEMENT.SORTIR')"
                               :type="'danger'"
                               :size="'sm'"
                               style="width:50%;"
                               @click="openSortieEquipement=true">
                               {{ $t("Desktop-zoom-sortir") }}
                           </vg-button>
                       </div>
                       <div>
                            <mouvement-viewer v-model="getEquipement.derniersMouvement" />
                       </div>

                       <hr class="no-margin">
                       <br>
                       <vg-libelle-editable v-if="getEquipement.lastInterventionExterne"
                            :label="$t('derniere-intervention-externe')">
                           <template #read>
                                <div style="display: flex;justify-content: flex-start;align-items: center;gap: 5px;">
                                    <vg-datetime-viewer v-model="getEquipement.lastInterventionExterne.dateEffectiveDebut"/>
                                    <span>{{ getEquipement.lastInterventionExterne.tiers.name }}</span>
                                    <vg-button type="info" size="xs" style="margin-left:5px;" @click="openIntervention">{{ $t("voir-intervention") }}</vg-button>
                                </div>
                           </template>
                       </vg-libelle-editable>

                   </template>
               </vg-collapse>

               <vg-collapse :title="$t('Desktop-zoom-gestionFinanciere')"
                   :collapseByDefault="false"
                   :type="'section'">
                   <template v-slot:pretitle>
                       <vg-button
                           @click="showUpdateEquipementForm = true"
                           size="md" type="default-round">
                           <i class="fas fa-euro-sign"></i>
                       </vg-button>

                   </template>
                   <template v-slot:content>
                       <vg-libelle-editable :label="$t('Desktop-zoom-tagComptable')">
                           <template v-slot:read>
                               <span >
                                    {{getEquipement.accountingTag}}
                               </span>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-contrats')">
                           <template v-slot:read>
                               <span >
                                   <ul style="list-style-type: none;">
                                       <li v-for="contrat in getEquipement.contrats" @click="$router.push({ name: '_contrat_id', params: { id:contrat.id  } })">
                                           <a style="cursor: pointer;">{{contrat.name}}</a>
                                       </li>
                                   </ul>
                               </span>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-numeroImmobilisation')">
                           <template v-slot:read>
                               <span >
                                    {{getEquipement.numeroImmobilisation}}
                               </span>
                           </template>
                       </vg-libelle-editable>



                       <vg-libelle-editable :label="$t('Desktop-zoom-ValeurAchat')">
                           <template v-slot:read>
                               <span v-if="getEquipement.valeurAchat">
                                    {{getEquipement.valeurAchat}} {{$app.devise}}
                               </span>
                               <span v-else>-</span>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-tauxDepreciation')">
                           <template v-slot:read>
                               <span >
                                    {{getEquipement.tauxDepreciationAnnuel}} %
                               </span>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-valeurActualisee')">
                           <template v-slot:read>
                               <span v-if="valeurActualisee">
                                    {{valeurActualisee}} {{$app.devise}}
                               </span>
                               <span v-else>-</span>
                           </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('Desktop-zoom-duree-amortissement')">
                           <template v-slot:read>
                               <span >
                                    {{dureeAmortissement}}
                               </span>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('Desktop-zoom-sourceFinancement')">
                           <template v-slot:read>
                               <span >
                                    {{getEquipement.sourceFinancement}}
                               </span>
                           </template>
                       </vg-libelle-editable>

                   </template>
               </vg-collapse>

               <vg-collapse :title="$t('Desktop-zoom-documentsAttaches')"
                   :collapseByDefault="false"
                   :type="'section'">
                   <template v-slot:pretitle>
                       <vg-button v-if="!isUploaderVisible" v-show="can('EQUIPEMENT.UPLOAD_DOCUMENT')"
                           @click="isUploaderVisible = true"
                           size="md" type="default-round">
                           <i class="fas fa-plus"></i>
                       </vg-button>

                       <vg-button v-else v-show="can('EQUIPEMENT.UPLOAD_DOCUMENT')"
                           @click="isUploaderVisible = false"
                           size="md" type="default-round">
                           <i class="fas fa-minus" ></i>
                       </vg-button>
                   </template>
                   <template v-slot:content>
                       <vg-files-uploader v-if="isUploaderVisible"
                           :tagsList="VgFilesMixins.tags"
                           @upload-done="VgFilesMixins_fetchFilesByUid();isUploaderVisible=!isUploaderVisible;">
                       </vg-files-uploader>

                       <vg-files-viewer-list-section
                           :files="VgFilesMixins_filesListSection"
                           @delete="VgFilesMixins_fetchFilesByUid()">
                       </vg-files-viewer-list-section>
                   </template>
               </vg-collapse>
           </template>
           <template #primary v-else>
               <div v-if="getEquipement && !showHistorique">
                   <vg-tabs :tabs="tabs" :color="'violet'" @tabClicked="handleTabClick" style="margin-bottom:10px;"></vg-tabs>
                   
                   <div id="caracteristiques-tab" v-show="currentTab == 'caracteristiques'">
                       <vg-collapse v-if="getEquipement.sortieEquipement"
                            :title="$t('Desktop-zoom-sortie')"
                            :type="'section'"
                            :collapseByDefault="false">
                           <template #content>
                               <vg-libelle-editable :label="$t('Desktop-zoom-date-sortie')">
                                  <template #read>
                                      <vg-datetime-viewer v-model="getEquipement.sortieEquipement.dateSortie" />
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">
                               <vg-libelle-editable :label="$t('Desktop-zoom-par')">
                                  <template #read>
                                      <span>{{getEquipement.sortieEquipement.user.nom}} {{getEquipement.sortieEquipement.user.prenom}}</span>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">
                               <vg-libelle-editable :label="$t('Desktop-zoom-type-sortie')">
                                  <template #read>
                                      <u>{{getEquipement.sortieEquipement.type}}</u>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin" v-if="getEquipement.sortieEquipement.equipementRemplacant_id">
                               <vg-libelle-editable :label="$t('Desktop-zoom-equipement-remplacant')"
                                    v-if="getEquipement.sortieEquipement.equipementRemplacant_id">
                                  <template #read>
                                      <vg-equipement-viewer :equipement="getEquipement.sortieEquipement.equipementRemplacant"/>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin" v-if="getEquipement.sortieEquipement.document && getEquipement.sortieEquipement.document.id">
                               <vg-libelle-editable :label="$t('Desktop-zoom-document-sortie')"
                                    v-if="getEquipement.sortieEquipement.document && getEquipement.sortieEquipement.document.id">
                                  <template #read>
                                      <vg-button type="grey" size="sm">
                                          <vg-files-viewer-document
                                          :file="getEquipement.sortieEquipement.document"
                                          noDownload/>
                                      </vg-button>
                                  </template>
                               </vg-libelle-editable>
                               <vg-button v-if="getEquipement.sortieEquipement.equipementRemplacant_id" type="info" style="width:100%;" @click="openZoomEquipementRemplacant">
                                   {{$t("voir-equipement-remplacant")}}
                               </vg-button>
                           </template>
                       </vg-collapse>
                       <vg-collapse :title="$t('caracteristiques')"
                            :collapseByDefault="false"
                            type="section">
                            <template #content>
                                <vg-libelle-editable :label="$t('hors-service')">
                                  <template v-slot:read>
                                    <vg-tag v-if="getEquipement.isHorsService" :label="$t('hors-service')" color="red"/>
                                    </template>
                                </vg-libelle-editable>
                                <hr class="no-margin">
                               <vg-libelle-editable :label="$t('Desktop-zoom-libelle')">
                                  <template v-slot:read>
                                      <span >
                                          <b> {{getEquipement.libel_equipement}} </b>                       
                                        </span>
                                    </template>
                                </vg-libelle-editable>
                                <hr class="no-margin">
                               <vg-libelle-editable :label="$t('batch-number')">
                                   <template v-slot:read>
                                       <span >
                                           <b> {{getEquipement.batchNumber}} </b>
                                       </span>
                                   </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">
                               <vg-libelle-editable :label="$t('Desktop-zoom-marque')">
                                  <template v-slot:read>
                                      <span >
                                          <b> {{getEquipement.marque}} </b>
                                      </span>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('Desktop-zoom-refConstructeur')">
                                   <template v-if="getEquipement.categorie.reference_constructeur_par_defaut" v-slot:read><span > {{getEquipement.categorie.reference_constructeur_par_defaut}} </span></template>
                                   <template v-else v-slot:read><span > {{getEquipement.refConstructeur}} </span></template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('Desktop-zoom-localisation')">
                                  <template v-slot:read>
                                      <span>
                                          <vg-lieu-path-viewer v-model="getEquipement.lieu.path"></vg-lieu-path-viewer>
                                      </span>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('Desktop-zoom-categorie')">
                                  <template v-slot:read>
                                      <span > {{ getEquipement.categorie.libelle }} </span>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('Mobile-zoom-refConstructeur')">
                                  <template v-slot:read>
                                      <span > {{ getEquipement.refConstructeur }} </span>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('Desktop-zoom-fournisseur')">
                                  <template v-slot:read>
                                      <span v-if="getEquipement.tiers"> {{ getEquipement.tiers.name }} </span>
                                      <span v-else> - </span>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('Mobile-zoom-numSerie')">
                                  <template v-slot:read>
                                      <span > {{ getEquipement.num_serie }} </span>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('Mobile-zoom-caracTechniques')">
                                  <template v-slot:read>
                                      <span > {{ getEquipement.carac_techniques }} </span>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('zoom-numeroImmobilisation')">
                                  <template v-slot:read>
                                      <span > {{ getEquipement.numeroImmobilisation }} </span>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('Desktop-zoom-type')">
                                  <template v-slot:read>
                                      <span > {{ getEquipement.equipementType }} </span>
                                  </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">
                               
                                <vg-libelle-editable :label="$t('statut-equipement')" 
                                    v-show="getEquipement.categorie && getEquipement.categorie.typeSetStatutEquipement">
                                    <template #read>
                                        <div style="display: flex;justify-content: space-between;align-items: center;gap: 20px;width:100%;">
                                            <span>{{ getEquipement.statutVerification }}</span>
                                            <vg-button type="danger" size="sm" 
                                                @click="showUpdateStatut=true;">
                                                {{ $t("update-statut") }}
                                            </vg-button>
                                        </div>
                                    </template>
                                </vg-libelle-editable>
                               <hr class="no-margin">

                           </template>
                        </vg-collapse>
                        <br>
                        <vg-collapse :title="$t('informations-complementaires')"
                            type="section">
                           <template #content>
                               <vg-libelle-editable :label="$t('Desktop-zoom-recensement')">
                                   <template v-slot:read>
                                       <vg-datetime-viewer v-model="getEquipement.created_at"/>
                                   </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('Desktop-zoom-dateMiseEnService')">
                                   <template v-slot:read>
                                       <vg-datetime-viewer v-model="getEquipement.miseEnService" hideTime/>
                                   </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('Desktop-zoom-dateSortie')">
                                   <template v-slot:read>
                                        <vg-datetime-viewer v-model="getEquipement.date_sortie" hideTime/>
                                   </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('date-expiration-garantie')">
                                   <template v-slot:read>
                                        <vg-datetime-viewer v-model="getEquipement.dateExpirationGarantie" hideTime/>
                                   </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('date-remplacement-previsionnelle')">
                                   <template v-slot:read>
                                        <vg-datetime-viewer v-model="getEquipement.dateRemplacementPrevisionelle" hideTime/>
                                   </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable :label="$t('duree-de-vie-previsionnelle')">
                                   <template v-slot:read>
                                       <span >
                                           <b> {{getEquipement.dureeExpirationGarantie}} </b>
                                       </span>
                                   </template>
                               </vg-libelle-editable>
                               <hr class="no-margin">

                               <vg-libelle-editable v-if="documentSortieEquipement" :label="documentSortieEquipement.tagLabel">
                                   <template v-slot:read>
                                       <vg-files-viewer-document
                                           :file="documentSortieEquipement">
                                       </vg-files-viewer-document>
                                   </template>
                               </vg-libelle-editable>
                           </template>
                       </vg-collapse>
                       <br>
                       <vg-files-viewer-photo
                          :files="VgFilesMixins_filesOnlyPhoto">
                       </vg-files-viewer-photo>
                   </div>

                   <div v-if="currentTab == 'historiques'">
                        <vg-collapse :title="$t('historique-maintenances')"
                            type="section"
                            collapseByDefault="true">
                            <template #pretitle>
                                <img src="/static/assets/icone/fiche-de-maintenance.png" style="width:20px;height:20px;"/>
                            </template>
                            <template #content>
                                <vg-fichesav-table
                                    :idTableau="'vg-maintenances-zoom-equipement'"
                    				:filters="getMaintenancesFilters"
                                    :counterName="'filtered'"
                                    style="height:50vh;"/>
                            </template>
                        </vg-collapse>
                        <br>
                        <vg-collapse :title="$t('historique-verifications')"
                            type="section"
                            collapseByDefault="true">
                            <template #pretitle>
                                <img src="/static/assets/icone/verification.png" style="width:20px;height:20px;"/>
                            </template>
                            <template #content>
                                <vg-verifications-viewer
                    				:filters="getVerificationsFilters" 
                                    style="height:50vh;"/>
                            </template>
                        </vg-collapse>
                        <br>
                        <vg-collapse :title="$t('historique-interventions')"
                            type="section"
                            collapseByDefault="true">
                            <template #pretitle>
                                <img src="/static/assets/icone/intervention.png" style="width:20px;height:20px;"/>
                            </template>
                            <template #content>
                                <vg-interventions-table
                                    :agFilters="getInterventionsFilters"
                                    :equipementId="getEquipement.id"
                                    :equipement="getEquipement"
                                    isPeriodiques
                                    :idTableau="'vg-interventions-periodiques-table'"
                                    @row-dbl-click="onOpenInterventionsPeriodique" 
                                    style="height:50vh;"/>
                            </template>
                        </vg-collapse>
                   </div>
                   <div id="documents-tab" v-if="currentTab == 'documents'">
                       <vg-button size="sm" @click="isUploaderVisible?isUploaderVisible=false:isUploaderVisible=true;" v-show="can('EQUIPEMENT.UPLOAD_DOCUMENT')">
                          <i class="fas fa-file-import"></i>
                           {{ $t("zoom-attacherDocument") }}
                       </vg-button>

                       <vg-files-uploader v-if="isUploaderVisible" v-show="can('EQUIPEMENT.UPLOAD_DOCUMENT')"
                       	:tagsList="VgFilesMixins.tags"
                       	@upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;">
                       </vg-files-uploader>

                       <vg-files-viewer-list-section
                       	:files="VgFilesMixins_filesListSection"
                       	@delete="VgFilesMixins_fetchFilesByUid()">
                       </vg-files-viewer-list-section>
                   </div>

                   <div id="consommables-tab" v-if="currentTab == 'consommables'">
                       <vg-consommables-viewer
                       v-model="consommablesEquipement"
                       @refresh-consommables="handleRefreshConsommables">
                       </vg-consommables-viewer>
                   </div>

                   <div id="consommables-tab" v-if="currentTab == 'taches'">
                       <vg-equipement-actives-taches>
                       </vg-equipement-actives-taches>
                   </div>
               </div>

                <vg-equipement-historique v-if="getEquipement && showHistorique"
                    v-model="getEquipement"
                    :activate="historiqueMode"
                    isEquipement />
           </template>

           <template  #secondary>
               <div v-if="getEquipement">
                   <vg-collapse v-if="getEquipement.sortieEquipement"
                        :title="$t('Desktop-zoom-sortie')"
                        :type="'section'"
                        :collapseByDefault="false">
                       <template #content>
                            <div style="display:flex;justify-content:flex-start;align-items:flex-start;gap: 10px;">
                                <div style="display:flex;flex-direction:column;margin-right:20px;">
                                   <vg-datetime-viewer v-model="getEquipement.sortieEquipement.dateSortie"/>
                                   <small>{{$t("par")}}: {{getEquipement.sortieEquipement.user.nom}} {{getEquipement.sortieEquipement.user.prenom}}</small>
                                </div>
                                <span><u>{{getEquipement.sortieEquipement.type}}</u></span>
                                <vg-equipement-viewer :equipement="getEquipement.sortieEquipement.equipement"/>
                                <span v-if="getEquipement.sortieEquipement.equipementRemplacant_id">{{$t("par")}}</span>
                                <vg-equipement-viewer v-if="getEquipement.sortieEquipement.equipementRemplacant_id"
                                    :equipement="getEquipement.sortieEquipement.equipementRemplacant"/>
                                <vg-button v-if="getEquipement.sortieEquipement.document && getEquipement.sortieEquipement.document.id"
                                    type="grey" size="sm">
                                    <vg-files-viewer-document
                                        :file="getEquipement.sortieEquipement.document"
                                        noDownload/>
                                </vg-button>
                                <vg-button v-if="getEquipement.sortieEquipement.equipementRemplacant_id"
                                    type="info" size="sm" @click="openZoomEquipementRemplacant">
                                    {{$t("voir-equipement-remplacant")}}
                                </vg-button>
                            </div>
                       </template>
                   </vg-collapse>
                   <vg-collapse :title="$t('Desktop-zoom-historique')"
                       :collapseByDefault="false"
                       :type="'section'"
                       >
                       <template v-slot:content>

                           <vg-equipement-historique v-if="getEquipement"
                               v-model="getEquipement" 
                               isEquipement />

                       </template>
                   </vg-collapse>
                <vg-collapse
                        :title="$t('section-log-equipement')"
                        :type="'section'"
                        :collapseByDefault="false">
                       <template #content>
                            <div style="display:flex;justify-content:flex-start;align-items:flex-start;gap: 10px;height:150px;overflow:auto;">
                                <ul class="flat-list">

                                    <li v-for="log in getEquipement.logs" >
                                        <div class="flat-list-item-tail"></div>
                                        <div class="flat-list-item-node flat-list-item-node-normal" ><!----></div>
                                        <div class="flat-list-item-wrapper" style="font-size:12px;">
                                            <div class="flat-list-item-content">
                                                <div v-if="log.field_updated == 'isHorsService'" >
                                                    <span v-if="parseInt(log.new_value) == 1 " style="color:red"> {{ $t("hors-service") }} </span>
                                                    <span v-else style="color:#059CFF;"> {{  $t("remis-en-service") }} </span>
                                                </div>
                                                <div v-else>
                                                    <span>{{ $t("modification-de") }} : <b>{{ $t(log.field_updated) }}</b>  <span v-if="log.old_value && log.old_value.length!=0" style="color:#059CFF;">{{ log.old_value }}</span> à <span style="color:#059CFF;">{{ log.new_value }}</span>.</span>
                                                    <span v-if="log.commentaire"> {{ $t("commentaire") }} : {{  log.commentaire }} </span>
                                                </div>
                                            </div>
                                            <div class="flat-list-item-bottom"> {{ $t("le") }} <vg-datetime-viewer v-model="log.created_at" useDeviceTimezone/> </div>
                                        </div>
                                    </li>
                                    <!-- Add more list items as needed -->
                                </ul>
                            </div>
                       </template>
                   </vg-collapse>

                   <br>
                   <vg-collapse :title="$t('synthese-valeur-cout')" v-if="getEquipement" v-show="can('EQUIPEMENT.READ_SYNTHESE')"
                       :collapseByDefault="false"
                       :type="'section'"
                       >
                       <template v-slot:content>
                           <vg-equipement-donnees-financiere/>
                       </template>
                   </vg-collapse>
                   <br>

                   <vg-collapse :title="$t('Desktop-zoom-photos')"
                       :collapseByDefault="false"
                       :type="'section'"
                       >
                       <template v-slot:content>

                           <vg-files-viewer-photo
                               :files="VgFilesMixins_filesOnlyPhoto" />

                       </template>
                   </vg-collapse>
                   <br>

                   <vg-collapse :title="$t('Desktop-section-consommations')"
                       :collapseByDefault="false"
                       :type="'section'"
                       >
                       <template v-slot:content>
                           <vg-consommable-mouvements-table
                                :filters="getConsommableMouvementsFilters" />
                       </template>
                   </vg-collapse>
                   <br>

                   <vg-collapse :title="$t('Desktop-section-consommables')" v-show="can('EQUIPEMENT.READ_CONSOMMABLES')"
                       :collapseByDefault="false"
                       :type="'section'"
                       >
                       <template #titlecomplement>
                           <vg-button :type="'default-info'" @click="showModalCreateConsommable=true" style="margin-right:10px;">
                               {{ $t("Desktop-section-consommables-nouveau") }}
                           </vg-button>

                           <vg-button :type="'default-info'" @click="openAttacherConsommablesModal">
                               {{ $t("Desktop-section-consommables-attacher") }}
                           </vg-button>
                       </template>
                       <template v-slot:content>
                           <vg-consommables-viewer
                               v-model="consommablesEquipement">
                           </vg-consommables-viewer>
                       </template>
                   </vg-collapse>

                   <vg-collapse :title="$t('Desktop-section-mouvements')"
                       :collapseByDefault="false"
                       :type="'section'"
                       >
                       <!--template #titlecomplement>
                           <vg-button :type="'default-info'" @click="showModalCreateConsommable=true" style="margin-right:10px;">
                               {{ $t("Desktop-section-consommables-nouveau") }}
                           </vg-button>

                           <vg-button :type="'default-info'" @click="openAttacherConsommablesModal">
                               {{ $t("Desktop-section-consommables-attacher") }}
                           </vg-button>
                       </template-->
                       <template v-slot:content>
                        <mouvements-equipements-table 
                            style="height:500px;" 
                            :filters="agFiltersMouvementsEquipement"
                            v-show="!displayMouvementEquipementsForm && !displayMouvementEquipementsBS"
                            @row-click="showMouvement"
                        ></mouvements-equipements-table>
                       </template>
                   </vg-collapse>

               </div>
           </template>

           <template #footer v-if="$vgutils.isMobile() && !getEquipement.sortieEquipement">

                    <vg-button v-if="currentTab == 'consommables'" v-show="can('EQUIPEMENT.CREATE_CONSOMMABLE')"
                    	:type="'default-link'"
                    	@click="showModalCreateConsommable=true"
                    >
                    	<i class="fas fa-plus"></i>
                        {{ $t("Desktop-section-consommables-nouveau") }}
                    </vg-button>

                    <vg-button v-if="currentTab == 'consommables'" v-show="can('EQUIPEMENT.ATTACHER_CONSOMMABLE')"
                    	:type="'default-link'"
                    	@click="openAttacherConsommablesModal">
                    	<i class="fas fa-plus"></i>
                        {{ $t("Desktop-section-consommables-attacher") }}
                    </vg-button>

                    <vg-button v-if="currentTab == 'caracteristiques'" v-show="can('EQUIPEMENT.UPDATE')"
                    	:type="'default-link'"
                    	@click="showUpdateEquipementForm=true"
                        style="height: 100%;">
                    	<i class="fas fa-pen"></i> {{$t("update")}}
                    </vg-button>

                    <vg-files-uploader v-if="currentTab != 'consommables'" v-show="can('EQUIPEMENT.UPLOAD_DOCUMENT')"
                    	:isOnlyPhoto="true"
                    	@upload-done="VgFilesMixins_fetchFilesByUid()"
                        style="height: 100%;"
                    >
                    </vg-files-uploader>

                    <vg-button v-if="getEquipement.taches.length && currentTab != 'consommables'" v-show="can('EQUIPEMENT.CHECKER')"
                    	:type="'default-link'"
                    	@click="goToCheckEquipement()"
                        style="height: 100%;font-size:11px;">
                    	{{$t("mobile-zoom-check-equipement")}}
                    </vg-button>

                    <vg-button v-if="currentTab != 'consommables'" v-show="can('EQUIPEMENT.CREATE_MAINTENANCE')"
                    	:type="'default-link'"
                    	@click="showModal.createfm = true"
                        style="height: 100%;font-size:11px;">
                    	<img src="static/assets/icone/fiche-de-maintenance.png" alt="icone fiche de maintenance" width="15" height="15"> {{ $t("Mobile-zoom-creationFM") }}
                    </vg-button>
                    
                    <vg-button v-if="currentTab != 'consommables'" v-show="can('EQUIPEMENT.CREATE_INTERVENTION_PREVENTIVE')"
                        type="default-link" 
                        @click="showModal.actions = true;"
                        style="height: 100%;font-size:11px;white-space:nowrap;">
                        <i class="fas fa-plus-circle"></i> {{$t("btn-footer-actions")}}
                    </vg-button>

                    <vg-button v-if="currentTab != 'consommables'" v-show="can('EQUIPEMENT.CREATE_DEMANDE_INTERVENTION_MOBILE')"
                    	:type="'default-link'"
                    	@click="goToDemandeIntervention"
                        style="height: 100%;font-size:11px;">
                    	<img src="static/assets/icone/fiche-de-maintenance.png" alt="icone fiche de maintenance" width="20" height="20"> {{ $t("demande-intervention") }}
                    </vg-button>

           </template>

    </vg-app-layout>

    <vg-equipement-statut-form v-if="showUpdateStatut"
        :categorie="getEquipement.categorie"
        @close="showUpdateStatut=false;" 
        @save="showUpdateStatut=false;fetch();" />

    <replace-qr-code-form v-if="showReplaceQrcode"
        :equipementId="getEquipement.id"
        @close="showReplaceQrcode = false">
    </replace-qr-code-form>

	<vg-equipement-form v-if="showUpdateEquipementForm"
        v-model="getEquipement"
		@close="showUpdateEquipementForm=false;"
		@save="onSaveUpdateEquipement">
	</vg-equipement-form>

    <vg-equipement-sortie-form

        v-if="openSortieEquipement"
        :equipement="getEquipement"
        @close="openSortieEquipement=false"
        @save="onSaveSortie">
    </vg-equipement-sortie-form>

    <vg-maintenance-form v-if="showModal.createfm"
        @close="showModal.createfm=false;"
        @created="showModal.createfm=false;"
        @open-maintenance="openMaintenance" />

	<vg-consommable-form v-if="showModalCreateConsommable"
		v-model="ConsommablesMixins.newConsommable"
		:isSaveUnable="ConsommablesMixins_isSaveDisabled"
		@close="showModalCreateConsommable=false;"
		@save="showModalCreateConsommable=false;" />

	<vg-consommables-equipement-modal v-if="showModalGererConsommables"
		v-model="consommables"
		:idEquipement="getEquipement.id"
		@close="showModalGererConsommables=false;"
		@refresh-consommables="handleRefreshConsommables">
	</vg-consommables-equipement-modal>

    <vg-equipement-etiquetage v-if="displayEtiquetage"
        @close="displayEtiquetage = false" 
        @save="onSaveNouvelleEtiquette" />
    
    <vg-intervention-form v-if="showModal.createIntervention"
        :isPeriodique="true"
        :defaultSelectedEquipements="selectedEquipements"
        :defaultStatut="isInterventionSansContrat?'intervention-realisee':'intervention-prevue'"
        :isSansContrat="isInterventionSansContrat"
        :canBeCreatedWithoutContrat="!isInterventionSansContrat"
        @close="showModal.createIntervention=false;"
        @save="showModal.createIntervention=false;" />

    <vg-modal v-if="showModal.actions"
        :title="$t('title-modal-actions')"
        @close="showModal.actions=false;">
        <template #body>
            <div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;gap:10px;">
                <vg-button 
                    type="info"
                    style="width:100%;"
                    @click="showUpdateStatut=true;showModal.actions=false;">
                    {{ $t("update-statut") }}
                </vg-button>
                <vg-button v-if="getEquipement.etiquetter != '1' && $vgutils.isMobile()"
                    type="info"
                    style="width:100%;"
                    @click="displayEtiquetage=true;showModal.actions=false;">
                    {{$t('etiquetter')}}
                </vg-button>
                <vg-button v-if="!getEquipement.sortieEquipement && $vgutils.isMobile()" 
                    v-show="can('EQUIPEMENT.REMPLACER_QR_CODE')"
                    @click="showReplaceQrcode = true;showModal.actions=false;"
                    type="info"
                    style="width:100%;">
                    {{$t("menuMore-replaceQrCode")}}
                </vg-button>
                <vg-button v-if="$app.role=='ROLE_ADMIN'  && !getEquipement.sortieEquipement" 
                    v-show="can('EQUIPEMENT.DEPLACER')"
                    @click="goToDeplacement"
                    type="info"
                    style="width:100%;">
                    {{ $t("Desktop-btn-deplacer") }}
                </vg-button>
                <vg-button v-if="$app.role=='ROLE_ADMIN'  && !getEquipement.sortieEquipement" 
                    v-show="can('EQUIPEMENT.SORTIR')"
                    @click="openSortieEquipement=true;showModal.actions=false;"
                    type="info"
                    style="width:100%;">
                    {{ $t("Desktop-zoom-sortir") }}
                </vg-button>
            </div>
            <br>
            <vg-collapse :title="$t('modal-actions-collapse-interventions')"
                :collapseByDefault="false">
                <template #content>
                    <div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;gap:10px;">
                        <vg-button 
                            @click="showModal.createIntervention = true;showModal.actions=false;" 
                            type="info"
                            style="width:100%;">
                            {{$t('Mobile-zoom-creation-intervention-sans-contrat')}}
                        </vg-button>
                        <vg-button 
                            @click="showModal.createIntervention = true;showModal.actions=false;" 
                            type="info"
                            style="width:100%;">
                            {{$t('Mobile-zoom-programmer-intervention-sans-contrat')}}
                        </vg-button>
                        <vg-button v-if="$vgutils.isMobile()"
                            @click="handleShowHistorique('interventions-periodiques')"
                            type="info"
                            style="width:100%;">
                            {{$t("voir-historique-interventions-periodiques")}}
                        </vg-button>
                        <vg-button v-if="$vgutils.isMobile()"
                            @click="handleShowHistorique('interventions-ponctuelles')"
                            type="info"
                            style="width:100%;">
                            {{$t("voir-historique-interventions-ponctuelles")}}
                        </vg-button>
                    </div>
                </template>
            </vg-collapse>
        </template>
        <template #footer>
            <vg-button @click="showModal.actions=false;" type="default-info">
                {{$t('btn-footer-close')}}
            </vg-button>
        </template>
    </vg-modal>

</div>

</template>
<script>


	import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';

    import VgCollapse from 'src/components/Vg/VgCollapse.vue';
	import VgButton from 'src/components/Vg/VgButton.vue';
	import VgDatetimeViewer from 'src/components/Vg/VgDatetimeViewer.vue';
    import VgTabs from 'src/components/Vg/VgTabs.vue';
    import VgTag from 'src/components/Vg/VgTag.vue';
    import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
    import VgInput from 'src/components/Vg/VgInput.vue';
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgTextMultiligne from 'src/components/Vg/VgTextMultiligne.vue';

    import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
    import MouvementViewer from "src/components/Vg/MouvementsEquipements/MouvementViewer.vue";

	import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
	import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
	import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
	import VgFilesViewerDocument from "src/components/Vg/Files/VgFilesViewerDocument.vue";

	import VgConsommablesViewer from "src/components/Vg/Consommables/VgConsommablesViewer.vue";
	import VgConsommableForm from 'src/components/Vg/Forms/VgConsommableForm.vue';
	import VgConsommablesEquipementModal from 'src/components/Vg/Consommables/VgConsommablesEquipementModal.vue';
	import VgConsommableMouvementsTable from 'src/components/Vg/Consommables/VgConsommableMouvementsTable.vue';

	import VgVerificationsViewer from 'src/components/Vg/Verification/VgVerificationsViewer.vue';
	import VgInterventionsTable from 'src/components/Vg/Interventions/VgInterventionsTable.vue';
    import VgFichesavTable from 'src/components/Vg/FicheSAV/VgFichesavTable.vue';
	import VgEquipementHistorique from 'src/components/Vg/Equipements/VgEquipementHistorique.vue';
	import VgEquipementDonneesFinanciere from 'src/components/Vg/Equipements/VgEquipementDonneesFinanciere.vue';

	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
    import equipementsMixins from "src/mixins/equipementsMixins.js";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";

    import VgEquipementForm from "src/components/Vg/Forms/VgEquipementForm.vue";
    import VgEquipementSortieForm from "src/components/Vg/Forms/VgEquipementSortieForm.vue";
    import ReplaceQrCodeForm from 'src/components/Vg/Forms/ReplaceQrCodeForm.vue';
    import VgMaintenanceForm from 'src/components/Vg/Forms/VgMaintenanceForm.vue';
    import VgEquipementEtiquetage from "src/components/Vg/Equipements/VgEquipementEtiquetage.vue"
    import VgEquipementStatutForm from "src/components/Vg/Forms/VgEquipementStatutForm.vue"
    import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue"

    import VgEquipementActivesTaches from 'src/components/Vg/Equipements/VgEquipementActivesTaches.vue';
    import MouvementsEquipementsTable from 'src/components/Vg/MouvementsEquipements/MouvementsEquipementsTable.vue';

    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
    export default {
        name: 'equipement',
        i18n:{
            messages: {
                "fr": {
                    "tab-historiques": "Historiques",
                    "historique-maintenances": "Fiches curatives",
                    "historique-verifications": "Préventif",
                    "historique-interventions": "Interventions préventives tiers",
                    "informations-complementaires": "Informations complémentaires",
                    "duree-de-vie-previsionnelle":"Durée de vie ( en Mois )",
                    "taches":"Tâches",
                    "scan-requis":"Le scan du qrCode est requis",
                    "date-expiration-garantie":"Date expiration garantie ( prévisionnelle )",
                    "date-remplacement-previsionnelle":"Date remplacement ( prévisionnelle )",
                    "update":"Modifier",
                    "section-log-equipement":"Journal d'évènements de l'équipement",
                    "caracteristiques":"Caractéristiques",
                    "Desktop-header-titre": "Equipements",
                    "Desktop-header-bouton": "Nouvel équipement",
                    "Desktop-filtres-header": "Filtres",
                    "Desktop-filtres-localisation": "Localisation",
                    "Desktop-filtres-site": "Site",
                    "Desktop-filtres-batiment": "Bâtiment",
                    "Desktop-filtres-etage": "Etage",
                    "Desktop-filtres-pieces": "Pièces",
                    "Desktop-filtres-groupe_equipement": "Montrer groupe équipements",
                    "Desktop-filtres-etat_equipement": "Etat équipement",
                    "Desktop-filtres-caracteristiques": "Caractéristiques",
                    "Desktop-filtres-categorie": "Catégorie",
                    "Desktop-filtres-marque": "Marque",
                    "Desktop-filtres-numero_serie": "Numéro série",
                    "Desktop-filtres-type_categorie": "type catégorie",
                    "Desktop-table-header-dateVerification": "Date vérification",
                    "Desktop-table-header-emplacement": "Emplacement",
                    "Desktop-table-header-equipement": "Equipement",
                    "Desktop-table-header-numImmobilisation": "Numero comptable",
                    "Desktop-table-header-etat": "Etat",
                    "Desktop-table-header-QRcode": "QR Code",
                    "Desktop-table-header-caracteristiques": "Caractéristiques",
                    "Desktop-table-header-numSerie": "N° série",
                    "Desktop-table-header-refConstructeur": "Ref. constructeur",
                    "Desktop-table-header-gep": "GEP",
                    "Desktop-table-header-nonConformite": "Non-conformité",
                    "Desktop-table-header-autre": "Autre",
                    "Desktop-table-header-tag": "compte comptable",
                    "Desktop-table-header-tag-categorie": "Etiquettes catégorie",
                    "Desktop-table-header-sourceFinancement": "Source financement",
                    "Desktop-table-header-typeCategorie": "Type catégorie",
                    "Desktop-table-header-marque": "Marque",
                    "Desktop-table-header-valeurAchat": "Valeur achat",
                    "Desktop-table-header-categorie": "Catégorie",
                    "Desktop-modaleCreationFM-header": "Création d'une fiche curative sur cet équipement",
                    "Desktop-modaleCreationFM-equipement": "Equipement N°",
                    "Desktop-modaleCreationFM-urgent": "Urgent",
                    "Desktop-modaleCreationFM-enregistrer": "Enregistrer",
                    "Desktop-zoom-header-popoverRetour": "Retour vers le tableau des équipements",
                    "Desktop-zoom-header-titre": "Détail de l'équipement",
                    "Desktop-zoom-header-creerFM": "Créer une fiche curative",
                    "Desktop-zoom-numSerie": "Numéro de série",
                    "detail": "Détails",
                    "caracteristiques-techniques": "Caractéristiques techniques",
                    "Desktop-zoom-typeCategorie": "Type de la catégorie",
                    "Desktop-zoom-type": "Type",
                    "Desktop-zoom-etat": "Etat",
                    "Desktop-zoom-marque": "Marque",
                    "Desktop-zoom-fournisseur": "Fournisseur",
                    "Desktop-zoom-popover-modificationCaracteristiques": "Modifier les caractéristiques de l'équipement",
                    "Desktop-zoom-libelle": "Libellé",
                    "Desktop-zoom-gestionCalendaire": "Gestion calendaire",
                    "Desktop-zoom-gestionFinanciere": "Gestion financière",
                    "Desktop-zoom-popover-modificationInformationsFinancieres": "Modifier les informations financières",
                    "Desktop-zoom-documentsAttaches": "Documents attachés",
                    "Desktop-zoom-popover-attacherDocument": "Attacher un document à l'équipement",
                    "Desktop-zoom-champsPersonnalises": "Champs personnalisés",
                    "Desktop-zoom-sortie": "Détails sortie équipement",
                    "Desktop-zoom-historique": "Historique",
                    "Desktop-zoom-contrats": "Contrats",
                    "Desktop-zoom-chronologie": "Chronologie",
                    "Desktop-zoom-categorie": "Catégorie",
                    "Desktop-zoom-refConstructeur": "Référence constructeur",
                    "Desktop-zoom-tagComptable": "Tag comptable",
                    "Desktop-zoom-numeroImmobilisation": "Numéro comptable",
                    "Desktop-zoom-recensement": "Date du recensement",
                    "Desktop-zoom-qrCode": "QrCode",
                    "zoom--sorti": "Sorti",
                    "Desktop-zoom-posEtiquette": "Position étiquette",
                    "Desktop-zoom-isEtiqueter": "Etiquette collée ?",
                    "statut-equipement": "Statut équipement",
                    "update-statut": "Modifier statut",

                    "Desktop-zoom-dateSortie": "Date de sortie",
                    "Desktop-zoom-sortir": "SORTIR",
                    "Desktop-zoom-popover-sortirEquipement": "Sortir l'équipement",
                    "Desktop-zoom-localisation": "Localisation",
                    "Desktop-zoom-dateMiseEnService": "Date de mise en service",
                    "Desktop-zoom-ValeurAchat": "Valeur d'achat",
                    "Desktop-zoom-tauxDepreciation": "Taux de dépréciation",
                    "Desktop-zoom-valActualisee": "Valeur actualisée",
                    "Desktop-zoom-sourceFinancement": "Source de financement",
                    "Desktop-zoom-photos": "Photos",
                    "Desktop-zoom-popover-enregistrer": "Enregistrer",
                    "Desktop-zoom-popover-annuler": "Annuler",
                    "Desktop-zoom-imprimer": "Imprimer la fiche",
                    "Desktop-section-consommables": "Consommables / pièces détachées",
                    "Desktop-section-consommations": "Consommations",
                    "Desktop-section-consommables-nouveau": "Nouveau consommable",
                    "Desktop-section-consommables-attacher": "Attacher consommables",
                    "modaleRapportAssets-header": "Rapport assets",
                    "modaleRapportAssets-footer-btn-exporter": "Exporter",
                    "tableauRapportAssets-header-qrCode": "QR Code",
                    "tableauRapportAssets-header-libelleCategorie": "Libellé catégorie",
                    "tableauRapportAssets-header-libelleEquipement": "Libellé équipement",
                    "tableauRapportAssets-header-service": "Service",
                    "tableauRapportAssets-header-lieu": "Lieu",
                    "tableauRapportAssets-header-tiers": "Tiers",
                    "tableauRapportAssets-header-refConstructeur": "Ref. constructeur",
                    "tableauRapportAssets-header-numeroSerie": "Numéro de série",
                    "tableauRapportAssets-header-etat": "Etat",
                    "tableauRapportAssets-header-dateDebut": "Date début",
                    "tableauRapportAssets-header-dateFin": "Date fin",
                    "tableauRapportAssets-header-moisUtilisation": "Mois d'utilisation",
                    "tableauRapportAssets-header-valeurAchat": "Valeur d'achat",
                    "tableauRapportAssets-header-tauxDepreciation": "Taux de dépréciation",
                    "tableauRapportAssets-header-depreciationAnnuelle": "Dépréciation annuelle",
                    "tableauRapportAssets-header-depreciationMensuelle": "Dépréciation mensuelle",

                    "Mobile-header-titre": "Equipements",
                    "menuMore-scanEquip": "Scanner équipement",
                    "menuMore-scanLieu": "Scanner lieu pour voir les equipements contenus",
                    "modaleScan-header": "Scanner lieu ou équipement",
                    "Mobile-filtres-site": "Site …",
                    "Mobile-filtres-batiment": "Bâtiment …",
                    "filtre-recherche": "Rechercher",
                    "tableauRecensementLe": "Recensement le",
                    "tableauA": "à",
                    "Mobile-zoom-titre": "Détail",
                    "zoom-menuMore-histoVerif": "Voir historique préventif",
                    "zoom-menuMore-histoMaint": "Voir historique curatif",
                    "zoom-menuMore-deplacerEquipement": "Déplacer équipement",
                    "zoom-numeroImmobilisation": "Numéro comptable",
                    "zoom-recensementLe": "Recensement le",
                    "zoom-a": "à",
                    "Mobile-zoom-libelle": "Libellé",
                    "Mobile-zoom-categorie": "Catégorie",
                    "Mobile-zoom-typeCategorie": "Type",
                    "Mobile-zoom-marque": "Marque",
                    "Mobile-zoom-refConstructeur": "Ref cons.",
                    "Mobile-zoom-path": "Emplacement",
                    "Mobile-zoom-etat": "Etat",
                    "Mobile-zoom-caracteristiques": "Caractéristiques",
                    "Mobile-zoom-gestionCalendaire": "Gestion calendaire",
                    "Mobile-zoom-documentsAttaches": "Documents attachés",
                    "Mobile-zoom-consommables": "Consommables",
                    "Mobile-zoom-numSerie": "Numéro série",
                    "Mobile-zoom-dateAchat": "Date d'achat",
                    "Mobile-zoom-dureeGarantie": "Durée de garantie",
                    "Mobile-zoom-sousGarantie": "Sous garantie",
                    "Mobile-zoom-dateSortie": "Date de sortie",
                    "Mobile-zoom-dateRecensement": "Date de recensement",
                    "Mobile-zoom-dateMiseEnService": "Date de mise en service",
                    "Mobile-zoom-boutonPhoto": "Photo équipement",
                    "Mobile-zoom-creationFM": "Créer fiche curative",
                    "Mobile-zoom-creation-intervention-sans-contrat": "Créer intervention tiers préventive (sans contrat)",
                    "Mobile-zoom-programmer-intervention-sans-contrat": "Programmer intervention tiers préventive",
                    "mobile-zoom-check-equipement": "Check équipement",
                    "Mobile-zoom-modaleCreationFM-equipement": "Equipement n°",
                    "Mobile-zoom-caracTechniques": "Caractéristiques techniques",
                    "zoom-attacherDocument": "Attacher un document",
                    "histoMaint-titre": "Historique curatif",
                    "histoMaint-tableau-fm": "Fiche n°",
                    "histoMaint-tableau-resolue": "Résolue",
                    "histoMaint-tableau-enCours": "En cours",
                    "histoVerif-titre": "Historique préventif",
                    "histoVerif-tableau-a": "à",
                    "histo-tableau-vide": "Pas de données",
                    "histo-tableau-chargement": "Chargement",
                    "sauvegarde_fm": "Sauvegarde de la fiche curative ...",
                    "sauvegarde_donnees": "Sauvegarde des données ...",
                    "chargement_operations": "Chargement des opérations ...",
                    "Mobile-zoom-marque": "Marque",
                    "voir-historique-interventions-periodiques": "Voir intervention périodiques",
                    "voir-historique-interventions-ponctuelles": "Voir interventions ponctuelles",
                    "Desktop-btn-deplacer": "Déplacer",
                    "Desktop-zoom-valeurActualisee": "Valeur actualisée",
                    "Desktop-zoom-duree-amortissement":"Amortissement ( en années )",
                    "sorti":"Sorti",
                    "menuMore-replaceQrCode":"Remplacer qrCode",
                    "batch-number":"N° de lot",
                    "ref-document-externe":"Référence document externe",
                    "usage":"Usage",
                    "verification-preventive":"Tâche récurrente",
                    "synthese-valeur-cout":"Synthèse valeur/coûts fiches curatives",
                    "voir-equipement-remplacant": "Voir équipement remplaçant",
                    "Desktop-zoom-date-sortie": "Date sortie",
                    "Desktop-zoom-par": "Par",
                    "Desktop-zoom-type-sortie": "Type",
                    "Desktop-zoom-equipement-remplacant": "Equipement remplaçant",
                    "Desktop-zoom-document-sortie": "Document",
                    "demande-intervention": "Demande intervention",
                    "par": "par",
                    "etiquetter": "Coller étiquette",
                    "derniere-intervention-externe": "Dernière intervention externe",
                    "voir-intervention": "Voir intervention",
                    "Desktop-section-mouvements": "Mouvements",
                    "statutVerification": "Statut",
                    "modification-de": "Modification de",
                    "commentaire": "Commentaire",
                    "le": "le",
                    "mouvement-pret": "Sortie temporaire équipement (prêt)",
                    "btn-footer-actions": "Actions",
                    "title-modal-actions": "Actions",
                    "btn-footer-close": "Fermer",
                    "modal-actions-collapse-interventions": "Interventions",
                    "modal-actions-collapse-historiques": "Historiques",
                    "hors-service": "Hors service",
                    "remis-en-service": "Remis En Service"
                },
                "en": {
                    "informations-complementaires": "Additional information",
                    "ref-document-externe":"External document reference",
                    "duree-de-vie-previsionnelle":"Expected lifespan ( years )",
                    "taches":"Tasks",
                    "section-log-equipement":"Equipment event log",
                    "scan-requis":"qrCode scann is required",
                    "mobile-zoom-check-equipement": "Check equipment",
                    "date-expiration-garantie":"Warranty end date",
                    "date-remplacement-previsionnelle":"Provisional replacement date",
                    "update":"Update",
                    "caracteristiques":"Characteristics",
                    "documents":"Documents",
                    "consommables":"Spare parts",
                    "voir-historique-interventions-ponctuelles": "Show the one off interventions",
                    "voir-historique-interventions-periodiques": "Show the recurrent interventions",
                    "Desktop-section-consommations": "Consumptions",
                    "Desktop-section-consommables": "Consumables / Spare parts",
                    "Desktop-section-consommables-nouveau": "Add consumable",
                    "Desktop-section-consommables-attacher": "Link consumables to this equipement",
                    "Desktop-header-titre": "Equipment",
                    "Desktop-header-bouton": "New equipment",
                    "Mobile-zoom-caracTechniques": "Technical characteristics",
                    "Desktop-filtres-header": "Filters",
                    "Desktop-filtres-localisation": "Location",
                    "Desktop-filtres-site": "Site",
                    "Desktop-filtres-batiment": "Building",
                    "Desktop-filtres-etage": "Level",
                    "Desktop-filtres-pieces": "Rooms",
                    "Desktop-filtres-groupe_equipement": "Show equipment group",
                    "Desktop-filtres-etat_equipement": "Equipment status",
                    "Desktop-filtres-caracteristiques": "Technical characteristics",
                    "Desktop-filtres-categorie": "Category",
                    "Desktop-filtres-marque": "Brand",
                    "Desktop-filtres-numero_serie": "Series number",
                    "Desktop-filtres-type_categorie": "category type",
                    "Desktop-table-header-dateVerification": "Verification date",
                    "Desktop-table-header-emplacement": "Position",
                    "Desktop-table-header-equipement": "Equipment",
                    "Desktop-table-header-numImmobilisation": "Accounting number",
                    "Desktop-table-header-etat": "Status",
                    "Desktop-table-header-QRcode": "QR Code",
                    "Desktop-table-header-caracteristiques": "Technical characteristics",
                    "Desktop-table-header-numSerie": "Series n°",
                    "Desktop-table-header-refConstructeur": "Manufacturer ref.",
                    "Desktop-table-header-gep": "GEP ?",
                    "Desktop-table-header-nonConformite": "Non-compliant",
                    "Desktop-table-header-autre": "Other",
                    "Desktop-table-header-tag": "Accounting Tag",
                    "Desktop-table-header-tag-categorie": "category tags",
                    "Desktop-table-header-sourceFinancement": "Source of financing",
                    "Desktop-table-header-typeCategorie": "Category type",
                    "Desktop-table-header-marque": "Brand",
                    "Desktop-table-header-valeurAchat": "Purchase value",
                    "Desktop-table-header-categorie": "Category",
                    "Desktop-modaleCreationFM-header": "Creation of curative sheet for this equipment",
                    "Desktop-modaleCreationFM-equipement": "Equipment N°",
                    "Desktop-modaleCreationFM-urgent": "Urgent",
                    "Desktop-modaleCreationFM-enregistrer": "Save",
                    "Desktop-zoom-header-popoverRetour": "Go back to table of equipment ",
                    "Desktop-zoom-header-titre": "Equipment details",
                    "Desktop-zoom-header-creerFM": "Create maintenance sheet",
                    "Desktop-zoom-numSerie": "Serial number",
                    "detail": "Details",
                    "Desktop-zoom-typeCategorie": "Category type",
                    "Desktop-zoom-etat": "Condition",
                    "Desktop-zoom-marque": "Brand",
                    "Desktop-zoom-fournisseur": "Supplier",
                    "Desktop-zoom-popover-modificationCaracteristiques": "Amend equipment characteristics",
                    "Desktop-zoom-libelle": "Naming",
                    "Desktop-zoom-isEtiqueter": "Label code sticked ?",
                    "statut-equipement": "Equipment status",
                    "update-statut": "Update status",
                    "Desktop-zoom-gestionCalendaire": "Calendar management",
                    "Desktop-zoom-gestionFinanciere": "Financial management",
                    "Desktop-zoom-popover-modificationInformationsFinancieres": "Amend financial information",
                    "Desktop-zoom-documentsAttaches": "Attached documents",
                    "Desktop-zoom-popover-attacherDocument": "Attach a document to the equipment",
                    "Desktop-zoom-champsPersonnalises": "Customised fields",
                    "Desktop-zoom-historique": "Equipment's register",
                    "Desktop-zoom-chronologie": "Chronology",
                    "Desktop-zoom-categorie": "Category",
                    "Desktop-zoom-contrats": "Contracts",
                    "Desktop-zoom-refConstructeur": "Manufacturer reference",
                    "Desktop-zoom-tagComptable": "Accounting tag",
                    "Desktop-zoom-numeroImmobilisation": "Accounting number",
                    "Desktop-zoom-recensement": "date of inventory",
                    "Desktop-zoom-dateSortie": "Date of removal",
                    "Desktop-zoom-sortir": "Remove",
                    "Desktop-zoom-duree-amortissement":"Depreciation ( monthly )",
                    "Desktop-zoom-valeurActualisee": "Actual cash value",
                    "Desktop-zoom-popover-sortirEquipement": "Remove equipment ",
                    "Desktop-zoom-localisation": "Location",
                    "Desktop-zoom-dateMiseEnService": "Start date",
                    "Desktop-zoom-ValeurAchat": "Purchase value",
                    "Desktop-zoom-tauxDepreciation": "Annual depreciation",
                    "Desktop-zoom-valActualisee": "Discounted value",
                    "Desktop-zoom-sourceFinancement": "Funding source",
                    "Desktop-zoom-photos": "Photos",
                    "Desktop-zoom-popover-enregistrer": "Save",
                    "Desktop-zoom-popover-annuler": "Cancel",
                    "Desktop-zoom-qrCode": "QrCode",
                    "zoom--sorti": "Out",
                    "Desktop-zoom-posEtiquette": "Label position",
                    "Desktop-zoom-imprimer": "Print sheet",
                    "modaleRapportAssets-header": "Assets report",
                    "modaleRapportAssets-footer-btn-exporter": "Export",
                    "tableauRapportAssets-header-qrCode": "Asset Tag Number",
                    "tableauRapportAssets-header-libelleCategorie": "Category of Asset",
                    "tableauRapportAssets-header-libelleEquipement": "Full Description",
                    "tableauRapportAssets-header-service": "Section",
                    "tableauRapportAssets-header-lieu": "Location",
                    "tableauRapportAssets-header-tiers": "Supplier's Name",
                    "tableauRapportAssets-header-refConstructeur": "Model Number",
                    "tableauRapportAssets-header-numeroSerie": "Serial Number",
                    "tableauRapportAssets-header-etat": "Condition of Asset",
                    "tableauRapportAssets-header-dateDebut": "Start Date",
                    "tableauRapportAssets-header-dateFin": "End Date",
                    "tableauRapportAssets-header-moisUtilisation": "Months in use",
                    "tableauRapportAssets-header-valeurAchat": "Cost",
                    "tableauRapportAssets-header-tauxDepreciation": "Depreciation Rate",
                    "tableauRapportAssets-header-depreciationAnnuelle": "Annual Depreciation",
                    "tableauRapportAssets-header-depreciationMensuelle": "Monthly Depreciation",

                    "Mobile-header-titre": "Equipments",
                    "menuMore-scanEquip": "Scan equipment",
                    "menuMore-scanLieu": "Scan place to see the inside equipments",
                    "modaleScan-header": "Scan place or equipment",
                    "Mobile-filtres-site": "Site ...",
                    "Mobile-filtres-batiment": "Building ...",
                    "filtre-recherche": "Search",
                    "tableauRecensementLe": "Inventoried on",
                    "tableauA": "at",
                    "Mobile-zoom-titre": "Detail",
                    "zoom-menuMore-histoVerif": "Preventive logs",
                    "zoom-menuMore-histoMaint": "Curative logs",
                    "zoom-menuMore-deplacerEquipement": "Displace equipment",
                    "zoom-recensementLe": "Inventoried on",
                    "Mobile-zoom-etat": "state",
                    "zoom-a": "at",
                    "Mobile-zoom-libelle": "Label",
                    "Mobile-zoom-categorie": "Category",
                    "Mobile-zoom-typeCategorie": "Type",
                    "Mobile-zoom-marque": "Brand",
                    "Mobile-zoom-refConstructeur": "Ref",
                    "Mobile-zoom-path": "Location",
                    "Mobile-zoom-caracteristiques": "Technical characteristics",
                    "Mobile-zoom-gestionCalendaire": "Calendar management",
                    "Mobile-zoom-documentsAttaches": "Attached documents",
                    "zoom-numeroImmobilisation": "Accounting number",
                    "Mobile-zoom-numSerie": "Serial number",
                    "Mobile-zoom-dateAchat": "Purchase date",
                    "Mobile-zoom-dureeGarantie": "Warranty period",
                    "Mobile-zoom-sousGarantie": "Under warranty",
                    "Mobile-zoom-dateSortie": "Removal date",
                    "Mobile-zoom-dateRecensement": "Inventory date",
                    "Mobile-zoom-dateMiseEnService": "Start date",
                    "Mobile-zoom-boutonPhoto": "Equipment photo",
                    "Mobile-zoom-creationFM": "Create curative sheet",
                    "Mobile-zoom-modaleCreationFM-equipement": "Equipment n°",
                    "zoom-attacherDocument": "Attach file",
                    "histoMaint-titre": "Maintenances chronology",
                    "histoMaint-tableau-fm": "MS n°",
                    "histoMaint-tableau-resolue": "Closed",
                    "histoMaint-tableau-enCours": "Ongoing",
                    "histoVerif-titre": "Preventive logs",
                    "histoVerif-tableau-a": "at",
                    "histo-tableau-vide": "No data",
                    "histo-tableau-chargement": "Loading",
                    "Desktop-btn-deplacer": "Relocate",
                    "sauvegarde_fm": "Saving maintenance sheet ...",
                    "sauvegarde_donnees": "Saving data ...",
                    "sorti":"Out",
                    "chargement_operations": "Loading operations ...",
                    "menuMore-replaceQrCode":"Replace qrCode",
                    "batch-number":"Batch N°",
                     "usage":"Usage",
                    "verification-preventive":"Recurrent task",
                    "synthese-valeur-cout":"Value/Costs curative sheets synthesis",
                    "voir-equipement-remplacant": "Show replacement equipment",
                    "demande-intervention": "Intervention request",
                    "etiquetter": "Stick qrCode",
                    "derniere-intervention-externe": "Last external intervention",
                    "voir-intervention": "Show intervention",
                    "Desktop-section-mouvements": "Movements",
                    "statutVerification":"Status",
                    "modification-de": "Update of",
                    "commentaire": "Comment",
                    "le": "At",
                    "btn-footer-actions": "Actions",
                    "title-modal-actions": "Actions",
                    "btn-footer-close": "Fermer",
                    "hors-service": "Out of service",
                    "remis-en-service": "Back in service"
                }
	        }
        },
        mixins:[
            equipementsMixins,
            ConsommablesMixins
		],
        props: {
            target: Object,
            template: String,
        },
        components: {
			VgEquipementHistorique,
            VgFilesUploader,
            VgFilesViewerListSection,
            VgFilesViewerPhoto,
            VgLibelleEditable,
            VgCheckbox,
            VgButton,
            VgModal,
            VgTextMultiligne,
            VgEquipementViewer,
            VgInterventionsTable,
            VgConsommablesViewer,
            VgConsommableForm,
            VgConsommablesEquipementModal,
            VgFilesViewerDocument,
			VgLieuPathViewer,
			VgDatetimeViewer,
			VgEquipementForm,
			VgMaintenanceForm,
			VgEquipementSortieForm,
            VgCollapse,
            VgInput,
            VgTabs,
            ReplaceQrCodeForm,
            VgTag,
            VgConsommableMouvementsTable,
            VgEquipementDonneesFinanciere,
            VgEquipementActivesTaches,
            VgSelect,
            VgVerificationsViewer,
            MouvementViewer,
            VgFichesavTable,
            MouvementsEquipementsTable,
            VgEquipementEtiquetage,
            VgEquipementStatutForm,
            VgInterventionForm
		},
        data: function() {
            return {
                userId: this.$app.appID,
                openSortieEquipement:false,
                currentTab:"caracteristiques",
                showHistorique:false,
                historiqueMode: null,
                showReplaceQrcode:false,
                showModal: {
                    createfm: false,
                    createIntervention: false,
                    showOperation : false,
                    actions: false
                },
                isInterventionSansContrat: false,
                operationList:[],
        		isUploaderVisible: false,
        		files: [],
                carrouselVisible: true,
				isConsommableOnglet:false,
				isCaracteristiquesOnglet:true,
				showUpdateEquipementForm: false,
                consommables: [],
				consommablesEquipement: [],
                showModalCreateConsommable: false,
                showModalGererConsommables: false,
                documentSortieEquipement: null,
                agFiltersMouvementsEquipement:{
                    idEquipement:{ attr: "me.equipement_id",colId: "me.equipement_id", value: this.$route.params.id, action:"equals"}
                },
                displayEtiquetage: false,
                showUpdateStatut: false,
                showUpdateStatut: false
            };
        },
        methods: {
            openIntervention: function(){
                this.$router.push({ name: "_intervention", params: { id: this.getEquipement.lastInterventionExterne.id } });
            },
            onOpenInterventionsPeriodique: function(intervention){
				this.$router.push({name: "_intervention", params: { id: intervention.id }});
			},
            onSaveNouvelleEtiquette: function(){
                this.fetch();
                this.displayEtiquetage = false;
            },
            openZoomEquipementRemplacant: function(){
                this.$router.push({ name: "_equipement_id", params: { id: this.getEquipement.sortieEquipement.equipementRemplacant_id } });
            },
            copy:function(){
                  var copyText = this.$refs["batch-number-value"].value;
                  navigator.clipboard.writeText(copyText).then(function() {
                      /* presse-papiers modifié avec succès */
                       console.log(copyText)
                    }, function() {
                        console.log("error copyText")
                      /* échec de l’écriture dans le presse-papiers */
                    });
            },
            onSaveSortie: function(sortie){
            	this.openSortieEquipement = false;
            	this.fetch();
            },
            fetchConsommables: function(){
                let metadatas = new Metadatas();
                this.ConsommablesMixins_getConsommables(metadatas).then((consommables)=>{
                    this.consommablesEquipement.forEach((consommableEquipement)=>{
                        let equipementId = consommableEquipement.equipement_id;
                        let consommableId = consommableEquipement.id;
                        consommables.consommables.forEach((consommable)=>{
                            if(consommable.id == consommableId){
                                consommable.equipement_id = equipementId;
                            }
                        });
                    })

                    this.consommables=consommables.consommables;

                });
            },
            fetchConsommablesEquipement: function(){
            	this.ConsommablesMixins_getEquipementConsommables(this.getEquipement.id).then((consommables)=>this.consommablesEquipement=consommables);
            },
            openAttacherConsommablesModal: function(){
            	this.fetchConsommables();
            	this.showModalGererConsommables = true;
            },
            handleRefreshConsommables: function(){
            	this.fetchConsommables();
            	this.fetchConsommablesEquipement();
            	if(this.showModalCreateConsommable) this.showModalCreateConsommable = false;
            },
            goToDeplacement: function(){
                this.$router.push({ name: "_equipement_id_deplacement", params: { uid: this.getEquipement.uid } });
            },
            goToDemandeIntervention: function(){
                this.$router.push({name: "_demande_intervention", params: {code: this.getEquipement.qrCode , isScann: true}});
            },
            fetchDocumentsSortieEquipement: function(){
                this.VgFilesMixins_fetchFilesByUid(this.getEquipement.uid_sortie).then((files)=>{
                    if(files && files[0]) this.documentSortieEquipement = files[0];
                    this.$forceUpdate();
                });
            },
            openMaintenance: function(maintenance){
                this.showModal.createfm = false;
                this.$router.push({ name: "_maintenance", params: { id: maintenance.id } });
            },
            fetch: function(){
                this.equipementsMixins_getEquipement(this.$route.params.id).then((equipement)=>{
                    this.VgFilesMixins_init(equipement.uid, "equipement");
                    this.fetchConsommablesEquipement();
                    if(this.getEquipement.date_sortie && this.getEquipement.uid_sortie) this.fetchDocumentsSortieEquipement();
                });
            },
            handleClickBtnRetour: function(){
                if(this.showHistorique){
                    this.showHistorique = false;
                }else{
                    this.$router.back();
                }

            },
            goToCheckEquipement: function(){
                if(this.getEquipement.taches.length > 1){
                    this.$router.push({name:"_process_verification_taches_selection", params:{type:"equipement", equipementId:this.getEquipement.id} });

                }else if(this.getEquipement.taches.length == 1){
                    // TODO ne peut y aller que si la tâche autorise la vérif sans scanner
                    let tache = this.getEquipement.taches[0];
                    if(parseInt(tache.isScanRequired)){
                        this.$router.push({name:"_process_verification_equipement_selection", params:{type:"equipement"} });
                    }else{
                        this.$router.push({name:"_process_verification", params:{type:"equipement", equipementId:this.getEquipement.id, tacheId: tache.idTache} });
                    }
                }

            	//this.$router.push({ name: '_precheck_equipements', params: { equipement: {qrCode: this.getEquipement.qrCode}}});
            },
            onSaveUpdateEquipement: function(equipement){
            	this.showUpdateEquipementForm = false;
                this.fetch();
            },
            handleTabClick:function(e){
                this.currentTab = e.name;
            },
            handleShowHistorique:function(mode){
                this.historiqueMode = mode;
                this.showHistorique = true;
                this.showModal.actions = false;
            }
        },
        computed: {
            ...mapGetters({
                getEquipement: 'EquipementsStore/getSelectedItem'
            }),
            selectedEquipements: function(){
                if(this.getEquipement) return [this.getEquipement];
                else return [];
            },
            getMaintenancesFilters: function(){
                return [{ attr: "materiel_id", value: this.getEquipement.id, action: "equals"}];
            },
            getInterventionsFilters: function(){
                return [
                    {attr: "ie.equipement_id", value: this.getEquipement.id, action: "equals", openParenthesis: true},
                    {attr: "cc.categorie_id", value: this.getEquipement.categorie_id, action: "equals", closeParenthesis: true, logicalOperator: "OR"}
                ];
            },
            getVerificationsFilters: function(){
                return [{ attr: "equipement_id", value: this.getEquipement.id, action: "equals"}];
            },
            getConsommableMouvementsFilters: function(){
                return [
                    {attr: "ce.equipement_id", value: this.getEquipement.id, action: "equals", openParenthesis: true},
                    {attr: "fm.materiel_id", value: this.getEquipement.id, action: "equals", logicalOperator: "OR", closeParenthesis: true}
                ];
            },
            valeurActualisee: function(){
            	var miseEnService = this.getEquipement.miseEnService && this.getEquipement.miseEnService!=""?
            		this.getEquipement.miseEnService:this.getEquipement.miseEnServiceDefault;
            	var depreciation = this.getEquipement.tauxDepreciationAnnuel && this.getEquipement.tauxDepreciationAnnuel!=""?
            		this.getEquipement.tauxDepreciationAnnuel: this.getEquipement.tauxDepreciationAnnuelDefault;
            	var valeur = this.getEquipement.valeurAchat && this.getEquipement.valeurAchat!=""?
            		this.getEquipement.valeurAchat: this.getEquipement.prixDefault;

            	var mois = moment().diff(moment(miseEnService), "months");
                if(!miseEnService | !depreciation | !valeur)return "-";
            	var depreciationMensuelle = (Number(depreciation)/100*valeur)/12;
            	if(depreciationMensuelle*mois > valeur) return "0";
            	else return this.$vgutils.round2decimal( valeur - depreciationMensuelle*mois);
            },
            dureeAmortissement: function(){
            	var miseEnService = this.getEquipement.miseEnService && this.getEquipement.miseEnService!=""?
            		this.getEquipement.miseEnService:this.getEquipement.miseEnServiceDefault;
            	var depreciation = this.getEquipement.tauxDepreciationAnnuel && this.getEquipement.tauxDepreciationAnnuel!=""?
            		this.getEquipement.tauxDepreciationAnnuel: this.getEquipement.tauxDepreciationAnnuelDefault;
            	var valeur = this.getEquipement.valeurAchat && this.getEquipement.valeurAchat!=""?
            		this.getEquipement.valeurAchat: this.getEquipement.prixDefault;

            	var mois = moment().diff(moment(miseEnService), "months");
                var depreciationMensuelle = (Number(depreciation)/100*valeur)/12;
            	let dureeAmortissement = valeur / depreciationMensuelle;
                return dureeAmortissement ? Math.round(dureeAmortissement/12) : "-";
            },
            getHeaderColor:function(){
                return this.$vgutils.isMobile() ? '#7b54a0' : 'white';
            },
            tabs: function(){
                return [
                    {
                        label: this.$t("caracteristiques"),
                        name:"caracteristiques"
                    },
                    {
                        label: this.$t("tab-historiques"),
                        name:"historiques"
                    },
                    {
                        label: this.$t("documents"),
                        name:"documents"
                    },
                    {
                        label: this.$t("consommables"),
                        name:"consommables"
                    },
                    {
                        label: this.$t("taches"),
                        name:"taches"
                    },
                ];
            },
            title:function(){
                let equipement = this.getEquipement ? this.getEquipement.libel_equipement : "-";
                let qrCode = this.getEquipement ? this.getEquipement.qrCode : "-";
                return equipement +" "+ qrCode;
            }
        },
		created: function(){
            this.fetch();
		},
		destroyed: function(){
            this.$store.dispatch("EquipementsStore/deleteSelectedItem");
		},
    };

</script>

<style lang="scss">
    .flat-list {
        margin: 0;
        list-style: none;
    }

    .flat-list li {
        position: relative;
        padding-bottom: 20px;
    }
    .flat-list-item-tail{
        position: absolute;
        left: 4px;
        height: 100%;
        border-left: 2px solid #e4e7ed;
    }
    .flat-list-item-node {
        position: absolute;
        background-color: #e4e7ed;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .flat-list-item-node-normal {
        left: -1px;
        width: 12px;
        height: 12px;
    }
    .flat-list-item-wrapper {
        position: relative;
        padding-left: 28px;
        top: -3px;
    }
    .flat-list-item-content{
        margin-top: 8px;
    }
    .flat-list-item-bottom{
        color: #909399;
        line-height: 1;
        font-size: 12px;
    }
    .secondary_section_title{
        color: #35b1ea;
        font-size: 16px;
    }
    .no-margin{
        margin: 5px !important;
    }
    .menu-more-item{
        border-bottom: 1px solid whitesmoke;
        width: 100%;
        padding: 10px 0;
    }
</style>
