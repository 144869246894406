<template>
	<vg-app-layout
	   :title="typeScanner?$t('title-scanner-'+typeScanner):$t('title-scanner')"
	   :icon="'mobile/scanner.png'"
	   :colorheader="$root.vgPurple">
		<template #primary>
			<vg-scanner
                isFetching
				@scanSuccess="onScanSuccess">
			</vg-scanner>
            <!--code-scanner
                @scanSuccess="onScanSuccess"
                @scanFailure="onScanFailure"
            ></code-scanner-->
		</template>
	</vg-app-layout>


</template>
<script>

import VgScanner from "src/components/Vg/VgScanner.vue";
import equipementsMixins from "src/mixins/equipementsMixins.js";
import Metadatas from "src/services/Metadatas.js";

export default {
    name: 'check',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title-scanner": "Scanner une étiquette",
            "title-scanner-equipement": "Scanner un équipement",
            "title-scanner-lieu": "Scanner un lieu",
            "alert-message-no-equipement": "Aucun équipement recensé avec le qrCode %{code}",
            "alert-message-no-lieu": "Aucun lieu recensé avec le qrCode %{code}"
        },
        "en": {
            "title-scanner": "Scan a label code",
            "title-scanner-equipement": "Scan an equipment",
            "title-scanner-lieu": "Scan a place",
            "alert-message-no-equipement": "No equipement listed with the code %{code}",
            "alert-message-no-lieu": "No place listed with the code %{code}",
            "prendre-photo-title": "Do you want to take a picture?",
            "joindre-photo-title": "Do you want to attach a picture",
            "joindre-photo": "Attach picture to the verification",
            "relever-compteur": "Read counter value"
        },
        "th": {
            "spentTime": "เวลาที่ใช้ไปโดยประมาณ (นาที)",
            "non": "ไม่",
            "oui": "ใช่",
            "oui-avec-photo": "ใช่ เพิ่มรูปภาพ"
        }
    }
},
    mixins:[ equipementsMixins ],
    components:{
        VgScanner
    },
    props:{

    },
    data: function() {
        return {
			typeScanner: this.$route.params.typeScanner?this.$route.params.typeScanner:null,
			redirect: this.$route.params.redirect?this.$route.params.redirect:null
        };
    },
    computed: {

    },
    methods: {
        isExpectingRedirectToDemandeIntervention: function(){
            return this.redirect == "_demande_intervention";
        },
        isNotALieu: function(equipement){
            return equipement.isGroupEqp == "0"
        },
		onScanSuccess: function(equipement){
            if(this.isExpectingRedirectToDemandeIntervention()){
                this.$router.push({name: '_demande_intervention', params: {code: equipement.qrCode}});
            }else{
                if(this.isNotALieu(equipement)){
                    this.$router.push({name: '_equipement_id', params: {id:equipement.id, equipement: equipement, isScann: true}});
                }else{
                    this.$router.push({ name: '_lieu_id', params: {id: equipement.idLieu} });
                }
            }
		}
    },
    created: function(){

    }
};
</script>

<style lang="scss" scoped>

</style>
