<template>
	<vg-modal
		:title="$t('modal-title-create')"
		:isSaveDisabled="isSaveDisabled"
		@close="$emit('close')"
		@save="saveConsommations">
		<template #body>
			<vg-collapse :title="$t('liste-consommables')"
				:collapseByDefault="isListeConsommableCollapsed"
				type="section"
				:killContentOnCollapse="false"
				@collapse="onCollapseListeConsommables">
				<template #titlecomplement v-if="$vgutils.isMobile()">
					<vg-button type="success" @click="showScanner=true;">
						<i class="fas fa-barcode"></i> {{$t("scanner-consommable")}}
					</vg-button>
				</template>
				<template #content>
					<vg-text-filter v-model="searchQuery"
                    	:placeholder="$t('search-field')" />
					<vg-consommable-etiquette-selector v-model="selectedTag" 
						filtreAsInput 
						@change="onChangeSelectedTag"/>
					<vg-consommables-table v-if="(isGestionMagasin && depotFlottant) || (!isGestionMagasin && depotDefault)"
						:id="'consommations-form-consommables-table'"
						:isTableau="false"
						:searchQuery="searchQuery"
						:filters="consommablesFilters"
						paginate
						:redirectToZoom="false"
						:depotId="getDepotId"
						@row-click="onRowClick" />
				</template>
			</vg-collapse>
			<hr>
			<vg-collapse :title="$t('consommations')"
				:collapseByDefault="false"
				type="section"
				:killContentOnCollapse="false">
				<template #content>
					<div v-for="(consommation, index) in consommations" :key="index"
						class="consommation">
						<div class="consommable-details">
							<span class="title">{{consommation.consommable.name}}</span>
							<small>{{$t("ref")}} {{consommation.consommable.refExterne}}</small>
						</div>
						<div class="consommation-details">
							<vg-input :title="$t('quantite-consommee')" v-model="consommation.quantite" inputType="float"
								@input="onInputQuantite($event, consommation, index)"/>
							<vg-input :title="$t('cout-consommation')" v-model="consommation.cout" inputType="financial"/>
							<vg-button type="default-danger" size="xs"
								@click="removeConsommation(consommation, index)">{{$t("remove")}}</vg-button>
						</div>
					</div>
				</template>
			</vg-collapse>
			<vg-modal v-if="showScanner"
				:title="$t('scanner-un-consommable')"
				@close="showScanner=false;">
				<template #body>
					<vg-scanner
						@scanSuccess="onScannSuccess" />
				</template>
				<template #footer>
					<vg-button @click="showScanner=false;">
						{{$t('close')}}
					</vg-button>
				</template>
			</vg-modal>
		</template>
	</vg-modal>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgCollapse from "src/components/Vg/VgCollapse.vue";
	import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
	import VgConsommablesTable from "src/components/Vg/Consommables/VgConsommablesTable.vue";
	import VgConsommableEtiquetteSelector from "src/components/Vg/Selectors/VgConsommableEtiquetteSelector.vue";
	import VgScanner from 'src/components/Vg/VgScanner.vue';
	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
	import StocksMixins from "src/mixins/StocksMixins.js";
	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from "vuex";
    export default {
        name: 'vg-consommations-form',
		mixins: [ ConsommablesMixins, StocksMixins ],
		components: {
			VgInput,
			VgButton,
			VgCollapse,
			VgTextFilter,
			VgConsommablesTable,
			VgConsommableEtiquetteSelector,
			VgScanner
		},
        props: {

        },
        data: function() {
            return {
                consommations: [],
				isListeConsommableCollapsed: false,
				showScanner: false,
				searchQuery: null,
				selectedTag: null
            };
        },
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title-create": "Consommations",
            "ref": "Réf.",
            "quantite": "Quantité en stock",
            "cout-unitaire": "Coût unitaire",
            "liste-consommables": "Liste des consommables",
            "quantite-consommee": "Quantité consommée",
            "cout-consommation": "Coût consommation",
            "consommations": "Consommations",
            "remove": "Retirer",
            "scanner-consommable": "Scanner consommable",
            "close": "Fermer",
            "aucun-consommable-correspondant": "Aucun consommable correspondant.",
			"search-field": "Rechercher un consommable"
        },
        "en": {
            "modal-title-create": "Consumptions"
        }
    }
},
		watch: {

		},
		created: function(){
			this.getFiltreTagInLocalStorage();
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			onChangeSelectedTag: function(tag){
				this.selectedTag = tag;
				this.fetch();
				this.setFiltreTagInLocalStorage();
			},
			setFiltreTagInLocalStorage: function(){
				localStorage.setItem("vg-consommable-datalist-selected-tag", this.selectedTag);
			},
			getFiltreTagInLocalStorage: function(){
				let tag = localStorage.getItem("vg-consommable-datalist-selected-tag");
				if(tag) this.selectedTag = tag;
			},
			onScannSuccess: function(code){
				let metadatas = new Metadatas();
				metadatas.setFilters({
					code: {attr: "c.code", value: code, action: "equals"},
					depot_id: {attr: "s.depot_id", value: this.getDepotId, action: "equals"}
				});
				this.ConsommablesMixins_getConsommables(metadatas, {_stored: false}).then((datas)=>{
					if(datas && datas.consommables && datas.consommables.length!=0) this.addConsommation(datas.consommables[0]);
					else alert(this.$t("aucun-consommable-correspondant"));
					this.showScanner = false;
				});
			},
			onInputQuantite: function(data, consommation, index){
				this.consommations[index].cout = Number(data)*Number(consommation.consommable.coutUnitaire);
			},
			onCollapseListeConsommables: function(isCollapse){
				this.isListeConsommableCollapsed = isCollapse;
			},
			onRowClick: function(row){
				this.addConsommation(row.data);
			},
			addConsommation: function(consommable){
				this.consommations.push({
					user_id: this.$app.idUser,
					consommable_id: consommable.id,
					dateMouvement: moment().format("YYYY-MM-DD HH:mm"),
					quantite: 1,
					cout: consommable.coutUnitaire,
					flag: "consommation-fm",
					commentaire: null,
					consommable: Object.assign({}, {}, consommable)
				});
				this.isListeConsommableCollapsed = true;
			},
			removeConsommation: function(consommable, index){
				this.consommations.splice(index, 1);
			},
			fetchDepots: function(){
	            return new Promise((resolve, reject)=>{
	                let metadatasDepots = new Metadatas();
	                this.StocksMixins_getDepots(metadatasDepots).then((datas)=>{
	                    resolve(datas);
	                });
	            });
	        },
			fetch: function(){
				this.fetchDepots();
			},
			saveConsommations: function(){
				this.ConsommablesMixins_createConsommations(this.consommations, this.maintenance.id).then((datas)=>{
					this.$emit("save", datas);
				});
			}
		},
        computed: {
			...mapGetters({
	            consommables: 'ConsommablesStore/getCollection',
				depotDefault: "StocksStore/getDepotDefault",
	            depotFlottant: "StocksStore/getDepotFlottant",
	            maintenance: "MaintenancesStore/getSelectedMaintenance"
	        }),
			isGestionMagasin: function(){
	            let user = window.sessionStorage.getItem("user");
	            user = JSON.parse(user);
	            return user.isGestionMagasin;
	        },
			isSaveDisabled: function(){
				return !this.consommations || this.consommations.length==0;
			},
			getDepotId: function(){
				return this.isGestionMagasin?this.depotFlottant.id:this.depotDefault.id;
			},
			consommablesFilters: function(){
				let filters = {};
				if(this.selectedTag) filters.field_etiquette = {attr: "c.nature", value: this.selectedTag, action: "start_with"};
				if(this.isGestionMagasin) filters.quantite = {attr: "s.quantite", value: 0.1, action: "strictly_greater_than"};
				return filters;
			},
		}
    };

</script>
<style lang="scss" scoped>
.consommable{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 5px;
	margin: 10px 0;
	padding-bottom: 10px;
	border-bottom: 1px solid whitesmoke;
	cursor:pointer;
	.consommable-details{
		.title{
			color: #35b1ea;
		}
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
	.consommable-stock{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
}
.consommable:hover{
	font-weight: bold;
}
.consommation{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 5px;
	margin: 10px 0;
	padding-bottom: 10px;
	border-bottom: 1px solid whitesmoke;
	.consommable-details{
		.title{
			color: #35b1ea;
		}
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
	.consommation-details{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
}
</style>
