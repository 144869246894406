import { render, staticRenderFns } from "./VgFilesProgressUploadBar.vue?vue&type=template&id=2116cad4&scoped=true&lang=html"
import script from "./VgFilesProgressUploadBar.vue?vue&type=script&lang=js"
export * from "./VgFilesProgressUploadBar.vue?vue&type=script&lang=js"
import style0 from "./VgFilesProgressUploadBar.vue?vue&type=style&index=0&id=2116cad4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2116cad4",
  null
  
)

export default component.exports