<template>
    <div class="categorie-typologie-cell-render">
        <span v-if="categorie.typologieMaintenance_name">{{ categorie.typologieMaintenance_name }}</span>
        <span v-else>-</span>
        <vg-icon url="/static/assets/icone/custom/edit.svg" size="20" :key="'categorie-typologie-id-'+categorie.id" 
            @click="$emit('edit', categorie)"
            style="cursor: pointer;" class="icon-edit"/>
    </div>
</template>


<script>
import Vue from "vue";
import VgIcon from "src/components/Vg/VgIcon.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"categorie-typologie-cell-render",
    mixins:[LangMixins],
    components:{
        VgIcon
    },
	props: {
		params: {
			type: Object,
			required: true
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            
        }
    }
},
    methods:{
        
    },
    computed:{
        categorie: function(){
            return this.params.data;
        }
    }
});
</script>
<style lang="scss">
.categorie-typologie-cell-render{
    .icon-edit:hover{
        cursor: pointer;
        background-color: #3999FF !important;
    }
}
</style>
<style lang="scss" scoped>
.categorie-typologie-cell-render{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    height: 100%;
    width:100%;
}
</style>
