
<template>
	<div class="parametres-menu">
        <div v-show="can('PARAMETRES.UTILISATEURS')" 
			:class="{'activate-blue': active=='utilisateurs'}" @click="activate('utilisateurs')">
			<!--img src="static/assets/icone/menu/tiers-contact.png"/--><span>{{ $t("menu-principal-utilisateurs-autorisations") }}</span>
		</div>
		<div v-show="can('PARAMETRES.ENTREPRISE')" 
			:class="{'activate-blue': active=='entreprise'}" @click="activate('entreprise')">
			<span>{{ $t("menu-principal-entreprise") }}</span>
		</div>
		<div v-show="can('PARAMETRES.MAINTENANCE')" 
			:class="{'activate-blue': active=='maintenance'}" @click="activate('maintenance')">
			<span>{{ $t("menu-principal-personnalisation-di") }}</span>
		</div>
		<!--div :class="{'activate-blue': active=='tiers'}" @click="activate('tiers')">
			{{ $t("menu-principal-gestion-tiers") }}
		</div-->
		<div v-show="can('PARAMETRES.LIEUX')" 
			:class="{'activate-blue': active=='lieux'}" @click="activate('lieux')">
			<span>{{ $t("menu-principal-personnalisation-lieux") }}</span>
		</div>
		<div v-show="can('PARAMETRES.ETIQUETTES')" 
			:class="{'activate-blue': active=='tags'}" @click="activate('tags')">
			<span>{{$t("menu-principal-personnalisation-tags")}}</span>
		</div>
		<div v-show="can('PARAMETRES.BONS_DE_COMMANDE')" 
			:class="{'activate-blue': active=='bon-de-commande'}" @click="activate('bon-de-commande')">
			<span>{{$t("menu-principal-personnalisation-bon-de-commande")}}</span>
		</div>
		<div v-show="can('PARAMETRES.CORPS_D_ETAT')" 
			:class="{'activate-blue': active=='corps-detat'}" @click="activate('corps-detat')">
			<span>{{$t("menu-principal-personnalisation-corps-detat")}}</span>
		</div>
		<div v-show="can('PARAMETRES.EQUIPEMENTS')" 
			:class="{'activate-blue': active=='equipements'}" @click="activate('equipements')">
			<span>{{$t("menu-principal-personnalisation-equipements")}}</span>
		</div>
		<div v-show="can('PARAMETRES.UTILISATEURS')" 
			:class="{'activate-blue': active=='invitations'}" @click="activate('invitations')">
			<span>{{$t("menu-principal-management-invitations")}}</span>
		</div>
		<br>
		<br>
		<vg-button size="sm" type="danger" @click="clearStorage">{{$t("reset")}}</vg-button>
		
		<stripe-customer-state></stripe-customer-state>
	</div>
</template>

<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import StripeCustomerState from "src/components/Vg/Stripe/StripeCustomerState.vue";

    export default {
        name: "parametres-menu",
		components:{
			VgButton,
			StripeCustomerState
		},
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "menu-principal-personnels": "Paramètres personnels",
            "menu-principal-entreprise": "Paramètres du compte",
			"menu-principal-management-invitations": "Gestion des invitations",
            "menu-principal-utilisateurs-autorisations": "Utilisateurs et autorisations",
            "menu-principal-personnalisation-di": "Paramètres curatif",
            "menu-principal-gestion-tiers": "Gérer les tiers",
            "menu-principal-personnalisation-lieux": "Personnaliser les lieux",
            "menu-principal-personnalisation-tags": "Personnaliser les étiquettes",
            "menu-principal-personnalisation-bon-de-commande": "Personnaliser bons de commande",
            "menu-principal-personnalisation-corps-detat": "Personnaliser Corps d'état",
            "menu-principal-personnalisation-equipements": "Personnaliser les équipements",
            "menu-principal-gestion-integrations": "Gestion des intégrations",
            "reset": "Réinitialiser filtres et tableaux"
        },
        "en": {
            "menu-principal-personnels": "Personal settings",
            "menu-principal-entreprise": "Account Settings",
			"menu-principal-management-invitations": "Invitations management",
            "menu-principal-utilisateurs-autorisations": "Users and permissions",
            "menu-principal-personnalisation-di": "Curative settings",
            "menu-principal-gestion-tiers": "Manage third parties",
            "menu-principal-personnalisation-lieux": "Customise locations",
            "menu-principal-personnalisation-tags": "Customize tags",
            "menu-principal-personnalisation-bon-de-commande": "Customize purchase orders",
            "menu-principal-gestion-integrations": "Intergration management",
            "menu-principal-personnalisation-corps-detat": "Customize job categories",
            "menu-principal-personnalisation-equipements": "Customize equipments",
            "reset": "Reset filters and tables"
        },
        "th": {
            "menu-principal-header": "เมนูการตั้งค่า",
            "menu-principal-personnels": "การตั้งค่าส่วนบุคคล",
			"menu-principal-management-invitations": "การจัดการคำเชิญ",
            "menu-principal-entreprise": "การตั้งค่าบริษัท",
            "menu-principal-utilisateurs-autorisations": "ผู้ใช้งานและการให้อนุญาต",
            "menu-principal-gestion-tiers": "จัดการบุคคลที่สาม",
            "menu-principal-personnalisation-lieux": "เลือกตั้งค่าตำแหน่งที่ตั้งเอง",
            "menu-principal-personnalisation-tags": "กำหนดแท็กเอง",
            "menu-principal-gestion-integrations": "การจัดการการบูรณาการ"
        }
    }
},
        data() {
            return {
                active: "utilisateurs"
            };
        },
        methods: {
			activate: function(section){
				this.$emit("activatesection", section);
				this.active = section;
			},
            clearStorage:function(){
                localStorage.clear();
                document.location.reload();
            }
        }
    };
</script>
<style lang="scss" scoped>
.parametres-menu{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	*{
		width: 100%;
	}
	div{
		padding:10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		border-bottom: 1px solid whitesmoke;
		img{
			height: 20px;
			width: 20px;
		}
	}
	div:hover{
		cursor:pointer;
		background-color: whitesmoke;
	}
	.activate-blue{
		background-color: #c9ecfc;
		color: #02a7f0;
		font-weight: bold;
	}

}
</style>
