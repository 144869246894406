<template>
	<div v-if="!collapsed"
		class="vg-key-figures-panel">
		<div class="header">
			<span style="white-space: nowrap;">{{ $t("chiffres-cles") }}</span>
			<vg-date-picker-shortcuts style="width:100%;font-size:11px;"
				:defaultStartDate="defaultDateIntervalle.startDate"
				:defaultEndDate="defaultDateIntervalle.endDate"
				opens="left"
				previousDatesPresets
				@select="onChangeDateIntervalle"
				@finish-selection="onChangeDateIntervalle"/>
			<vg-button type="grey" @click="onClickCollapsePanel">
				<i class="fas fa-chevron-right"></i>
			</vg-button>
		</div>
		<div class="content">
			<vg-dashboard-curatif-urgences 
				:defaultDateIntervalle="defaultDateIntervalle"
				hideHeaderComplement 
				@selected="onSelectedPieUrgences"/>
			<vg-dashboard-curatif-repartition-age
				hideHeaderComplement 
				@selected="onSelectedAge"/>
			<vg-dashboard-preventif-prochaines-interventions />
			<vg-dashboard-preventif-progression-interne 
				@selected="onSelectProgression" />
		</div>
		<vg-modal v-if="showMaintenancesListModal"
			:width="'80vw'"
			:height="'100vh'"
			@close="onCloseMaintenancesListModal">
			<template #header>
				{{$t('modal-details-title')}} :
			</template>
			<template #body>
				<div style="width:100%;height:100%;">
					<div style="display:flex;flex-direction:column;" >
						<vg-text-filter style="width:50%;" class="hidden-print"
							v-model="searchQuery" />
					</div>
					<vg-fichesav-table
						:searchQuery="searchQuery"
						:filters="maintenancesFilters"
						:counterName="'filtered'" />
				</div>
			</template>
			<template #footer>
				<vg-button
					type="default"
					@click="onCloseMaintenancesListModal">
					{{$t("fermer")}}
				</vg-button>
				<vg-button class="not-responsive"
					type="success"
					@click="openRapportMaintenances">
					{{$t("generer-rapport")}}
				</vg-button>
				<vg-button class="not-responsive"
					type="success"
					@click="exportCSV()">
					{{$t("export-csv")}}
				</vg-button>
			</template>
		</vg-modal>
		<vg-modal v-if="showProgressionModal"
			:width="'80vw'"
			:height="'100vh'"
			@close="onCloseProgressionModal">
			<template #header>
				{{$t("modal-details-title-progression")}}: <b style="margin-left:2px;">{{selectedProgression.libel_tache}}</b>
			</template>
			<template #body>
				<span>{{$t("equipements-fait")}}: {{selectedProgression.total_done}} / {{selectedProgression.total_todo}}</span>
				<br>
				<span>{{$t("date-butoire")}}: <vg-datetime-viewer v-model="selectedProgression.date_butoire" hideTime/></span>
				<br>
				<br>
				<tag-grid
					:idTableau="'dashboard-progression-tableau'"
					v-model="gridOptionsZoom"
					:columnDefs="columnDefsZoom"
					:rowData="equipmentToCheck"
					:showLoadingOverlay="showEquipementsLoadingOverlay"
					:overlayLoadingText="$t('equipements-overlay-loading-text')"
					:overlayNoRowsText="$t('equipements-overlay-no-rows-text')" />
			</template>
			<template #footer>
				<vg-button
					type="default"
					@click="onCloseProgressionModal">
					{{$t("fermer")}}
				</vg-button>
			</template>
		</vg-modal>
	</div>
	<div v-else
		class="vg-key-figures-panel reduced-panel">
		<div class="header">
			<vg-button type="grey" @click="onClickCollapsePanel">
				<i class="fas fa-chevron-left"></i>
			</vg-button>
		</div>
	</div>
</template>
<script>
import VgButton from "src/components/Vg/VgButton.vue";
import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import TagGrid from "src/components/Grid/TagGrid.vue";
import VgDashboardCuratifUrgences from "src/components/Vg/Dashboard/VgDashboardCuratifUrgences.vue";
import VgDashboardCuratifRepartitionAge from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionAge.vue";
import VgDashboardPreventifProchainesInterventions from "src/components/Vg/Dashboard/VgDashboardPreventifProchainesInterventions.vue";
import VgDashboardPreventifProgressionInterne from "src/components/Vg/Dashboard/VgDashboardPreventifProgressionInterne.vue";
import VgFichesavTable from "src/components/Vg/FicheSAV/VgFichesavTable.vue";
import ZoomDashboardCellRender from "src/components/Vg/TagGrid/ZoomDashboardCellRender.vue";

import VerificationMixins from "src/mixins/VerificationMixins.js";

import { mapGetters } from 'vuex';

    export default {
        name: 'vg-key-figures-panel',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "chiffres-cles": "Chiffres clés",
			"modal-details-title": "Fiches curatives",
			"modal-details-title-verifications": "Liste des verifications",
			"modal-details-title-progression": "Progression tâche",
			"fermer": "Fermer",
			"generer-rapport": "Générer rapport pdf",
			"export-csv": "Export csv",
			"equipements-fait": "Equipements fait",
			"date-butoire": "Date butoire"
        },
        "en": {
            "chiffres-cles": "Key figures",
			"modal-details-title": "Curative sheets",
			"modal-details-title-verifications": "Checklist",
			"modal-details-title-progression": "Task progress",
			"fermer": "Close",
			"generer-rapport": "Create PDF report",
			"export-csv": "Export CSV",
			"equipements-fait": "Equipments done",
			"date-butoire": "Deadline"
        }
	}
},
		components: {
			VgButton,
			VgTextFilter,
			VgDatePickerShortcuts,
			VgDatetimeViewer,
			TagGrid,
			VgDashboardCuratifUrgences,
			VgDashboardCuratifRepartitionAge,
			VgDashboardPreventifProchainesInterventions,
			VgDashboardPreventifProgressionInterne,
			VgFichesavTable
		},
		mixins: [ VerificationMixins ],
        props: {
			
        },
        data: function() {
            return {
				collapsed: false,
				defaultDateIntervalle: {
					startDate: moment().startOf('month').format("YYYY-MM-DD 00:00"), 
					endDate: moment().endOf('month').format("YYYY-MM-DD 23:59")
				},
				showMaintenancesListModal: false,
				maintenancesFilters: {},
				maintenancesStored: [],
				countersStored: null,
				selectedProgression: null,
				showProgressionModal: false,
				showProgressionLoadingOverlay: false,
				equipmentToCheck: [],
				gridOptionsZoom: {
	                headerHeight: 0,
	                rowHeight: 100,
	            },
				columnDefsZoom: [{
	                    headerName: "Libelle tache",
	                    field: 'libel_equipement',
	                    colId: 'libel_equipement',
	                    suppressSizeToFit: false,
	                    unSortIcon: true,
	                    cellClass: ["vg-cell-mobile-card"],
	                    cellRenderer: (params) => new ZoomDashboardCellRender({
	                        propsData: {
	                            params: params.data,
	                            icon:this.selectedProgression.icon
	                        }
	                    }).$mount().$el,
	                },
	                {
	                    headerName: "id",
	                    width: 180,
	                    field: 'id',
	                    colId: 'id',
	                    hide: true,
	                },
	                {
	                    headerName: "path",
	                    width: 180,
	                    field: 'path',
	                    colId: 'path',
	                    hide: true,
	                },
	                {
	                    headerName: "qrCode",
	                    width: 180,
	                    field: 'qrCode',
	                    colId: 'qrCode',
	                    hide: true,
	                },
	                {
	                    headerName: "isScanRequired",
	                    width: 180,
	                    field: 'isScanRequired',
	                    colId: 'isScanRequired',
	                    hide: true
	                }
	            ]
            };
        },
		methods: {
			onChangeDateIntervalle: function(data){
				console.log("CHANGE DATE INTERVALLE", data);
				this.defaultDateIntervalle = data;
			},
			onClickCollapsePanel: function(){
				this.collapsed = !this.collapsed;
				this.$emit("collapse", this.collapsed);
			},
			onCloseMaintenancesListModal: function(){
				this.showMaintenancesListModal = false;
				this.maintenancesFilters = [];
				this.resetMaintenancesStored();
				//this.$store.dispatch("MaintenancesStore/setMaintenances", []);
			},
			saveMaintenancesStored: function(){
				this.maintenancesStored = [...this.maintenances];
				this.countersStored = Object.assign({}, {}, this.counters);
			},
			resetMaintenancesStored: function(){
				this.$store.dispatch("MaintenancesStore/setMaintenances", this.maintenancesStored);
				this.$store.dispatch("MaintenancesStore/setMaintenanceCounters", this.countersStored);
			},
			onSelectedAge: function(datas){
				this.saveMaintenancesStored();
				this.maintenancesFilters = [...datas.filters];
				this.showMaintenancesListModal = true;
			},
			onSelectedPieUrgences: function(datas){
				this.saveMaintenancesStored();
				this.maintenancesFilters = [...datas.filters];
				this.maintenancesFilters.push({attr: "fm.dateOuvertureSAV", value: [datas.dateIntervalle.startDate, datas.dateIntervalle.endDate], action: "between"});
				this.showMaintenancesListModal = true;
			},
			openRapportMaintenances: function(){
				this.$router.push({ name: '_maintenances_print' });
			},
			exportCSV: function(){
				window.dispatchEvent(new CustomEvent("askForCSV", {
					"detail": {"idTableau": "vg-fichesav-table"}
				}));
			},
			onSelectProgression: function(datas){
				this.selectedProgression = datas.progression;
				this.showProgressionModal = true;
				this.showProgressionLoadingOverlay = true;
				this.VerificationMixins_getTacheState(datas.progression).then((response)=>{
	                this.equipmentToCheck = response;
	                this.showProgressionLoadingOverlay = false;
	            });
			},
			onCloseProgressionModal: function(){
				this.showProgressionModal = false;
				this.equipmentToCheck = [];
				this.selectedProgression = null;
			},
		},
		computed: {
			...mapGetters({
                  maintenances: 'MaintenancesStore/getMaintenances',
				  counters: "MaintenancesStore/getMaintenancesCounters"
            }),
			datePickerShortcutsCollection: function(){
				return ["today", "week", "last2weeks", "month", "last30days", "last3months", "last12months", "lastyear", "year"];
			}
		},
		created: function(){
			if(localStorage.getItem("key-figures-panel-opened") && localStorage.getItem("key-figures-panel-opened")==="false") this.collapsed = true;
		}
    };

</script>
<style lang="scss" scoped>
.vg-key-figures-panel{
	height: inherit;
	width: calc(100% - 20px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	margin-right: 20px;
	.header{
		background-color: #E8EAEE;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		font-size: 16px;
		height: 40px;
		width: 100%;
		padding: 0 10px;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.content{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 10px;
		width: 100%;
		height: calc(100% - 40px - 20px - 10px);
		overflow-y: auto;
		>div{
			max-height: 30vh;
			min-height: 200px;
		}
	}
}
.reduced-panel{
	border-left: 1px solid #E8EAEE;
}
@media only screen and (max-width: 1200px){
	
}

</style>
