<template>
  <div class="complete-validation-container">
    <div class="card">
      <div class="card-header">
        <h2>{{ $t('title') }}</h2>
      </div>
      <div class="card-body">
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <div v-if="success" class="alert alert-success">{{ success }}</div>
        <form @submit.prevent="submitForm">
          <!--vg-input 
            :title="$t('email')" 
            v-model="form.email" 
            inputType="email" 
            :disabled="true"
            :placeholder="$t('your_email')"
          /-->
          
          <div class="form-group">
            <vg-input 
              :title="$t('name')" 
              v-model="form.name" 
              inputType="text" 
              :isRequiredValue="true"
              :placeholder="$t('enter_name')"
              :class="{'is-invalid': validationErrors.name}"
              ref="nameInput"
              name="name"
            />
            <div class="invalid-feedback" v-if="validationErrors.name">
              {{ validationErrors.name }}
            </div>
          </div>

          <div class="form-group">
            <vg-input 
              :title="$t('surname')" 
              v-model="form.surname" 
              inputType="text" 
              :isRequiredValue="true"
              :placeholder="$t('enter_surname')"
              :class="{'is-invalid': validationErrors.surname}"
              ref="surnameInput"
              name="surname"
            />
            <div class="invalid-feedback" v-if="validationErrors.surname">
              {{ validationErrors.surname }}
            </div>
          </div>

          <div class="form-group">
            <vg-input 
              :title="$t('password')" 
              v-model="form.password" 
              inputType="password" 
              :isRequiredValue="true"
              :placeholder="$t('enter_password')"
              :class="{'is-invalid': validationErrors.password}"
              ref="passwordInput"
              name="password"
            />
            <div class="invalid-feedback" v-if="validationErrors.password">
              {{ validationErrors.password }}
            </div>
            <div class="password-strength" v-if="form.password">
              <div class="strength-meter">
                <div class="strength-meter-fill" :style="{ width: passwordStrength.score + '%', backgroundColor: passwordStrength.color }"></div>
              </div>
              <small>{{ $t('password_strength') }}: {{ $t(passwordStrength.label) }}</small>
            </div>
          </div>
          
          <div class="form-group">
            <vg-input 
              :title="$t('confirm_password')" 
              v-model="form.password_confirm" 
              inputType="password" 
              :isRequiredValue="true"
              :placeholder="$t('confirm_your_password')"
              :class="{'is-invalid': validationErrors.password_confirm}"
              ref="confirmPasswordInput"
              name="password_confirm"
            />
            <div class="invalid-feedback" v-if="validationErrors.password_confirm">
              {{ validationErrors.password_confirm }}
            </div>
          </div>
          
          <div class="form-group">
            <vg-button 
              type="info" 
              :disabled="isSubmitting"
              @click="submitForm"
            >
              <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ $t('complete_button') }}
            </vg-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VgInput from '../../components/Vg/VgInput.vue';
import VgButton from '../../components/Vg/VgButton.vue';

export default {
  name: 'CompleteValidation',
  components: {
    VgInput,
    VgButton
  },
  data() {
    return {
      form: {
        invitation_token: '',
        email: '',
        name: '',
        surname: '',
        password: '',
        password_confirm: ''
      },
      validationErrors: {},
      error: null,
      success: null,
      isSubmitting: false,
      showPassword: false,
      showConfirmPassword: false
    };
  },
  i18n: {
    messages: {
      en: {
        title: 'Complete Your Registration',
        email: 'Email',
        your_email: 'Your email',
        name: 'Name',
        enter_name: 'Enter your name',
        surname: 'Surname',
        enter_surname: 'Enter your surname',
        password: 'Password',
        enter_password: 'Enter your password',
        confirm_password: 'Confirm Password',
        confirm_your_password: 'Confirm your password',
        complete_button: 'Complete Registration',
        password_required: 'Password is required',
        password_min_length: 'Password must be at least 8 characters',
        confirm_required: 'Please confirm your password',
        passwords_not_match: 'Passwords do not match',
        invalid_token: 'Invalid invitation token. Please check your invitation link.',
        registration_success: 'Registration completed successfully! You can now log in.',
        registration_error: 'An error occurred while completing your registration. Please try again.',
        password_strength: 'Password strength',
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        very_strong: 'Very strong'
      },
      fr: {
        title: 'Complétez Votre Inscription',
        email: 'Email',
        your_email: 'Votre email',
        name: 'Prénom',
        enter_name: 'Entrez votre prénom',
        surname: 'Nom',
        enter_surname: 'Entrez votre nom',
        password: 'Mot de passe',
        enter_password: 'Entrez votre mot de passe',
        confirm_password: 'Confirmer le mot de passe',
        confirm_your_password: 'Confirmez votre mot de passe',
        complete_button: 'Terminer l\'inscription',
        password_required: 'Le mot de passe est requis',
        password_min_length: 'Le mot de passe doit contenir au moins 8 caractères',
        confirm_required: 'Veuillez confirmer votre mot de passe',
        passwords_not_match: 'Les mots de passe ne correspondent pas',
        invalid_token: 'Jeton d\'invitation invalide. Veuillez vérifier votre lien d\'invitation.',
        registration_success: 'Inscription terminée avec succès ! Vous pouvez maintenant vous connecter.',
        registration_error: 'Une erreur s\'est produite lors de votre inscription. Veuillez réessayer.',
        password_strength: 'Force du mot de passe',
        weak: 'Faible',
        medium: 'Moyen',
        strong: 'Fort',
        very_strong: 'Très fort'
      },
      th: {
        title: 'ลงทะเบียนให้เสร็จสมบูรณ์',
        email: 'อีเมล',
        your_email: 'อีเมลของคุณ',
        name: 'ชื่อ',
        enter_name: 'กรอกชื่อของคุณ',
        surname: 'นามสกุล',
        enter_surname: 'กรอกนามสกุลของคุณ',
        password: 'รหัสผ่าน',
        enter_password: 'ใส่รหัสผ่านของคุณ',
        confirm_password: 'ยืนยันรหัสผ่าน',
        confirm_your_password: 'ยืนยันรหัสผ่านของคุณ',
        complete_button: 'ลงทะเบียนให้เสร็จสมบูรณ์',
        password_required: 'ต้องระบุรหัสผ่าน',
        password_min_length: 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร',
        confirm_required: 'กรุณายืนยันรหัสผ่านของคุณ',
        passwords_not_match: 'รหัสผ่านไม่ตรงกัน',
        invalid_token: 'โทเค็นเชิญไม่ถูกต้อง โปรดตรวจสอบลิงก์เชิญของคุณ',
        registration_success: 'ลงทะเบียนเสร็จสมบูรณ์แล้ว! คุณสามารถเข้าสู่ระบบได้แล้ว',
        registration_error: 'เกิดข้อผิดพลาดขณะลงทะเบียน โปรดลองอีกครั้ง',
        password_strength: 'ความแข็งแรงของรหัสผ่าน',
        weak: 'อ่อนแอ',
        medium: 'ปานกลาง',
        strong: 'แข็งแรง',
        very_strong: 'แข็งแรงมาก'
      }
    }
  },
  computed: {
    passwordStrength() {
      const password = this.form.password || '';
      
      if (!password) {
        return { score: 0, label: 'weak', color: '#ff4d4d' };
      }
      
      let score = 0;
      
      // Length check
      if (password.length >= 8) score += 20;
      if (password.length >= 12) score += 10;
      
      // Complexity checks
      if (/[A-Z]/.test(password)) score += 20; // Has uppercase
      if (/[a-z]/.test(password)) score += 15; // Has lowercase
      if (/[0-9]/.test(password)) score += 15; // Has number
      if (/[^A-Za-z0-9]/.test(password)) score += 20; // Has special char
      
      // Determine strength label and color
      let label, color;
      if (score < 40) {
        label = 'weak';
        color = '#ff4d4d'; // Red
      } else if (score < 60) {
        label = 'medium';
        color = '#ffaa00'; // Orange
      } else if (score < 80) {
        label = 'strong';
        color = '#2db92d'; // Green
      } else {
        label = 'very_strong';
        color = '#00802b'; // Dark green
      }
      
      return { score, label, color };
    }
  },
  created() {
    // Get the invitation token from the URL query parameters
    this.form.invitation_token = this.$route.query.invitation_token || '';
    this.form.email = this.$route.query.email || '';
    
    if (!this.form.invitation_token) {
      this.error = this.$t('invalid_token');
    }
  },
  methods: {
    validateForm() {
      this.validationErrors = {};
      
      if (!this.form.name) {
        this.validationErrors.name = this.$t('name_required');
      }

      if (!this.form.surname) {
        this.validationErrors.surname = this.$t('surname_required');
      }

      if (!this.form.password) {
        this.validationErrors.password = this.$t('password_required');
      } else if (this.form.password.length < 8) {
        this.validationErrors.password = this.$t('password_min_length');
      }
      
      if (!this.form.password_confirm) {
        this.validationErrors.password_confirm = this.$t('confirm_required');
      } else if (this.form.password !== this.form.password_confirm) {
        this.validationErrors.password_confirm = this.$t('passwords_not_match');
      }
      
      return Object.keys(this.validationErrors).length === 0;
    },
    async submitForm() {
      if (!this.validateForm()) {
        return;
      }
      
      this.isSubmitting = true;
      this.error = null;
      this.success = null;
      
      try {
        // Use the Verifgood JS SDK to complete the registration
        await this.$vg.invitations.completeRegistration({
          invitation_token: this.form.invitation_token,
          name: this.form.name,
          surname: this.form.surname,
          password: this.form.password,
          password_confirm: this.form.password_confirm
        });
        
        this.success = this.$t('registration_success');
        
        // Redirect to login page after a short delay
        setTimeout(() => {
          window.location.href = '/auth_';
        }, 2000);
      } catch (err) {
        console.error('Error completing registration:', err);
        this.error = err.message || this.$t('registration_error');
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
</script>

<style scoped>
.complete-validation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.card {
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.card-header {
  background-color: #2563EB;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.language-selector {
  width: 100px;
}

.card-body {
  padding: 20px;
  background-color: white;
}

.form-group {
  margin-bottom: 20px;
}

.password-strength {
  margin-top: 8px;
}

.strength-meter {
  height: 4px;
  background-color: #e9ecef;
  border-radius: 2px;
  margin-bottom: 5px;
}

.strength-meter-fill {
  height: 100%;
  border-radius: 2px;
  transition: width 0.3s ease;
}

@media (max-width: 576px) {
  .card {
    max-width: 100%;
  }
}
</style>
