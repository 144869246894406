import { render, staticRenderFns } from "./VgPlanInteractif.vue?vue&type=template&id=bf8b817a&scoped=true"
import script from "./VgPlanInteractif.vue?vue&type=script&lang=js"
export * from "./VgPlanInteractif.vue?vue&type=script&lang=js"
import style0 from "./VgPlanInteractif.vue?vue&type=style&index=0&id=bf8b817a&prod&lang=scss&scoped=true"
import style1 from "./VgPlanInteractif.vue?vue&type=style&index=1&id=bf8b817a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf8b817a",
  null
  
)

export default component.exports