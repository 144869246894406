<template>
    <div class="categorie-libelles-problems-cell-render">
        <span v-if="params.data.libellesCategorie && params.data.libellesCategorie.length!=0">{{ getLibellesCategorie }}</span>
        <span v-else>-</span>
        <vg-icon url="/static/assets/icone/custom/edit.svg" size="20" :key="'categorie-libelle-id-'+params.id" 
            @click="$emit('edit', params.data)"
            style="cursor: pointer;" class="icon-edit"/>
    </div>
</template>


<script>
import Vue from "vue";
import VgIcon from "src/components/Vg/VgIcon.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"categorie-libelles-problems-cell-render",
    mixins:[LangMixins],
    components:{
        VgIcon
    },
	props: {
		params: {
			type: Object,
			required: true
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            
        }
    }
},
    methods:{
        
    },
    computed:{
        getLibellesCategorie: function(){
            return this.params.data.libellesCategorie.map(item => {
                return item.libelle;
            }).join(", ");
        }
    }
});
</script>
<style lang="scss">
.categorie-libelles-problems-cell-render{
    .icon-edit:hover{
        cursor: pointer;
        background-color: #3999FF !important;
    }
}
</style>
<style lang="scss" scoped>
.categorie-libelles-problems-cell-render{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    height: 100%;
    width:100%;
}
</style>
