<template>
    <div class="dragndropzone"  @drop="drop">
        <input type="file" 
        :multiple="multiple" name="fields[assetsFieldHandle][]" 
        id="assetsFieldHandle" @change="onChange" 
        ref="file" :accept="accept" hidden />
    
        <label for="assetsFieldHandle" @dragover="dragover" @dragleave="dragleave">
            <div style="display: flex;flex-direction: column;">
                <i class="fas fa-file-upload" style="font-size: 32px;color: #c0c4cc;padding: 8px;cursor: pointer;"></i>
                <span style="color: #606266;font-size: 14px;;font-weight: 100;padding: 5px;cursor: pointer;">
                    {{ $t("info") }}
                </span>
            </div>
        </label>
        <ul class="mt-4" v-if="multiple && filelist.length" v-cloak>
            <li class="text-sm p-1" v-for="file in filelist">
                {{ file.name }}<vg-button type="danger"  @click="remove(filelist.indexOf(file))" title="Remove file"><i class="fas fa-trash-alt"></i></vg-button>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'vg-drag-n-drop-zone',
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: ".pdf,.jpg,.jpeg,.png,.ifc,.xlsx,.xls,.csv"
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "info": "Déposez vos fichiers ici ou cliquer pour envoyer un fichier."
            },
            "en": {
                "info": "Drop your files here or click to send a file."
            }

        }
    },
    data() {
        return {
            filelist: []
        }
    },
    methods: {
        onChange() {
            this.filelist = [...this.$refs.file.files];
            this.$emit('selected-files', this.filelist);
        },
        remove(i) {
            this.filelist.splice(i, 1);
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('dragOver')) {
                event.currentTarget.classList.add('dragOver');
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.remove('dragOver');
        },
        drop(event) {
            console.log(event)
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            // Clean up
            event.currentTarget.classList.remove('dragOver');
        }
    }
}
</script>
<style scoped>
    .dragndropzone{
        width: 300px;
        height: 100px;
        text-align: center;
        border: 1px dashed #059cff;
        border-radius: 10px;
    }
    .dragOver{
        background-color: #d0e7f7;
    }
    .dragndropzone:hover{
        border: 1px dashed #3eb2ff;
        opacity:75%;
        cursor: pointer;
    }
</style>