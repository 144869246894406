<template>
   <div>
      <table id="checkpointsTable">
         <thead>
            <tr>
               <th> {{$t("question")}} <span class="required">*</span></th>
               <th> {{$t('erreur-on-non-conformite') }} <span class="required">*</span></th>
               <th> {{$t('type-reponse-attendue') }} <span class="required">*</span></th>
               <th class="complement complement-sm"> {{$t("order-of-appearance")}} </th>
               <th class="complement"> {{$t("rubrique")}} </th>
               <th v-if="isEditionMode"> {{ $t("actions") }} </th>
            </tr>
         </thead>
         <tbody>
            <!-- Viewer checkpoints déjà ajouté -->
            <tr v-for="(chk ,i) in checkpoints"
               :key="'checkpoint-'+i"
               @dblclick="setEditCheckpoint(chk, i)" >
               <!-- question -->
               <!-- Viewer checkpoint -->
               <td v-if="chk && editedCheckpoint == null" > {{ chk.type_reponse=="consommation"?chk.consommable.name:chk.question }}</td>
               <td v-if="chk && editedCheckpoint == null"> {{ chk.messageOnVerifError }} <span v-if="chk.type_reponse=='Nombre'">{{chk.minVal}} - {{chk.maxVal}}</span></td>
               <td v-if="chk && editedCheckpoint == null"> {{ chk.type_reponse }} </td>
               <td v-if="chk && editedCheckpoint == null"> {{ chk.orderOfAppearance }} </td>
               <td v-if="chk && editedCheckpoint == null"> {{ chk.rubrique }} </td>
               <td v-if="chk && editedCheckpoint == null">
                    <vg-button v-if="indexEditedCheckpoint!=i && isEditionMode"
                        type="default-danger"
                        size="xs"
                        @click="setEditCheckpoint(chk, i)">
                        {{$t('update')}}
                    </vg-button>
                    <vg-button v-if="indexEditedCheckpoint!=i && isEditionMode"
                        type="danger"
                        size="xs"
                        @click="handleDeleteCheckpoint(i)">
                        {{$t('delete')}}
                    </vg-button>
                </td>
               <!-- Fin Viewer checkpoint -->

               <td v-if="editedCheckpoint != null && indexEditedCheckpoint==i">
                    <vg-input v-if="editedCheckpoint && indexEditedCheckpoint==i && editedCheckpoint.type_reponse!='consommation'"
                        v-model="editedCheckpoint.question"
                        isRequiredValue />
                    <span v-else-if="editedCheckpoint.type_reponse!='consommation'">
                        {{editedCheckpoint.question}}
                    </span>
                    <span v-else-if="editedCheckpoint.type_reponse=='consommation'">
                        {{$t("consommation-de")}} <u>{{editedCheckpoint.consommable.name}}</u>
                    </span>
               </td>
               <!-- message de non conformité -->
               <td v-if="editedCheckpoint != null && indexEditedCheckpoint==i">
                    <div v-if="editedCheckpoint && indexEditedCheckpoint==i && editedCheckpoint.type_reponse == 'Nombre'"
                        style="display:flex;flex-direction:row;">
                        <vg-input 
                            :inputType="number"
                            v-model="editedCheckpoint.minVal"
                            isRequiredValue />
                        <vg-input
                            :inputType="number"
                            v-model="editedCheckpoint.maxVal"
                            isRequiredValue />
                    </div>
                    <vg-input v-else-if="editedCheckpoint && indexEditedCheckpoint==i && editedCheckpoint.type_reponse != 'Nombre'"
                        v-model="editedCheckpoint.messageOnVerifError"
                        isRequiredValue />
                    <span v-else-if="!indexEditedCheckpoint==i && editedCheckpoint.type_reponse == 'Nombre'">
                        {{editedCheckpoint.minVal}} - {{editedCheckpoint.maxVal}}
                    </span>
                    <span v-else-if="!indexEditedCheckpoint==i && editedCheckpoint.type_reponse != 'Nombre'">
                        {{editedCheckpoint.messageOnVerifError}}
                    </span>
               </td>
               <!-- type réponse attendue -->
               <td v-if="editedCheckpoint != null && indexEditedCheckpoint==i">
                    <vg-input v-if="editedCheckpoint && indexEditedCheckpoint==i"
                        isRequiredValue>
                        <vg-select
                            v-model="editedCheckpoint.type_reponse"
                            :clearable="false"
                            :options="getTypesReponse"
                            :attributeValue="'value'"
                            :attributeLabel="'label'"
                            :placeholder="$t('type-reponse')"
                            @input="onSelectTypeReponse"/>
                    </vg-input>
                    <span v-else>{{editedCheckpoint.type_reponse}}</span>
               </td>
               <!-- ordre d'apparence -->
               <td v-if="editedCheckpoint != null && indexEditedCheckpoint==i">
                    <vg-input v-if="editedCheckpoint && indexEditedCheckpoint==i"
                        v-model="editedCheckpoint.orderOfAppearance"
                        inputType="number" />
                    <span v-else>{{editedCheckpoint.orderOfAppearance}}</span>
               </td>
               <!-- rubrique -->
               <td v-if="editedCheckpoint != null && indexEditedCheckpoint==i">
                    <vg-input v-if="editedCheckpoint && indexEditedCheckpoint==i"
                        v-model="editedCheckpoint.rubrique"/>
                    <span v-else>{{editedCheckpoint.rubrique}}</span>
               </td>

                <td v-if="editedCheckpoint!=null && indexEditedCheckpoint==i">
                    <vg-button v-if="editedCheckpoint && indexEditedCheckpoint==i && editedCheckpoint.type_reponse == 'relever_usage_seuils'" 
                        @click="showEditSeuils = true" 
                        size="xs"> 
                        {{$t('show-seuils-checkpoint')}} 
                    </vg-button>
                    <vg-button v-if="editedCheckpoint && indexEditedCheckpoint==i"
                        type="default-danger"
                        size="xs"
                        @click="doneEdit(i)">
                        {{$t('validate')}}
                    </vg-button>
                    <vg-button v-if="editedCheckpoint && indexEditedCheckpoint==i"
                        size="xs"
                        @click="cancelEdit()">
                        {{$t('cancel')}}
                    </vg-button>
                </td>
            </tr>

            <!-- ligne ci-dessous = ligne d'ajout d'un élément -->
            <tr v-if="!editedCheckpoint && isEditionMode">
                <!-- question -->
               <td>
                    <vg-input v-if="checkpoint.type_reponse!='consommation'" v-model="checkpoint.question" 
                        :placeholder="$t('question')"
                        isRequiredValue />
                    <vg-button v-else-if="checkpoint.type_reponse=='consommation' && !checkpoint.consommable_id"
                        @click="showConsommableSelectorModal=true;">{{$t("select-consommable")}}</vg-button>
                    <span v-else-if="checkpoint.type_reponse=='consommation' && checkpoint.consommable_id && checkpoint.consommable">
                        {{checkpoint.consommable.name}} <i class="fas fa-times" style="cursor:pointer;" @click="checkpoint.consommable_id=null;checkpoint.consommable=null;"></i>
                    </span>
               </td>
               <!-- message non conformité-->
               <td>
                    <div v-if="checkpoint.type_reponse=='Nombre'"
                        style="display:flex;flex-direction:row;gap:5px;">
                        <vg-input 
                            :inputType="number"
                            v-model="checkpoint.minVal"
                            :placeholder="$t('nombre-valeur-min')"
                            isRequiredValue />
                        <vg-input
                            :inputType="number"
                            :placeholder="$t('nombre-valeur-max')"
                            v-model="checkpoint.maxVal"
                            isRequiredValue />
                    </div>
                    <vg-input v-if="checkpoint.type_reponse!='consommation'"
                        v-model="checkpoint.messageOnVerifError"
                        :placeholder="$t('erreur-on-non-conformite')"
                        isRequiredValue/>
               </td>
               <!-- type réponse attendue -->
               <td>
                  <vg-input
                     v-model="checkpoint.type_reponse"
                     isRequiredValue>
                     <vg-select
                        v-model="checkpoint.type_reponse"
                        :clearable="false"
                        :options="getTypesReponse"
                        :attributeValue="'value'"
                        :attributeLabel="'label'"
                        :placeholder="$t('type-reponse')"
                        @input="onSelectTypeReponse"/>
                  </vg-input>
               </td>
               <!-- ordre d'apparence-->
               <td>
                  <vg-input v-model="checkpoint.orderOfAppearance" inputType="number"/>
               </td>
               <!-- rubrique -->
               <td>
                    <vg-input v-model="checkpoint.rubrique"
                        :placeholder="$t('rubrique')" />
               </td>
               <!-- actions -->
               <td>
                <vg-button
                     v-if="checkpoint.type_reponse == 'relever_usage_seuils'"
                     size="xs"
                     @click="showEditSeuils = true">
                     {{$t('show-seuils-checkpoint')}}
                </vg-button>
                <vg-button
                     type="success"
                     size="xs"
                     :disabled="disabledCreateCheckpoint"
                     @click="handleCreateCheckpoint" >
                     {{$t("add")}}
                </vg-button>
               </td>
            </tr>
         </tbody>
      </table>

        <checkpoint-seuils v-if="!editedCheckpoint && showEditSeuils"
             v-model="checkpoint"
             @close="showEditSeuils=false"
             @change="changeSeuil"
             :key="'checkpoint-seuil-create-checkpoint'" />
        <checkpoint-seuils v-if="editedCheckpoint && showEditSeuils"
             v-model="editedCheckpoint"
             @close="showEditSeuils=false"
             @change="changeSeuil"
             :key="'checkpoint-seuil-update-checkpoint'" />
        <vg-modal v-if="showConsommableSelectorModal"
            :title="$t('choisir-consommable')"
            @close="showConsommableSelectorModal=false;">
            <template #body>
                <vg-consommable-selector @selected="onSelectConsommable"/>
            </template>
            <template #footer>
                <vg-button @click="showConsommableSelectorModal=false;">
                    {{$t("close")}}
                </vg-button>
            </template>
        </vg-modal>
   </div>
</template>
<script>
    import CheckpointMixins from "src/mixins/CheckpointMixins.js";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgConsommableSelector from "src/components/Vg/Selectors/VgConsommableSelector.vue";
    import CheckpointSeuils from "src/components/Vg/Checkpoints/CheckpointSeuils.vue";

    export default {
        name: 'vg-checkpoints-form',
        mixins: [ CheckpointMixins ],
        components:{
            VgModal,
            VgButton,
            VgInput,
            CheckpointSeuils,
            VgSelect,
            VgConsommableSelector
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "checkpoints-form-cancel": "Annuler",
            "checkpoints-form-save": "Enregistrer",
            "checkpoints-form-update": "Modifier",
            "checkpoints-form-delete": "Supprimer",
            "checkpoints-form-add": "Ajout",
            "checkpoints-form-checkpoints": "Point de vérification",
            "question": "Libellé du point",
            "rubrique": "Rubrique",
            "actions": "Actions",
            "type-reponse-attendue": "Réponse attendue",
            "erreur-on-non-conformite": "Texte si non conformité",
            "order-of-appearance": "Ordre dans checklist",
            "show-seuils-checkpoint": "Définir seuils",
            "erreur": "Non conforme",
            "consommation-de": "Consommation de",
            "choisir-consommable": "Choisir le consommable",
            "close": "Fermer",
            "select-consommable": "Consommable",
            "type-oui-non": "Oui/Non",
            "type-nombre": "Nombre",
            "type-index": "Index",
            "type-relever-usage-seuils": "Relevé usage seuils",
            "type-commentaire": "Commentaire",
            "type-oui-non-nc": "Oui/Non/NC",
            "type-consommation": "Consommation",
            "cancel": "Annuler",
            "update": "Modifier",
            "validate": "Valider",
            "delete": "Supprimer",
            "add": "Ajouter",
            "nombre-valeur-min": "Valeur minimum",
            "nombre-valeur-max": "Valeur maximum"
        },
        "en": {
            "checkpoints-form-cancel": "Cancel",
            "checkpoints-form-save": "Save",
            "checkpoints-form-update": "Update",
            "checkpoints-form-delete": "Delete",
            "checkpoints-form-add": "Add",
            "checkpoints-form-checkpoints": "checkpoints",
            "question": "Label",
            "actions": "Actions",
            "type-reponse-attendue": "Expected response",
            "order-of-appearance": "Order in checklist",
            "show-seuils-checkpoint": "Set threshold",
            "erreur-on-non-conformite": "Text if non-compliance",
            "consommation-de": "Consumption of",
            "choisir-consommable": "Choose spare part/consumable",
            "close": "Close",
            "select-consommable": "Consumable",
            "type-oui-non": "Yes/No",
            "type-nombre": "Number",
            "type-index": "Index",
            "type-relever-usage-seuils": "Usage threshold statement",
            "type-commentaire": "Comment",
            "type-oui-non-nc": "Yes/No/NC",
            "type-consommation": "Consumption",
            "cancel": "Cancel",
            "update": "Update",
            "validate": "Validate",
            "delete": "Delete",
            "add": "Add",
            "nombre-valeur-min": "Minimum value",
            "nombre-valeur-max": "Maximum value"
        }
    }
},
        props: {
            /**
            * @model
            */
            value:{
                type: Object,
                default:function(){
                    return {};
                }
            },
            isEditionMode:{
                type:Boolean,
                default:function(){
                    return true;
                }
            }
        },
        data: function() {
            return {
                checkpoints:[],
                editedCheckpoint:null,
                indexEditedCheckpoint: null,
                showEditSeuils:false,
                checkpoint:{
                    question: null,
                    type_reponse: "Oui/Non",
                    rubrique: null,
                    messageOnVerifError: " ",
                    orderOfAppearance: 1,
                    userId: this.$app.appID,
                    minVal: null,
                    maxVal: null,
                    idTache_id:null,
                    composant_id:null,
                    seuils:[],
                    consommable_id: null,
                    consommable: null
                },
                hideSendCheckpoint: false,
                showConsommableSelectorModal: false
            };
        },
        watch: {
            value: {
                handler: function(value) {
                    this.checkpoints = [...value.checkpoints];
                },
                deep: true
            },
        },
        computed:{
            getTypesReponse: function(){
                return [
                    {label: this.$t("type-oui-non"), value: "Oui/Non"},
                    {label: this.$t("type-nombre"), value: "Nombre"},
                    {label: this.$t("type-index"), value: "Index"},
                    {label: this.$t("type-relever-usage-seuils"), value: "relever_usage_seuils"},
                    {label: this.$t("type-commentaire"), value: "Commentaire"},
                    {label: this.$t("type-oui-non-nc"), value: "Oui/Non/NC"},
                    {label: this.$t("type-consommation"), value: "consommation"}
                ];
            },
            tacheExistsInDatabase:function(){
                return this.value.hasOwnProperty("id") && this.value.id;
            },
            disabledCreateCheckpoint: function(){
                if(this.checkpoint.type_reponse=="consommation") return this.hideSendCheckpoint || !this.checkpoint.consommable_id;
                else return this.hideSendCheckpoint || !this.checkpoint.question || !this.checkpoint.type_reponse || !this.checkpoint.messageOnVerifError;
            }
        },
        created: function() {
            this.checkpoints = [...this.value.checkpoints];
            if(this.tacheExistsInDatabase) this.checkpoint.idTache_id = this.value.id;
        },
        methods: {
            cancelEdit: function(){
                this.indexEditedCheckpoint = null;
                this.editedCheckpoint = null;
            },
            isEditedCheckpoint: function(checkpoint, index){
                return this.isEditionMode && this.editedCheckpoint && this.indexEditedCheckpoint==index;
            },
            onSelectConsommable: function(consommable){
                this.checkpoint.consommable = Object.assign({}, {}, consommable);
                this.checkpoint.consommable_id = consommable.id;
                this.showConsommableSelectorModal = false;
            },
            onSelectTypeReponse: function(type){
                if(type=="consommation") this.checkpoint.question = this.$t("consommation-de");
                else this.checkpoint.question = null;
            },
            /**
             * @param array seuils
             */
            changeSeuil: function(seuils){
                if(this.editedCheckpoint) this.editedCheckpoint.seuils = seuils;
                else this.checkpoint.seuils = seuils;
            },
            /**
            * handleUpdateCheckpoint.
            *
            * @param  {object} checkpoint
            */
            handleUpdateCheckpoint:function(checkpoint){
                checkpoint = this.removeUselessPropertiesBeforeupdate(checkpoint);
                this.checkpointsMixins_updateCheckpoint(checkpoint);
                return;
            },
            handleCreateCheckpoint:function(){
                if(this.tacheExistsInDatabase && this.checkpoint.question){
                    this.hideSendCheckpoint = true;
                    this.checkpointsMixins_createCheckpoints([this.checkpoint]).then((datas)=>{
                        this.checkpoints.push(datas[0]);
                        this.$emit("update-checkpoints", this.checkpoints);
                        this.resetCheckpointObject();
                        this.hideSendCheckpoint = false;
                    });
                }else if (!this.tacheExistsInDatabase && this.checkpoint.question) {
                    this.checkpoints.push(Object.assign({},{},this.checkpoint));
                    this.checkpoints = this.checkpoints.filter((chk)=>chk!=null && chk.hasOwnProperty("question"));
                    this.$emit("update-checkpoints", this.checkpoints);
                    this.resetCheckpointObject();
                }
                this.checkpoint.orderOfAppearance = this.checkpoints.length++;
                return;
            },
            /**
             * handleDeleteCheckpoint.
             *
             * @param  {integer} checkpointIndex position in checkpoints list
             */
            handleDeleteCheckpoint:function(checkpointIndex){
                if(this.tacheExistsInDatabase){
                        this.checkpointsMixins_deleteCheckpoint(this.checkpoints[checkpointIndex]);
                }
                this.checkpoints.splice(checkpointIndex, 1);
                this.$emit("update-checkpoints", this.checkpoints);
            },
            /**
             * handleSelectCheckpoint.
             *
             * @param  {object} checkpoint description
             */
            handleSelectCheckpoint:function(checkpoint){
                this.checkpoint = checkpoint;
                return;
            },
            /**
            * @param {object} checkpoint
            */
            setEditCheckpoint:function(checkpoint, index){
                if(this.isEditionMode){
                    this.editedCheckpoint = Object.assign({},{}, checkpoint);
                    this.indexEditedCheckpoint = index;
                }
            },
            /**
             * @param {integer} checkpointIndex position of checkpoints list
             */
            doneEdit:function(checkpointIndex){
                this.checkpoints[checkpointIndex] = this.editedCheckpoint;
                this.$emit("update-checkpoints", this.checkpoints);
                if(this.tacheExistsInDatabase) this.handleUpdateCheckpoint(this.checkpoints[checkpointIndex]);
                this.editedCheckpoint = null;
                this.indexEditedCheckpoint = null;
            },
            resetCheckpointObject:function(){
                this.checkpoint = {
                    question: null,
                    type_reponse: "Oui/Non",
                    rubrique: null,
                    messageOnVerifError: " ",
                    orderOfAppearance: 1,
                    userId: this.$app.appID,
                    minVal: null,
                    maxVal: null,
                    idTache_id:this.tacheExistsInDatabase? this.value.id:null,
                    composant_id:null,
                    seuils:[],
                    consommable_id: null,
                    consommable: null
                };
            },
            /**
             * removeUselessPropertiesBeforeupdate.
             *
             * @param  {object} checkpoint
             * @return {object} checkpoint object
             */
            removeUselessPropertiesBeforeupdate:function(checkpoint){
                if(checkpoint.hasOwnProperty("composant")) delete checkpoint.composant;
                return checkpoint;
            }
        },
        directives: {
          "checkpoint-focus": function(el, binding) {
            if (binding.value) {
              el.focus();
            }
          }
        }
    };
</script>
<style lang="scss" scoped>
.checkpoints-table{
    width: inherit;
    
    .header{
        display: grid;
        grid-template-columns: 3fr 3fr 2fr 1fr 2fr 1fr;
    }
    
    /*.header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
    }

    .footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
    }*/
}
    #checkpointsTable {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      .complement{
        font-weight: normal !important;
      }
      .complement-sm{
        width: 80px;
      }
      .required{
        color: red;
      }
    }

    #checkpointsTable td, #checkpointsTable th {
      border: 1px solid #dcdfe6;
      padding: 4px;
    }

    #checkpointsTable tr:nth-child(even){
        /*background-color: #whitesmoke;*/
    }

    #checkpointsTable tr:hover {
        /*background-color: whitesmoke;*/
    }

    #checkpointsTable th {
        font-size: 11px;
        padding: 10px;
      text-align: left;
      /*background-color: whitesmoke;*/
    }
    #checkpointsTable tr:last-child{
        margin-top:5px;
        /*background-color:whitesmoke;*/
    }
</style>
<docs>

    ### vg-checkpoints-form exemple:

    #### basic usage
    Comment here
    ```html static
          <vg-checkpoints-form></vg-checkpoints-form>
    ```

    ### Create form checkpoints
    ```js
    new Vue({
      data(){
        return {}
      },
      template: `
        <div>
            <vg-checkpoints-form>
            </vg-checkpoints-form>

        </div>
      `
    })
    ```
    ### update form checkpoints
    ```js
    new Vue({
      data(){
        return {
            checkpoints:{id:1}
        }
      },
      template: `
        <div>
            <vg-checkpoints-form v-model="checkpoints">
            </vg-checkpoints-form>

        </div>
      `
    })
    ```
</docs>
