<template>
    <div class="scanner-container">
        <button v-if="!isIOS && !showLoaderSuccess" :class="['btn-camera',{'btn-camera-high-quality':highResMode}]" @click="changeCameraQuality">
            <img src="static/assets/icone/custom/focus.svg" id="scann-icon-quality" />
        </button>
        <div id="reader"></div>
        <div class="feedback-scanner" v-if="showLoaderAwait">
            <i id="scann-icon-loader"></i>
            <span v-if="!isIOS">{{ $t("loading-scanner") }}</span>
            <span v-else>{{ $t("loading-scanner-ios") }}</span>
        </div>
        <div class="feedback-scanner" v-if="showLoaderFetchData">
            <i id="scann-icon-search"></i>
            <span>{{ $t("loading-datas") }}</span>
        </div>
        <div class="feedback-scanner" v-if="showLoaderSuccess">
            <i id="scann-icon-valid"></i>
            <span>{{ $t("loading-success") }}</span>
        </div>
        <div class="feedback-scanner feedback-scanner-error" v-if="showErrorMessage">
            <i id="scann-icon-error"></i>
            <span>{{ $t("error-message") }}</span>
        </div>
        <div class="feedback-scanner" v-if="showLoaderCheckCode">
            <i id="scann-icon-search"></i>
            <span>{{ $t("loading-check") }}</span>
        </div>
        <div class="feedback-scanner feedback-scanner-error" v-if="showLoaderCodeExistant">
            <i id="scann-icon-error"></i>
            <span>{{ $t("message-code-existant") }}</span>
        </div>
        <div class="feedback-scanner feedback-scanner-error" v-if="showErrorOuvertureScanner && permissionState">
            <i id="scann-icon-impossible"></i>
            <span>{{ $t("message-impossible-ouvrir-scanner-"+permissionState) }}</span>
            <vg-button type="grey" @click="reload">{{ $t("rafraichir-page") }}</vg-button>
        </div>
    </div>
</template>

<script>
import {Html5Qrcode} from "html5-qrcode";
import Metadatas from "src/services/Metadatas.js";
import equipementsMixins from "src/mixins/equipementsMixins.js";
import VgButton from "src/components/Vg/VgButton.vue";

export default {
    name: "vg-scanner",
    components: {
        VgButton
    },
    props:{
        /** récupère l'équipement/pièce associé au code scanné, 
         * si aucun équipement/pièce associé relance le scanner, 
         * sinon retourne l'équipement */
        isFetching:{
            type: Boolean,
            default: false
        },
        /** pour lancer le scann en mode scanner nouveau code, 
         * le scanner vérifie si le code n'est pas déjà associé à un équipement/pièce et le retourne,
         * sinon lerelance le scanner */
        isNewCode:{
            type: Boolean,
            default: false
        },
        collection:{
            type: Boolean,
            default: true
        }
    },
    mixins:[equipementsMixins],
    i18n: {
        messages: {
            fr: {
                "loading-scanner": "Chargement du scanner en cours...",
                "loading-scanner-ios": "Chargement du scanner compatible en cours...",
                "loading-success": "Scann réussi !",
                "loading-datas": "Vérification du QrCode en cours...",
                "error-message": "QrCode non connu...",
                "loading-check": "Vérification du QrCode en cours...",
                "message-code-existant": "QrCode déjà utilisé, veuillez scanner un autre QrCode...",
                "vers-scanner": "Revenir au scanner",
                "rafraichir-page": "Rafraîchir la page",
                "message-impossible-ouvrir-scanner-denied": "Accès à la caméra refusé. Veuillez l’autoriser dans les paramètres pour utiliser le scanner.",
                "message-impossible-ouvrir-scanner-prompt": "Permission requise. Veuillez autoriser l’accès à la caméra pour utiliser le scanner.",
                "message-impossible-ouvrir-scanner-granted": "Une erreur s'est produite. Veuillez rafraîchir la page et réessayer."
            },
            en: {
                "loading-scanner": "Loading scanner...",
                "loading-scanner-ios": "Loading compatible scanner...",
                "loading-success": "Scann successful !",
                "loading-datas": "Checking QrCode...",
                "error-message": "Unknown QrCode",
                "loading-check": "Checking QrCode...",
                "message-code-existant": "QrCode already used, please scan another QrCode...",
                "vers-scanner": "Back to scanner",
                "rafraichir-page": "Reload",
                "message-impossible-ouvrir-scanner-denied": "Camera access denied. Please allow it in the settings to use the scanner.",
                "message-impossible-ouvrir-scanner-prompt": "Permission required. Please allow camera access to use the scanner.",
                "message-impossible-ouvrir-scanner-granted": "An error occurred. Please refresh the page and try again."
            }
        }
    },
    data() {
        return {
            qrCodeScanner: null,
            currentCameraId: null,
            isScanning: false,
            showLoaderAwait: true,
            showLoaderSuccess: false,
            showErrorMessage: false,
            showLoaderFetchData: false,
            showLoaderCheckCode: false,
            showLoaderCodeExistant: false,
            showErrorOuvertureScanner: false,
            permissionState: null,
            isIOS: null,
            highResMode: false // Pour savoir si on est en mode amélioré
        };
    },
    mounted() {
        this.checkPermission();
        this.initDefaultHighResMode();
        this.initNavigatorUserAgentData();
        this.initScanner();
    },
    methods: {
        reload(){
            location.reload();
        },
        initDefaultHighResMode() {
            if(localStorage.getItem("scanner-high-res-mode")){
                this.highResMode = localStorage.getItem("scanner-high-res-mode")=="1";
            }
        },
        initNavigatorUserAgentData() {
            try{
                if(navigator && navigator.userAgentData){
                    let navigatorUserAgentData = navigator.userAgentData;
                    console.log("NAVIGATOR USER AGENT DATA", navigatorUserAgentData);
                    this.isIOS = /iPad|iPhone|iPod/.test(navigatorUserAgentData) && !window.MSStream;
                    if(this.isIOS) this.highResMode = true;
                }
            }catch(e){
                console.log("IMPOSSIBLE TO GET NAVIGATOR USER AGENT DATA", e);
            }
        },
        async checkPermission() {
            const status = await navigator.permissions.query({ name: "camera" });
            this.permissionState = status.state;
        },
        async initScanner() {
            try {
                const devices = await Html5Qrcode.getCameras();
                if (devices && devices.length) {
                    console.log("Caméras détectées:", devices);
                    
                    this.currentCameraId = devices[devices.length - 1].id;
                    this.qrCodeScanner = new Html5Qrcode("reader");

                    let constraints = {
                        fps: 10,
                        qrbox: { width: 250, height: 250 },
                        disableFlip: true, // Évite les erreurs sur certaines caméras
                        videoConstraints: {
                            facingMode: "environment"
                        }
                    };

                    if (this.highResMode) {
                        constraints.aspectRatio = 1.777; // 16:9 pour compatibilité
                        constraints.videoConstraints.width = { ideal: 1920 };
                        constraints.videoConstraints.height = { ideal: 1080 };
                    }

                    this.isScanning = true; // Active le scan
                    this.showLoaderAwait = false;
                    await this.qrCodeScanner.start(
                        this.currentCameraId,
                        constraints,
                        this.onScanSuccess,
                        this.onScanFailure
                    );
                }else{
                    this.showLoaderAwait = false;
                    this.showErrorOuvertureScanner = true;
                }
            } catch (err) {
                console.error("Erreur d'accès à la caméra :", err);
                this.showLoaderAwait = false;
                this.showErrorOuvertureScanner = true;
            }
        },
        async onScanSuccess(decodedText) {
            if (!this.isScanning) return; // Empêcher les scans multiples
            this.isScanning = false; // Désactiver le scan après une lecture
            if(this.isFetching){ 
                this.fetchData(decodedText);
            }else if(this.isNewCode){ 
                this.checkIfCodeExist(decodedText);
            }else{
                this.showLoaderSuccess = true;
                this.$emit("scanSuccess", decodedText);
            }
            try {
                await this.qrCodeScanner.stop(); // Arrêter le scanner
                console.log("Scanner arrêté après une détection réussie.");
            } catch (err) {
                console.error("Erreur lors de l'arrêt du scanner :", err);
            }
        },
        onScanFailure(error) {
            // Gestion des erreurs de scan (optionnel)
        },
        restartScanner() {
            this.showLoaderAwait = false;
            this.showLoaderSuccess = false;
            this.showErrorMessage = false;
            this.showLoaderFetchData = false;
            this.showLoaderCheckCode = false;
            this.showLoaderCodeExistant = false;
            if (!this.isScanning) {
                this.initScanner(); // Redémarrer le scan avec la résolution actuelle
            }
        },
        changeCameraQuality() {
            if (this.qrCodeScanner) {
                this.showLoaderAwait = true;
                this.qrCodeScanner.stop().then(() => {
                    this.highResMode = !this.highResMode;
                    localStorage.setItem("scanner-high-res-mode", this.highResMode ? "1" : "0");
                    this.initScanner(); // Redémarrer avec haute résolution
                }).catch(err => {
                    console.error("Erreur lors de l'arrêt du scanner :", err);
                });
            }
        },
        fetchEquipementsByQrCode: function(code){
            if(this.collection){
                return new Promise((resolve, reject)=>{
                    let metadatas = new Metadatas();
                    metadatas.setFilters({
                        qrCode: {attr: "e.qrCode", value: code, action: "equals"}
                    });
                    this.equipementsMixins_getEquipements(metadatas, {_stored: false}).then((equipements)=>{
                        if(equipements && equipements.datas && equipements.datas[0]) resolve(equipements.datas[0]);
                        else resolve(null);
                    }).catch((e)=>{
                        reject(e);
                    });
                });
            }else{
                return new Promise((resolve, reject)=>{
                    this.equipementsMixins_getEquipementByQrCode(code, {_stored: false}).then((equipement)=>{
                        resolve(equipement);
                    }).catch((e)=>{
                        reject(e);
                    });
                });
            }
        },
        fetchData: function(code){
            this.showLoaderFetchData = true;
            this.fetchEquipementsByQrCode(code).then((equipement)=>{
                this.showLoaderFetchData = false;
                if(equipement){ 
                    this.showLoaderSuccess = true;
                    this.$emit("scanSuccess", equipement);
                }else{
                    this.showLoaderFetchData = false;
                    this.showErrorMessage = true;
                    // timeout pour laisser le temps à l'utilisateur de lire le message
                    setTimeout(()=>{
                        this.restartScanner();
                    }, 1500);
                }
            }).catch((e)=>{
                this.showLoaderFetchData = false;
                this.showErrorMessage = true;
                // timeout pour laisser le temps à l'utilisateur de lire le message
                setTimeout(()=>{
                    this.restartScanner();
                }, 1500);
            });
        },
        checkIfCodeExist: function(code){
            this.showLoaderCheckCode = true;
            this.fetchEquipementsByQrCode(code).then((equipement)=>{
                this.showLoaderCheckCode = false;
                if(equipement){ 
                    this.showLoaderCodeExistant = true;
                    // timeout pour laisser le temps à l'utilisateur de lire le message
                    setTimeout(()=>{
                        this.restartScanner();
                    }, 1500);
                }else{
                    this.showLoaderCheckCode = false;
                    this.$emit("scanSuccess", code);
                }
            }).catch((e)=>{
                this.showLoaderCheckCode = false;
                this.$emit("scanSuccess", code);
            });
        }
    },
    beforeDestroy() {
        if (this.qrCodeScanner) {
            this.qrCodeScanner.stop().catch(err => console.error("Erreur lors de l'arrêt du scanner :", err));
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-camera {
    float: right;
    z-index: 999 !important;
    position: absolute;
    right: 20px;
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: #ccc;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.btn-camera-high-quality {
    background-color:#50b659;
}
#scann-icon-quality{
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url("static/assets/icone/custom/focus.svg");
    mask-image: url("static/assets/icone/custom/focus.svg");
}

.scanner-container {
    height: -webkit-fill-available;
    width:-webkit-fill-available;
    #reader{
        width: -webkit-fill-available;
    }
}
.feedback-scanner{
    color: #35B1EA;
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    gap:10px;
    width:100%;
    min-height: 50%;
    height: 100%;
    font-size: 20px;
    #scann-icon-loader{
        width: 60px;
        height: 60px;
        animation: rotate 0.8s linear infinite;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url("static/assets/icone/custom/loader-circle.svg");
        mask-image: url("static/assets/icone/custom/loader-circle.svg");
        background-color: #35B1EA;
    }
    #scann-icon-valid{
        width: 60px;
        height: 60px;
        animation: draw-check 0.5s ease-in-out forwards 0.3s;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url("static/assets/icone/custom/scan-face.svg");
        mask-image: url("static/assets/icone/custom/scan-face.svg");
        background-color: #35B1EA;
    }
    #scann-icon-search{
        width: 60px;
        height: 60px;
        animation: pulse 1.5s infinite;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url("static/assets/icone/custom/scan-search.svg");
        mask-image: url("static/assets/icone/custom/scan-search.svg");
        background-color: #35B1EA;
    }
    #scann-icon-error{
        width: 60px;
        height: 60px;
        animation: draw-error 0.5s ease-in-out forwards 0.3s;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url("static/assets/icone/custom/scan-line.svg");
        mask-image: url("static/assets/icone/custom/scan-line.svg");
        background-color: red;
    }
    #scann-icon-impossible{
        width: 60px;
        height: 60px;
        animation: draw-error 0.5s ease-in-out forwards 0.3s;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url("static/assets/icone/custom/ban.svg");
        mask-image: url("static/assets/icone/custom/ban.svg");
        background-color: red;
    }
}
.feedback-scanner-error{
    color: red;
}
.btn-back{
    font-size: 16px;
    background-color: #ccc;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    height:30px;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes draw-check {
    from {
        opacity: 0;
        transform: rotate(45deg) scale(0);
    }
    to {
        opacity: 1;
        transform: rotate(45deg) scale(1);
    }
    
}
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes draw-error {
    0% {
        opacity: 0;
        transform: rotate(0deg) scale(0);
    }
    100% {
        opacity: 1;
        transform: rotate(0deg) scale(1);
    }
    50% {
        opacity: 1;
        transform: rotate(0deg) scale(2);
    }
    
}
</style>