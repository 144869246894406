<template>
    <vg-modal
        :width="'70vw'"
        :title="$t('title') + ' ' + maintenancesIdsList.length"
        @close="$emit('close')">
        <template #body>
            <div :class="{'body-full-height': showAffectationPourTiers, 'body-height': !showAffectationPourTiers}">
                <div style="width:100%;">
                    <vg-input :title="$t('affecter-a')" isRequiredValue>
                        <vg-affectables-selector
                            :defaultAffectes="defaultAffectes"
                            :filtersTiers="$app.account_type=='prestataire'?filtersTiersAffectables:{}"
                            @select="onSelectAffectes"
                            @set-default-affectes="onSetDefaultAffectes"/>
                    </vg-input>
                    <br>
                    <div class="plan-intervention">
                        <vg-checkbox :label="$t('planifier-intervention')"
                            :defaultChecked="isDatePlanifiee"
                            :inputValue="isDatePlanifiee"
                            @checked="isDatePlanifiee=true;"
                            @unchecked="isDatePlanifiee=false;"
                            style="min-width:200px;"/>
                        <span v-if="isDatePlanifiee" style="color:#BBBCBE;font-size:12px;min-width:150px;">{{$t("date-previsionnelle")}}</span>
                        <vg-date-picker v-if="isDatePlanifiee"
                            :type="'datetime-local'"
                            :from="affectation.start"
                            :to="affectation.end"
                            @change="onChangeDateAffectation"/>
                    </div>
                    <div  style="margin-bottom:40px;">
                        <div class="send-email-section" v-show="true">
                            <vg-input :title="$t('email-destinataire')" isRequiredValue>
                                <vg-contacts-selector v-model="affectationEmail.destinataireTiers"
                                    :attributeValue="'email'"
                                    multiple
                                    :filters="contactsFilters"/>
                            </vg-input>
                            <vg-input :title="$t('email-cc-interne')">
                                <vg-users-selector v-model="affectationEmail.cc"
                                    :attributeValue="'email'"
                                    multiple
                                    showEmail
                                    :placeholder="$t('cc-interne-placeholder')" />
                            </vg-input>
                            <vg-checkbox v-show="isDatePlanifiee"
                                :label="$t('transmettre-date-previsionnelles-entreprise')"
                                :defaultChecked="affectationEmail.sendDatePlanifieeDansEmail"
                                :inputValue="affectationEmail.sendDatePlanifieeDansEmail"
                                @checked="affectationEmail.sendDatePlanifieeDansEmail=true;"
                                @unchecked="affectationEmail.sendDatePlanifieeDansEmail=false;"/>
                            <vg-input v-model="affectationEmail.commentaireEmail"
                                :title="$t('text-complementaire-email')"
                                :inputType="'textarea'"/>
                            <vg-input :title="$t('select-files-to-send')">
                                <vg-files-selector 
                                    @loading="isFilesSelectorLoading=$event"
                                    @downloaded-files="onDownloadedFiles"/>
                            </vg-input>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <vg-button type="default" @click="$emit('close')">{{$t("close")}}</vg-button>
            <vg-button type="success" :disabled="isFilesSelectorLoading || isLoading || isSaveButtonIsDisabled" @click="saveAffectation">{{$t("save")}}</vg-button>
        </template>
    </vg-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgDatePicker from "src/components/Vg/VgDatePicker.vue";
import VgAffectablesSelector from "src/components/Vg/Selectors/VgAffectablesSelector.vue";
import VgContactsSelector from "src/components/Vg/Selectors/VgContactsSelector.vue";
import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";
import VgFilesSelector from "src/components/Vg/Files/VgFilesSelector.vue";

import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
import AffectationsMixins from "src/mixins/AffectationsMixins.js";

export default {
    name: "vg-fichesav-affectation-multiple",
    components: {
        VgFichesavDescription,
        VgButton,
        VgInput,
        VgCheckbox,
        VgDatePicker,
        VgAffectablesSelector,
        VgContactsSelector,
        VgUsersSelector,
        VgFilesSelector
    },
    mixins: [MaintenanceMixins, AffectationsMixins],
    props: {
        maintenancesIdsList: {
            type: Array,
            required: true
        },
        defaultStartDate: {
            type: String,
            default: null
        },
        defaultEndDate: {
            type: String,
            default: null
        }
    },
    i18n: {
        "locale": navigator.language,
        "messages": {
            "fr": {
                "title": "Affectation fiches maintenances",
                "dans": "dans",
                "save": "Enregistrer",
                "close": "Annuler",
                "affecter-a": "Affecter à",
                "text-complementaire-email": "Texte complémentaire dans email (optionnel)",
                "planifier-intervention": "Planifier intervention ?",
                "date-previsionnelle": "Date prévisionnelle",
                "envoyer-email-entreprise": "Envoyer email à l'entreprise",
                "transmettre-date-previsionnelles-entreprise": "Transmettre les dates prévisionnelles à l'entreprise dans l'email",
                "envoyer-email-entreprise-infos": "dans le cas d'une affectation à une personne un email est systematiquement envoyé si cet utilisateur a réglé dans ses paramètres la réception de ce type d'email",
                "email-cc-interne": "Utilisateurs en CC (optionnel)",
                "email-destinataire": "Destinataire email",
                "cc-interne-placeholder": "Sélectionner les utilisateurs à mettre en CC",
                "select-files-to-send": "Sélectionner les fichiers à envoyer en pièces-jointes"
            },
            "en": {
                "title": "Maintenance sheet assignment",
                "dans": "in",
                "save": "Save",
                "close": "Cancel",
                "affecter-a": "Assign to",
                "text-complementaire-email": "Additionnal text to send in the email (optionnal)",
                "planifier-intervention": "Plan intervention ?",
                "date-previsionnelle": "Provisional date",
                "envoyer-email-entreprise": "Send email to company",
                "transmettre-date-previsionnelles-entreprise": "Send provisional dates to the company in the email",
                "envoyer-email-entreprise-infos": "in the case of an assignment to a person an email is systematically sent if this user has set in his parameters the reception of this type of email",
                "email-cc-interne": "Users in CC (optional)",
                "email-destinataire": "Email recipient",
                "cc-interne-placeholder": "Select users to include in CC",
                "select-files-to-send": "Select files to send as attachments"
            }
        }
    },
    watch: {
        maintenancesIdsList: {
            handler: function (newVal) {
                this.maintenancesIds = newVal;
            },
            deep: true
        }
    },
    created: function () {
        this.maintenancesIds = this.maintenancesIdsList;
    },
    data: function () {
        return {
            isDatePlanifiee: this.defaultStartDate && this.defaultEndDate ? true : false,
            affectation: {
                dateAffectation: null,
                affectes: [],
                start: this.defaultStartDate ? this.defaultStartDate : moment().format("YYYY-MM-DD HH:mm"),
                end: this.defaultEndDate ? this.defaultEndDate : moment().add(30, "minutes").format("YYYY-MM-DD HH:mm")
            },
            affectationEmail: {
                sendEmail: false,
                destinataireTiers: [],
                cc: this.getEmailCCLocalStorage,
                sendDatePlanifieeDansEmail: false,
                commentaireEmail: null,
                files: []
            },
            contactsFilters: {
                tiers_id: { attr: "c.tiers_id", value: null, action: "equals" }
            },
            filtersTiersAffectables: {
                type: { attr: "t.type", value: "Client", action: "not_equals" }
            },
            showAffectationPourTiers: false,
            isLoading: false,
            isFilesSelectorLoading: false,
            defaultAffectes: { users: [], tiers: [] }, // {users: [array of ids], tiers: [array of ids]}
            maintenances: [],
            maintenancesIds: [],
        };
    },
    computed: {
        isSaveButtonIsDisabled: function () {
            if (this.affectationEmail.sendEmail && this.affectationEmail.destinataireTiers.length == 0) return true;
            if (!this.affectation.affectes || this.affectation.affectes.length == 0) return true;
            else if (this.isDatePlanifiee && (!this.affectation.start || !this.affectation.end)) return true;
            else if (this.isDatePlanifiee && typeof (this.affectation.start) == "string" && this.affectation.start == "Invalid date") return true;
            else if (this.isDatePlanifiee && typeof (this.affectation.end) == "string" && this.affectation.end == "Invalid date") return true;
            else return false;
        },
    },
    methods: {
        onSetDefaultAffectes: function (affectes) {
            this.affectation.affectes = [...affectes];
        },
        onSelectAffectes: function (affectes) {
            this.affectation.affectes = [...affectes];
            let indexTiersAffecte = affectes.findIndex((affecte) => affecte.hasOwnProperty("name"));
            this.showAffectationPourTiers = true;
            //this.isDatePlanifiee = !this.showAffectationPourTiers;
            if (indexTiersAffecte != -1){ 
                this.contactsFilters.tiers_id.value = affectes[indexTiersAffecte].id;
            }
            this.affectationEmail = {
                sendEmail: false,
                destinataireTiers: [],
                cc: this.getEmailCCLocalStorage,
                sendDatePlanifieeDansEmail: false,
                commentaireEmail: null,
                files: []
            };
        },
        onChangeDateAffectation: function (data) {
            this.affectation.start = data.dateMin;
            this.affectation.end = data.dateMax;
        },
        saveAffectation: function () {
            this.isLoading = true;
            this.affectation.dateAffectation = moment().format("YYYY-MM-DD HH:mm");
            this.affectation.affectant_id = this.$app.idUser;
            if (!this.isDatePlanifiee) {
                this.affectation.start = null;
                this.affectation.end = null;
            }
            this.AffectationsMixins_saveSchedules(this.affectation, this.maintenancesIds).then(()=>{
                this.isLoading = false;
                this.$emit("success");
                alert("Affectation enregistrée avec succès");
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.detail-fichesav-modal {
    .fichesav-info {
        display: flex;
        > div {
            margin-bottom: 10px;
            &:first-child {
                color: lightgray;
                width: 40%;
            }
        }
    }
}
.plan-intervention {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}
.send-email-section {
    margin: 10px 35px;
}
@media only screen and (max-width: 1200px) {
    .plan-intervention {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .send-email-section {
        margin: 10px 0;
    }
}
.closebtn-fichesav {
    display: flex;
    justify-content: center;
}

.fichesav-description {
    margin-bottom: 15px;
    font-size: 16px;
    .vg-fichesav-description {
        display: inline;
    }
}
.body-full-height {
    /*height:100vh !important;*/
}
.body-height {
    /*height: auto !important;*/
}
</style>
