<template>
   <div class="select-releve" >

        <vg-text-filter v-model="searchfield" />

        <vg-tabs
            :tabs="tabs"
            :color="'dark'"
            @tabClicked="handleTabClick" />

        <div class="container-releves" v-if="getRelevesFiltered.length">
            <vg-button type="grey" v-for="(releve, index) in getRelevesFiltered" :key="'btn-releves-'+index"
                @click="goToProcessVerification(releve)">
                <span>{{releve.libel_equipement}}</span> <small>{{releve.qrCode}}</small>
            </vg-button>
        </div>

        <div class="container-releves" v-else>
            <span class="info">{{ $t("aucun-releves") }}</span>
        </div>

   </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import VgTextFilter from "src/components/Vg/VgTextFilter.vue"
import VgScanner from "src/components/Vg/VgScanner.vue";
import VgTabs from "src/components/Vg/VgTabs.vue";
import VgButton from "src/components/Vg/VgButton.vue";
export default {
    name: 'select-releve',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "aucun-releves": "Aucun relevés",
            "restante": "Restantes",
            "termines": "Terminés"
        },
        "en": {
            "aucun-releves": "No meter",
            "restante": "Remaining",
            "termines": "Done"
        },
        "th": {
            "aucun-releves": "ไม่มีการวัด"
        }
    }
}    ,
    mixins:[],
    components:{
        VgScanner,
        VgTextFilter,
        VgTabs,
        VgButton
    },
    props:{
        releves:{
            type: Array,
            default:function(){
                return [];
            }
        }
    },
    data: function() {
        return {
			searchfield: "",
            hideTacheDone:true
        };
    },
    computed: {
        getReleves: function(){
            return this.releves.filter((releve)=>this.getTypeReleve?releve.type_tache==this.getTypeReleve:true);
        },
        getRelevesFiltered:function(){
            let releves = this.getReleves;
            let tacheId = this.$route.params.tacheId;
            releves = releves.filter(releve=>releve.isVerificationNeeded == this.hideTacheDone && ( (!tacheId) || (tacheId && releve.idTache==tacheId)));
            //if(!this.searchfield) return releves.filter((r, index, self)=>self.findIndex(rr=>rr.id==r.id)==index);
            let equipements = releves.filter((releve)=>releve["libel_equipement"].includes(this.searchfield));
            //let filteredReleve = equipements.filter((r, index, self)=>self.findIndex(rr=>rr.id==r.id)==index);

            return equipements;
        },
        getTypeReleve: function(){
            if(this.$route.params.type=="compteur") return "Relever_compteur";
            else if(this.$route.params.type=="grandeur-physique") return "Relever_Grandeur_physique";
            else return null;
        },
        /**
        * @return integer
        */
        countTacheRestante:function(){
            if(!this.releves.length)return 0;
            let releves = this.releves;
            return releves.filter(compteur=>compteur.isVerificationNeeded == true).length;
        },
        /**
        * @return integer
        */
        countTacheTermines:function(){
            if(!this.releves.length)return 0;
            let releves = this.releves;
            return releves.filter(compteur=>compteur.isVerificationNeeded != true).length;
        },
        /**
        * @return array
        */
        tabs: function(){
            return [
                {
                    label: this.$t("restante"), name:"restante", counter: this.countTacheRestante, filters:{}
                },
                {
                    label: this.$t("termines"), name:"termine", counter: this.countTacheTermines, filters:{}
                }
            ];
        },
    },
    methods: {

        handleTabClick:function(e){
            e.name == "restante" ? this.hideTacheDone = true:this.hideTacheDone = false;
        },
        goToProcessVerification:function(releve){
            let dupliquesReleves = this.getReleves.filter((r)=>r.isVerificationNeeded == this.hideTacheDone && r.idEquipement==releve.idEquipement);
            if(dupliquesReleves.length==1){
                this.$router.push({name:"_process_verification", params:{type:"equipement", equipementId:releve.idEquipement, tacheId:releve.idTache} });
            }else{
                this.$router.push({name:"_process_verification_taches_selection", params: {type:this.$route.params.type, equipementId:releve.idEquipement}});
            }
        }
    },
    created: function(){

    },
    mounted: function(){
        this.$forceUpdate();
    }
};
</script>

<style lang="scss" scoped>
	.select-releve{
		height: 100%;
        width: 100%;
		.container-releves{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            overflow-y: auto;
            padding: 0;
            padding-top: 5px;
            .vg-button{
                width:100%;
                margin-right: 0px !important;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                background-color: #fff6ed !important;
                >small{
                    color: grey;
                }
            }
            .info{
				font-size: 11px;
				font-weight: italic;
				color: #606266;
			}
		}
	}
</style>
