<template>
    <vg-modal :title="$t('title')"
        @close="$emit('close')">
        <template #body-left>
            <div v-if="selectedCategories && selectedCategories.length != 0">
                <h3>{{ $t("selected-categories") }}</h3>
                <ul class="selected-categories-list">
                    <li v-for="(category, index) in selectedCategories" :key="'categorie-'+category.id">
                        {{ category.libelleCatgorie }}
                    </li>
                </ul>
            </div>
            <div v-else-if="selectedCategorie">
                <h3>{{ $t("selected-category") }}</h3>
                <p>{{ selectedCategorie.libelleCatgorie }}</p>
            </div>
            <div v-else>
                <p>{{ $t("no-category-selected") }}</p>
            </div>
        </template>
        <template #body-right>
            <h3>{{ $t("libelles-problems") }}</h3>
            <vg-todo-list v-model="libellesProblems"
                :displayedProperty="'libelle'"
                :placeholderInput="$t('placeholder-new-libelle')"
                @deleteItem="handleDeleteLibelleCategorie">
                <template v-slot:item="{item}">
                    <div>{{item.libelle}}</div>
                </template>
            </vg-todo-list>
        </template>
        <template #footer>
            <vg-button
                type="default"
                @click="$emit('close')" >
                {{ $t("cancel") }}
            </vg-button>
            <vg-button
                :disabled="isLoading || libellesProblems.length==0"
                type="success"
                @click="onSaveLibellesProblems" >
                {{ $t("save") }}
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>
import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgTodoList from 'src/components/Vg/VgTodoList.vue';
import LibellesCategorieMixins from "src/mixins/LibellesCategorieMixins.js";
import { mapGetters } from 'vuex';

export default {
    name: 'vg-categorie-libelles-form',
    components: {
        VgModal,
        VgButton,
        VgTodoList
    },
    mixins: [LibellesCategorieMixins],
    i18n: { 
        "locale": navigator.language,
        "messages": {
            "fr": {
                "title": "Configuration des libellés problèmes",
                "selected-categories": "Catégories sélectionnées",
                "selected-category": "Catégorie sélectionnée",
                "no-category-selected": "Aucune catégorie sélectionnée",
                "libelles-problems": "Libellés problèmes",
                "cancel": "Annuler",
                "save": "Enregistrer",
                "placeholder-new-libelle": "Nouveau libellé problème"
            },
            "en": {
                "title": "Problem labels configuration",
                "selected-categories": "Selected categories",
                "selected-category": "Selected category",
                "no-category-selected": "No category selected",
                "libelles-problems": "Problem labels",
                "cancel": "Cancel",
                "save": "Save",
                "placeholder-new-libelle": "New problem label"
            }
        }
    },
    props: {
        
    },
    data: function() {
        return {
            libellesProblems: [],
            isLoading: false
        };
    },
    computed: {
        ...mapGetters({
            selectedCategorie: 'CategoriesStore/getSelectedItem',
            selectedCategories: "CategoriesStore/getSelectedItems"
        })
    },
    watch: {
        
    },
    created: function() {
        this.initLibellesProblems();
    },
    methods: {
        initLibellesProblems: function() {
            // If we have a single selected category, use its libelles
            if (this.selectedCategorie && !this.selectedCategories?.length) {
                this.libellesProblems = this.selectedCategorie.libellesCategorie || [];
            } 
            // If we have multiple selected categories, start with default libelles
            else if (this.selectedCategories && this.selectedCategories.length!=0) {
                this.libellesProblems = [];
            } else {
                this.libellesProblems = [];
            }
        },
        handleDeleteLibelleCategorie: function(item) {
            if (item.id) {
                // Remove the item from the local array
                const index = this.libellesProblems.findIndex(libelle => libelle.id === item.id);
                if (index !== -1) this.libellesProblems.splice(index, 1);
                this.isLoading = true;
                this.LibellesCategorieMixins_delete(item.id).then(() => {
                    this.isLoading = false;
                });
            } else {
                // If the item doesn't have an ID, it's not saved to the server yet
                const index = this.libellesProblems.findIndex(libelle => libelle === item);
                if (index !== -1) this.libellesProblems.splice(index, 1);
            }
        },
        formatLibellesData: function(){
            let libellesData = [];
            // If we have a single selected category
            if (this.selectedCategorie && !this.selectedCategories?.length) {
                this.libellesProblems.forEach(libelle => {
                    if (!libelle.id) {
                        libellesData.push({
                            libelle: libelle.libelle,
                            categorie_id: this.selectedCategorie.id
                        });
                    }
                });
            } 
            // If we have multiple selected categories
            else if (this.selectedCategories && this.selectedCategories.length > 0) {
                this.selectedCategories.forEach(category => {
                    this.libellesProblems.forEach(libelle => {
                        libellesData.push({
                            libelle: libelle.libelle,
                            categorie_id: category.id
                        });
                    });
                });
            }
            return libellesData;
        },
        onSaveLibellesProblems: function() {
            this.isLoading = true;
            let libellesData = this.formatLibellesData();
            if(libellesData.length==0 || libellesData.every(libelle => libelle.id)) {
                this.isLoading = false;
                this.$emit("close");
                return;
            }
            this.LibellesCategorieMixins_create(libellesData).then((datas)=> {
                this.isLoading = false;
                this.$emit('save', datas);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.selected-categories-list {
    max-height: 200px;
    overflow-y: auto;
    padding-left: 20px;
    margin-top: 10px;
    
    li {
        margin-bottom: 5px;
    }
}
</style>
