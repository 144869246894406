import { render, staticRenderFns } from "./VgSnackbar.vue?vue&type=template&id=ad08c650&scoped=true"
import script from "./VgSnackbar.vue?vue&type=script&lang=js"
export * from "./VgSnackbar.vue?vue&type=script&lang=js"
import style0 from "./VgSnackbar.vue?vue&type=style&index=0&id=ad08c650&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad08c650",
  null
  
)

export default component.exports