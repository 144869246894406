<template>
	<div class="vg-synthese-maintenance-preventive-table">
		<div class="header">
			<span class="main-cell">{{ $t("header-categorie") }}</span>
			<div class="month-cells">
				<span class="month-cell" v-for="(month, index) in months">{{ $t("month-"+month.month, {year: month.year}) }}</span>
			</div>
		</div>
		<div class="body">
			<div class="table-row-bloc" v-for="(categorie, index) in syntheseGroupByCategories" 
				:key="'categorie-'+categorie.id">
				<div class="table-row section-row">
					<div class="main-cell section-cell">
						<span class="title"><vg-icon url="/static/assets/icone/custom/chevron-right.svg" size="md"/> {{ categorie.libelleCatgorie }}</span>
						<span>{{ categorie.nbEquipements }}</span>
					</div>
					<div class="month-cells">
						<div class="month-cell month-cell-clickable" v-for="month in months" :key="categorie.id+'-month-'+month.YYYYMM">
							<div v-if="!categorie.interventions || !categorie.interventions[month.YYYYMM]">
								
							</div>
							<div v-else
								:class="['month-cell-badge',{'badge-red': isCounterRed(categorie, month.YYYYMM),
									'badge-orange': isCounterOrange(categorie, month.YYYYMM),
									'badge-green': isCounterGreen(categorie, month.YYYYMM)}]">
								<span v-if="categorie.interventions[month.YYYYMM].nombre_interventions_realisees">
									{{$t("realisees")}}: {{ categorie.interventions[month.YYYYMM].nombre_interventions_realisees }}</span>
								<span v-if="categorie.interventions[month.YYYYMM].nombre_interventions_non_validees">
									{{$t("non-validees")}}: {{ categorie.interventions[month.YYYYMM].nombre_interventions_non_validees }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="table-row section-container" v-for="(categorieContrat, index) in categorie.contrats">
					<div class="main-cell">
						<span class="title" @click="openContrat(categorieContrat.contrat_id)">{{ categorieContrat.contrat_name }}</span>
						<span>{{ categorieContrat.libelleCatgorie }}</span>
						<small>{{ categorieContrat.contrat_tiers_name }} - {{ categorieContrat.contrat_type }}</small>
					</div>
					<div class="month-cells">
						<div class="month-cell month-cell-clickable" v-for="month in months" :key="categorieContrat.contrat_id+'-month-'+month.YYYYMM"
							@mouseover="showIconAdd.contrat_id=categorieContrat.contrat_id;showIconAdd.month=month.YYYYMM"
							@mouseleave="showIconAdd.contrat_id=null;showIconAdd.month=null">
							<div v-if="!categorieContrat.interventions">
								
							</div>
							<div v-else-if="categorieContrat.interventions[month.YYYYMM] && categorieContrat.interventions[month.YYYYMM].nombre_interventions_realisees!=null && categorieContrat.interventions[month.YYYYMM].nombre_interventions_non_validees!=null" 
								:class="['month-cell-badge',{'badge-red': isCounterRed(categorieContrat, month.YYYYMM),
									'badge-orange': isCounterOrange(categorieContrat, month.YYYYMM),
									'badge-green': isCounterGreen(categorieContrat, month.YYYYMM)}]">
								<span v-if="categorieContrat.interventions[month.YYYYMM].nombre_interventions_realisees">{{$t("realisees")}}: {{ categorieContrat.interventions[month.YYYYMM].nombre_interventions_realisees }}</span>
								<span v-if="categorieContrat.interventions[month.YYYYMM].nombre_interventions_non_validees">{{$t("non-validees")}}: {{ categorieContrat.interventions[month.YYYYMM].nombre_interventions_non_validees }}</span>
							</div>
							<div v-else-if="categorieContrat.interventions[month.YYYYMM] && categorieContrat.interventions[month.YYYYMM].nombre_interventions_programmees" 
								class="month-cell-badge badge-grey">
								<span>{{$t("prevu")}}: {{ categorieContrat.interventions[month.YYYYMM].nombre_interventions_programmees }}</span>
							</div>
							<div v-else>
								
							</div>
							<div style="display: flex;justify-content: center;align-items: center;gap: 2px;">
								<vg-button v-if="categorieContrat.interventions && categorieContrat.interventions[month.YYYYMM]" 
									v-show="showIconAdd.contrat_id==categorieContrat.contrat_id && showIconAdd.month==month.YYYYMM" 
									size="sm" type="grey" :key="categorieContrat.contrat_id+'-month-'+month.YYYYMM+'-btn-open'"
									@click="onClickDetails(categorieContrat, month)">
									<vg-icon url="/static/assets/icone/custom/zoom-in.svg" size="xs"/>
								</vg-button>
								<vg-button 
									v-show="showIconAdd.contrat_id==categorieContrat.contrat_id && showIconAdd.month==month.YYYYMM" 
									size="sm" type="grey" :key="categorieContrat.contrat_id+'-month-'+month.YYYYMM+'-btn-add'"
									@click="onClickAdd(categorieContrat, month)">
									<vg-icon url="/static/assets/icone/custom/plus.svg" size="xs"/>
								</vg-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<vg-modal v-if="showModalInterventions"
			:width="'70vw'"
			@close="onCloseModalInterventions">
			<template #header>
				<div>
					{{ $t("header-contrat", {month: $t("month-"+selectedInterventions.month), year: selectedInterventions.year}) }}
					<span style="font-weight: bold;margin-left:10px;">{{ selectedInterventions.categorieContrat.contrat_name }} - {{ selectedInterventions.categorieContrat.libelleCatgorie }}</span>
				</div>
			</template>
			<template #body>
				<div style="margin-bottom:5px;display: flex;justify-content: space-between;align-items: center;gap:10px;">
					<b>{{ $t("liste-des-interventions") }}</b>
					<small style="color:#333;">{{ $t("nb-interventions", {nb: selectedInterventions.counters.nombre_interventions_realisees+selectedInterventions.counters.nombre_interventions_non_validees}) }}</small>
				</div>
				<vg-interventions-table 
					:agFilters="interventionsFilters"/>
			</template>
			<template #footer>
				<vg-button type="default-info" @click="onCloseModalInterventions">{{ $t("close") }}</vg-button>
			</template>
		</vg-modal>
		<vg-intervention-form v-if="showInterventionForm"
			:idContrat="selectedCategorieContrat.contrat_id"
			:idTiers="selectedCategorieContrat.contrat_tiers_id"
			:defaultDatePrevisionnelleDebut="selectedYYYYMM+'-01 08:00'"
			:defaultDatePrevisionnelleFin="selectedYYYYMM+'-01 09:00'"
			@close="showInterventionForm=false;selectedCategorieContrat=null;selectedYYYYMM=null;"
			@save="onSaveCreateIntervention"/>
	</div>
</template>
<script>

	import SyntheseMaintenanceMixins from "src/mixins/SyntheseMaintenanceMixins.js";

    import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
    import VgTooltip from "src/components/Vg/VgTooltip.vue";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgIcon from "src/components/Vg/VgIcon.vue";
	import VgInterventionsTable from "src/components/Vg/Interventions/VgInterventionsTable.vue";
	import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";

	import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
	
    export default {
        name: 'vg-synthese-maintenance-preventive-table',
    	props: {
			startDate: {
				type: String,
				default: null
			},
			endDate:{
				type: String,
				default: null
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
        },
		watch: {
			filters: {
				handler: function(newfilters){
                    //this.metadatasContrats.setFilters(newfilters);
					this.fetch();
				},
				deep: true
			},
			searchQuery: {
				handler: function(newfilters){
					this.fetch();
				}
			},
			startDate: {
				handler: function(){
					this.fetch();
				}
			}
		},
		mixins: [
			SyntheseMaintenanceMixins
		],
		components: {
            VgTextFilter,
			VgTooltip,
			VgModal,
			VgButton,
			VgIcon,
			VgInterventionsTable,
			VgInterventionForm
		},
		i18n:    { "locale":navigator.language,
    "messages": {
		"fr": {
			"overlay-loading-text": "Chargement des données...",
			"overlay-no-rows-text": "Aucune donnée correspondant au filtrage",
			"header-categorie": "Catégorie",
			"month-01": "Janv",
			"month-02": "Fév",
			"month-03": "Mars",
			"month-04": "Avr",
			"month-05": "Mai",
			"month-06": "Juin",
			"month-07": "Juil",
			"month-08": "Août",
			"month-09": "Sept",
			"month-10": "Oct",
			"month-11": "Nov",
			"month-12": "Déc",
			"interne": "(interne)",
			"equipements": "eqts",
			"close": "Fermer",
			"prevu": "Prévu",
			"realisees": "Réalisées",
			"non-validees": "Non validées",
			"header-contrat": "Interventions - {month} {year}",
			"liste-des-interventions": "Liste des interventions",
			"nb-interventions": "{nb} interventions programmées",
			"aucun-contrat": "Aucun contrat",
			"add-contrat": "Ajouter un contrat",
			"add-intervention": "Ajouter intervention",
		},
		"en": {
			"overlay-loading-text": "Loading datas...",
			"overlay-no-rows-text": "No datas matching the filters",
			"header-categorie": "Category",
			"month-01": "Jan",
			"month-02": "Feb",
			"month-03": "Mar",
			"month-04": "Apr",
			"month-05": "May",
			"month-06": "Jun",
			"month-07": "Jul",
			"month-08": "Aug",
			"month-09": "Sep",
			"month-10": "Oct",
			"month-11": "Nov",
			"month-12": "Dec",
			"interne": "(internal)",
			"equipements": "eqts",
			"close": "Close",
			"prevu": "Scheduled",
			"realisees": "Realized",
			"non-validees": "Not validated",
			"header-contrat": "Interventions - {month} {year}",
			"liste-des-interventions": "List of interventions",
			"nb-interventions": "{nb} scheduled interventions",
			"aucun-contrat": "No contract",
			"add-contrat": "Add a contract",
			"add-intervention": "Add intervention",
		}
    }
},
        data: function(){
            return {
				showLoadingOverlay: false,
                metadatas: new Metadatas(),
				synthese: [],
				months: [],
				showModalInterventions: false,
				selectedInterventions: {
					categorieContrat: null,
					counters: null,
					month: null,
					year: null,
					YYYYMM: null
				},
				selectedCategorieContrat: null,
				selectedYYYYMM: null,
				showInterventionForm: false,
				showIconAdd: {contrat_id: null},
            };
        },
		created: function(){
			this.initTableau();
			this.fetch();
		},
		mounted: function(){
			window.addEventListener("askGenerateReport", this.generateReport);
		},
		methods: {
			onSaveCreateIntervention: function(){
				this.showInterventionForm = false;
				this.selectedCategorieContrat = null;
				this.selectedYYYYMM = null;
				this.fetch();
			},
			isCounterGreen: function(categorieContrat, YYYYmm){
				return categorieContrat.interventions[YYYYmm].nombre_interventions_realisees>0 && categorieContrat.interventions[YYYYmm].nombre_interventions_non_validees==0;
			},
			isCounterOrange: function(categorieContrat, YYYYmm){
				return categorieContrat.interventions[YYYYmm].nombre_interventions_realisees>0 && categorieContrat.interventions[YYYYmm].nombre_interventions_non_validees>0;
			},
			isCounterRed: function(categorieContrat, YYYYmm){
				return categorieContrat.interventions[YYYYmm].nombre_interventions_realisees==0 && categorieContrat.interventions[YYYYmm].nombre_interventions_non_validees>0;
			},
			openContrat: function(contratId){
				this.$router.push({name: "_contrat_id", params: {id: contratId}});
			},
			onClickDetails: function(categorieContrat, month){
				this.selectedInterventions.categorieContrat = categorieContrat;
				this.selectedInterventions.counters = categorieContrat.interventions[month.YYYYMM];
				this.selectedInterventions.month = month.month;
				this.selectedInterventions.year = month.year;
				this.selectedInterventions.YYYYMM = month.YYYYMM;
				this.showModalInterventions = true;
			},
			onClickAdd: function(categorieContrat, month){
				this.selectedCategorieContrat = categorieContrat;
				this.selectedYYYYMM = month.YYYYMM;
				this.showInterventionForm = true;
			},
			onCloseModalInterventions: function(){
				this.showModalInterventions = false;
				this.selectedInterventions = {
					categorieContrat: null,
					counters: null,
					month: null,
					year: null,
					YYYYMM: null
				};
			},
			generateMonths: function(){
				let start = new Date(this.startDate);
				let end = new Date(this.endDate);
				let months = [];

				// Normalisation des dates pour commencer à la première date du mois
				start.setDate(1);
				end.setDate(1);

				let year = null;
				let month = null;

				// Boucle pour ajouter chaque mois à la liste
				while (start <= end) {
					year = start.getFullYear();
					month = start.getMonth() + 1; // Les mois commencent à 0, donc on ajoute 1
					months.push({year: year, month: month.toString().padStart(2, '0'), YYYYMM: year+"-"+month.toString().padStart(2, '0')});
					start.setMonth(start.getMonth() + 1); // Passer au mois suivant
				}

				this.months = months;
			},
			initTableau: function(){
				this.generateMonths();
			},
			fetch: function(){
				this.$emit("loading", true);
				let filters = Object.assign({}, this.filters, {
					libelleCatgorie: {attr: "cat.libelleCatgorie", value: this.searchQuery, action: "contains", openParenthesis: true},
					contrat_name: {attr: "c.name", value: this.searchQuery, action: "contains", logicalOperator: "OR"},
					contrat_type: {attr: "c.type", value: this.searchQuery, action: "contains", logicalOperator: "OR"},
					contrat_tiers_name: {attr: "t.name", value: this.searchQuery, action: "contains", logicalOperator: "OR", closeParenthesis: true}
				});
				this.metadatas.setFilters(filters);
				this.SyntheseMaintenanceMixins_get(this.startDate, this.endDate, this.metadatas).then((datas)=>{
					this.$emit("loading", false);
					this.synthese = datas.categoriesContrats;
				});
			},
		},
        computed:{
			...mapGetters({
				
            }),
			syntheseGroupByCategories: function(){
				const groupedCategories = {};
				// Parcourir les éléments de la synthèse
				this.synthese.forEach(item => {
					const categoryKey = item.libelleCatgorie;
					// Si la catégorie n'existe pas encore, l'initialiser
					if (!groupedCategories[categoryKey]) {
						groupedCategories[categoryKey] = {
							id: item.id,
							libelleCatgorie: item.libelleCatgorie,
							tags: item.tags,
							icon: item.icon,
							isGe: item.isGe,
							interventions: {},
							contrats: []
						};
					}
					// Ajouter le contrat à la catégorie correspondante
					groupedCategories[categoryKey].contrats.push(item);
					if(item.interventions){
						// Parcourir les mois d'interventions
						Object.keys(item.interventions).forEach((YYYYMM)=>{
							if(!groupedCategories[categoryKey].interventions[YYYYMM]){
								groupedCategories[categoryKey].interventions[YYYYMM] = {
									nombre_interventions_programmees: 0,
									nombre_interventions_realisees: 0,
									nombre_interventions_non_validees: 0
								};
							}
							groupedCategories[categoryKey].interventions[YYYYMM].nombre_interventions_programmees += item.interventions[YYYYMM].nombre_interventions_programmees?item.interventions[YYYYMM].nombre_interventions_programmees:0;
							groupedCategories[categoryKey].interventions[YYYYMM].nombre_interventions_realisees += item.interventions[YYYYMM].nombre_interventions_realisees?item.interventions[YYYYMM].nombre_interventions_realisees:0;
							groupedCategories[categoryKey].interventions[YYYYMM].nombre_interventions_non_validees += item.interventions[YYYYMM].nombre_interventions_non_validees?item.interventions[YYYYMM].nombre_interventions_non_validees:0;
						});
					}
				});
				// Retourner les catégories regroupées sous forme de tableau
				return Object.values(groupedCategories);
			},
			interventionsFilters: function(){
				let startDate = new Date(this.selectedInterventions.counters.annee_mois);
				startDate.setDate(1);
				startDate = startDate.toISOString().split("T")[0];
				let endDate = new Date(this.selectedInterventions.counters.annee_mois);
				endDate.setMonth(endDate.getMonth() + 1);
				endDate = endDate.toISOString().split("T")[0];
				return {
					contrat_id: { attr:"i.contrat_id", value: this.selectedInterventions.categorieContrat.contrat_id, action:"equals" },
					datePrevisionnelleDebut: { attr:"i.datePrevisionnelleDebut", value: [startDate, endDate], action:"between", openParenthesis: true},
					dateEffectiveDebut: { attr:"i.dateEffectiveDebut", value: [startDate, endDate], action:"between", logicalOperator: "OR", closeParenthesis: true}
				};
			},
        }
    };
</script>
<style lang="scss" scoped>
.vg-synthese-maintenance-preventive-table{
	border: 1px solid #BDC3C7;
	font-size: 12px;
	height: 100%;
	.header{
		display: grid;
		grid-template-columns: minmax(200px, 1fr) 4fr;
		height: 40px;
		background-color: #f5f7f7;
		color: rgba(0, 0, 0, 0.54);
		font-weight: 600;
		font-size: 12px;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
		border-bottom: 1px solid #BDC3C7;
	}
	.body{
		height: calc(100% - 40px);
		overflow-y: auto;
		.table-row{
			display: grid;
			grid-template-columns: minmax(200px, 1fr) 4fr;
			justify-self: stretch;
			align-items: stretch;
			border-bottom: 1px solid #d9dcde;
		}
		.table-row:hover{
			background-color: #f5f7f7;
		}
		.section-row{
			background-color: #E8EAEE;
			font-weight: 600;
			color: #3999FF;
		}
		.section-container{
			padding-left:10px;

		}
	}
	.main-cell{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		border-right: 1px solid #d9dcde;
		padding: 5px;
		.title{
			font-weight: 600;
			color: #3999FF;
		}
		.title:hover{
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.month-cells{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
	}
	.month-cell{
		border-right: 1px solid #d9dcde;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2px;
		.month-cell-badge{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: white;
			font-size: 10px;
			white-space: nowrap;
			padding: 5px 4px 4px 4px;
			border-radius: 4px;
		}
		.badge-grey{
			background-color: #d2d5dd;
		}
		.badge-red{
			background-color: red
		}
		.badge-orange{
			background-color: orange
		}
		.badge-green{
			background-color: #50B659;
		}
	}
	.month-cell-clickable:hover{
		cursor: pointer;
		border: 1px solid #3999FF;
	}
	.collapser{
		cursor: pointer;
	}
	.collapser:hover{
		color: #3498db;
	}
	.progression-dot{
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin: 0 2px;
	}
	.progression-dot-grey{
		background-color: #d2d5dd;
	}
	.progression-dot-red{
		background-color: rgba(255, 0, 0, 0.5);
	}
	.progression-dot-orange{
		background-color: rgba(255, 165, 0, 0.5);
	}
	.progression-dot-green{
		background-color: rgba(0, 128, 0, 0.5);
	}
}
</style>
