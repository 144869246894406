<template>
    <div>
        <vg-datetime-viewer v-model="params.data.dateOperation">
        </vg-datetime-viewer>
        <br>
        <span v-if="params.data.username">{{$t("par")}} <span>{{params.data.username}}</span></span>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "date-operation-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components: {
            VgDatetimeViewer
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "par": "par"
        },
        "en": {
            "par": "by"
        },
        "th": {
            "par": "โดย"
        }
    }
}
    });
</script>

<style scoped>

</style>
