<template>
    <vg-modal :title="$t('title')"
        @close="$emit('close')">
        <template #body-left>
            <div v-if="selectedCategories && selectedCategories.length != 0">
                <h3>{{ $t("selected-categories") }}</h3>
                <ul class="selected-categories-list">
                    <li v-for="(category, index) in selectedCategories" :key="'categorie-'+category.id">
                        {{ category.libelleCatgorie }}
                    </li>
                </ul>
            </div>
            <div v-else-if="selectedCategorie">
                <h3>{{ $t("selected-category") }}</h3>
                <p>{{ selectedCategorie.libelleCatgorie }}</p>
            </div>
            <div v-else>
                <p>{{ $t("no-category-selected") }}</p>
            </div>
        </template>
        <template #body-right>
            <h3>{{ $t("associer-corps-detat") }}</h3>
            <vg-corps-detat-selector v-model="corpsDetat_id">
            </vg-corps-detat-selector>
        </template>
        <template #footer>
            <vg-button
                type="default"
                @click="$emit('close')" >
                {{ $t("cancel") }}
            </vg-button>
            <vg-button
                :disabled="isLoading"
                type="success"
                @click="onSave" >
                {{ $t("save") }}
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>
import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgCorpsDetatSelector from 'src/components/Vg/Selectors/VgCorpsDetatSelector.vue';
import CategorieMixins from "src/mixins/CategorieMixins.js";
import { mapGetters } from 'vuex';

export default {
    name: 'vg-categorie-corps-detat-form',
    components: {
        VgModal,
        VgButton,
        VgCorpsDetatSelector
    },
    mixins: [CategorieMixins],
    i18n: { 
        "locale": navigator.language,
        "messages": {
            "fr": {
                "title": "Configuration du corps d'état",
                "associer-corps-detat": "Associer un corps d'état",
                "selected-categories": "Catégories sélectionnées",
                "selected-category": "Catégorie sélectionnée",
                "no-category-selected": "Aucune catégorie sélectionnée",
                "cancel": "Annuler",
                "save": "Enregistrer"
            },
            "en": {
                "title": "Configure the associated curative sheet ",
                "associer-corps-detat": "Associate a technical field",
                "selected-categories": "Selected categories",
                "selected-category": "Selected category",
                "no-category-selected": "No category selected",
                "cancel": "Cancel",
                "save": "Save"
            }
        }
    },
    props: {
        
    },
    data: function() {
        return {
            isLoading: false,
            corpsDetat_id: null
        };
    },
    computed: {
        ...mapGetters({
            selectedCategorie: 'CategoriesStore/getSelectedItem',
            selectedCategories: "CategoriesStore/getSelectedItems"
        })
    },
    watch: {
        
    },
    created: function() {
        if(this.selectedCategorie && this.selectedCategorie.corpsDetat_id){
            this.corpsDetat_id = this.selectedCategorie.corpsDetat_id;
        }
    },
    methods: {
        formatData: function(){
            // If we have a single selected category
            if (this.selectedCategorie && !this.selectedCategories?.length) {
                return [
                    {
                        id: this.selectedCategorie.id,
                        corpsDetats: [
                            {
                                id: this.corpsDetat_id
                            }
                        ]
                    }
                ];
            } 
            // If we have multiple selected categories
            else if (this.selectedCategories && this.selectedCategories.length > 0) {
                let datas = [];
                this.selectedCategories.forEach(category => {
                    datas.push({
                        id: category.id,
                        corpsDetats: [
                            {
                                id: this.corpsDetat_id
                            }
                        ]
                    });
                });
                return datas;
            }
        },
        onSave: function() {
            this.isLoading = true;
            let datas = this.formatData();
            this.CategorieMixins_updateCollection(datas).then(() => {
                this.isLoading = false;
                this.$emit('save');
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.selected-categories-list {
    max-height: 200px;
    overflow-y: auto;
    padding-left: 20px;
    margin-top: 10px;
    
    li {
        margin-bottom: 5px;
    }
}
</style>
