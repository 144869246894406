<template>
    <vg-operation-viewer
        v-model="operation"
        :fm="fm"
        @update="onUpdate"
        @open-cloture-photo="onOpenCloturePhoto" />
</template>

<script>
    import Vue from "vue";

    import VgOperationViewer from "src/components/Vg/Operation/VgOperationViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "operation-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true
			},
			operation: {
				type: Object,
				required: true
			},
            fm: {
                type: Object
            }
		},
        data: function(){
            return {

            };
        },
        watch:{

        },
        components: {
            VgOperationViewer
        },
        methods:{
            onUpdate: function(operation){
                this.$emit("update", operation);
            },
            onOpenCloturePhoto: function(photo){
                console.log("open-cloture-photo+++++++++++", photo);
                this.$emit("open-cloture-photo", photo);
            }
        }
    });
</script>

<style lang="scss" scoped>
.link{
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
}
.prestataire{
    color:#409eff;
    font-size:14px;
    text-decoration:underline;
    cursor:pointer;
    font-weight:bold;
}
.cout{
    color:#409eff;
    font-size:14px;
    font-weight:bold;
    padding-left:0px;
}
.rate{
    color:#409eff;
    font-size:11px;
    font-weight:bold;
    padding-left:0px;
}
.user-name{
    font-weight: bold;
}
.subtitle{
    color:#409eff;
    font-size:11px;
}
.subsubtitle{
    color:#7f7f7f;
    font-size:11px;
}
</style>
