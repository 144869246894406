<template>
    <vg-app-layout
        :title="pageTitle"
        :icon="$vgutils.isMobile()?'cle_blanc.png':'fiche-de-maintenance.png'"
        @save-filters="FiltersMixins_saveFilters(agfilters)"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        :globalFilters="hasActiveGlobalFilters"
        :filtersTitle="$t('modal-filters-title')"
        :canExportXls="false"
        @action-export="handleActionExport"
        @remove-filters="onRemoveFilters"
        @global-filters-change="onGlobalFiltersChange"
        @global-filters-remove="deleteGlobalFilters">

        <template #action-button-item>
            <li @click="openRapportPrinter">{{$t("generer-rapport")}}</li>
        </template>

        <template #search>
            <vg-text-filter v-model="searchQuery"></vg-text-filter>
        </template>

        <template #header-top-right-pre>
            <img src="/static/assets/icone/mobile/scanner.png" width="28" height="28" @click="showScanner=true;"/>
        </template>

        <template #panel-filters-attributs>
            <div class="filters-group">
                <div class="filters-group__quick-actions">
                    <vg-input class="filters-group__item">
                        <vg-checkbox
                            :inputValue="agfilters.field_urgence.value"
                            :defaultChecked="agfilters.field_urgence.value"
                            @checked="agfilters.field_urgence.value=true"
                            @unchecked="agfilters.field_urgence.value=false">
                            <div class="checkbox-content">
                                <img src="static/assets/icone/urgence.png" class="checkbox-icon"/>
                                <span>{{$t('urgence')}}</span>
                            </div>
                        </vg-checkbox>
                    </vg-input>

                    <vg-input class="filters-group__item">
                        <vg-checkbox
                            :inputValue="agfilters.field_isBloquant.value"
                            :defaultChecked="agfilters.field_isBloquant.value"
                            @checked="agfilters.field_isBloquant.value=true"
                            @unchecked="agfilters.field_isBloquant.value=false">
                            <div class="checkbox-content">
                                <img src="static/assets/icone/maintenance-bloquante-red.svg" class="checkbox-icon"/>
                                <span>{{$t('bloquantes')}}</span>
                            </div>
                        </vg-checkbox>
                    </vg-input>
                </div>

                <div class="filters-group__main">
                    <vg-input
                        class="filters-group__item"
                        :placeholder="$t('corps-detat')"
                        :title="$t('corps-detat')"
                        v-tooltip="$t('corps-detat-tooltip')">
                        <vg-corps-detat-selector
                            v-model="agfilters.field_corpsDetat.value"
                            @change="handleChangeCorpsDetat" />
                    </vg-input>

                    <vg-input
                        class="filters-group__item"
                        :placeholder="$t('affectes')"
                        :title="$t('affectes')"
                        v-tooltip="$t('affectes-tooltip')">
                        <div class="selector-group">
                            <vg-users-selector v-model="agfilters.field_user_affecte.value" />
                            <vg-tiers-selector v-model="agfilters.field_tier_affecte.value" />
                        </div>
                    </vg-input>

                    <vg-input
                        class="filters-group__item"
                        :placeholder="$t('demandeur')"
                        :title="$t('demandeur')"
                        v-tooltip="$t('demandeur-tooltip')">
                        <vg-users-selector v-model="agfilters.field_demandeur.value" />
                    </vg-input>
                </div>

                <vg-filter-maintenance-origine class="filters-group__item" @input="handleChangeOriginFilter"/>
            </div>
        </template>

        <template #panel-filters-localisation>
            <vg-filter-sites-selector v-model="agfilters.field_path.value"/>
        </template>

        <template #panel-widget-lieux>
            <vg-tree-lieux v-model="agfilters.field_path.value"/>
        </template>

        <template #header-bottom-left-multiple-actions-count>
            <span v-if="getSelectedMaintenances.length">
                {{$t("fiches-curatives-selected", {nb: getSelectedMaintenances.length})}}
            </span>
        </template>
        <template #header-bottom-left-multiple-actions-buttons> 
            <vg-button v-if="getSelectedMaintenances.length"
                type="primary" 
                @click="showClotureModal=true;">
                <i class="fas fa-check-circle"></i>
                {{$t("cloturer")}}
            </vg-button>
            <vg-button v-if="getSelectedMaintenances.length"
                type="default" 
                @click="handlePriseEnCompte">
                {{$t("prendre-en-compte")}}
            </vg-button>
            <vg-button v-if="getSelectedMaintenances.length"
                type="default" 
                @click="handleMiseEnAttente">
                {{$t("mettre-en-attente")}}
            </vg-button>
            <vg-button v-if="getSelectedMaintenances.length"
                type="default" 
                @click="showAffectationMultipleModal=true;">
                {{$t("affecter")}}
            </vg-button>
            <vg-button v-if="getSelectedMaintenances.length"
                type="default-danger"
                v-show="getSelectedMaintenances.length == 1" 
                @click="openDialogBoxConfirmationSuppression">
                {{$t("supprimer")}}
            </vg-button>
        </template>

        <template #primary>
            <div v-if="$vgutils.isMobile() && showInfo" style="width:100%;color:white;display:flex;justify-content:space-between;background-color:#059cff;padding:5px 0;">
                <span style="width:90%;text-align:center;">{{$t("info-long-click-mobile")}}</span>
                <div @click="onCloseInfo()" style="text-align:center;width:10%;"><i class="fas fa-times"></i></div>
            </div>
            <vg-maintenances-tabs v-model="focusedTab" @focused="onFocusedTab"/> 
            <vg-maintenance-timeline 
                v-if="$vgutils.isMobile() && focusedTab && focusedTab=='mesAffectationsPlanifiees'"
                :agfilters="agfilters">
            </vg-maintenance-timeline>
            
            <vg-fichesav-table 
                v-else-if="$vgutils.isMobile() && focusedTab && focusedTab=='mesAffectationsNonPlanifiees'"
                :searchQuery="searchQuery"
                :filters="maintenanceFilters"
                counterName="filtered"
                :page="'maintenances'">
            </vg-fichesav-table>

            <vg-fichesav-table 
                v-else
                :searchQuery="searchQuery"
                :filters="maintenanceFilters"
                :showColumnsFinances="showColumnsFinances"
                counterName="filtered"
                :page="'maintenances'"
                :isOnlyNonAffectes="focusedTab=='nonAffecte'">
            </vg-fichesav-table>
        </template>

        <template #pagination>
        </template>

        <template #secondary-filters>
            <vg-button v-show="showColumnsFinances" @click="showColumnsFinances=!showColumnsFinances"  >
                {{$t("cacher-couts")}}
            </vg-button>
            <vg-button v-show="!showColumnsFinances" @click="showColumnsFinances=!showColumnsFinances"  >
                {{$t("afficher-couts")}}
            </vg-button>
            <vg-input :title="$t('filter-Date')" >
                <vg-filter-interval-date
                    :defaultStartDate="agfilters.field_dateOuvertureSAV.value?agfilters.field_dateOuvertureSAV.value[0]:null"
                    :defaultEndDate="agfilters.field_dateOuvertureSAV.value?agfilters.field_dateOuvertureSAV.value[1]:null"
                    @change="handleFilterIntervalDate" />
            </vg-input>
            <vg-collapse :title="$t('filtres-complementaires')"
                :killContentOnCollapse="false">
                <template #content>
                    <vg-filter-maintenance-origine  @input="handleChangeOriginFilter"/>
                    <vg-typologie-maintenance-filter v-show="hasAtLeastOneTypologieMaintenance" v-model="agfilters.field_typologie.value"/>
                    <vg-filter-site v-model="agfilters.field_path.value" />
                    <vg-filter-service v-model="agfilters.field_service.value" />
                    <vg-input
                        :placeholder="$t('corps-detat')"
                        :title="$t('corps-detat')">
                        <vg-corps-detat-selector
                            v-model="agfilters.field_corpsDetat.value"
                            @change="handleChangeCorpsDetat" />
                    </vg-input>
                    <vg-input
                        :placeholder="$t('affectes')"
                        :title="$t('affectes')">
                        <vg-users-selector v-model="agfilters.field_user_affecte.value" />
                        <vg-tiers-selector v-model="agfilters.field_tier_affecte.value" />
                    </vg-input>
                    <vg-input
                        :placeholder="$t('demandeur')"
                        :title="$t('demandeur')">
                        <vg-users-selector v-model="agfilters.field_demandeur.value" />
                    </vg-input>
                </template>
            </vg-collapse>
        </template>

        <template #secondary-widget>
            <vg-tree-lieux v-model="agfilters.field_path.value" />
        </template>

        <dialog-secured-action
            v-if="isModalConfirmDeleteOpened"
            :valid-response="getSelectedMaintenances.length"
            @save="handleSupprimerMaintenance"
            @close="isModalConfirmDeleteOpened = false">
            <template v-slot:header-title>
                {{ $t("user-form-confirm-suppression") }}
           </template>
        </dialog-secured-action>

        <vg-modal v-if="showScanner"
            :title="$t('scanner-une-etiquette')"
            @close="showScanner=false;">
            <template #body>
                <vg-scanner
                    :isDemo="false"
                    @scanSuccess="onScannSuccess" />
            </template>
            <template #footer>
                <vg-button @click="showScanner=false;">
                    {{$t('close')}}
                </vg-button>
            </template>
        </vg-modal>

        <vg-maintenances-cloture-form v-if="showClotureModal"
            @close="showClotureModal=false;"
            @save="onSaveClotures"/>

        <vg-fichesav-affectation-multiple v-if="showAffectationMultipleModal" 
            :maintenancesIdsList="getSelectedMaintenances"
            @close="showAffectationMultipleModal=false;"
            @success="handleAffectationMultipleSuccess" />

    </vg-app-layout>
</template>

<script>
    import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
    import LieuMixins from "src/mixins/LieuMixins.js";
    import FiltersMixins from "src/mixins/FiltersMixins.js";
    import VgFichesavTable from 'src/components/Vg/FicheSAV/VgFichesavTable.vue';
    import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgFilterIntervalDate from "src/components/Vg/FicheSAV/VgFilterIntervalDate.vue";
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgCollapse from "src/components/Vg/VgCollapse.vue";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
    import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
    import VgGroup from "src/components/Vg/VgGroup.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgModal from "src/components/Vg/VgModal.vue";
    import Metadatas from "src/services/Metadatas.js";
    import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
    import VgMaintenanceTimeline from 'src/components/Vg/FicheSAV/VgMaintenanceTimeline.vue';
    import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
    import VgTypologieMaintenanceFilter from "src/components/Vg/Filters/VgTypologieMaintenanceFilter.vue";
    import VgCorpsDetatSelector from "src/components/Vg/Selectors/VgCorpsDetatSelector.vue";
    import VgFilterService from 'src/components/Vg/Filters/VgFilterService.vue';
    import VgUsersSelector from 'src/components/Vg/Selectors/VgUsersSelector.vue';
    import VgTiersSelector from 'src/components/Vg/Selectors/VgTiersSelector.vue';
    import VgFilterMaintenanceOrigine from "src/components/Vg/Filters/VgFilterMaintenanceOrigine.vue";
    import VgMaintenancesClotureForm from "src/components/Vg/Forms/VgMaintenancesClotureForm.vue";
    import VgScanner from "src/components/Vg/VgScanner.vue";
    import VgFichesavAffectationMultiple from "src/components/Vg/FicheSAV/VgFichesavAffectationMultiple.vue";
    import VgMaintenancesTabs from "src/components/Vg/FicheSAV/VgMaintenancesTabs.vue";
    import { mapGetters } from 'vuex';

    export default {
        name: 'maintenances',
        components: {
            VgFichesavTable,
            VgTextFilter,
            VgFilterIntervalDate,
            VgInput,
            VgSelect,
            VgCollapse,
            VgCheckbox,
            VgTreeLieux,
            VgGroup,
            VgButton,
            VgModal,
            DialogSecuredAction,
            VgMaintenanceTimeline,
            VgFilterSite,
            VgCorpsDetatSelector,
            VgTypologieMaintenanceFilter,
            VgScanner,
            VgFilterService,
            VgUsersSelector,
            VgTiersSelector,
            VgFilterMaintenanceOrigine,
            VgMaintenancesClotureForm,
            VgFichesavAffectationMultiple,
            VgMaintenancesTabs
        },
        i18n: {
            "locale": navigator.language,
            "messages": {
                "fr": {
                    "corps-detat": "Filtrage par corps d'état",
                    "affectes": "Fiche affectée à",
                    "demandeur": "Demandeur",
                    "prendre-en-compte": "Prendre en compte",
                    "mettre-en-attente": "Mettre en attente",
                    "supprimer": "Supprimer",
                    "cloturer": "Clôturer",
                    "filter-Site": "Site",
                    "filter-Date": "intervalle dates",
                    "filtres": "Filtres",
                    "page-title": "Fiches curatives",
                    "generer-rapport": "Imprimer rapport",
                    "user-form-confirm-suppression": "Vous allez supprimer des fiches cette action est irréversible.",
                    "afficher-couts": "Afficher les coûts",
                    "cacher-couts": "Cacher les coûts",
                    "urgence": "Urgence",
                    "liste-maintenances": "Liste-fiches-curatives",
                    "info-long-click-mobile": "Nouveau: clic long ouvre les actions",
                    "modal-filters-title": "Filtrer les fiches curatives",
                    "close": "Fermer",
                    "filtrage-sauvegarde": "Les filtres ont étés mémorisés",
                    "scanner-une-etiquette": "Scanner une étiquette",
                    "filtres-complementaires": "Filtres complémentaires",
                    "fiches-curatives-selected": "{nb} fiche(s) sélectionnée(s)",
                    "corps-detat-tooltip": "Sélectionnez le corps d'état pour filtrer les fiches",
                    "affectes-tooltip": "Filtrer par personne ou société affectée",
                    "demandeur-tooltip": "Filtrer par demandeur",
                    "cloturer-tooltip": "Clôturer les fiches sélectionnées"
                },
                "en": {
                    "corps-detat": "Filtered by technical field",
                    "affectes": "Sheet assigned to",
                    "demandeur": "Requester",
                    "filter-Site": "Site",
                    "filter-Date": "date interval",
                    "filtres": "Filters",
                    "page-title": "Curative sheets",
                    "generer-rapport": "Print report",
                    "user-form-confirm-suppression": "Are you sure to delete permanently these sheets.",
                    "prendre-en-compte": "Consider",
                    "retour-a-faire": "Require a Feedback",
                    "mettre-en-attente": "Put on hold",
                    "supprimer": "Delete",
                    "cloturer": "Resolve",
                    "afficher-couts": "Display cost columns",
                    "cacher-couts": "Hide cost columns",
                    "urgence": "Emergency",
                    "liste-maintenances": "Curative-sheets-list",
                    "info-long-click-mobile": "New: long click opens actions",
                    "modal-filters-title": "Filter curative sheets",
                    "close": "Close",
                    "filtrage-sauvegarde": "Filters has been saved",
                    "scanner-une-etiquette": "Scan a label",
                    "filtres-complementaires": "Additional filters",
                    "fiches-curatives-selected": "{nb} selected sheets",
                    "corps-detat-tooltip": "Select technical field to filter sheets",
                    "affectes-tooltip": "Filter by assigned person or company",
                    "demandeur-tooltip": "Filter by requester",
                    "cloturer-tooltip": "Close selected sheets"
                }
            }
        },
        mixins: [
            MaintenanceMixins,
            LieuMixins,
            FiltersMixins
        ],
        props: {
            openScanner: {
                type: Boolean,
                default: false
            },
            defaultFilters: {
                type: Object,
                default: function () {
                    return {};
                }
            }
        },
        data: function () {
            return {
                searchQuery: "",
                focusedTab: null,
                isModalConfirmDeleteOpened: false,
                agfilters: {
                    id: { attr: "fm.id", colId: "id", value: null, action: "contains", openParenthesis: true },
                    libelEquipement: { attr: "eq.libel_equipement", colId: "libel_equipement", value: null, action: "contains", logicalOperator: "OR" },
                    libelComposant: { attr: "comp.libelComposant", colId: "libelComposant", value: null, action: "contains", logicalOperator: "OR" },
                    userNomAffecte: { attr: "fuaffecte.nom", colId: "nom_affecte", value: null, action: "contains", logicalOperator: "OR" },
                    userPrenomAffecte: { attr: "fuaffecte.prenom", colId: "prenom_affecte", value: null, action: "contains", logicalOperator: "OR" },
                    tiersAffecte: { attr: "ti.name", colId: "tiers_name", value: null, action: "contains", logicalOperator: "OR" },
                    commentaireClient: { attr: "fm.commentaireClient", colId: "commentaireClient", value: null, action: "contains", logicalOperator: "OR", closeParenthesis: true },
                    field_typologie: { attr: "fm.typologie", colId: "typologie", value: null, action: "equals" },
                    field_origin: { attr: "fm.origin", colId: "origin", value: null, action: "equals" },
                    field_service: { attr: "l.service", colId: "service", value: null, action: "equals" },
                    field_path: { attr: "l.path", colId: "path", value: null, action: "contains" },
                    field_corpsDetat: { attr: "ce.id", colId: "ce.id", value: null, action: "equals" },
                    field_user_affecte: { attr: "affu.user_id", value: null, action: "equals" },
                    field_tier_affecte: { attr: "afft.tiers_id", value: null, action: "equals" },
                    field_demandeur: { attr: "fm.idUser", value: null, action: "equals" },
                    field_statut: { attr: "fm.statut", colId: "statut", value: ["En_cours", "prise_en_compte", "en_attente"], action: "contains" },
                    field_dateOuvertureSAV: { attr: "fm.dateOuvertureSAV", colId: "dateOuvertureSAV", value: null, action: "between" },
                    field_dateFermetureSAV: { attr: "fm.dateFermetureSAV", colId: "dateFermetureSAV", value: null, action: "between" },
                    field_urgence: { attr: "fm.urgence", colId: "urgence", value: false, action: "equals" },
                    field_isBloquant: { attr: "fm.isBloquant", colId: "isBloquant", value: null, action: "equals" },
                    field_retourAFaire: { attr: "fm.isRetourAFaire", colId: "retourAFaire", value: false, action: "equals" },
                    field_relance: { attr: "fm.isRelance", colId: "relance", value: false, action: "equals" },
                    field_affectation: { attr: "aff.id", colId: "affectation", value: null, action: "is_not_null" },
                    field_mesAffectations: { attr: "aff.id", colId: "mesAffectations", value: null, action: "is_not_null" },
                    field_codeEquipement: { attr: "eq.qrCode", value: null, action: "equals", openParenthesis: true },
                    field_codeLieu: { attr: "l.codeUn", value: null, action: "equals", closeParenthesis: true, logicalOperator: "OR" },
                },
                showColumnsFinances: false,
                showInfo: true,
                showScanner: this.openScanner,
                showClotureModal: false,
                showAffectationMultipleModal: false
            }
        },
        watch: {
            agfilters: {
                handler: function (nfilters) {
                    this.FiltersMixins_saveFilters(nfilters);
                },
                deep: true
            },
            searchQuery: {
                handler: function (query) {
                    if (query != null && query.length) {
                        let searchQuery = query.split(" ").join('","');
                        this.agfilters.id.value = searchQuery;
                        this.agfilters.libelEquipement.value = searchQuery;
                        this.agfilters.libelComposant.value = searchQuery;
                        this.agfilters.userNomAffecte.value = searchQuery;
                        this.agfilters.userPrenomAffecte.value = searchQuery;
                        this.agfilters.tiersAffecte.value = searchQuery;
                        this.agfilters.commentaireClient.value = searchQuery;
                    } else {
                        this.agfilters.id.value = null;
                        this.agfilters.libelEquipement.value = null;
                        this.agfilters.libelComposant.value = null;
                        this.agfilters.userNomAffecte.value = null;
                        this.agfilters.userPrenomAffecte.value = null;
                        this.agfilters.tiersAffecte.value = null;
                        this.agfilters.commentaireClient.value = null;
                    }
                    this.FiltersMixins_saveSearchQuery(query);
                }
            }
        },
        methods: {
            handleAffectationMultipleSuccess: function () {
                this.showAffectationMultipleModal = false;
                location.reload();
            },
            onSaveClotures: function (datas) {
                this.showClotureModal = false;
                let idMaintenances = datas.map((data) => data.id);
                let modifiedMaintenances = [];
                let copyMaintenances = [...this.getMaintenances];
                copyMaintenances.forEach((maintenance, index) => {
                    if (idMaintenances.indexOf(maintenance.id) != -1) {
                        modifiedMaintenances.push(Object.assign({}, maintenance, {
                            statut: "Resolue"
                        }));
                        modifiedMaintenances[index].statut = "Resolue";
                        this.$store.dispatch("MaintenancesStore/deleteMaintenance", maintenance.id);
                    }
                });
                this.$store.dispatch("MaintenancesStore/decrementCounter", { name: "En_cours", qty: this.getSelectedMaintenances.length });
                this.$store.dispatch("MaintenancesStore/incrementCounter", { name: "Resolue", qty: this.getSelectedMaintenances.length });
                this.$store.dispatch("MaintenancesStore/setMaintenances", modifiedMaintenances);
                this.$store.dispatch("MaintenancesStore/selectMaintenances", []);
            },
            deleteGlobalFilters: function () {
                let filtersName = Object.keys(this.agfilters);
                let filters = {};
                let filter = {};
                filtersName.forEach((name) => {
                    filter = Object.assign({}, {}, this.agfilters[name]);
                    if (name.startsWith("global_")) filter.value = null;
                    filters[name] = Object.assign({}, {}, filter);
                });
                this.agfilters = filters;
                this.FiltersMixins_saveFilters(this.agfilters);
            },
            onGlobalFiltersChange: function (filters) {
                this.onRemoveFilters();
                this.agfilters = Object.assign({}, this.agfilters, filters);
            },
            onScannSuccess: function (code) {
                this.agfilters.field_codeEquipement.value = code;
                this.agfilters.field_codeLieu.value = code;
                this.showScanner = false;
            },
            onRemoveFilters: function () {
                this.pageFilters.forEach((filterName) => {
                    if (filterName != "urgence") this.agfilters[filterName].value = null;
                    else this.agfilters[filterName].value = false;
                });
                this.agfilters.field_dateOuvertureSAV.value = null;
                this.agfilters.field_dateFermetureSAV.value = null;
                this.agfilters.field_codeEquipement.value = null;
                this.agfilters.field_codeLieu.value = null;
                this.agfilters.field_typologie.value = null;
                this.agfilters.field_corpsDetat.value = null;
                this.agfilters.field_user_affecte.value = null;
                this.agfilters.field_tier_affecte.value = null;
                this.agfilters.field_demandeur.value = null;
                this.agfilters.field_origin.value = null;
                this.agfilters.field_service.value = null;
                this.searchQuery = null;
                this.FiltersMixins_deleteStoredCurrentFilters();
            },
            onCloseInfo: function () {
                this.showInfo = !this.showInfo;
                localStorage.setItem("maintenance-mobile-show-info", this.showInfo);
            },
            openRapportPrinter: function () {
                this.$router.push({ name: '_maintenances_print' })
                /*var event = new CustomEvent("askGenerateReport", {
                  detail: {"idTableau":"vg-fichesav-table"}
                });
                window.dispatchEvent(event);*/
            },
            onFocusedTab: function (e) {
                this.agfilters = Object.assign(this.agfilters, e.filters);
                this.$store.dispatch("MaintenancesStore/setMaintenances", []);
            },
            handleFilterIntervalDate: function (e) {
                if (e.startDate && e.endDate) {
                    this.agfilters["field_" + e.targetedColumn].value = [e.startDate, e.endDate];
                } else {
                    this.agfilters.field_dateOuvertureSAV.value = null;
                    this.agfilters.field_dateFermetureSAV.value = null;
                }
            },
            handleActionExport: function (e) {
                let metadatas = new Metadatas();
                metadatas.setFilters(this.agfilters);
                switch (e.name) {
                    case "askForXLS":
                        this.MaintenanceMixins_getFile(metadatas, this.$t("liste-maintenances"), "xlsx").then((datas) => { });
                        break;
                    case "askForCSV":
                        this.MaintenanceMixins_getFile(metadatas, this.$t("liste-maintenances"), "csv").then((datas) => { });
                        break;
                    case "gestionColonnesEventId":
                        window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                            "detail": { "idTableau": "vg-fichesav-table" }
                        }));
                        break;
                    default:
                        break;
                }
            },
            handleRetourAFaire: function () {
                let maintenances = this.getSelectedMaintenances;
                this.MaintenanceMixins_setStatusMaintenances(maintenances, "retourAFaire").then((datas) => {
                    let idMaintenances = datas.map((data) => {
                        return data["id"];
                    });
                    let maintenances = this.getMaintenances.map((maintenance) => {
                        if (idMaintenances.indexOf(maintenance["id"]) != -1) {
                            maintenance["isRetourAFaire"] = true;
                        }
                        return maintenance;
                    });
                    this.$store.dispatch("MaintenancesStore/incrementCounter", { name: "isRetourAFaire", qty: this.getSelectedMaintenances.length });
                    this.$store.dispatch("MaintenancesStore/setMaintenances", maintenances);
                    this.$store.dispatch("MaintenancesStore/selectMaintenances", []);
                });
            },
            handleMiseEnAttente: function () {
                this.MaintenanceMixins_mettreEnAttenteMaintenances(this.getSelectedMaintenances).then(() => {
                    let selectedMaintenances = this.getSelectedMaintenances;
                    let idMaintenances = selectedMaintenances.map((data) => {
                        return data["id"];
                    });
                    let maintenances = this.getMaintenances.map((maintenance) => {
                        if (idMaintenances.indexOf(maintenance["id"]) != -1) {
                            maintenance["statut"] = "en_attente";
                        }
                        return maintenance;
                    });
                    this.$store.dispatch("MaintenancesStore/incrementCounter", { name: "en_attente", qty: this.getSelectedMaintenances.length });
                    this.$store.dispatch("MaintenancesStore/setMaintenances", maintenances);
                    this.$store.dispatch("MaintenancesStore/selectMaintenances", []);
                });
            },
            handlePriseEnCompte: function () {
                this.MaintenanceMixins_prendreEnCompteMaintenances(this.getSelectedMaintenances).then(() => {
                    let selectedMaintenances = this.getSelectedMaintenances;
                    let idMaintenances = selectedMaintenances.map((data) => {
                        return data["id"];
                    });
                    let maintenances = this.getMaintenances.map((maintenance) => {
                        if (idMaintenances.indexOf(maintenance["id"]) != -1) {
                            maintenance["statut"] = "prise_en_compte";
                        }
                        return maintenance;
                    });
                    this.$store.dispatch("MaintenancesStore/incrementCounter", { name: "prise_en_compte", qty: this.getSelectedMaintenances.length });
                    this.$store.dispatch("MaintenancesStore/setMaintenances", maintenances);
                    this.$store.dispatch("MaintenancesStore/selectMaintenances", []);
                });
            },
            openDialogBoxConfirmationSuppression: function () {
                this.isModalConfirmDeleteOpened = true;
            },
            handleSupprimerMaintenance: function () {
                let maintenance = this.getSelectedMaintenances[0];
                this.MaintenanceMixins_delete(maintenance).then(() => {
                    this.isModalConfirmDeleteOpened = false;
                    this.$store.dispatch("MaintenancesStore/selectMaintenances", []);
                    this.$store.dispatch("MaintenancesStore/decrementCounter", { name: "En_cours", qty: this.getSelectedMaintenances.length });
                    this.$store.dispatch("MaintenancesStore/decrementCounter", { name: "all", qty: this.getSelectedMaintenances.length });
                });
            },
            handleChangeOriginFilter: function (e) {
                this.agfilters.field_origin = e.filters.field_origin;
            }
        },
        created: function () {
            if (typeof (localStorage.getItem("maintenance-mobile-show-info")) == "string") this.showInfo = localStorage.getItem("maintenance-mobile-show-info") == "true";
            this.FiltersMixins_page_name = "maintenances";
            let filters = this.FiltersMixins_getStoredFilters();
            filters.field_dateOuvertureSAV.value = null;
            filters.field_dateFermetureSAV.value = null;
            this.agfilters = Object.assign(this.agfilters, this.defaultFilters, filters);
            this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
        },
        mounted: function () {
            if (typeof (localStorage.getItem("maintenance-mobile-show-info")) == "string") this.showInfo = localStorage.getItem("maintenance-mobile-show-info") == "true";
        },
        computed: {
            ...mapGetters({
                getSelectedMaintenances: 'MaintenancesStore/getSelectedMaintenances',
                getMaintenances: 'MaintenancesStore/getMaintenances',
                getMaintenancesCounters: 'MaintenancesStore/getMaintenancesCounters',
                getMaintenancesAffectees: 'MaintenancesStore/getMaintenancesAffectees',
                hasAtLeastOneTypologieMaintenance: "TypologiesMaintenanceStore/hasAtLeastOneTypologieMaintenance"
            }),
            pageFilters: function () {
                return ["field_path", "field_corpsDetat", "field_user_affecte", "field_tier_affecte", "field_codeEquipement", "field_typologie"];
            },
            activeFilters: function () {
                let filtersNames = this.pageFilters;
                let activeFilters = filtersNames.filter((filterName) => this.agfilters[filterName].value != null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length != 0));
                let nFilters = 0;
                if (this.agfilters.field_dateOuvertureSAV.value || this.agfilters.field_dateFermetureSAV.value) nFilters += 1;
                if (this.searchQuery && this.searchQuery.length != 0) nFilters += 1;
                return activeFilters.length + nFilters;
            },
            maintenanceFilters: function () {
                //return ["field_path", "field_corpsDetat", "field_user_affecte", "field_tier_affecte", "field_codeEquipement", "field_typologie", "field_demandeur"];
                return Object.values(this.agfilters);
            },
            getHeaderColor: function () {
                return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
            },
            hasActiveGlobalFilters: function () {
                let filters = this.agfilters;
                for (let filterName in filters) {
                    if (filterName.startsWith("global_") && filters[filterName].value !== null) {
                        return true;
                    }
                }
                return false;
            },
            pageTitle: function () {
                let pageTitle = this.$t("page-title");
                if (this.agfilters.field_typologie.value) {
                    pageTitle = this.agfilters.field_typologie.value;
                }
                return pageTitle;
            }
        }
    };
</script>

<style lang="scss">
.filters-group {
    padding: 1rem;
    
    &__quick-actions {
        display: flex;
        gap: 1rem;
        margin-bottom: 1.5rem;
    }

    &__main {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1rem;
    }

    &__item {
        transition: all 0.3s ease;

        &:hover {
            transform: translateY(-2px);
        }
    }
}

.checkbox-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.checkbox-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease;

    &:hover {
        transform: scale(1.1);
    }
}


.selector-group {
    display: flex;
    gap: 0.5rem;
}

// Transitions
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

@media (max-width: 768px) {
    .filters-group {
        &__quick-actions {
            flex-direction: column;
        }

        &__main {
            grid-template-columns: 1fr;
        }
    }

}
</style>