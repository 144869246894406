<template>
  <vg-app-layout
    :title="$t('manage_invitations')"
    :isZoomLayout="true"
    :isActionButtonDisplay="false"
    @backpage="$router.back();"
  >
  <template #create-button>
    <vg-button 
        type="info" 
        @click="showInvitationModal = true"
        icon="plus">
        {{ $t('create_invitation') }}
      </vg-button>
  </template>
  <template #body>
    <div class="manage-invitations-container">
      <h2>{{ $t('active_invitations') }}</h2>
    <div class="invitation-info-card" v-if="!isLoading && !invitations.length">
      <div class="card-icon">
        <vg-icon url="/static/assets/icone/custom/info.svg" />
      </div>
      <div class="card-content">
        <h3>{{ $t('invitation_info_title') }}</h3>
        <p>{{ $t('invitation_info_description') }}</p>
      </div>
    </div>
    <!-- Filters -->
    <div style="display:flex;flex-direction: column;gap:10px;" v-show="invitations.length">
      <div class="filter-role">
        <span class="filter-label">{{ $t('filter_by_role') }}:</span>
        <div class="role-buttons">
          <vg-button 
            v-for="role in roleOptions" 
            :key="role.value"
            :type="selectedRoleFilter === role.value ? 'info' : 'default-info'"
            size="small"
            @click="filterByRole(role.value)">
            {{ role.text }}
          </vg-button>
          <vg-button 
            type="default"
            size="small"
            v-if="selectedRoleFilter"
            @click="clearRoleFilter()">
            {{ $t('clear_filter') }}
          </vg-button>
        </div>
      </div>
      <vg-input
        :title="$t('filter_by_email')"
        @delayed-input="filterByEmail"
        :placeholder="$t('enter_email')"
      >
      </vg-input>
        <!-- Status tabs -->
        <vg-tabs
          v-model="selectedStatusTab"
          :color="'gris'"
          :tabs="statusTabs"
          @tabClicked="onStatusTabChange">
        </vg-tabs>
    </div>
    
    <!-- Loading indicator -->
    <div class="loading-container" v-if="isLoading">
      <div class="spinner"></div>
      <p>{{ $t('loading_invitations') }}</p>
    </div>
    
    <!-- Invitations table -->
    <div class="invitations-list" v-if="filteredInvitations.length && !isLoading">
      <div class="table-responsive">
        <table class="invitations-table">
          <thead>
            <tr>
              <th>{{ $t('email') }}</th>
              <th>{{ $t('role') }}</th>
              <th>{{ $t('status') }}</th>
              <th>{{ $t('created_on') }}</th>
              <th>{{ $t('expires_on') }}</th>
              <th class="actions-column">{{ $t('actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invitation in filteredInvitations" :key="invitation.id">
              <td>{{ invitation.email }}</td>
              <td><span class="role-badge">{{ getRoleName(invitation.role) }}</span></td>
              <td><span class="status-badge" :class="invitation.status">{{ $t('status_' + invitation.status) }}</span></td>
              <td>{{ formatDate(invitation.createdAt) }}</td>
              <td>{{ formatExpiryDate(invitation.createdAt) }}</td>
              <td class="actions-cell">
                <vg-button 
                  @click="regenerateInvitation(invitation.id)"
                  icon="refresh"
                  type="secondary"
                  size="small"
                  title="Renvoyer"
                  :disabled="invitation.status === 'completed'">
                  {{ $t('resend') }}
                </vg-button>
                <vg-button 
                  @click="deleteInvitation(invitation.id)"
                  icon="trash"
                  type="danger"
                  size="small"
                  title="Delete"
                  :disabled="invitation.status === 'completed'"
                >
                  {{ $t('delete') }}
                </vg-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <!-- Empty state -->
    <div class="empty-state" v-if="!filteredInvitations.length && !isLoading" style="display:flex;flex-direction: column;align-items: center;">
      <vg-button 
        type="info" 
        @click="showInvitationModal = true"
        icon="plus"
        style="margin-bottom: 10px;">
        {{ $t('create_invitation') }}
      </vg-button>
      <p>{{ $t('no_invitations') }}</p>
    </div>
    
    <!-- Invitation modal -->
    <vg-invitation-link-modal 
      v-if="showInvitationModal" 
      @close="onModalClose"
      @invitation-created="onInvitationCreated"
      @error="handleError" />
    </div>
  </template>
    
  </vg-app-layout>
</template>

<script>
import VgButton from 'src/components/Vg/VgButton.vue';
import VgIcon from 'src/components/Vg/VgIcon.vue';
import VgInvitationLinkModal from 'src/components/Vg/Invitation/VgInvitationLinkModal.vue';
import VgAppLayout from 'src/components/Vg/Layout/AppLayout/VgAppLayout.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import { Metadatas } from 'verifgood-js-sdk';

export default {
  name: 'manage-invitations',
  components: {
    VgButton,
    VgIcon,
    VgInvitationLinkModal,
    VgAppLayout,
    VgTabs,
    VgInput
  },
  i18n: {
    messages: {
      en: {
        manage_invitations: 'Manage Invitations',
        create_invitation: 'Invite User',
        invitation_info_title: 'About Invitations',
        invitation_info_description: 'Invitations allow you to invite new users to join your organization. You can specify their role when creating the invitation. The invitation link will be valid for 7 days.',
        active_invitations: 'Active Invitations',
        loading_invitations: 'Loading invitations...',
        no_invitations: 'No active invitations found. Create a new invitation to get started.',
        email: 'Email',
        role: 'Role',
        status: 'Status',
        status_pending: 'Pending',
        status_accepted: 'Accepted',
        status_completed: 'Completed',
        created_on: 'Created on',
        expires_on: 'Expires on',
        actions: 'Actions',
        copy_link: 'Copy',
        resend: 'Resend',
        delete: 'Delete',
        link_copied: 'Invitation link copied to clipboard',
        invitation_resent: 'Invitation was resent successfully',
        confirm_delete: 'Are you sure you want to delete this invitation?',
        delete_success: 'Invitation deleted successfully',
        delete_error: 'Error deleting invitation',
        role_admin: 'Administrator',
        role_user: 'User',
        role_manager: 'Manager',
        role_viewer: 'Viewer',
        role_technicien: 'Technician',
        role_basic: 'Requester',
        role_sous_traitant: 'Subcontractor',
        role_basic_verificateur: 'Requester Verifier (ex: Housekeeper)',
        role_superviseur: 'Supervisor',
        role_chef_equipe: 'Verification Operator',
        role_description: 'Role Descriptions',
        filter_by_email: 'Filter by email',
        filter_by_role: 'Filter by role',
        filter_by_status: 'Filter by status',
        enter_email: 'Enter email to filter',
        clear_filter: 'Clear filter',
        all_invitations: 'All invitations',
        status_tab_all: 'All',
        status_tab_pending: 'Pending',
        status_tab_accepted: 'Accepted',
        status_tab_completed: 'Completed'
      },
      fr: {
        manage_invitations: 'Gérer les invitations',
        create_invitation: 'Inviter un utilisateur',
        invitation_info_title: 'À propos des invitations',
        invitation_info_description: 'Les invitations vous permettent d\'inviter de nouveaux utilisateurs à rejoindre votre organisation. Vous pouvez spécifier leur rôle lors de la création de l\'invitation. Le lien d\'invitation sera valide pendant 7 jours.',
        active_invitations: 'Invitations actives',
        loading_invitations: 'Chargement des invitations...',
        no_invitations: 'Aucune invitation active trouvée. Créez une nouvelle invitation pour commencer.',
        email: 'Email',
        role: 'Rôle',
        status: 'Statut',
        status_pending: 'En attente',
        status_accepted: 'Acceptée',
        status_completed: 'Terminée',
        created_on: 'Créée le',
        expires_on: 'Expire le',
        actions: 'Actions',
        copy_link: 'Copier',
        resend: 'Renvoyer',
        delete: 'Supprimer',
        link_copied: 'Lien d\'invitation copié dans le presse-papiers',
        invitation_resent: 'L\'invitation a été renvoyée avec succès',
        confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette invitation ?',
        delete_success: 'Invitation supprimée avec succès',
        delete_error: 'Erreur lors de la suppression de l\'invitation',
        role_admin: 'Administrateur',
        role_user: 'Utilisateur',
        role_manager: 'Gestionnaire',
        role_viewer: 'Observateur',
        role_technicien: 'Technicien',
        role_basic: 'Utilisateur Basic',
        role_sous_traitant: 'Sous-traitant',
        role_basic_verificateur: 'Demandeur vérificateur (ex: Gouvernante)',
        role_superviseur: 'Superviseur',
        role_chef_equipe: 'Chef d\'équipe',
        role_description: 'Description des rôles',
        filter_by_email: 'Filtrer par email',
        filter_by_role: 'Filtrer par rôle',
        filter_by_status: 'Filtrer par statut',
        enter_email: 'Entrez un email pour filtrer',
        clear_filter: 'Effacer le filtre',
        all_invitations: 'Toutes les invitations',
        status_tab_all: 'Tous',
        status_tab_pending: 'En attente',
        status_tab_accepted: 'Acceptées',
        status_tab_completed: 'Terminées'
      },
      th: {
        manage_invitations: 'จัดการคำเชิญ',
        create_invitation: 'สร้างคำเชิญ',
        invitation_info_title: 'เกี่ยวกับคำเชิญ',
        invitation_info_description: 'คำเชิญช่วยให้คุณสามารถเชิญผู้ใช้ใหม่เข้าร่วมองค์กรของคุณ คุณสามารถระบุบทบาทของพวกเขาเมื่อสร้างคำเชิญ ลิงก์คำเชิญจะมีอายุ 7 วัน',
        active_invitations: 'คำเชิญที่ใช้งานอยู่',
        loading_invitations: 'กำลังโหลดคำเชิญ...',
        no_invitations: 'ไม่พบคำเชิญที่ใช้งานอยู่ สร้างคำเชิญใหม่เพื่อเริ่มต้น',
        email: 'อีเมล',
        role: 'บทบาท',
        status: 'สถานะ',
        status_pending: 'รอดำเนินการ',
        status_accepted: 'ยอมรับแล้ว',
        status_completed: 'เสร็จสมบูรณ์',
        created_on: 'สร้างเมื่อ',
        expires_on: 'หมดอายุเมื่อ',
        actions: 'การดำเนินการ',
        copy_link: 'คัดลอก',
        resend: 'ส่งใหม่',
        delete: 'ลบ',
        link_copied: 'คัดลอกลิงก์คำเชิญไปยังคลิปบอร์ดแล้ว',
        invitation_resent: 'ส่งคำเชิญซ้ำเรียบร้อยแล้ว',
        confirm_delete: 'คุณแน่ใจหรือไม่ว่าต้องการลบคำเชิญนี้?',
        delete_success: 'ลบคำเชิญสำเร็จแล้ว',
        delete_error: 'เกิดข้อผิดพลาดในการลบคำเชิญ',
        role_admin: 'ผู้ดูแลระบบ',
        role_user: 'ผู้ใช้',
        role_manager: 'ผู้จัดการ',
        role_viewer: 'ผู้ดู',
        filter_by_email: 'กรองตามอีเมล',
        filter_by_role: 'กรองตามบทบาท',
        filter_by_status: 'กรองตามสถานะ',
        enter_email: 'ป้อนอีเมลเพื่อกรอง',
        clear_filter: 'ล้างตัวกรอง',
        all_invitations: 'คำเชิญทั้งหมด',
        status_tab_all: 'ทั้งหมด',
        status_tab_pending: 'รอดำเนินการ',
        status_tab_accepted: 'ยอมรับแล้ว',
        status_tab_completed: 'เสร็จสมบูรณ์'
      }
    }
  },
  data() {
    return {
      showInvitationModal: false,
      invitations: [],
      isLoading: false,
      emailFilter: '',
      selectedRoleFilter: '',
      selectedStatusTab: 'all',
      statusTabs: [
        { 
          name: 'all', 
          label: this.$t('status_tab_all')
        },
        { 
          name: 'pending', 
          label: this.$t('status_tab_pending')
        },
        { 
          name: 'accepted', 
          label: this.$t('status_tab_accepted')
        },
        { 
          name: 'completed', 
          label: this.$t('status_tab_completed')
        }
      ],
      roleOptions: [
        { value: 'ROLE_ADMIN', text: this.$t('role_admin') },
        { value: 'ROLE_TECHNICIEN', text: this.$t('role_technicien') },
        { value: 'ROLE_BASIC', text: this.$t('role_basic') },
        { value: 'ROLE_SOUS_TRAITANT', text: this.$t('role_sous_traitant') },
        { value: 'ROLE_BASIC_VERIFICATEUR', text: this.$t('role_basic_verificateur') },
        { value: 'ROLE_SUPERVISEUR', text: this.$t('role_superviseur') },
        { value: 'ROLE_CHEF_EQUIPE', text: this.$t('role_chef_equipe') }
      ]
    };
  },
  created() {
    this.fetchInvitations();
  },
  computed: {
    filteredInvitations() {
      // Apply all filters
      return this.invitations.filter(invitation => {
        // Filter by email
        const emailMatch = !this.emailFilter || 
          invitation.email.toLowerCase().includes(this.emailFilter.toLowerCase());
        
        // Filter by role
        const roleMatch = !this.selectedRoleFilter || 
          invitation.role === this.selectedRoleFilter;
        
        // Filter by status
        const statusMatch = this.selectedStatusTab === 'all' || 
          invitation.status === this.selectedStatusTab;
        
        return emailMatch && roleMatch && statusMatch;
      });
    }
  },
  methods: {
    async fetchInvitations() {
      this.isLoading = true;
      try {
        const metadatas = new Metadatas();
        // You can add filters here if needed
        // metadatas.setFilter('status', 'active', 'equals');
        
        const response = await this.$vg.invitations.getAll(metadatas);
        // Extract invitations from the response object
        this.invitations = response.invitations || [];
        console.log('Fetched invitations:', this.invitations);
      } catch (error) {
        console.error('Error fetching invitations:', error);
        this.handleError(error.message || 'Failed to load invitations');
      } finally {
        this.isLoading = false;
      }
    },
    
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },
    
    formatExpiryDate(createdAtString) {
      if (!createdAtString) return '';
      // Calculate expiry date (7 days after creation)
      const createdAt = new Date(createdAtString);
      const expiryDate = new Date(createdAt);
      expiryDate.setDate(expiryDate.getDate() + 7);
      return expiryDate.toLocaleDateString();
    },
    
    getRoleName(roleValue) {
      const role = this.roleOptions.find(r => r.value === roleValue);
      return role ? role.text : roleValue;
    },
    
    async regenerateInvitation(id) {
      // Skip if invitation is completed
      const invitation = this.invitations.find(inv => inv.id === id);
      if (invitation && invitation.status === 'completed') return;
      
      try {
        await this.$vg.invitations.regenerateInvitationLink(id);
        alert(this.$t('invitation_resent'));
      } catch (error) {
        console.error('Error regenerating invitation:', error);
        this.handleError(error.message || 'Failed to resend invitation');
      }
    },
    
    async deleteInvitation(id) {     
      if (!confirm(this.$t('confirm_delete'))) {
        return;
      }
      
      try {
        await this.$vg.invitations.remove(id);
        this.invitations = this.invitations.filter(inv => inv.id !== id);
        alert(this.$t('delete_success'));
      } catch (error) {
        console.error('Error deleting invitation:', error);
        this.handleError(error.message || this.$t('delete_error'));
      }
    },
    
    onModalClose() {
      this.showInvitationModal = false;
    },
    
    onInvitationCreated(invitation) {
      // Add the new invitation to the list without having to refetch all invitations
      //this.invitations.unshift(invitation);

      // informations are missing and should be added into the verifgood-js-sdk 
      // so let's make a fetch to get the new invitation
      this.fetchInvitations();
    },
    
    handleError(errorMessage) {
      // You could use a toast notification system here
      console.error('Invitation error:', errorMessage);
      alert(errorMessage);
    },

    // New filter methods
    filterByEmail(email) {
      this.emailFilter = email;
    },

    filterByRole(role) {
      if (this.selectedRoleFilter === role) {
        this.selectedRoleFilter = '';
      } else {
        this.selectedRoleFilter = role;
      }
    },

    clearRoleFilter() {
      this.selectedRoleFilter = '';
    },

    onStatusTabChange(tab) {
      this.selectedStatusTab = tab.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.manage-invitations-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }
}

.filter-container {
  margin-bottom: 20px;
}

.filter-group {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 16px;
}

.filter-email {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
}

.filter-role {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-label {
  font-weight: 500;
  font-size: 14px;
  color: #495057;
}

.role-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.invitation-info-card {
  display: flex;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  
  .card-icon {
    margin-right: 16px;
    
    img {
      width: 32px;
      height: 32px;
    }
  }
  
  .card-content {
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
    }
    
    p {
      margin: 0;
      color: #6c757d;
    }
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
}

.invitations-list {
  margin-top: 24px;
  
  h2 {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 600;
  }
}

.table-responsive {
  overflow-x: auto;
}

.invitations-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  th, td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #e9ecef;
  }
  
  th {
    font-weight: 600;
    color: #495057;
    background-color: #f8f9fa;
  }
  
  tr:last-child td {
    border-bottom: none;
  }
  
  .actions-column {
    text-align: center;
    min-width: 180px;
  }
  
  .actions-cell {
    display: flex;
    gap: 8px;
    justify-content: center;
  }
}

.role-badge {
  display: inline-block;
  background-color: #e9ecef;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  
  &.pending {
    background-color: #ffc107;
    color: #212529;
  }
  
  &.accepted {
    background-color: #28a745;
    color: white;
  }
  
  &.completed {
    background-color: #6c757d;
    color: white;
  }
}

.empty-state {
  text-align: center;
  padding: 40px;
  color: #6c757d;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
    
    h1 {
      margin-bottom: 16px;
    }
  }
  
  .filter-group {
    flex-direction: column;
  }
  
  .filter-email, .filter-role {
    width: 100%;
    max-width: 100%;
  }
  
  .invitations-table {
    th, td {
      padding: 8px;
    }
    
    .actions-cell {
      flex-direction: column;
    }
  }
}
</style>
