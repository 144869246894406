<template>
  <div>
    <vg-modal
        :title="$t('update-equipments-title')"
        :isSaveDisabled="isSaveDisabled"
        @close="close"
        @save="handleSaveUpdateEquipements"
    >
      <template v-slot:body>

        <form id="deplacements-equipement-form"
            ref="deplacements-equipements-form"
            onSubmit="return false;">
            <vg-select
                v-model="selectedField"
                :options="getFields"
                :attributeLabel="'label'"
                :attributeValue="'value'"
                @input="onChangeSelectedField" />
            <br>
            <vg-input
                v-show="
                selectedField && selectedField != 'miseEnService'
                && selectedField != 'valeurAchat'
                && selectedField != 'carac_techniques'
                && selectedField != 'tagsEquipements'
                && selectedField != 'idCategorie_id'
                "
                v-model="userEntry"
                inputType="text"
                :placeholder="$t('textarea-placeholder') + selectedFieldTranslated"
            >
            </vg-input>

            <vg-input
                v-if="selectedField && selectedField == 'carac_techniques'  "
                v-model="userEntry"
                inputType="textarea"
                :placeholder="$t('textarea-placeholder') + selectedFieldTranslated"
            >
            </vg-input>

            <!--div v-show="selectedField && selectedField == 'valeurAchat' " style="display:flex;align-items: baseline;">
                <vg-input
                    v-model="userEntry"
                    inputType="number"
                    :placeholder="$t('valeurAchat')"
                >
                </vg-input>
                <div style="width: 10px;padding: 5px;">
                    {{$app.devise}}
                </div>
            </div-->

            <vg-input
                v-show="selectedField && selectedField == 'miseEnService' "
                v-model="userEntry"
                inputType="date"
                :placeholder="$t('date-placeholder')"
            >
            </vg-input>

            <vg-input
                v-show="selectedField && selectedField == 'tagsEquipements' "
                :placeholder="$t('equipment-tags')"
            >
                <vg-tags-selector
                    v-model="selectedTags"
                    :type="'equipement/tag'"
                    :attributeValue="null"
                    multiple
                    @change="handleTagsEquipementChange"
                >
                </vg-tags-selector>
            </vg-input>

            <vg-input v-show="selectedField && selectedField == 'idCategorie_id' ">
                <vg-categorie-selector v-model="userEntry"
                    :isGe="false"/>
            </vg-input>

            <input
              v-show="false"
              ref="equipement-submit"
              type="submit"
            >
        </form>

      </template>

    </vg-modal>

  </div>
</template>
<script>

import equipementsMixins from "src/mixins/equipementsMixins.js";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgTagsSelector from 'src/components/Vg/Selectors/VgTagsSelector.vue';
import VgCategorieSelector from 'src/components/Vg/Selectors/VgCategorieSelector.vue';

import { mapGetters } from 'vuex';

export default {
    name: 'vg-update-equipements-form',
    components:{
        VgSelect,
        VgInput,
        VgTagsSelector,
        VgCategorieSelector
    },
    mixins: [equipementsMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "date-placeholder": "Selectionner une date",
            "textarea-placeholder": "Saisir une valeur",
            "update-equipments-title": "Modifier les équipements sélectionnés",
            "refConstructeur": "Ref. Constructeur",
            "carac_techniques": "Caractéristiques",
            "miseEnService": "Date de mise en service",
            "numSerie": "N° de série",
            "valeurAchat": "Valeur achat",
            "tags": "Etiquettes",
            "equipment-tags": "Etiquettes équipements",
            "reference-document-externe": "Référence document externe",
            "batchNumber": "N° de lot",
            "categorie": "Catégorie"
        },
        "en": {
            "date-placeholder": "Pick a date",
            "textarea-placeholder": "Set a value for ",
            "update-equipments-title": "Update selected equipements",
            "refConstructeur": "Manufacturer reference",
            "carac_techniques": "caractheristics",
            "miseEnService": "Commissioning date",
            "numSerie": "Serial N°",
            "valeurAchat": "Purchase value",
            "tags": "Tags",
            "equipment-tags": "Equipment Tags",
            "reference-document-externe": "External document reference",
            "batchNumber": "batch N°",
            "categorie": "Category"
        }
    }
},
    props: {},
    data: function() {
        return {
            fields:[
                {label:this.$t("refConstructeur"),value:"refConstructeur"},
                {label:this.$t("carac_techniques"),value:"carac_techniques"},
                {label:this.$t("numSerie"),value:"numSerie"},
                {label:this.$t("miseEnService"),value:"miseEnService"},
                {label:this.$t("tags"),value:"tagsEquipements"},
                {label:this.$t("reference-document-externe"),value:"ReferenceDocumentExterne"},
                {label:this.$t("valeurAchat"),value:"valeurAchat"},
                {label:this.$t("categorie"),value:"idCategorie_id"},
                /*{label:this.$t("batchNumber"),value:"batchNumber"}*/
            ],
            selectedField:null,
            userEntry:null,
            selectedTags:[],
        };
    },
    watch: {},
    created: function(){},
    mounted: function(){},
    computed:{
        ...mapGetters({
            selectedEquipements: "EquipementsStore/getSelectedItems"
        }),
        selectedFieldTranslated:function(){
            return this.$t(this.selectedField);
        },
        getFields: function(){
            return this.fields.sort((a, b)=>{
                const labelA = a.label.toLowerCase();
                const labelB = b.label.toLowerCase();
                
                if (labelA < labelB) return -1;
                if (labelA > labelB) return 1;
                return 0;
            });
        },
        isSaveDisabled: function(){
            return !this.selectedField || !this.userEntry;
        }
    },
    methods: {
        onChangeSelectedField: function(){
            this.userEntry = null;
        },
        close:function(){
            this.$emit("close");
        },
        handleTagsEquipementChange:function(tags){
            this.userEntry = tags;
        },
        handleSaveUpdateEquipements:function(){
            let formatedEquipments = [];
            this.selectedEquipements.forEach((equipement, i) => {
                formatedEquipments.push(this.formatEquipments(equipement));
            });
            this.equipementsMixins_update_equipements(formatedEquipments).then(()=>{
                this.$store.dispatch("EquipementsStore/setSelectedItems",[]);
                this.$emit("save");
            });
        },
        formatEquipments:function(equipement){
            let formatedEquipment = {id:equipement.id, userId:equipement.userId}; 
            formatedEquipment[this.selectedField] = this.userEntry;
            return formatedEquipment;
        }
    }
};
</script>
<style lang="scss" scoped>
    table,
    td {
        border: 1px solid #333;
    }

    thead,
    tfoot {
        background-color: #333;
        color: #fff;
    }
</style>
<docs>

    ### vg-equipement-form
    Create|Update|Delete equipement

    #### basic usage

    ```html static
        <vg-equipement-form v-if="equipement || isEquipementFormDisplayed"
            v-model="equipement"
            @close="isEquipementFormDisplayed=false;equipement=null;"
            @created="isEquipementFormDisplayed=false;fetch()"
            @updated="isEquipementFormDisplayed=false;fetch()"
            @deleted="isEquipementFormDisplayed=false;fetch()"
        >
        </vg-equipement-form>
    ```
</docs>
