<template>
    <vg-app-layout
        :title="selectedTier.name"
        :colorheader="getHeaderColor"
        :isZoomLayout="true"
        :isActionButtonDisplay="false"
        @backpage="$router.go(-1)">
        <template #create-button>
            <vg-button
                type="default-danger"
                @click="isTiersFormOpened = true;">
                {{$t('update')}}
            </vg-button>
         </template>

        <template #primary>
            <vg-collapse :title="$t('caracteristiques')"
                :collapseByDefault="false"
                :type="'section'">
                <template #content>
                    <vg-libelle-editable :label="$t('name')" >
                        <template v-slot:read>
                            {{selectedTier.name}}
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('address')" >
                        <template v-slot:read>
                            <vg-text-multiligne v-model="selectedTier.address"/>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('mark')" >
                        <template v-slot:read>
                            <span v-if="selectedTier.score == '-'"> {{$t('not-defined')}}</span>
                            <span v-else> {{selectedTier.score}} / 5 </span>
                        </template>
                    </vg-libelle-editable>

                    <vg-libelle-editable :label="$t('totalCoutInterventionsPonctuelles')" >
                        <template v-slot:read>
                            <b> {{selectedTier.totalCoutInterventionsPonctuelles}} {{$app.devise}} </b>
                        </template>
                    </vg-libelle-editable>

                    <vg-libelle-editable :label="$t('type')" >
                        <template v-slot:read>
                            {{selectedTier.type}}
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('description')" >
                        <template v-slot:read>
                            <vg-text-multiligne v-model="selectedTier.description"/>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('tags')" >
                        <template v-slot:read>
                            {{selectedTier.tags}}
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('affectation-sites')" >
                        <template v-slot:read>
                            <vg-sites-table v-if="selectedTier && filtersSites.id.value"
                                :filters="filtersSites"
                                displayCard />
                        </template>
                    </vg-libelle-editable>
                </template>
            </vg-collapse>

            <vg-collapse :title="$t('contacts')"
                :collapseByDefault="false"
                :type="'section'">
                <template #content>
                    <vg-button type="success" @click="isContactsFormDisplayed=true;" style="width:100%;margin-bottom: 10px;">
                        {{ $t("creer-contact") }}
                    </vg-button>
                    <vg-contacts-table v-if="selectedTier.id"
                        :tiersId="selectedTier.id" 
                        isCards />
                </template>
            </vg-collapse>

            <vg-collapse :title="$t('documents')"
                :collapseByDefault="false"
                :type="'section'">
                <template v-slot:pretitle>
                    <vg-button
                        size="md"
                        type="default-round"
                        @click="isFileUploaderDisplayed = !isFileUploaderDisplayed"
                    >
                        <i class="far fa-plus-square"></i>
                    </vg-button>
                </template>
                <template #content>
                    <vg-files-uploader
                        v-show="isFileUploaderDisplayed"
                        :tagsList="VgFilesMixins.tags"
                        @upload-done="VgFilesMixins_fetchFilesByUid()">
                    </vg-files-uploader>
                    <vg-files-viewer-list-section
                        :files="VgFilesMixins_filesListSection"
                        @delete="VgFilesMixins_fetchFilesByUid()">
                    </vg-files-viewer-list-section>
                </template>
            </vg-collapse>
        </template>

        <template #secondary>          
            <!--interventions préventives -->
            <vg-collapse v-if="selectedTier"
                :title="$t('interventions-preventives')"
                type="section"
                :collapseByDefault="false">
                <template #pretitle>
                    <action-menu-aggrid :idTableau="'vg-interventions-periodiques-table'" />
                </template>
                <template #content>
                    <vg-interventions-table
                        :idTableau="'vg-interventions-periodiques-table'"
                        :agFilters="interventionsFilters"
                        :hiddenColumns="['tiers.name']"
                        isPeriodiques
                        @row-dbl-click="onInterventionPeriodiqueRowDblClick"
                        :key="'interventions-periodiques'" />
                </template>
            </vg-collapse>
            <br>

            <vg-tabs                 
                :tabs="tabs"
                :color="'bleu'"
                @tabClicked="handleTabClicked"
            >
                
            </vg-tabs>
            <div style="height:400px;">
                <!--maintenances curatives -->
                <vg-fichesav-table
                    v-if="selectedTier && activeTab === 'maintenance-curative'"
                            :idTableau="'vg-maintenances-curatives-tiers-table'"
                            :filters="maintenancesFilters"
                            :stored="false"/>
                <br>
                <!--réserves -->
                <vg-fichesav-table
                    v-if="selectedTier && activeTab === 'reserves'"
                            :idTableau="'vg-reserves-tiers-table'"
                            isReserves
                            :pinnedColumns="[]"
                            :hiddenColumns="['dateFermetureSAV','affectation','workingTime','operation_validation']"
                            :filters="reservesFilters"
                            :stored="false" />
                <br>
            </div>
           

            <!--contrats -->
            <vg-collapse
                :title="$t('contrats')"
                type="section"
                :collapseByDefault="false" >
                <template #pretitle>
                    <action-menu-aggrid :idTableau="'vg-contrats-table'" />
                </template>
                <template #content>
                    <vg-contrats-table
                        class="height-300"
                        :filters="contratsFilters"
                        @row-click="handleClickRow"
                        @row-dbl-click="handleDblClickContrat" />
                </template>
            </vg-collapse>
            <br>
            <!-- si compte connecté en prestataire => affiche équipements-->
            <vg-collapse v-if="selectedTier && $app.account_type=='prestataire'"
                :title="$t('equipements-du-client')"
                type="section"
                :collapseByDefault="false">
                <template v-slot:pretitle>
                    <action-menu-aggrid :idTableau="'vg-equipements-tiers-table'" />
                </template>
                <template #content>
                    <vg-equipements-table
                        style="height:300px;"
                        :pinnedColumns="[]"
                        :filters="equipementsFilters" />
                </template>
            </vg-collapse>
        </template>

        <template #footer v-if="$vgutils.isMobile()">

        </template>

        <template #tools>
            <vg-intervention-form v-if="showInterventionModal"
                v-model="intervention"
                :idTiers="selectedTier.id"
                :isPeriodique="isPeriodique"
                :isPonctuelle="!isPeriodique"
                @close="showInterventionModal=false;intervention=null;"
                @save="showInterventionModal=false;intervention=null;" />
            <vg-tiers-form v-if="isTiersFormOpened"
                v-model="selectedTier"
                @deleted="$router.go(-1)"
                @created="isTiersFormOpened=false"
                @updated="fetch();isTiersFormOpened=false;"
                @close="isTiersFormOpened=false" />
            <vg-contacts-form v-if="isContactsFormDisplayed"
                @close="isContactsFormDisplayed = false"
                :tiersId="selectedTier.id" />
        </template>
    </vg-app-layout>
</template>
<script>
import TiersMixins from "src/mixins/TiersMixins.js";
import VgTiersForm from 'src/components/Vg/Forms/VgTiersForm.vue';
import VgContactsForm from 'src/components/Vg/Forms/VgContactsForm.vue';
import VgButton from "src/components/Vg/VgButton.vue";
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgTextMultiligne from 'src/components/Vg/VgTextMultiligne.vue';

import VgInterventionsTable from "src/components/Vg/Interventions/VgInterventionsTable.vue";
import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";

import VgContratsTable from "src/components/Vg/Contrat/VgContratsTable.vue";
import VgFichesavTable from 'src/components/Vg/FicheSAV/VgFichesavTable.vue';
import VgSitesTable from 'src/components/Vg/Lieu/VgSitesTable.vue';
import VgEquipementsTable from 'src/components/Vg/Equipements/VgEquipementsTable.vue';

import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
import ActionMenuAggrid from "src/components/Vg/TagGrid/ActionMenuAggrid.vue";

import VgContactsTable from "src/components/Vg/Contacts/VgContactsTable.vue"
import VgLieuxSelector from "src/components/Vg/Selectors/VgLieuxSelector";

import { mapGetters } from 'vuex';
export default {
    name: 'tier',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "caracteristiques": "Caractéristiques",
            "name": "Libellé",
            "address": "Adresse",
            "type": "Type",
            "description": "Description",
            "tags": "Etiquette",
            "affectation-sites": "Affectation site",
            "contacts": "Contacts",
            "creer-contact": "Créer un contact",
            "documents": "Documents",
            "interventions-preventives": "Interventions préventives",
            "maintenances-curatives": "Fiches curatives",
            "reserves": "Réserves / Observations",
            "contrats": "Contrats",
            "not-defined": "Non renseigné",
            "update": "Modifier",
            "totalCoutInterventionsPonctuelles": "Coût total interventions ponctuelles",
            "mark": "Note",
            "equipements-du-client": "Equipements du client",
            "onglet-interventions-preventives": "Interventions préventives",
            "onglet-interventions-curatives": "Interventions curatives"
        },
        "en": {
            "caracteristiques": "Characteristics",
            "name": "Naming",
            "address": "Address",
            "type": "Provider type",
            "description": "Description",
            "tags": "Tags",
            "affectation-sites": "Acting perimeter",
            "contacts": "Contacts",
            "creer-contact": "Create a contact",
            "documents": "Files",
            "interventions-preventives": "Preventive interventions",
            "maintenances-curatives": "Curatives sheets",
            "reserves": "Reservations / Observations",
            "contrats": "Contracts",
            "not-defined": "Not defined",
            "update": "Update",
            "totalCoutInterventionsPonctuelles": "One off interventions total cost",
            "mark": "Mark",
            "equipements-du-client": "Customer's equipments",
            "onglet-interventions-preventives": "Preventive interventions",
            "onglet-interventions-curatives": "Curative interventions"
        }
    }
},
    mixins:[TiersMixins],
    components:{
        VgInterventionsTable,
        VgInterventionForm,
        VgContratsTable,
        VgFichesavTable,
        VgEquipementsTable,
        VgCollapse,
        VgLibelleEditable,
        VgFilesViewerListSection,
        VgFilesViewerPhoto,
        VgFilesUploader,
        VgTiersForm,
        VgButton,
        VgContactsTable,
        VgContactsForm,
        ActionMenuAggrid,
        VgTabs,
        VgTextMultiligne,
        VgLieuxSelector,
        VgSitesTable
    },
    data:function(){
        return {
            intervention:null,
            isPeriodique : false,
            showInterventionModal:false,
            isTiersFormOpened:false,
            isFileUploaderDisplayed:false,
            isContactsFormDisplayed:false,
            activeTab: 'maintenance-curative',
            filtersSites:{
                id: {attr:"l.id", value:null, action:"equals"},
                type_lieu: {attr: "type_lieu", value: "Site", action: "equals"}
            }
        }
    },
    computed:{
        ...mapGetters({
            selectedTier: 'TiersStore/getSelectedItem'
        }),
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? 'rgb(108, 58, 158)' : 'white';
        },
        interventionsFilters:function() {
            return {tiers_id: { attr:"i.tiers_id",colId: "tiers_id", value: this.selectedTier.id, action:"equals" }};
        },
        contratsFilters:function(){
            return {
                tiers_id: { attr:"c.tiers_id", value: this.selectedTier.id, action:"equals" }
            };
        },
        maintenancesFilters: function(){
            return [
                {"attr":"fm.statut","value":["En_cours","prise_en_compte","en_attente"],"action":"equals"},
                {"attr":"tiers_id","value":this.selectedTier.id,"action":"equals"}
            ];
        },
        reservesFilters: function(){
            return [
                {"attr":"fm.statut","value":["En_cours","prise_en_compte","en_attente"],"action":"equals"},
                {"attr":"ti.id","value":this.selectedTier.id,"action":"equals"}
            ];
        },
        equipementsFilters: function(){
            return {
                path: {attr: "l.path", value: this.selectedTier.sites.map((site)=>site.path), action:"start_with"},
                isGroupEqp: {attr: "e.isGroupEqp", value: 1, action: "not_equals"}
            };
        },
        tabs: function(){
			return [
				{label: this.$t("maintenances-curatives"), name:"maintenance-curative", counter: null, filters:[]},
				{label: this.$t("reserves"), name:"reserves", counter: null, filters:[]}
			];
		},
    },
    methods:{
        fetch:function(){
            let tierId = this.$route.params.id;
            this.TiersMixins_getTiersId(tierId).then((tiers)=>{
                this.filtersSites.id.value = tiers.lieux;
                this.VgFilesMixins_init(tierId, "tiers");
            });

        },

        /**
         * onInterventionPeriodiqueRowDblClick.
         *
         * @param  {object} intervention.
         */
        onInterventionPeriodiqueRowDblClick: function(intervention){
            this.intervention = intervention;
            this.isPeriodique = true;
            this.showInterventionModal = true;
        },

        /**
         * onInterventionPonctuelleRowDblClick.
         *
         * @param  {object} intervention
         */
        onInterventionPonctuelleRowDblClick: function(intervention){
            this.intervention = intervention;
            this.isPeriodique = false;
            this.showInterventionModal = true;
        },
		handleTabClicked: function(tab){
			this.activeTab = tab.name;
		}
    },
    created:function(){
        this.fetch();
    },
    destroyed:function(){
        this.$store.dispatch("TiersStore/setSelectedItem",{});
    }
}
</script>
<style>
.height-300{
    height: 300px !important;
}
</style>
