<template>
    <vg-app-layout
        :title="$t('selection-tache')"
        :isLoadingState="isLoading"
        :colorheader="getHeaderColor"
        :isZoomLayout="true"
        :isActionButtonDisplay="false"
        class="hidden-print"
        @action-export="actionExport"
        @backpage="$router.go(-1);">
        <template #primary>
            <div class="header-equipement" v-if="equipement && equipement.isGroupEqp=='0'">
                <span class="label">{{equipement.libel_equipement}}</span>
                <span class="code">{{equipement.qrCode}}</span>
            </div>
            <div class="header-equipement" v-else-if="equipement && equipement.isGroupEqp=='1'">
                <vg-lieu-path-viewer v-model="equipement.path" class="label" onlyPiece/>
                <vg-lieu-path-viewer v-model="equipement.path" class="code" onlyPath/>
            </div>
            <vg-equipement-actives-taches v-if="getTaches.length"
                :taches="getTaches"
                :afterSaveRedirectTo="afterSaveRedirectTo" />
            <div v-else-if="$app.role == 'ROLE_CHEF_EQUIPE'"
                style="color:#35b1ea;display:flex;justify-content:center;align-items:center;">
                <span>{{ $t("aucune-tache-mes-affectes") }}</span>
            </div>
            <div v-else
                style="color:#35b1ea;display:flex;justify-content:center;align-items:center;">
                <span>{{ $t("aucune-tache") }}</span>
            </div>
        </template>
        <template #footer>
            <vg-button type="grey" style="width:100%;" @click="$router.go(-1)">
                {{ $t("annuler") }}
            </vg-button>
        </template>
    </vg-app-layout>
</template>

<script>
import TachesMixins from "src/mixins/TachesMixins";
import equipementsMixins from "src/mixins/equipementsMixins";
import VerificationMixins from "src/mixins/VerificationMixins";

import VgButton from 'src/components/Vg/VgButton.vue';

import VgEquipementActivesTaches from 'src/components/Vg/Equipements/VgEquipementActivesTaches.vue';
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";


import { mapGetters } from 'vuex';

export default {
    name: "vg-process-verification-tache-selection",
    components: {
		VgLieuPathViewer,
        VgButton,
        VgEquipementActivesTaches
    },
    mixins: [TachesMixins, equipementsMixins, VerificationMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "selection-tache": "Sélectionner une tâche",
            "annuler": "Annuler",
            "aucune-tache-mes-affectes": "Aucune tâche ne vous est affectée",
            "aucune-tache": "Aucune tâche n'est disponible"
        },
        "en": {
            "selection-tache": "Select a task",
            "annuler": "Cancel",
            "aucune-tache-mes-affectes": "No task is assigned to you",
            "aucune-tache": "No task is available"
        }
    }
},
    props:{
        afterSaveRedirectTo:{
			type: String,
			default: null
		}
    },
    data: function() {
        return {
            isLoading:false,
            taches:[],
            equipement:null,
        }
    },
    watch: {},
    computed: {
        ...mapGetters({
              equipement: 'EquipementsStore/getSelectedItem',
        }),
        /**
        * @return string
        */
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#f36f3a' : 'white';
        },
        getTaches: function(){
            if(this.$route.params && this.$route.params.type == "compteur") return this.taches.filter((tache)=>tache.type_tache=="Relever_compteur");
            else if(this.$route.params && this.$route.params.type == "grandeur-physique") return this.taches.filter((tache)=>tache.type_tache=="Relever_Grandeur_physique");
            else if(this.$route.params && this.$route.params.type == "lieu") return this.taches.filter((tache)=>tache.type_tache=="Verification_Lieu");
            else return this.taches;
        }
    },
    methods: {
        fetch:function(){
            let equipementId = this.$route.params["equipementId"];
            this.isLoading = true;
            let uniquementLestachesQuiMeSontAttribues = this.$app.role == "ROLE_CHEF_EQUIPE";
            console.log("fetch", equipementId, uniquementLestachesQuiMeSontAttribues);
            this.VerificationMixins_startVerification(equipementId, null, uniquementLestachesQuiMeSontAttribues).then((payload)=>{
                this.taches = payload["taches"];
                this.equipement = payload["equipement"];
                this.isLoading = false;
            });
        },
    },
    created: function() {
        this.fetch();
    }
}
</script>

<style lang="scss" scoped>
.header-equipement{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 15px 0;
    .label{
        font-weight: bold;
        font-size:12px;
    }
    .code{
        color:#bac0c9;
        font-size:11px;
    }
}
</style>
