<template>
	<vg-tabs v-if="!$vgutils.isMobile()" class="vg-maintenances-tabs hidden-print"
		v-model="focusedTab"
		:tabs="tabs"
		:color="'jaune'"
		@tabClicked="handleTabClick" />
	<vg-select v-else class="vg-maintenances-tabs hidden-print"
        v-model="focusedTab"
        :options="options"
        :attributeValue="'name'"
        :clearable="false"
        size="lg"
        style="margin: 10px 0;"
        @input="onChangeFocusedTab">
		<template #option="{option}">
            <div style="display: flex;justify-content: flex-start;align-items: center;gap:5px;">
                <img :src="'static/assets/icone/custom/'+option.icon+'.svg'" style="width:25px;height:25px;"/> 
                <span>{{ option.label }}</span>
            </div>
        </template>
		<template #selected-option="{option}">
            <div style="display: flex;justify-content: flex-start;align-items: center;gap:5px;">
                <img :src="'static/assets/icone/custom/'+option.icon+'.svg'" style="width:25px;height:25px;"/> 
                <span>{{ option.label }}</span>
            </div>
        </template>
	</vg-select>
</template>
<script>
	import VgTabs from "src/components/Vg/VgTabs.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
    import FiltersMixins from "src/mixins/FiltersMixins.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-maintenances-tabs',
		components: {
			VgTabs,
            VgSelect
		},
        mixins: [FiltersMixins],
    	props: {
            value:{
                type: String,
                default: null
            }
        },
        data: function() {
            return {
                focusedTab: this.value || "encours"
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "supervisor": "A valider",
            "non-affecte": "non affectées",
            "mes-affectations": "Mes affectations",
            "mes-affectations-planifiees": "Planifiées",
            "mes-affectations-non-planifiees": "Non planifiées",
            "retour-a-faire": "Retour à faire",
            "toutes": "Toutes",
            "encours": "En cours",
            "resolue": "Clôturées",
            "urgent": "Urgentes",
            "bloquantes": "Bloquantes",
            "urgence": "urgence",
            "retourAFaire": "retours à faire",
            "relance": "relance"
        },
        "en": {
            "supervisor": "To validate",
            "non-affecte": "non affected",
            "mes-affectations": "My assignments",
            "mes-affectations-planifiees": "Planned",
            "mes-affectations-non-planifiees": "Unplanned",
            "retour-a-faire": "Return to do",
            "toutes": "All",
            "encours": "in progress",
            "resolue": "Closed",
            "urgent": "Urgent",
            "bloquantes": "Blocking",
            "urgence": "urgency",
            "retourAFaire": "returns to do",
            "relance": "relaunch"
        }
    }
},
		created: function(){
            this.FiltersMixins_page_name = "maintenances";
			if(this.FiltersMixins_getDefaultFocusedTab()) this.focusedTab = this.FiltersMixins_getDefaultFocusedTab();
		},
		mounted: function(){
			if(this.$app.role=="ROLE_SOUS_TRAITANT"){
                this.handleTabClick(this.mesAffectationsTab);
                this.$forceUpdate();
            }
		},
		methods: {
            onChangeFocusedTab: function(tabName){
                this.$emit("input", tabName);
                this.FiltersMixins_saveDefaultTab(tabName);
                this.$emit("focused", this.options.find((opt)=>opt.name == tabName));
            },
			handleTabClick: function(tab){
                this.$emit("input", tab.name);
                this.FiltersMixins_saveDefaultTab(tab.name);
				this.$emit("focused", tab);
			}
		},
        computed: {
			...mapGetters({
				getMaintenancesCounters: 'MaintenancesStore/getMaintenancesCounters'
            }),
			mesAffectationsTab:function(){
                return this.tabs.map((tab)=>{
                    return tab.name == "mesAffectations" ? tab : null;
                })[0];
            },
			tabs: function(){
                let tabs = [];
                // jutilise le isMobile pour séparer les tabs car ordonnencement
                // des tabs entre desktop et mobile sont différentes
                if(this.can("MAINTENANCES.TAB_EN_COURS")){
                    tabs.push({label: this.$t("encours"), name:"encours", counterName: "En_cours", counter: this.getMaintenancesCounters["En_cours"], filters:
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //field_affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}

                        }
                    });
                }
                if(this.$app.handleFmBloquante && this.can("MAINTENANCES.TAB_BLOQUANTES") ){
                    tabs.push({label: this.$t("bloquantes"), img: "static/assets/icone/maintenance-bloquante-red.svg", name:"bloquante",counterName: "bloquantes", isNotMobile:true, counter: this.getMaintenancesCounters["bloquantes"], filters:
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:true, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //field_affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                        }
                    });
                }
                if(this.can("MAINTENANCES.TAB_URGENTES") ){
                    tabs.push({label: this.$t("urgent"), name:"urgence",counterName: "urgence", isNotMobile:true, counter: this.getMaintenancesCounters["urgence"], filters:
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:true, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                        }
                    });
                }
                if(this.can("MAINTENANCES.TAB_NON_AFFECTES") ){
                    tabs.push({label: this.$t("non-affecte"), name:"nonAffecte",counterName: "nonAffectes", isNotMobile:true, counter: this.getMaintenancesCounters["nonAffectes"], filters:

                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:1, action:"is_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_null"}
                        }
                    });
                }
                if(this.can("MAINTENANCES.TAB_MES_AFFECTATIONS") ){
                    tabs.push({label: this.$t("mes-affectations"), name:"mesAffectations", counterName: "mesAffectations", counter: this.getMaintenancesCounters["mesAffectations"], filters:
                            // cas partiulier l'objet affecté arrive en dehors de la requête
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:1, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:this.$app.idUser, action:"is_not_null"}
                        }
                    });
                }
                if(this.can("MAINTENANCES.TAB_RETOUR_A_FAIRE") && this.$app.isFmRetourAFaire ){
                    tabs.push({label: this.$t("retourAFaire"), name:"retourAFaire",counterName: "retourAFaire", isNotMobile:true, counter: this.getMaintenancesCounters["isRetourAFaire"], filters:

                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:true, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                        }
                    });
                }
                if(this.can("MAINTENANCES.TAB_RELANCES") ){
                    tabs.push({label: this.$t("relance"), name:"relance", counterName: "isRelance", counter: this.getMaintenancesCounters["isRelance"], filters:

                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:true, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                        }
                    });
                }
                if(this.can("MAINTENANCES.TAB_SUPERVISOR") && this.$app.isFmClosedBySuperviseur){
                    tabs.push({label: this.$t("supervisor"), name:"supervisor",counterName: "Supervisor", isNotMobile:true, counter: this.getMaintenancesCounters["Supervisor"], filters: {
                        field_statut: {attr:"fm.statut",colId: "statut", value:"Supervisor", action:"equals"},
                        field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                        field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                        field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                        field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                        //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                        field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                    }});
                }
                if(this.can("MAINTENANCES.TAB_RESOLUES") ){
                    tabs.push({label: this.$t("resolue"), name:"resolue",counterName: "Resolue", isNotMobile:true, counter: this.getMaintenancesCounters["Resolue"], filters: {
                        field_statut: {attr:"fm.statut",colId: "statut", value:"Resolue", action:"equals"},
                        field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                        field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                        field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                        field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                        //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                        field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                    }});
                }
                if(this.can("MAINTENANCES.TAB_TOUTES") ){
                    tabs.push({label: this.$t("toutes"), name:"toutes",counterName: "all", isNotMobile:true, counter: this.getMaintenancesCounters["all"], filters:
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:null, action:"equals"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                        }
                    });
                }
                return tabs;
    		},
            options: function(){
                let tabs = [];
                /*{ value: "en-cours", label: "🚀 En cours", color: "text-blue-500", badgeColor: "bg-red-500" },
  { value: "planifiees", label: "📅 Planifiées", color: "text-green-500", badgeColor: "bg-green-500" },
  { value: "non-planifiees", label: "⏳ Non planifiées", color: "text-yellow-500", badgeColor: "bg-yellow-500" },
  { value: "bloquantes", label: "⛔ Bloquantes", color: "text-red-600", badgeColor: "bg-red-600" },
  { value: "urgentes", label: "⚡ Urgentes", color: "text-red-500", badgeColor: "bg-orange-500" },
  { value: "relance", label: "🔄 Relance", color: "text-purple-500", badgeColor: "bg-purple-500" },
  { value: "cloturee", label: "✅ Clôturée", color: "text-gray-500", badgeColor: "bg-gray-500" },
  { value: "toutes", label: "📂 Toutes", color: "text-black", badgeColor: "bg-black" },*/
                if(this.can("MAINTENANCES.TAB_EN_COURS")){
                    tabs.push({label: this.$t("encours"), name:"encours", icon: "rocket", counterName: "En_cours", counter: this.getMaintenancesCounters["En_cours"], filters:
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"},
                            field_affectationStartNull: {attr: "aff.start", value: null, action: "is_null"},
                            field_affectationEndNull: {attr: "aff.end", value: null, action: "is_null"}
                        }
                    });
                }
                if(this.can("MAINTENANCES.TAB_MES_AFFECTATIONS") ){
                    tabs.push({label: this.$t("mes-affectations-planifiees"), name:"mesAffectationsPlanifiees", 
                        icon: "calendar-days", counterName: "mesAffectations", filters:
                            // cas partiulier l'objet affecté arrive en dehors de la requête
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:1, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:this.$app.idUser, action:"is_not_null"},
                            field_affectationStartNull: {attr: "aff.start", value: null, action: "is_null"},
                            field_affectationEndNull: {attr: "aff.end", value: null, action: "is_null"}
                        }
                    });
                }
                if(this.can("MAINTENANCES.TAB_MES_AFFECTATIONS") ){
                    tabs.push({label: this.$t("mes-affectations-non-planifiees"), name:"mesAffectationsNonPlanifiees", 
                        icon: "hourglass", counter: this.getMaintenancesCounters["mesAffectationsNonPlanifiees"], counterName: "mesAffectationsNonPlanifiees", filters:
                            // cas partiulier l'objet affecté arrive en dehors de la requête
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:1, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:this.$app.idUser, action:"is_not_null"},
                            field_affectationStartNull: {attr: "aff.start", value: 1, action: "is_null"},
                            field_affectationEndNull: {attr: "aff.end", value: 1, action: "is_null"}
                        }
                    });
                }
                if(this.$app.handleFmBloquante && this.can("MAINTENANCES.TAB_BLOQUANTES") ){
                    tabs.push({label: this.$t("bloquantes"), img: "static/assets/icone/maintenance-bloquante-red.svg", name:"bloquante",
                        icon: "circle-minus", counterName: "bloquantes", isNotMobile:true, counter: this.getMaintenancesCounters["bloquantes"], filters:
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:true, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"},
                            field_affectationStartNull: {attr: "aff.start", value: null, action: "is_null"},
                            field_affectationEndNull: {attr: "aff.end", value: null, action: "is_null"}
                        }
                    });
                }
                if(this.can("MAINTENANCES.TAB_URGENTES") ){
                    tabs.push({label: this.$t("urgent"), name:"urgence",counterName: "urgence", 
                        icon: "zap", isNotMobile:false, counter: this.getMaintenancesCounters["urgence"], filters:
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:true, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"},
                            field_affectationStartNull: {attr: "aff.start", value: null, action: "is_null"},
                            field_affectationEndNull: {attr: "aff.end", value: null, action: "is_null"}
                        }
                    });
                }
                if(this.can("MAINTENANCES.TAB_RESOLUES") ){
                    tabs.push({label: this.$t("resolue"), name:"resolue",
                        icon: "check", counterName: "Resolue", isNotMobile:true, counter: this.getMaintenancesCounters["Resolue"], filters: {
                        field_statut: {attr:"fm.statut",colId: "statut", value:"Resolue", action:"equals"},
                        field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                        field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                        field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                        field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                        //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                        field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                    }});
                }
                if(this.can("MAINTENANCES.TAB_TOUTES") ){
                    tabs.push({label: this.$t("toutes"), name:"toutes",
                        icon: "folder-open", counterName: "all", isNotMobile:true, counter: this.getMaintenancesCounters["all"], filters:
                        {
                            field_statut: {attr:"fm.statut",colId: "statut", value:null, action:"equals"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                        }
                    });
                }
                return tabs;
            }
		}
    };

</script>
<style lang="scss" >
.vg-maintenances-tabs{
	width: 100%;
}
</style>
